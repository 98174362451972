import type { Tuple } from "@mantine/core";

export const DEFAULT_COLORS = {
	dark: [
		"#262C30",
		"#54646E",
		"#7B919D",
		"#96B0C0",
		"#B8CEDB",
		"#D7E4EE",
		"#FFF",
		"#FFF",
		"#FFF",
		"#FFF",
	],
	gray: [
		"#F2F2F2",
		"#EAEAEA",
		"#DEDEDE",
		"#C0C0C0",
		"#969696",
		"#5F5F5F",
		"#393939",
		"#1B1B1B",
		"#0D0D0D",
		"#000000",
	],
	purple: [
		"#8A00E5",
		"#8A00E5",
		"#8A00E5",
		"#8A00E5",
		"#8A00E5",
		"#8A00E5",
		"#8A00E5",
		"#641E8C",
		"#4D217A",
		"#1B1534",
	],
	blue: [
		"#001E52",
		"#00328C",
		"#0057C6",
		"#0084E1",
		"#1CACFE",
		"#6ADDFF",
		"#FFF",
		"#FFF",
		"#FFF",
		"#FFF",
	],
	green: [
		"#05371E",
		"#006C3B",
		"#099053",
		"#27B66D",
		"#14DA79",
		"#00FD79",
		"#FFF",
		"#FFF",
		"#FFF",
		"#FFF",
	],
	yellow: [
		"#786400",
		"#8C7500",
		"#C6AE00",
		"#E2D000",
		"#F6E600",
		"#FFF91F",
		"#FFF",
		"#FFF",
		"#FFF",
		"#FFF",
	],
	brown: [
		"#3D3535",
		"#564646",
		"#7D6766",
		"#8F7C7A",
		"#AF9E9C",
		"#CFC0BE",
		"#FFF",
		"#FFF",
		"#FFF",
		"#FFF",
	],
} as Record<string, Tuple<string, 10>>;
