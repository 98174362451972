import { Group, Text, ThemeIcon, UnstyledButton } from "@mantine/core";
import { useLiveQuery } from "dexie-react-hooks";
import { type ChatEntity, db } from "../db";

interface MainLinkProps {
	icon: React.ReactNode;
	color: string;
	label: string;
	chat: ChatEntity;
}

export function MainLink({ icon, color, label, chat }: Readonly<MainLinkProps>) {
	const firstMessage = useLiveQuery(async () => {
		return (await db.messages.orderBy("createdAt").toArray()).filter(
			(m) => m.chatId === chat.id,
		)[0];
	}, [chat]);

	return (
		<UnstyledButton
			sx={(theme) => ({
				// display: "block",
				width: "100%",
				padding: theme.spacing.xs,
				borderRadius: theme.radius.sm,
				color,
			})}
		>
			<Group>
				<ThemeIcon color={color} variant="dark">
					{icon}
				</ThemeIcon>
				<Text
					size="sm"
					style={{
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
						overflow: "hidden",
						flex: 1,
						width: 0,
					}}
				>
					<Text size="xs">
						<strong>{firstMessage?.content}</strong>
					</Text>
					<Text size="xs">
						{typeof firstMessage?.createdAt === "number"
							? new Intl.RelativeTimeFormat("en", { numeric: "auto" }).format(
								Math.round((firstMessage.createdAt - Date.now()) / (1000 * 60 * 60 * 24)),
								"day",
							) : typeof firstMessage?.createdAt === "object"
								? new Intl.RelativeTimeFormat("en", { numeric: "auto" }).format(
									Math.round(
										// @ts-ignore
										(firstMessage.createdAt?.getTime() - Date.now()) /
										(1000 * 60 * 60 * 24),
									),
									"day",
								)
								: firstMessage?.createdAt}
					</Text>
				</Text>
			</Group>
		</UnstyledButton>
	);
}
