import { createFileRoute } from '@tanstack/react-router'
import { DocumentsRoute } from '../../containers/DocumentsRoute'

export const Route = createFileRoute('/_layout/chats/$chatId')({
  component: DocumentsChat,
})

export default function DocumentsChat() {
  return <DocumentsRoute index="fsr" />
}
