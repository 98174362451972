import { useAccount, useMsal } from "@azure/msal-react";
import {
    Box,
    Text
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_SortingState,
    type MRT_Virtualizer,
    MantineReactTable,
    useMantineReactTable,
} from "mantine-react-table";
import {
    type UIEvent, useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import { useInfiniteQuery } from "react-query";
import { useTab } from "src/contexts/tabContext";
import { chatVectorIndex, ChatVectorIndexShorthand, type FSR } from "src/db";
import { BACKEND_URL } from "src/hooks/useMetadata";
import { getAPI, postAPI } from "src/utils/fetch";
import { metadataSAP } from "../../metadata-gen/metadata-sap";
import TopToolbarCustomActions from "./TopToolbarCustomActions";

function csvJSON(csv: string) {
    const lines = csv.split("\n");
    const result = [];
    const headers = lines[0].split(",");

    for (let i = 1; i < lines.length; i++) {
        const obj = {};
        const currentline = lines[i].split(",");

        for (let j = 0; j < headers.length; j++) {
            // @ts-ignore
            obj[headers[j]] = currentline[j];
        }

        result.push(obj);
    }

    return result;
}

export type SalesforceDocument = {
    _index: string;
    _id: string;
    _score: number;
    fields: {
        "Author(s)": string;
        "Machine No": string;
        "Customer Name": string;
        "Type of Activity": string;
        "Report Name": string;
        "Product Type": string;
        Location: string;
        Date: string;
        "Order No Internal": string;
        Project: string;
        Codeword: string;
    };
};

const metaSAPJson = csvJSON(metadataSAP);

export type MetadataDocument = {
    _index: string;
    _id: string;
    _score: number;
    fields: {
        "Author(s)": string;
        "Machine No": string;
        "Customer Name": string;
        "Type of Activity": string;
        report_name: string;
        "Product Type": string;
        Location: string;
        Date: string;
        "Order No Internal": string;
        Project: string;
        Codeword: string;
        DRAWING_NO?: string;
        DRAWING_TITLE?: string;
        source?: string;
    };
};

export type DrawingDocument = {
    _index: string;
    _id: string;
    _score: number;
    fields: {
        DRAWING_NO: string;
        DRAWING_TITLE: string;
        source: string;
    };
};

export type MindmapDocument = {
    _index: string;
    _id: string;
    _score: number;
    fields: {
        title: string;
        source: string;
        ingestion_date: string;
    };
}


// Define the columns for the Salesforce documents
const salesforceColumns: MRT_ColumnDef<SalesforceDocument>[] = [
    {
        accessorFn: (row) => row.fields?.["Customer Name"] || "N/A",
        header: "Account Name",
        Header: () => "Customer Name",
        filterFn: "includesString",
    },
    {
        accessorFn: (row) => row.fields?.Subject || "N/A",
        header: "Subject",
        filterFn: "includesString",
    },
    {
        accessorFn: (row) => row.fields?.["Case Number"] || "N/A",
        header: "Case Number",
        filterFn: "includesString",
    },
    {
        accessorFn: (row) => row.fields?.["Case Owner"] || "N/A",
        header: "Case Owner",
        filterFn: "includesString",
    },
    {
        accessorFn: (row) => row.fields?.["Date/Time Opened"] || "N/A",
        header: "Date/Time Opened",
        filterFn: "includesString",
    },
    {
        accessorFn: (row) => row.fields?.["Machine No"] || "N/A",
        header: "Machine No",
        filterFn: "includesString",
    },
    {
        accessorFn: (row) => row.fields?.["Product Type"] || "N/A",
        header: "Product Type",
        filterFn: "includesString",
    },
];

const fsrColumns: MRT_ColumnDef<MetadataDocument>[] = [
    {
        accessorFn: (originalRow) => {
            return originalRow.fields
                ? originalRow.fields.report_name || "N/A"
                : "N/A";
        },
        header: "Report Name",
        accessorKey: "report_name",
        filterFn: "includesString",
    },
    {
        accessorFn: (originalRow) => {
            if (!originalRow.fields) return "";
            if (!originalRow.fields) return "";
            const possibleMatch = metaSAPJson.filter(
                (row) => row.UNIT_NAME__C === originalRow.fields.Codeword,
            );
            const valsToAdd = [];
            possibleMatch?.forEach((match) => {
                valsToAdd.push(
                    ...[
                        match["CUSTOMER ACCOUNT"],
                        match["SAP NO"],
                        match["SAP ORIG SERIAL"],
                        match["SF ORIG SERIAL"],
                        match["SF SERIAL"],
                        match.SFID,
                        match.TRAIN,
                    ],
                );
            });
            return [originalRow.fields["Customer Name"] || "N/A", ...valsToAdd].join(
                " ",
            );
        },
        filterVariant: "autocomplete",
        enableFilterMatchHighlighting: true,
        enableClickToCopy: false,
        Cell: ({ row }) => (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                }}
            >
                <span>
                    {row.original.fields ? row.original.fields["Customer Name"] : ""}
                </span>
            </Box>
        ),
        header: "Customer Name",
        filterFn: "includesString",
    },
    {
        accessorFn: (originalRow) => {
            if (!originalRow.fields) return "";
            const possibleMatch = metaSAPJson.filter((row) =>
                [
                    row["SAP NO"],
                    row["SF ORIG SERIAL"],
                    row["SAP ORIG SERIAL"],
                    row["SF SERIAL"],
                ].includes(originalRow.fields["Machine No"]),
            );
            const valsToAdd = [];
            possibleMatch?.forEach((match) => {
                valsToAdd.push(
                    ...[
                        match["CUSTOMER ACCOUNT"],
                        match["SAP NO"],
                        match["SAP ORIG SERIAL"],
                        match["SF ORIG SERIAL"],
                        match["SF SERIAL"],
                        match.SFID,
                        match.TRAIN,
                    ],
                );
            });
            return [originalRow.fields["Machine No"] || "N/A", ...valsToAdd].join(
                " ",
            );
        },
        header: "Machine No",
        filterFn: "includesString",
        Cell: ({ row }) => (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                }}
            >
                <span>
                    {row.original.fields ? row.original.fields["Machine No"] : ""}
                </span>
            </Box>
        ),
    },
    {
        accessorFn: (originalRow) => {
            if (!originalRow.fields) return "";
            const dateValue = originalRow.fields?.Date
                ? new Date(originalRow.fields.Date)
                : new Date("2000-01-01T00:00:00.000Z");
            if (dateValue) {
                dateValue.setHours(0, 0, 0, 0);
            }
            return dateValue;
        },
        header: "Date",
        filterVariant: "date-range",
        sortingFn: "datetime",
        Cell: ({ cell }) => {
            const dateValue = cell.getValue<Date>();
            if (
                dateValue &&
                dateValue.getTime() !== new Date("2000-01-01T00:00:00.000Z").getTime()
            ) {
                return dateValue.toLocaleDateString(undefined, {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                });
            }
            return "N/A";
        },
        Header: ({ column }) => <em>{column.columnDef.header}</em>,
    },
    {
        accessorFn: (originalRow) =>
            originalRow.fields
                ? originalRow.fields["Type of Activity"] || "N/A"
                : "N/A",
        header: "Type of Activity",
        filterFn: "includesString",
    },
    {
        accessorFn: (originalRow) =>
            originalRow.fields ? originalRow.fields.Codeword || "N/A" : "N/A",
        header: "Codeword",
        filterFn: "includesString",
        size: 300,
    },
    {
        accessorFn: (originalRow) =>
            originalRow.fields ? originalRow.fields["Product Type"] || "N/A" : "N/A",
        header: "Product Type",
        filterFn: "includesString",
    },
    {
        accessorFn: (originalRow) =>
            originalRow.fields ? originalRow.fields.Location || "N/A" : "N/A",
        header: "Location",
        filterFn: "includesString",
    },
    {
        accessorFn: (originalRow) =>
            originalRow.fields ? originalRow.fields["Author(s)"] || "N/A" : "N/A",
        header: "Author(s)",
        filterFn: "includesString",
        size: 150,
    },
    {
        accessorFn: (originalRow) =>
            originalRow.fields ? originalRow.fields.Project || "N/A" : "N/A",
        header: "Project",
        filterFn: "includesString",
    },
    {
        accessorFn: (originalRow) =>
            originalRow.fields
                ? originalRow.fields["Order No Internal"] || "N/A"
                : "N/A",
        header: "Order No Internal",
        filterFn: "includesString",
    },
];

const drawingsColumns: MRT_ColumnDef<DrawingDocument>[] = [
    {
        header: "Drawing No",
        accessorKey: "DRAWING_NO",
        // enableColumnFilter: false,
        accessorFn: (originalRow) =>
            originalRow.fields ? originalRow.fields.DRAWING_NO : "N/A",
    },
    {
        header: "Drawing Title",
        accessorKey: "DRAWING_TITLE",
        // enableColumnFilter: false,
        filterFn: "includesString",
        accessorFn: (originalRow) =>
            originalRow.fields ? originalRow.fields.DRAWING_TITLE : "N/A",
    },
    {
        header: "Source",
        accessorKey: "source",
        // enableColumnFilter: false,
        filterFn: "includesString",
        accessorFn: (originalRow) =>
            originalRow.fields ? originalRow.fields.source : "N/A",
    },
]

const mindmapsColumns: MRT_ColumnDef<MetadataDocument>[] = [
    {
        header: "ID",
        // enableColumnFilter: false,
        accessorFn: (originalRow) => originalRow._id?.replace(/\.json_\d+_\d+$/, '.json'),
    },
    {
        header: "Title",
        // enableColumnFilter: false,
        accessorFn: (originalRow) => originalRow.fields ? originalRow.fields.title : "N/A"
    },
    {
        header: "Source",
        // enableColumnFilter: false,
        filterFn: "includesString",
        accessorFn: (originalRow) =>
            originalRow.fields ? originalRow.fields.source : "N/A",
    },
    {
        header: "Ingestion Date",
        // enableColumnFilter: false,
        filterFn: "includesString",
        accessorFn: (originalRow) =>
            originalRow.fields ? originalRow.fields.ingestion_date : "N/A",
    },
]

export type ReportSchema = {
    _index: string;
    _id: string;
    _score: number;
    _source: {
        metadata: {
            "Customer Name": string;
            "Machine Number": string;
            "Product Type": string;
        }
    }
}

const rdsColumns: MRT_ColumnDef<ReportSchema>[] = [
    {
        header: "Customer Name",
        accessorFn: (originalRow) =>
            originalRow._source.metadata["Customer Name"] || "N/A",
    },
    {
        header: "Machine Number",
        accessorFn: (originalRow) =>
            originalRow._source.metadata["Machine Number"] || "N/A",
    },
    {
        header: "Product Type",
        accessorFn: (originalRow) =>
            originalRow._source.metadata["Product Type"] || "N/A",
    },
]
const columnsMap: Record<ChatVectorIndexShorthand, MRT_ColumnDef<SalesforceDocument | MetadataDocument | DrawingDocument>[]> = {
    fsr: fsrColumns as MRT_ColumnDef<SalesforceDocument>[],
    sf: salesforceColumns as MRT_ColumnDef<MetadataDocument>[],
    drawings: drawingsColumns as MRT_ColumnDef<DrawingDocument>[],
    mindmaps: mindmapsColumns as MRT_ColumnDef<MetadataDocument>[],
    rds: rdsColumns as MRT_ColumnDef<SalesforceDocument>[],
}

const MetadataDocuments = ({ index }: { index: ChatVectorIndexShorthand }) => {
    const columns = useMemo(
        // () => (index === "fsr" ? fsrColumns : salesforceColumns),
        () => columnsMap[index] ?? [],
        [index],
    );

    const rowVirtualizerInstanceRef =
        useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);

    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
        [],
    );

    const [globalFilter, setGlobalFilter] = useState<string>();

    useEffect(() => {
        // Reset all filters when the index prop changes
        setColumnFilters([]);
        setGlobalFilter(undefined);
        setSorting([]);
        setColumnFilters([]);

        // Reset scroll position
        if (rowVirtualizerInstanceRef.current) {
            rowVirtualizerInstanceRef.current.scrollToIndex(0);
        }
    }, [index]);

    const { instance } = useMsal();
    const account = useAccount();
    const apiKey = account?.idToken;

    const [scrollId, setScrollId] = useState<string | null>(null);

    const { data, fetchNextPage, isFetching, isLoading } =
        useInfiniteQuery({
            queryKey: [
                "metadata-documents",
                index,
                sorting,
                columnFilters,
                globalFilter,
            ],
            queryFn: async ({ pageParam = null }) => {
                const PATH = `v1/search/${chatVectorIndex?.find(ind => ind.shorthand === index)?.value[0]}/metadata/docs?max_result=100`;

                let response;
                if (pageParam) {
                    const url = new URL("v1/search/scroll", BACKEND_URL);
                    url.searchParams.set("_scroll_id", pageParam);
                    response = await getAPI({ url: url.toString(), apiKey, instance });
                } else if (globalFilter) {
                    const url = new URL("v1/search/text", BACKEND_URL);
                    response = await postAPI({
                        url: url.toString(),
                        apiKey,
                        instance,
                        payload: {
                            query: globalFilter,
                            metadata_filter:
                                columnFilters?.filter(val => Array.isArray(val.value) ? val.value.filter(Boolean).length > 0 : Boolean(val.value)).map((filter) => ({
                                    key: filter.id,
                                    value: filter.value,
                                })) ?? [],
                        },
                    });
                } else {
                    const url = new URL(PATH, BACKEND_URL);
                    // Reset scroll_id and add filters for the first request
                    response = await postAPI({
                        url: url.toString(),
                        apiKey,
                        instance,
                        payload:
                            columnFilters?.filter(val => Array.isArray(val.value) ? val.value.filter(Boolean).length > 0 : Boolean(val.value)).map((filter) => ({
                                key: filter.id,
                                value: filter.value,
                            })) ?? [],
                    });
                }
                const data = await response.json();
                setScrollId(data._scroll_id);
                return data;
            },
            getNextPageParam: () => scrollId,
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            renderDetailPanel: ({ row }) => index === "drawings" ? (
                <Box
                    sx={{
                        display: 'grid',
                        margin: 'auto',
                        gridTemplateColumns: '1fr 1fr',
                        width: '100%',
                    }}
                >
                    <Text>Address: {row.original.address}</Text>
                    <Text>City: {row.original.city}</Text>
                    <Text>State: {row.original.state}</Text>
                    <Text>Country: {row.original.country}</Text>
                </Box>
            ) : null,
            positionExpandColumn: "first"

        });

    const flatData = useMemo(
        () =>
            data?.pages.flatMap((page) =>
                page.hits.hits.map((doc) => ({
                    ...doc,
                    fields: doc._source.metadata,
                })),
            ) ?? [],
        [data],
    );

    const totalDBRowCount = data?.pages?.[0]?.hits?.total?.value ?? 0;

    const fetchMoreOnBottomReached = useCallback(
        (containerRefElement?: HTMLDivElement | null) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                if (
                    scrollHeight - scrollTop - clientHeight < 400 &&
                    !isFetching &&
                    flatData.length < totalDBRowCount
                ) {
                    fetchNextPage();
                }
            }
        },
        [fetchNextPage, isFetching, flatData.length, totalDBRowCount],
    );

    useEffect(() => {
        if (rowVirtualizerInstanceRef.current) {
            try {
                rowVirtualizerInstanceRef.current.scrollToIndex(0);
            } catch (e) {
                console.error(e);
            }
        }
    }, [sorting]);

    useEffect(() => {
        fetchMoreOnBottomReached(tableContainerRef.current);
    }, [fetchMoreOnBottomReached]);

    const [opened] = useDisclosure(false);
    const { setSelectedTab } = useTab();
    useEffect(() => {
        setSelectedTab("field_service_reports");
    }, [opened]);

    const selectedQuestions = [];
    const [rowVirtualizerRef, setRowVirtualizerRef] = useState<MRT_Virtualizer<
        HTMLDivElement,
        HTMLTableRowElement
    > | null>(null);
    const tableContainerRef = useRef<HTMLDivElement>(null); //we can get access to the underlying TableContainer element and react to its scroll events

    const table = useMantineReactTable({
        columns,
        data: flatData ?? [],
        enableColumnOrdering: true,
        enablePinning: true,
        enableBottomToolbar: false,
        renderToolbarAlertBannerContent: () => <></>,
        enableColumnResizing: true,
        enableColumnFilterModes: false,
        filterFn: "includesString",
        enablePagination: false,
        enableRowNumbers: true,
        enableClickToCopy: true,
        getRowId: (row) => {
            if (!row.fields) return undefined;
            return `${row.fields.report_name}_${row._id}`;
        },
        renderTopToolbarCustomActions: () => <TopToolbarCustomActions
            table={table}
            index={index}
            data={data}
            columnFilters={columnFilters}
        />,
        mantineToolbarAlertBannerProps: {
            style: {
                display: "none",
                background: "#f0f0f0",
                fontWeight: "bold",
            },
        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        enableSelectAll: false,
        selectAllMode: "page",
        enableGrouping: true,
        enableRowVirtualization: true,
        enableDensityToggle: false,
        mantineTableContainerProps: {
            ref: tableContainerRef, //get access to the table container element
            sx: { padding: 0, borderRadius: 0, maxHeight: "80vh" },
            onScroll: (
                event: UIEvent<HTMLDivElement>, //add an event listener to the table container element
            ) => fetchMoreOnBottomReached(event.target as HTMLDivElement),
        },
        mantinePaperProps: { sx: { borderRadius: 0, zIndex: 102 } },
        onSortingChange: setSorting,
        state: { isLoading, sorting },
        initialState: {
            showGlobalFilter: true,
            density: "xs",
            showColumnFilters: true,
        },
        rowVirtualizerInstanceRef: (instance) => {
            setRowVirtualizerRef(instance);
        },
        rowVirtualizerProps: {
            overscan: 10,
            onScroll: (event) => {
                const { scrollOffset, scrollHeight, clientHeight } = event;
                if (scrollOffset + clientHeight >= scrollHeight - 200) {
                    // Load more data when user scrolls to bottom
                    // Implement your data fetching logic here
                    console.log("Load more data");
                }
            },
        },
    });

    useEffect(() => {
        if (rowVirtualizerRef) {
            rowVirtualizerRef.scrollToIndex(0);
        }
    }, [sorting, rowVirtualizerRef]);

    return <MantineReactTable table={table} />;
};

export default MetadataDocuments;
