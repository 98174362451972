import { MantineProvider } from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'
import { QueryClient } from 'react-query'
import { SE_THEME } from 'src/styles/se-energy.theme'

export const Route = createRootRouteWithContext<{
    queryClient: QueryClient
}>()({
    component: RootComponent,
})

const environment = import.meta.env.VITE_ENVIRONMENT;

export function RootComponent() {
    return (
        <MantineProvider
            withGlobalStyles
            withNormalizeCSS
            withCSSVariables
            theme={{
                ...SE_THEME,
            }}
        >
            <Outlet />
            {environment && environment === "local" ? <TanStackRouterDevtools position="bottom-right" /> : null}
            <Notifications />
        </MantineProvider>
    )
}