import type { IPublicClientApplication } from "@azure/msal-browser";
import * as Sentry from "@sentry/react";
import { v4 as uuidv4 } from "uuid";
import { loginRequest } from "./authConfig";

type FetchAPIParams = {
	url: string;
	method: "GET" | "POST" | "PUT" | "DELETE";
	payload: any;
	apiKey?: string;
	instance: IPublicClientApplication;
};

const createHeaders = (apiKey?: string) => {
	if (!apiKey) {
		throw new Error("No API key provided");
	}
	const transactionId = uuidv4();

	Sentry.withScope((scope) => {
		scope.setTag("transaction_id", transactionId);
	});
	return {
		"Content-Type": "application/json",
		Authorization: `Bearer ${apiKey}`,
		"X-Transaction-ID": transactionId,
	};
};
const handleResponse = async (
	response: Response,
	instance: IPublicClientApplication,
	options: RequestInit,
) => {
	if (!response.ok) {
		if (response.status === 401) {
			await instance.acquireTokenSilent({
				scopes: [],
			});
			await instance.loginRedirect(loginRequest());
			response = await fetch(response.url, options);
		} else {
			throw new Error(`HTTP error! status: ${response.status}`);
		}
	}
	return response;
};

export const postAPI = async ({
	url,
	payload,
	apiKey,
	instance,
}: Omit<FetchAPIParams, "method">) => {
	const headers = createHeaders(apiKey);
	const options = {
		headers,
		method: "POST",
		body: JSON.stringify(payload),
	};

	const response = await fetch(url, options);
	return handleResponse(response, instance, options);
};

export const getAPI = async ({
	url,
	apiKey,
	instance,
}: Omit<FetchAPIParams, "method" | "payload">) => {
	const headers = createHeaders(apiKey);
	const options = {
		headers,
		method: "GET",
	};

	const response = await fetch(url, options);
	return handleResponse(response, instance, options);
};
