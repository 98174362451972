import {
  ActionIcon,
  Alert,
  Anchor,
  Avatar,
  Box,
  Button,
  Flex,
  Group,
  Menu,
  Modal,
  Table,
  Text,
  Textarea,
  Title,
  rem,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  IconAlertCircle,
  IconDotsVertical,
  IconFileDislike,
  IconFileLike,
  IconTrash,
} from "@tabler/icons-react";
import { useEffect, useMemo, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "property-information";
import { useAccount, useMsal } from "@azure/msal-react";
import { type MessageEntity, db } from "../db";
import { DeleteMessageEntityItemModal } from "./DeleteChatMessageItemModal";
import { LogoIcon } from "./Logo";
import { MessageItemCode } from "./MessageItemCode";
import "../styles/markdown.scss";
import type { IPublicClientApplication } from "@azure/msal-browser";
import { notifications } from "@mantine/notifications";
import * as Sentry from "@sentry/react";
import { useLiveQuery } from "dexie-react-hooks";
import { loginRequest } from "src/utils/authConfig";
import { useAdmin } from "src/utils/useAdmin";
import { v4 as uuidv4 } from "uuid";
import { CHAT_COMPLETIONS_URL } from "../hooks/useChatCompletion";
import { AsyncPdfViewer } from "./TopToolbarCustomActions";
import { PdfFocusProvider } from "@llamaindex/pdf-viewer";
import { useFeature } from "flagged";

export const MDTable = ({ node, ...props }) => (
  <Table
    striped
    highlightOnHover
    fontSize="xs"
    verticalSpacing="xs"
    horizontalSpacing="xs"
    {...props}
  />
);

export const MDCode = ({ node, ...props }) => <MessageItemCode {...props} />;

export const openPdfFromUrl = async (
  pdfUrl: string,
  apiKey: string,
  instance: any,
) => {
  try {
    const token = `Bearer ${apiKey}`;
    const fetchResponse = await fetch(pdfUrl, {
      headers: {
        Authorization: token,
        Accept: "application/pdf",
      },
    });

    if (fetchResponse.status === 201) {
      const url = await fetchResponse.text();
      const newWindow = window.open(url, "_blank", "");
      if (newWindow) {
        newWindow.onload = () => {
          URL.revokeObjectURL(url);
        };
      } else {
        // Try opening a new tab differently from window.open
        const a = document.createElement("a");
        a.href = url;
        a.target = "_blank";
        a.rel = "noopener noreferrer";
        a.click();
      }
    } else {
      await instance.loginRedirect(loginRequest());
      notifications.show({
        title: "Error opening PDF",
        message: `Could not open PDF from URL: ${pdfUrl}`,
        color: "red",
      });
      throw new Error(`Could not open PDF from URL: ${pdfUrl}`);
    }
  } catch (error) {
    console.error(`Error opening PDF from URL: ${pdfUrl}`, error);
  }
};

export const getPdfUrlForInlineView = async (
  pdfUrl: string,
  apiKey: string,
  instance: IPublicClientApplication,
): Promise<string | null> => {
  try {
    const token = `Bearer ${apiKey}`;
    const fetchResponse = await fetch(pdfUrl, {
      headers: {
        Authorization: token,
        Accept: "application/pdf",
      },
    });

    if (fetchResponse.status === 201) {
      return fetchResponse.text();
    }
    await instance.loginRedirect(loginRequest());
    notifications.show({
      title: "Error opening PDF",
      message: `Could not open PDF from URL: ${pdfUrl}`,
      color: "red",
    });
    return null;
  } catch (error) {
    console.error(`Error getting PDF URL for inline view: ${pdfUrl}`, error);
    return null;
  }
};

export const MDA = ({ node, ...props }) => {
  const { instance } = useMsal();
  const account = useAccount();
  const apiKey = account?.idToken;
  const openPDFModal = useFeature("OPEN_PDF_MODAL");
  const [opened, { open, close }] = useDisclosure(false);

  if (props.href?.endsWith(".pdf") || props.href?.endsWith(".html")) {
    const hrefWithoutSandbox = props.href
      .replace("sandbox:", "")
      .replace("http://example.com", "")
      .replace("https://example.com", "");
    const decodedHref = decodeURIComponent(hrefWithoutSandbox);
    const fullUrl = `${CHAT_COMPLETIONS_URL}${decodedHref.startsWith("/") ? "" : "/"}${decodedHref}`;
    return (<>
      <Modal opened={opened} onClose={close} withCloseButton={false} size="90vw">
        <MDAWithPdfPreview
          node={node}
          href={props.href}
          instance={instance}
          apiKey={apiKey}
          {...props}
        />
      </Modal>
      <a
        target="_blank"
        href={fullUrl}
        onClick={(e) => {
          if (openPDFModal) open();
          else openPdfFromUrl(fullUrl, apiKey, instance);
          e.preventDefault();
        }}
        rel="noreferrer"
      >
        {props.children}
      </a>
    </>);
  }
  return (
    <a target="_blank" href={props.href} {...props}>
      {props.children}
    </a>
  );
};
export const MDAWithPdfPreview = ({ node, ...props }) => {
  // Get the instance and apiKey from the useMsal and useAccount hooks
  const { instance } = useMsal();
  const account = useAccount();
  const apiKey = account?.idToken;

  if (props.href?.endsWith(".pdf")) {
    const hrefWithoutSandbox = props.href
      .replace("sandbox:", "")
      .replace("http://example.com", "")
      .replace("https://example.com", "");
    const decodedHref = decodeURIComponent(hrefWithoutSandbox);
    const fullUrl = `${CHAT_COMPLETIONS_URL}${decodedHref.startsWith("/") ? "" : "/"}${decodedHref}`;
    return (
      <Flex gap="md" align="center">
        {/* Render the AsyncPdfViewer component */}
        <AsyncPdfViewer
          pdfUrl={fullUrl}
          apiKey={apiKey}
          row={decodedHref.split("object_name=")[1] || ""} // Assuming the file name is the last part of the URL
          instance={instance}
        />
      </Flex>
    );
  } else if (props.href?.endsWith(".html")) {
    const hrefWithoutSandbox = props.href
      .replace("sandbox:", "")
      .replace("http://example.com", "")
      .replace("https://example.com", "");
    const decodedHref = decodeURIComponent(hrefWithoutSandbox);
    const fullUrl = `${CHAT_COMPLETIONS_URL}${decodedHref.startsWith("/") ? "" : "/"}${decodedHref}`;
    return (
      <a
        target="_blank"
        href={fullUrl}
        rel="noreferrer"
      >
        {props.children}
      </a>
    );
  }
  return (
    <a target="_blank" href={props.href} {...props}>
      {props.children}
    </a>
  );
};

export function isAllCaps(str: string): boolean {
  return str === str.toUpperCase();
}

export const renderStructuredRequest = (
  data: string | Record<any, any>,
): React.ReactNode => {
  const renderObject = (obj: Record<any, any>) => (
    <div>
      <pre>{JSON.stringify(obj, null, 2)}</pre>
    </div>
  );

  const renderArray = (arr: any[]) => (
    <ul>
      {arr.map((item) => (
        <li key={item}>{renderStructuredRequest(item)}</li>
      ))}
    </ul>
  );

  try {
    if (!data) {
      return null;
    }

    if (typeof data === "string") {
      let parsedData;
      try {
        parsedData = JSON.parse(data);
      } catch (error) {
        return <span>{data}</span>; // Return original string if parsing fails
      }

      if (Array.isArray(parsedData)) {
        return renderArray(parsedData);
      }
      if (
        typeof parsedData === "object" &&
        parsedData !== null &&
        Object.keys(parsedData).length > 0
      ) {
        return renderObject(parsedData);
      }
      return <span>{data}</span>; // Fallback for parsed data that is neither array nor object
    }
    if (typeof data === "object" && data !== null) {
      return renderObject(data);
    }
    return <span>{JSON.stringify(data)}</span>; // Fallback for non-string, non-object data types
  } catch (error) {
    return <span>Error rendering data</span>;
  }
};

export const sendFeedbackToApi = async (
  apiKey: string | undefined,
  responseId: string,
  feedbackType: string,
  feedback: string,
) => {
  const transactionId = uuidv4();

  Sentry.withScope((scope) => {
    scope.setTag("transaction_id", transactionId);
  });

  const response = await fetch(`${CHAT_COMPLETIONS_URL}/v1/feedback/upsert`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${apiKey}`,
      "X-Transaction-ID": transactionId,
    },
    method: "POST",
    body: JSON.stringify({
      response_id: responseId,
      feedback_type: feedbackType,
      comments: feedback,
    }),
  });
  // Do the some handling for the response that was being done in the SSE event listeners
  const res = await response.json();
  if (response.status !== 200) {
    notifications.show({
      color: "red",
      title: "Sorry, Feedback failed to be sent!",
      message: res.detail ?? "Please try again later",
    });
  } else {
    notifications.show({
      color: "green",
      title: "Feedback sent successfully!",
      message: "Thanks for sharing, we'd keep improving!",
    });
  }
};

interface AnswerProps {
  content: string;
  index: number;
  answers: string[];
  isAssistant: boolean;
  message: MessageEntity;
  question: MessageEntity | null;
  retry: (newMessage: string) => Promise<void>;
  isAdmin: boolean;
  wordCount: number;
  showOldword: boolean;
  nextAssistantMessage: MessageEntity | undefined;
}

const Answer = ({
  content,
  index,
  answers,
  isAssistant,
  message,
  question,
  retry,
  isAdmin,
  wordCount,
  showOldword,
  nextAssistantMessage,
}: AnswerProps) => {
  return (
    <>
      {answers.length > 1 && content && isAssistant && !message.hasError ? (
        <Title order={6}>
          {index === 0 ? "CTS Knowledge Base" : "Public data"}
        </Title>
      ) : null}
      {index === 0 && message.hasError ? (
        <Alert
          data-testid="error-alert"
          mt="xs"
          mb="md"
          variant="light"
          color="red"
          title="Error happened"
        >
          {message.error ?? message.detail}Please try again sending this message{" "}
          <Button
            variant="light"
            c="white"
            data-testid="retry-button"
            size="xs"
            onClick={() => retry(decodeURIComponent(question?.content ?? ""))}
          >
            Try Again
          </Button>
        </Alert>
      ) : (
        !message.done &&
        typeof nextAssistantMessage?.content === "undefined" &&
        !message.content &&
        !message.hasError && <></>
      )}
      <Box
        sx={{ flex: 1, alignSelf: "center" }}
        className="markdown"
        data-testid="markdown"
      >
        <PdfFocusProvider>
          {<ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
              table: MDTable,
              code: MDCode,
              // a: message?.chatId?.toString().startsWith("drawings_") ? MDAWithPdfPreview : MDA,
              a: MDA,
            }}
          >
            {content}
          </ReactMarkdown>}
        </PdfFocusProvider>
      </Box>
    </>
  );
}

export const AnswersToBeRendered = ({
  answers,
  isAssistant,
  message,
  question,
  retry,
  isAdmin,
  wordCount,
  showOldword,
  nextAssistantMessage,
}: {
  answers: string[];
  isAssistant: boolean;
  message: MessageEntity;
  question: MessageEntity | null;
  retry: (newMessage: string) => Promise<void>;
  isAdmin: boolean;
  wordCount: number;
  showOldword: boolean;
  nextAssistantMessage: MessageEntity | undefined;
}) => {
  return (
    <>
      {answers.map((content, index) => (
        <Answer
          key={`answer-${message.responseId}-${message.id}`}
          content={content}
          index={index}
          answers={answers}
          isAssistant={isAssistant}
          message={message}
          question={question}
          retry={retry}
          isAdmin={isAdmin}
          wordCount={wordCount}
          showOldword={showOldword}
          nextAssistantMessage={nextAssistantMessage}
        />
      ))}
    </>
  );
};
export const MessageItem = function MessageItem({
  message,
  nextAssistantMessage,
  retry,
  sendFeedbackToApi,
}: {
  sendFeedbackToApi: (
    apiKey: string | undefined,
    responseId: string,
    feedbackType: string,
    feedback: string,
  ) => Promise<void>;
  message: MessageEntity;
  nextAssistantMessage?: MessageEntity;
  retry: (newMessage: string) => Promise<void>;
}) {
  const isAdmin = useAdmin();
  const [deleteOpened, { open: openDelete, close: closeDelete }] =
    useDisclosure(false);
  const account = useAccount();
  const apiKey = account?.idToken;

  useEffect(() => {
    document.querySelector(".mantine-AppShell-main")?.scrollIntoView(false)
  }, [])

  const question = useLiveQuery(async () => {
    if (!message.repliedId) {
      return null;
    }
    const result = await db.messages.get(message.repliedId);
    return result;
  }, [message.repliedId]);

  const wordCount = useMemo(() => {
    if (typeof message.content !== "string") return 0;
    const matches = message.content?.match(/[\w'-()]+/gi);
    return matches ? matches.length : 0;
  }, [message.content]);

  const [modalOpened, { open: openModal, close: closeModal }] =
    useDisclosure(false);
  const [feedback, setFeedback] = useState<string | null>(null);
  const [feedbackType, setFeedbackType] = useState<"positive" | "negative">();

  const showOldword =
    message?.suggestions &&
    message?.suggestions?.length > 0 &&
    message.suggestions[0]?.oldWord;
  const isCaptialCase =
    showOldword && isAllCaps(message?.suggestions[0]?.oldWord);
  const isAssistant = message.role === "assistant";
  const answers = [message?.content, message?.pretrained?.answer].filter(
    (c) => Boolean(c) && c !== "N/A",
  );

  const [isSendingFeedback, setIsSendingFeedback] = useState(false);
  const sendFeedback = async (
    type: "negative" | "positive",
    defaultFeedback: string,
  ) => {
    if (!isSendingFeedback) {
      setIsSendingFeedback(true);
      setFeedbackType(type);
      setFeedback(defaultFeedback);
      if (type === "positive") {
        await sendFeedbackToApi(
          apiKey,
          message.responseId,
          "positive",
          defaultFeedback,
        );
        closeModal();
      } else {
        openModal();
      }
      setIsSendingFeedback(false);
    }
  };
  if (answers.length === 0) return;

  return (
    <>
      {showOldword ? (
        <Alert
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark" ? theme.colors.dark[6] : "#fff",
          })}
          icon={<IconAlertCircle size="1rem" />}
          title={
            <Anchor
              onClick={() => {
                retry(
                  decodeURIComponent(question?.content ?? "").replace(
                    message?.suggestions?.[0]?.oldWord ?? "",
                    message?.suggestions?.[0]?.text ?? "",
                  ),
                );
              }}
            >
              <Title display="flex" order={6} color="blue">
                <Text fw="400">Search for </Text>{" "}
                <Text
                  mx=".25rem"
                  transform={isCaptialCase ? "uppercase" : "none"}
                >
                  {message.suggestions[0]?.text}
                </Text>{" "}
                instead of{" "}
                <Text mx=".25rem">{message.suggestions[0]?.oldWord}</Text> ?
              </Title>
            </Anchor>
          }
          radius="xs"
          withCloseButton
          data-testid="error-alert"
        />
      ) : (
        <Flex
          className="message-item"
          gap="sm"
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === "dark" ? theme.colors.dark[6] : "#fff",
          })}
        >
          {message.role === "user" && (
            <Avatar color="blue" radius="xl" data-testid="user-avatar">
              <Text c="blue.5">
                {account?.name
                  ?.split(" ")
                  .slice(0, 2)
                  .map((name) => name[0])
                  .reverse()
                  .join("")}
              </Text>
            </Avatar>
          )}
          {isAssistant && (
            <div data-testid="assistant-logo">
              <LogoIcon style={{ height: 32, width: 32 }} />
            </div>
          )}

          <Box
            sx={{ flex: 1, alignSelf: "center", position: "relative" }}
            className="markdown"
            data-testid="message-content"
          >
            {!showOldword ? (
              <AnswersToBeRendered
                answers={answers}
                isAssistant={isAssistant}
                message={message}
                question={question}
                retry={retry}
                isAdmin={isAdmin}
                wordCount={wordCount}
                showOldword={showOldword}
                nextAssistantMessage={nextAssistantMessage}
              />
            ) : null}
            {isAssistant /*&& !feedbackSent*/ ? (
              <Group mb="md" sx={{ justifyContent: "end" }}>
                <Text variant="gradient">Did we answer your question ?</Text>
                <ActionIcon
                  color="green.3"
                  variant="subtle"
                  size="lg"
                  aria-label="Like"
                  sx={{ cursor: "pointer" }}
                  onClick={() =>
                    sendFeedback("positive", "You Answered my question!")
                  }
                  data-testid="like-menu-item"
                >
                  <IconFileLike style={{ width: rem(20) }} stroke={1.5} />
                </ActionIcon>
                <ActionIcon
                  variant="subtle"
                  color="red"
                  size="lg"
                  aria-label="Like"
                  sx={{ cursor: "pointer" }}
                  onClick={() =>
                    sendFeedback(
                      "negative",
                      "This didn't answer my question, I expected: ....",
                    )
                  }
                  data-testid="dislike-menu-item"
                >
                  <IconFileDislike style={{ width: rem(20) }} stroke={1.5} />
                </ActionIcon>
              </Group>
            ) : null}
          </Box>
          {isAssistant ? (
            <Menu
              position="left"
              shadow="md"
              width={200}
              data-testid="assistant-menu"
            >
              <Menu.Target>
                <ActionIcon data-testid="assistant-menu-target">
                  <IconDotsVertical size={18} />
                </ActionIcon>
              </Menu.Target>

              <Menu.Dropdown data-testid="assistant-menu-dropdown">
                <Menu.Item
                  icon={<IconFileLike size={14} />}
                  onClick={() => {
                    setFeedbackType("positive");
                    openModal();
                  }}
                  data-testid="inside-like-menu-item"
                >
                  Like
                </Menu.Item>
                <Menu.Item
                  icon={<IconFileDislike size={14} />}
                  onClick={() => {
                    setFeedbackType("negative");
                    openModal();
                  }}
                  data-testid="inside-dislike-menu-item"
                >
                  Dislike
                </Menu.Item>
                <Menu.Item
                  color="red"
                  icon={<IconTrash size={14} />}
                  data-testid="delete-menu-item"
                  onClick={openDelete}
                >
                  Delete
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          ) : null}
          <DeleteMessageEntityItemModal
            message={message}
            isOpen={deleteOpened}
            close={closeDelete}
          />
          <Modal
            size="lg"
            opened={modalOpened}
            onClose={closeModal}
            title="Write your feedback"
            data-testid="feedback-modal"
          >
            <Group align="flex-end">
              <Textarea
                placeholder="Enter your feedback"
                value={feedback ?? ""}
                onChange={(e) => setFeedback(e.target.value)}
                style={{ width: "100%" }}
                minRows={4}
                data-testid="feedback-textarea"
              />
              <Button
                onClick={() =>
                  sendFeedbackToApi(
                    apiKey,
                    message.responseId,
                    feedbackType,
                    feedback,
                  )
                }
                data-testid="submit-feedback-button"
              >
                Submit
              </Button>
            </Group>
          </Modal>
        </Flex>
      )}
    </>
  );
};
