import Dexie, { type IndexableType, type Table } from "dexie";
import "dexie-export-import";
import { nanoid } from "nanoid";
import type { DebugEvent } from "../hooks/useChatCompletion";

interface Settings {
	id: "general";
	openAiApiKey?: string;
	openAiModel?: string;
	debug?: boolean;
	sim_score_threshold?: number;
	sim_k_limit?: number;
	stream?: boolean;
	suggestions?: boolean;
	use_agents?: boolean;
	chat_vector_index?: ChatVectorIndex;
}

export enum ChatRole {
	SYSTEM = "system",
	USER = "user",
	ASSISTANT = "assistant",
}

export const SF = "sf";
export const FSR = "fsr";
export const RDS = "rds";
export const MINDMAPS = "mindmaps";
export const DRAWINGS = "drawings";
export type ChatVectorIndex =
	| "cts_knowledge_search_chunkwise"
	| "field_service_reports"
	| "field_service_reports_sg"
	| "combined"
	| "drawings"
	| "rds_reports"
	| "mindmaps";
export type ChatVectorIndexShorthand =
	| typeof SF
	| typeof FSR
	| "combined"
	| "drawings"
	| "rds"
	| "mindmaps";

export const chatVectorIndex: {
	label: string;
	value: ChatVectorIndex[];
	shorthand: ChatVectorIndexShorthand;
}[] = [
		{
			label: "SF",
			value: ["salesforce", "cts_knowledge_search_chunkwise"],
			shorthand: SF,
		},
		{
			label: "FSR",
			value: ["field_service_reports", "field_service_reports_sg"],
			shorthand: FSR,
		},
		{ label: "Combined (SF+FSR)", value: ["combined"], shorthand: "combined" },
		{ label: "SGT Mindmaps", value: ["mindmaps"], shorthand: "mindmaps" },
		{ label: "Engineering Drawings", value: ["drawings"], shorthand: "drawings" },
		{ label: "RDS Reports", value: ["rds_reports"], shorthand: "rds" },
	];

export class ChatEntity {
	id!: string;
	description!: string;
	totalTokens!: number;
	createdAt!: number;
	vectorIndex!: ChatVectorIndex;

	static _(vectorIndex: ChatVectorIndex) {
		const record = new ChatEntity();
		record.id = `${chatVectorIndex.find((v) => v.value.includes(vectorIndex))?.shorthand ?? "combined"}_${nanoid()}`;
		record.description = `Chat-${record.id.slice(0, 8)}`;
		record.totalTokens = 0;
		record.createdAt = new Date().getTime();
		record.vectorIndex = vectorIndex;

		return record;
	}

	setId(id: string) {
		this.id = id;
		return this;
	}

	setDescription(description: string) {
		this.description = (description || "New Chat").trim();
		return this;
	}

	setTotalTokens(totalTokens: number) {
		this.totalTokens = totalTokens;
		return this;
	}

	add() {
		return db.chats.add(this);
	}
}

interface SuggestionOption {
	text: string;
	score: number;
	freq: number;
}

export type SingleSuggestionOption = SuggestionOption & { oldWord: string };

interface Suggestion {
	text: string;
	oldWord?: string;
	offset: number;
	length: number;
	options: SuggestionOption[];
}

export interface KeySuggestion {
	key: string;
	suggestions: Suggestion[];
}

export class MessageEntity {
	id!: IndexableType;
	chatId!: IndexableType;
	responseId!: string;
	content!: string;
	pretrained!: string;
	done?: boolean;
	debug?: DebugEvent[];
	hasError?: boolean;
	error?: string;
	suggestions?: SingleSuggestionOption[];
	role!: ChatRole;
	createdAt!: number;
	repliedId!: IndexableType;

	static _() {
		const record = new MessageEntity();
		record.id = nanoid();
		record.createdAt = new Date().getTime();
		return record;
	}

	setId(id: IndexableType) {
		this.id = id;
		return this;
	}

	setResponseId(responseId: string) {
		this.responseId = responseId;
		return this;
	}

	setChatId(id: IndexableType) {
		this.chatId = id;
		return this;
	}

	setContent(content?: string) {
		this.content = (content ?? "unknown response").trim();
		return this;
	}

	setRole(role: ChatRole) {
		this.role = role;
		return this;
	}

	setRepliedId(repliedId: IndexableType) {
		this.repliedId = repliedId;
		return this;
	}

	setIsLoading() {
		this.done = false;
		return this;
	}

	add() {
		return db.messages.add(this);
	}
}

class Database extends Dexie {
	chats!: Table<ChatEntity>;
	messages!: Table<MessageEntity>;
	settings!: Table<Settings>;

	constructor() {
		super("CTS-KS-7.2");
		this.version(7).stores({
			chats: "id, createdAt",
			messages: "id, chatId, responseId, createdAt",
			settings: "id",
		});

		this.on("populate", async () => {
			db.settings.add({
				id: "general",
			});
		});
	}
}
export const db = new Database();

export const updateSettings = <T extends string>(key: T, value: string) => {
	db.settings.where({ id: "general" }).modify((settings: Record<T, string>) => {
		settings[key] = value;
	});
};
