import { useFeature } from "flagged";
import type React from "react";
import {
	type ReactNode,
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import {
	type ChatVectorIndex,
	type ChatVectorIndexShorthand,
	SF,
	chatVectorIndex,
	updateSettings,
} from "../db";

interface TabContextType {
	selectedTab: ChatVectorIndex;
	selectedTabShorthand: ChatVectorIndexShorthand;
	setSelectedTab: (tab: ChatVectorIndex) => void;
}

const defaultContextValue: TabContextType = {
	selectedTab: "combined",
	selectedTabShorthand: SF,
	setSelectedTab: () => { },
};

const TabContext = createContext(defaultContextValue);

interface TabProviderProps {
	children: ReactNode;
}

export const TabProvider: React.FC<TabProviderProps> = ({ children }) => {
	// const [selectedTab, setSelectedTab] = useState<ChatVectorIndex>(localStorage.getItem('selectedTab') as ChatVectorIndex === 'field_service_reports' ? 'field_service_reports' : 'cts_knowledge_search_chunkwise');
	// const search = useSearch()
	// console.log('search', search)
	const isMindamaps = useFeature("MINDMAPS");
	const [selectedTab, setSelectedTab] = useState<ChatVectorIndex>(localStorage.getItem("selectedTab") || "combined");

	useEffect(() => {
		if (isMindamaps) setSelectedTab("combined");
	}, [isMindamaps]);

	useEffect(() => {
		localStorage.setItem("selectedTab", selectedTab);
		setSelectedTab(selectedTab);
		updateSettings("chat_vector_index", value);
	}, [selectedTab]);

	const selectedTabShorthand: ChatVectorIndexShorthand =
		chatVectorIndex.find((index) => index.value.includes(selectedTab))
			?.shorthand ?? "combined";

	const value = useMemo(
		() => ({
			selectedTab,
			selectedTabShorthand,
			setSelectedTab,
		}),
		[selectedTab, selectedTabShorthand, setSelectedTab],
	);

	return <TabContext.Provider value={value}>{children}</TabContext.Provider>;
};

export const useTab = () => useContext(TabContext);
