import {
	ActionIcon,
	Container,
	Flex,
	Menu,
	useMantineTheme,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconDotsVertical, IconMessages } from "@tabler/icons-react";
import { Link } from "@tanstack/react-router";
import download from "downloadjs";
import { useMemo } from "react";
import {
	type ChatEntity,
	type ChatVectorIndexShorthand,
	chatVectorIndex,
	db,
} from "../db";
import { useChatId } from "../hooks/useChatId";
import { DeleteChatModal } from "./DeleteChatModal";
import { EditChatModal } from "./EditChatModal";
import { MainLink } from "./MainLink";

export const Chats = ({
	search,
	type,
	chats,
}: {
	search: string;
	type: ChatVectorIndexShorthand;
	chats?: ChatEntity[];
}) => {
	const chatId = useChatId();

	const filteredChats = useMemo(
		() =>
			(chats ?? []).filter((chat) => {
				const foundVector = chatVectorIndex.find((vector) =>
					vector.value.includes(chat.vectorIndex),
				);
				if (!search)
					return (
						foundVector?.value.includes(chat.vectorIndex) &&
						chat.id.startsWith(type)
					);
				return (
					foundVector?.value.includes(chat.vectorIndex) &&
					chat.id.startsWith(type) &&
					chat.description.toLowerCase().includes(search)
				);
			}) /*.filter(chat => chat.totalMessages > 0)*/,
		[chats, search, type],
	);

	const theme = useMantineTheme();
	return (
		<Container py="xs" px="0">
			{filteredChats.map((chat) => (
				<Flex
					key={chat.id}
					className={chatId === chat.id ? "active" : undefined}
					data-testid={`chat-${chat.id}`}
					sx={(theme) => ({
						marginTop: 1,
						borderLeft: `6px solid ${theme.fn.rgba(theme.colors.purple[1], 0)}`,
						transition: "border-left 0.2s",
						"&:hover, &.active": {
							borderLeft: `6px solid ${theme.fn.lighten(theme.colors.purple[4], 0.2)}`,
						},
					})}
				>
					<Link to={`/chats/${chat.id}`} style={{ flex: 1 }}>
						<MainLink
							icon={<IconMessages size="1rem" />}
							color={chatId === chat.id ? theme.colors.purple[4] : "black"}
							chat={chat}
							label={chat.description}
						/>
					</Link>
					<Menu shadow="md" width={200} keepMounted>
						<Menu.Target>
							<ActionIcon sx={{ height: "auto" }}>
								<IconDotsVertical
									color={chatId === chat.id ? theme.colors.purple[4] : "black"}
									size={20}
								/>
							</ActionIcon>
						</Menu.Target>
						<Menu.Dropdown>
							{/* Add Export */}
							<Menu.Item
								onClick={async () => {
									const blob = await db.export({
										filter(table, value, key) {
											if (table === "chats") {
												return value.id === chat.id;
											}
											if (table === "messages") {
												return value.chatId === chat.id;
											}
											return true;
										},
									});
									download(
										blob,
										`chatgpt-export-${new Date().toLocaleString()}.json`,
										"application/json",
									);
									notifications.show({
										title: "Exporting Data",
										message: "Your data is being exported.",
									});
								}}
							>
								Export
							</Menu.Item>
							<EditChatModal chat={chat}>
								<Menu.Item>Edit</Menu.Item>
							</EditChatModal>
							<DeleteChatModal chat={chat}>
								<Menu.Item>Delete</Menu.Item>
							</DeleteChatModal>
						</Menu.Dropdown>
					</Menu>
				</Flex>
			))}
		</Container>
	);
};
