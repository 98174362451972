import { useAccount } from "@azure/msal-react";

export const useAdmin = () => {
	const account = useAccount();
	if (!account?.idTokenClaims?.groups) return false;
	return account.idTokenClaims.groups.includes(
		import.meta.env.VITE_AD_ADMIN_GROUP,
	);
};

export const useNotAuthorizedUser = () => {
	const account = useAccount();
	if (!account) return null;
	if (!account?.idTokenClaims?.groups) return true;
	return false;
};
