import React from 'react';
import { FlagsProvider } from "flagged";
import { createRoot } from "react-dom/client";
import "./styles/style.scss";
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { QueryClient, QueryClientProvider } from "react-query";
import { TabProvider } from "./contexts/tabContext";
import { msalConfig } from "./utils/authConfig";
import { createHashHistory, createRouter, RouterProvider } from '@tanstack/react-router';
import * as Sentry from "@sentry/react";

// Import the generated route tree
import { routeTree } from './routeTree.gen';
import { MantineProvider } from '@mantine/core';
import { SE_THEME } from './styles/se-energy.theme';

const hashHistory = createHashHistory()

// Create a new router instance
export const router = createRouter({
	history: hashHistory,
	routeTree,
	context: {
		queryClient: new QueryClient
	}
})

// Register the router instance for type safety
declare module '@tanstack/react-router' {
	interface Register {
		router: typeof router
	}
}

const domElementId = 'app'

// Render the app
const rootElement = document.getElementById(domElementId)
if (!rootElement) {
	throw new Error(`Element with id ${domElementId} not found`)
}

const environment = import.meta.env.VITE_ENVIRONMENT;
if (environment && environment !== "local") {
	Sentry.init({
		dsn: "https://8f33466b4db54a81a4676c90d9d3da48@crash.siemens-energy.com/67",
		integrations: [
			// See docs for support of different versions of variation of react router
			// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
			Sentry.tanstackRouterBrowserTracingIntegration(router),
			Sentry.replayIntegration(),
		],


		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
		environment: environment,
	});
}

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig);

(async () => {
	await msalInstance.initialize();
})();

// Default to using the first account if no account is active on page load
if (
	!msalInstance.getActiveAccount() &&
	msalInstance.getAllAccounts().length > 0
) {
	// Account selection logic is app dependent. Adjust as needed for different use cases.
	msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);

	let email = msalInstance?.getActiveAccount()?.username
	Sentry.setUser({ email: email });

} else if (msalInstance?.getActiveAccount()) {

	let email = msalInstance?.getActiveAccount()?.username
	Sentry.setUser({ email: email });
}

msalInstance.addEventCallback((event) => {
	if (
		(event.eventType === EventType.LOGIN_SUCCESS ||
			event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
			event.eventType === EventType.SSO_SILENT_SUCCESS) &&
		event?.payload?.account
	) {
		msalInstance.setActiveAccount(event?.payload?.account);

		let email = event?.payload?.account.username
		Sentry.setUser({ email: email });
	}
});

const queryClient = new QueryClient();
createRoot(rootElement).render(
	<React.StrictMode>
		<MsalProvider instance={msalInstance}>
			<QueryClientProvider client={queryClient}>
				<FlagsProvider features={import.meta.env.VITE_FEATURES ? import.meta.env.VITE_FEATURES.split(",") : []}>
					<TabProvider>
						<MantineProvider
							withGlobalStyles
							withNormalizeCSS
							withCSSVariables
							theme={{
								...SE_THEME,
							}}
						>
							<RouterProvider router={router} />
						</MantineProvider>
					</TabProvider>
				</FlagsProvider>
			</QueryClientProvider>
		</MsalProvider>
	</React.StrictMode>
);
