import { createFileRoute } from '@tanstack/react-router'
import { ChatRoute } from 'src/containers/ChatsRoute'

export const Route = createFileRoute('/_layout/')({
  component: Chats,
})

function Chats() {
  return <ChatRoute />
}
