import type { MantineThemeOverride } from "@mantine/core";

import { DEFAULT_COLORS } from "./default-colors";

export const SE_THEME: MantineThemeOverride = {
	colorScheme: "light",
	white: "#fff",
	black: "#000",
	colors: DEFAULT_COLORS,
	fontFamily: `'siemens', ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto,Ubuntu, Cantarell, Noto Sans, sans-serif, Helvetica Neue, Arial,Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji`,
	defaultGradient: {
		from: "#1B1534",
		to: "#4D217A",
		deg: 45,
	},
	defaultRadius: "lg",
	primaryColor: "purple",
	globalStyles: (theme) => ({
		body: {
			backgroundColor:
				theme.colorScheme === "dark"
					? theme.colors.dark[9]
					: theme.colors.gray[0],
		},
	}),
	components: {
		Modal: {
			defaultProps: {
				padding: "xl",
			},
			styles: {
				title: {
					fontSize: "1.2rem",
					fontWeight: 600,
				},
			},
		},
		ModalRoot: {
			defaultProps: {
				centered: true,
			},
		},
		Overlay: {
			defaultProps: {
				opacity: 0.6,
				blur: 6,
			},
		},
		ActionIcon: {
			styles: {},
		},
		// Input: {
		//   defaultProps: {
		//     variant: "filled",
		//   },
		// },
		InputWrapper: {
			styles: {
				label: {
					marginBottom: 4,
				},
			},
		},
		Code: {
			styles: (theme) => ({
				root: {
					fontSize: theme.fontSizes.sm,
					backgroundColor:
						theme.colorScheme === "dark"
							? theme.colors.dark[7]
							: theme.colors.gray[1],
				},
			}),
		},
	},
};
