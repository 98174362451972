export const metadataSAP = `SAP NO,SFID,CUSTOMER ACCOUNT,UNIT_NAME__C,SF SERIAL,SF ORIG SERIAL,SAP ORIG SERIAL,SAP DESCRIPTION,TRAIN,MODEL__C,START_UP_DATE__C,FAC_DATE__C,BOOK_ORDER_DATE__C,STATUS__C,FRAME_FAMILY__C,COUNTRY_CODE__C,AGREEMENT_TYPE__C,AGREEMENT TYPE,PRODUCT NAME,APPLICATION,DRIVEN UNIT
M0E7/01,6043921,Batıçim Enerji,M0F1/01-Rm312-Gt3,M0E7/01,,N/A,SGT-100 PACKAGE - MOTHBALLED GB,TRAIN_M0E7/01,SGT-100-1S,2002-03-07,2003-03-07,2001-03-07,Scrapped,SGT-100,TR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
HI6052RT/02,6437670,Larsen & Toubro Infotech Limited-Mumbai,SAGAR Pragatti,HI6052/02 RT,NR0368/02,NR0368/02,SGT-200 (LCN NR0368) C1C M2C E1C DC,TRAIN_HI6052RT/02,SGT-200-2S,,,2013-09-30,Not Running – Preserved,SGT-200,IN,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NU0214/02,6044312,Caspian Pipeline Consortium (CPC-R) - Kropotkin,CPC Kropotkin,NU0214/02,,N/A,SGT-100-2S C3C M2C E1C DC GB HC,TRAIN_NU0214/02,SGT-100-2S,2014-04-10,2016-04-09,2010-05-01,Operating,SGT-100,RU,Frame Agreement – Includes RDS,ADHOC,I4-003,30,COMPRESSOR
BF000089U04,6675017,ENI - Petrobel _ Zohr field,Zohr,BF000089U04,NA0493/01,NA0493/01,SGT-400 (NA0493) C3C M2C E1C DC HC,TRAIN_BF000089U04,SGT-400,2018-08-30,2020-08-29,2017-07-31,Operating,SGT-400,EG,Corrective LTP – Includes RDS,LTP,I4-008,,GENERATOR
W8A9/02,6032825,Nem/Abr - Roquette UK Limited,Roquette 2,W8A9/02,,N/A,SGT-300 PACKAGE C3C M1C E2S DS GB,TRAIN_W8A9/02,SGT-300,1998-01-01,1998-12-31,1997-03-01,Not Running – Not Preserved,SGT-300,GB,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
M9C8/01,6141685,Dummy Account: Asia Pacific - Japan,"DNP, Mihara Unit #2",M9C8/01,,N/A,SGT-100 PACKAGE - SCRAPPED GB,TRAIN_M9C8/01,SGT-100-1S,1999-01-01,2003-05-13,1998-01-01,Not Running – Not Preserved,SGT-100,JP,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M309/01,6079808,Bumi Armada Claire FPSO,Griffin Field FPSO,M309/01,,N/A,SGT-100 PACKAGE - MOTHBALLED,TRAIN_M309/01,SGT-100-1S,1992-12-31,1994-03-26,1991-12-31,Scrapped,SGT-100,MY,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
HI6097/01,6689730,Midcoast Pipelines (East Texas) L.P.,Grapeland Compressor Station,HI6097/01,NC0549/01 - BD000885U01,NC0549/01 BD000885,SGT-400 PACKAGE(NC0549) C4S M2C EX DC HS,TRAIN_HI6097/01,SGT-400,2021-03-14,2022-03-13,2020-02-01,Operating,SGT-400,US,Preventive LTP – Includes RDS,LTP,I4-008,,COMPRESSOR
NR0220/01,6104652,MEGAL Verdichterstation Wildenranna,MEGAL GmbH Co,NR0220/01,,N/A,SGT-200 PACKAGE CX M1C E1C DS GB,TRAIN_NR0220/01,SGT-200-2S,2016-03-16,2019-03-15,2010-07-01,Operating,SGT-200,DE,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
M532/01,217901,Fortistar Methane Group - Arbor Hills Landfill,Gas Recovery Systems Inc. Michigan,M532/01,9100/130,N/A,SGT-100 PACKAGE CX,TRAIN_M532/01,SGT-100-1S,1996-02-03,1997-02-03,1995-02-03,Operating,SGT-100,US,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
BF000103U01,6665289,PTT Wang Noi Compressor Station,,BF000103U01,NC0489/01,NC0489/01,SGT-400 PACKAGE (LCN NC0489) C3S EX,TRAIN_BF000103U01,SGT-400,2018-12-21,2020-03-20,2017-03-01,Operating,SGT-400,TH,No Contract or Agreement,ADHOC,I4-008,,COMPRESSOR
S225/01,6141752,National Iranian Oil Company (NIOC) HQ,Sirri Island,S225/01,,N/A,SGT-200 PACKAGE CX M2C EX DC GB,TRAIN_S225/01,SGT-200-1S,1992-04-30,1993-04-28,1991-02-01,Operating,SGT-200,IR,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
HI6037/03,6083357,GLNG - Fairview-HCS-04 South,Fairview 04,HI6037/03,NC0247/03,NC0247/03,SGT-400(LCN NC0247) C3C M2C E2S DC GB HC,TRAIN_HI6037/03,SGT-400,2015-05-31,2016-10-29,2011-03-31,Scrapped,SGT-400,AU,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
S114/01,6079564,Qatar Petroleum - Doha - Dukhan Power Station,Dukhan Power Station,S114/01,,N/A,SGT-200 PACKAGE GB,TRAIN_S114/01,SGT-200-1S,1992-01-01,1992-06-14,1990-01-01,Not Running – Not Preserved,SGT-200,QA,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M374/01,6141472,Pamesa,Pamesa 1,M374/01,,N/A,SGT-100 PACKAGE - MOTHBALLED GB,TRAIN_M374/01,SGT-100-1S,1994-01-01,1994-10-24,1992-12-01,Not Running – Not Preserved,SGT-100,ES,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NA0373/01,6436511,JV Consolidated & Mandalay,Mandalay,NA0373/01,,N/A,SGT-400 PACKAGE GB,TRAIN_NA0373/01,SGT-400,,,2013-11-30,Storage,SGT-400,MM,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
NA0136/02,6047570,Sengileevskiy tsementnyy zavod Ltd.  (Eurocement group),Mordov Cement 2,NA0136/02,,N/A,SGT-400 PACKAGE C3S M1C E1C DS GB,TRAIN_NA0136/02,SGT-400,2015-06-30,2016-05-30,2008-04-01,Not Running – Not Preserved,SGT-400,RU,Preventive LTP,LTP,I4-008,10,GENERATOR
U777/01,6038844,Oleoductos del Valle - Oldelval - Chimpay,Chimpay,U777/01,,N/A,SGT-100-2S PACKAGE CX M1S EX GB,TRAIN_U777/01,SGT-100-2S,1998-01-01,1999-10-10,1997-09-01,Operating,SGT-100,AR,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
HI6097/02,6690196,Midcoast Pipelines (East Texas) L.P.,Grapeland Compressor Station,HI6097/02,NC0549/02 - BD000885U02,NC0549/02 BD000885,SGT-400 PACKAGE(NC0549) C3C M2C EX DC HC,TRAIN_HI6097/02,SGT-400,2021-03-14,2022-03-13,2020-02-01,Operating,SGT-400,US,Preventive LTP – Includes RDS,LTP,I4-008,,COMPRESSOR
W3B2/02,6048908,Qatar Petroleum - Halul Island,Hallul Island 3,W3B2/02,NW0029/02,N/A,SGT-300 PACKAGE C3S M2S E2S DS,TRAIN_W3B2/02,SGT-300,2001-01-01,2005-03-30,2000-09-01,Not Running – Preserved,SGT-300,QA,Call Off Contract Goods and Services,ADHOC,I4-021,10,GENERATOR
NA0136/01,6047571,Sengileevskiy tsementnyy zavod Ltd.  (Eurocement group),Mordov Cement 2,NA0136/01,NA0136/01,N/A,SGT-400 PACKAGE C3S M2C E1C DC GB,TRAIN_NA0136/01,SGT-400,2014-10-16,2015-10-15,2008-04-01,Operating,SGT-400,RU,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
BD000820U01,6684569,IGSA S.A. de C.V.,IGSA - Santa Lucia,BD000820U01,NA0539/01,NA0539/01,SGT-400 (NA0539) C4S M2C EX DC HS,TRAIN_BD000820U01,SGT-400,2022-10-12,2024-10-11,2019-09-30,Operating,SGT-400,MX,No Contract or Agreement,ADHOC,I4-008,,GENERATOR
NC0579/01,6704655,TAP Greece,TAP X1,NC0579/01,BD001214U01,BD001214U01,SGT-400 PACKAGE,TRAIN_NC0579/01,SGT-400,2024-12-01,2026-11-29,2023-03-01,Order,SGT-400,GR,,,I4-008,,
W3C8/03,6053124,Mellitah Oil & Gas B.V - Sabratha Platform,"AGIP GAS/Sabratha platform, NC41",W3C8/03,NW0035/03,N/A,SGT-300 C3C M2C E2S DC,TRAIN_W3C8/03,SGT-300,2003-11-26,2005-01-23,2002-11-26,Operating,SGT-300,LY,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
NM0122/04,6058304,In Salah Gas Field - Reg - Teg - Krechba,In Salah Gas Field - Teg,NM0122/04,,N/A,SGT-100 PACKAGE C2S M2C E1C DC GB,TRAIN_NM0122/04,SGT-100-1S,2010-08-28,2012-08-27,2008-01-07,Operating,SGT-100,DZ,Call Off Contract Goods and Services,ADHOC,I4-002,10,GENERATOR
NC0303/01,6258188,HABANIYAH,HABANIYAH,NC0303/01,BCD01114U01,N/A,SGT-400 PACKAGE C1C,TRAIN_NC0303/01,SGT-400,,,2012-03-31,Storage,SGT-400,IQ,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
NS0351/01,6368132,ZNGT- Jing de Zhen,ZNGT Jing de Zhen,NS0351/01,,N/A,SGT-200 PACKAGE GB C3S HC,TRAIN_NS0351/01,SGT-200-1S,2013-12-22,2014-12-21,2013-06-01,Not Running – Not Preserved,SGT-200,CN,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
HI6064/04,6553358,"Esentia Pipeline de Occidente, S. de R.L. de C.V - Villa de Reyes",Villa de Reyes,HI6064/04,NC0469/02,NC0469/02,SGT-400 PKG (LCN NC0469) C3S M2C EX DC,TRAIN_HI6064/04,SGT-400,2020-11-01,2024-10-30,2016-06-30,Operating,SGT-400,MX,Preventive LTP,LTP,I4-008,,COMPRESSOR
M708/04,6142070,National Iranian Gas Company,Farashband S1,M708/04,,N/A,SGT-100 PACKAGE C2S M2C EX DC GB,TRAIN_M708/04,SGT-100-1S,1997-01-01,1998-02-22,1996-01-01,Operating,SGT-100,IR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NW0227/02,6104645,TEC RU-1 Belaruskaly,Belaruskali RU-1,NW0227/02,,BD000021,SGT-300(FSP BD000021)C2C M2C E1C DC GB,TRAIN_NW0227/02,SGT-300,2013-01-23,2015-01-22,2010-08-01,Operating,SGT-300,BY,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
BD000286U02,6552735,Total E&P UK - Culzean Platform,Culzean Power Gen B,BD000286U02,NW0442/02,NW0442/02,SGT-300 (LCN NW0442) C3S M1S EX,TRAIN_BD000286U02,SGT-300,2019-06-16,2021-06-15,2015-09-07,Operating,SGT-300,GB,Call off Agreement / Support LTP,ADHOC,I4-021,30,GENERATOR
S9J1/01,6044670,Descartes-Descartes-Dalkia - Descartes,Dalkia - Descartes,S9J1/01,,N/A,SGT-200 PACKAGE,TRAIN_S9J1/01,SGT-200-1S,2000-01-07,2001-03-22,1999-03-01,Scrapped,SGT-200,FR,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
HI6074/02,6680741,Mubadala Energy,Pegaga Mubadala,HI6074/02,BD000717U02,NW0525/01 BD000717,SGT-300 PACKAGE C3S M1S EX,TRAIN_HI6074/02,SGT-300-1S,2022-09-23,2024-05-22,2018-09-30,Operating,SGT-300,MY,Frame Agreement,ADHOC,I4-021,,GENERATOR
BF000119U01,6673472,OGC - Salalah LPG,,BF000119U01,NC0511/01,NC0511/01,SGT-400 (NC0511) C3C M1S EX HC,TRAIN_BF000119U01,SGT-400,2021-03-25,2023-03-24,2017-09-30,Operating,SGT-400,OM,Preventive LTP – Includes RDS,LTP,I4-008,,COMPRESSOR
NR0104/05,6038188,Petróleo Brasileiro S/A - Petrobras - G&E/UN-APGN/UTGC (Fase III),SITI - CACIMBAS PHASE III,NR0104/05,HI6016 - 1550016,HI6016 1550016,SGT-200 PACKAGE M1C E1C DS GB 2007,TRAIN_NR0104/05,SGT-200-2S,2010-11-15,2011-11-14,2007-03-01,Operating,SGT-200,BR,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
S2B4/03,6137991,National Iranian Oil Company (NIOC),Dorood Generator,S2B4/03,,N/A,SGT-200 PACKAGE M1C E1C DS 2006,TRAIN_S2B4/03,SGT-200-1S,2003-12-04,2004-12-04,2002-12-04,Operating,SGT-200,IR,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
R9M7/01,216165,Alliance Pipeline - Blueberry Hill,Alliance Pipelines Blueberry Hill Compressor Station Alberta Canada,R9M7/01,9080/343,N/A,SGT-200-2S PACKAGE EX 2020,TRAIN_R9M7/01,SGT-200-2S,2000-10-22,2002-12-15,1999-05-01,Operating,SGT-200,CA,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
A3B0/02,6142696,Floaters SPA - FPSO Mystras,Cyclone B Mystras,A3B0/02,,N/A,SGT-400 PACKAGE CX M1S,TRAIN_A3B0/02,SGT-400,2005-11-09,2006-11-09,2004-11-09,Not Running – Not Preserved,SGT-400,NG,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
U2C5/02,6045256,ENI S.p.A. - DIME Crotone,ENI Crotone,U2C5/02,,N/A,SGT-100-2S PACKAGE C2S M2C E1C DC GB,TRAIN_U2C5/02,SGT-100-2S,2002-06-26,2010-06-30,2001-06-26,Operating,SGT-100,IT,Framework Agreement Goods and Services,ADHOC,I4-003,30,COMPRESSOR
U8E9/01,6142937,Oleoductos del Valle - Oldelval - Allen,Allen,U8E9/01,,N/A,SGT-100-2S PACKAGE - SITE EMPTY M1S,TRAIN_U8E9/01,SGT-100-2S,1999-01-01,1999-12-31,1998-01-01,Scrapped,SGT-100,AR,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
U706/02,6138014,National Iranian Oil Refining & Product Distribution Company (NIORDC),Sabzab,U706/02,,N/A,SGT-100-2S PACKAGE C2S M1S EX GB,TRAIN_U706/02,SGT-100-2S,1998-09-07,1999-09-06,1997-04-01,Operating,SGT-100,IR,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
NR0159/01,6038163,Transportadora Associada de Gás S/A - TAG - EComp de Juaruna,SITI Petrobras Coari,NR0159/01,6024,HI6024,SGT-200 PACKAGE C1S M2S E2S DS GB,TRAIN_NR0159/01,SGT-200-2S,2010-11-30,2011-11-29,2009-01-01,Operating,SGT-200,BR,No Contract or Agreement,ADHOC,I4-006,,COMPRESSOR
NM0342/02,6197134,Neptune E&P UK - Cygnus,Neptune Energy Cygnus Platform,NM0342/02,NM0342/02,N/A,SGT-100 PACKAGE C3S M2C E1C DC GB,TRAIN_NM0342/02,SGT-100-1S,2016-11-07,2018-11-06,2012-12-31,Operating,SGT-100,GB,Preventive LTP – Includes RDS,LTP,I4-002,10,GENERATOR
BC000538U01,6681770,New Fortress Energy - Gulf of Mexico (Mex),FAST LNG,BD001037U01,NA0529/01,NA0529/01 BD001037 HI6101,SGT-400 PACKAGE (NA0529) CX,TRAIN_BC000538U01,SGT-400,2024-01-01,2024-12-30,2021-06-01,Installation & Commissioning,SGT-400,MX,No Contract or Agreement,ADHOC,I4-008,,GENERATOR
S710/01,6032850,ENGIE - Humber Energy,Humber Energy 1,S710/01,,N/A,SGT-200 PACKAGE C3C M2C E1C DC GB HC,TRAIN_S710/01,SGT-200-1S,1997-01-01,1997-12-15,1996-03-01,Operating,SGT-200,GB,Corrective LTP – Includes RDS,LTP,I4-005,10,GENERATOR
R536/01,6051099,TotalEnergies E&P Nederland BV,Total E&P Nederland BV K6,R536/01,9080/176,N/A,SGT-200 PACKAGE C2S M2C DC,TRAIN_R536/01,SGT-200-2S,1996-01-01,1997-08-01,1995-09-01,Operating,SGT-200,NL,Frame Agreement – Includes RDS,ADHOC,I4-006,30,COMPRESSOR
S2A9/01,6053155,Sarir Oil Operations - Jakhira Field,Jakhira 7,S2A9/01,,N/A,SGT-200 PACKAGE - SPARE CORE 2017,TRAIN_S2A9/01,SGT-200,2003-01-01,2003-12-18,2002-01-01,Operating,SGT-200,LY,Other and 24/7 Global Helpdesk and/or RDS included,ADHOC,I4-005,,GENERATOR
NR0039/01,6112078,Kharg Aboozer,Aboozar - Kharg Gas,NR0039/01,NR0039/01,N/A,SGT-200-2S PACKAGE,TRAIN_NR0039/01,SGT-200-2S,,,2005-02-24,Storage,SGT-200,IR,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
S718/01,6079560,Qatar Petroleum - Doha - Dukhan Power Station,Dukhan Power Station,S718/01,,N/A,SGT-200 PACKAGE GB,TRAIN_S718/01,SGT-200-1S,1991-01-01,1998-01-29,1990-01-01,Not Running – Not Preserved,SGT-200,QA,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
S751/01,6038135,Petróleo Brasileiro S/A - Petrobras - E&P/SSE/UN-BC/ATP-MRL/FPSO P35,FPSO P35,S751/01,,N/A,SGT-200 PACKAGE EX GB,TRAIN_S751/01,SGT-200-1S,1997-01-01,1998-07-01,1996-01-01,Not Running – Preserved,SGT-200,BR,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M708/01,6142072,National Iranian Gas Company,Pataveh S2,M708/01,,N/A,SGT-100 PACKAGE C2S M2C EX DC GB,TRAIN_M708/01,SGT-100-1S,1997-01-01,1998-01-18,1996-01-01,Operating,SGT-100,IR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
HI6030/01RW,6104811,ONGC B&S B193 Platform,ONGC SIME DARBY - B193,HI6030/01RW,NW0223/01,NW0223/01,SGT-300(LCN NW0223)C1S M2C E2S DC,TRAIN_HI6030/01RW,SGT-300,2015-01-06,2013-05-31,2010-08-31,Operating,SGT-300,IN,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
BF000089U03,6662751,ENI - Petrobel _ Zohr field,Zohr,BF000089U03,NA0472/03,NA0472/03,SGT-400 (NA0472) C3C M2C E1C DC HC,TRAIN_BF000089U03,SGT-400,2018-06-06,2019-06-05,2016-09-30,Operating,SGT-400,EG,Corrective LTP – Includes RDS,LTP,I4-008,,GENERATOR
S135/01,6141681,Dummy Account: Asia Pacific - Japan,Himeji,S135/01,,N/A,SGT-200 PACKAGE - SCRAPPED,TRAIN_S135/01,SGT-200,1992-01-01,1993-04-01,1990-01-01,Scrapped,SGT-200,JP,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NA0373/02,6541049,JV Consolidated & Mandalay,Mandalay,NA0373/02,,N/A,SGT-400 PACKAGE GB,TRAIN_NA0373/02,SGT-400,,,2014-02-28,Storage,SGT-400,MM,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
R742/01,217050,TC Energy - CDN Mainline - Les Cedres Station 148,Stn.148 (ex. Columbia Gas Strasburg 1),R742/01,9080/217,N/A,SGT-200-2S PACKAGE,TRAIN_R742/01,SGT-200-2S,1997-01-01,1999-09-01,1996-07-01,Storage,SGT-200,CA,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NA0389/01,6490631,ZNGT Liyuan (COG),ZNGT Liyuan (COG),NA0389/01,,BD000202U01,SGT-400 (FSP BD000202) C1C M2S E2S GB,TRAIN_NA0389/01,SGT-400,2015-07-22,2016-07-21,2014-03-31,Not Running – Preserved,SGT-400,CN,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
BF000063U01,6544044,Nawara OMV (GTP/CPF),Nawara Gas Treatment Plant (GTP),BF000063U01,NC0416/01,NC0416/01,SGT-400 PKG (LCN NC0416) C3S M2S E1C DS,TRAIN_BF000063U01,SGT-400,2020-06-29,2022-06-28,2015-03-31,Operating,SGT-400,TN,Other and 24/7 Global Helpdesk and/or RDS included,ADHOC,I4-008,30,COMPRESSOR
BF000089U01,6662749,ENI - Petrobel _ Zohr field,Zohr,BF000089U01,NA0472/01,NA0472/01,SGT-400 (NA0472) C3C M2C E1C DC HC,TRAIN_BF000089U01,SGT-400,2018-04-29,2020-04-28,2016-09-30,Operating,SGT-400,EG,Corrective LTP – Includes RDS,LTP,I4-008,10,GENERATOR
80/84028-01,6108666,Dunn Paper,PORT HURON MICHIGAN,80/84028/01,,N/A,SGT-200 PACKAGE - SOLD & BECAME J0G2/02,TRAIN_80/84028-01,SGT-200-1S,1985-09-01,1986-09-01,1984-09-01,Scrapped,SGT-200,US,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M504/02,6048802,Qatargas - North Field Bravo (NFB) Offshore Platform,North Field Phase 2- 2,M504/02,,N/A,SGT-100 C3S M1C E2S DS GB,TRAIN_M504/02,SGT-100-1S,1995-12-05,1996-12-05,1994-12-05,Operating,SGT-100,QA,Call Off Contract Goods and Services,ADHOC,I4-002,10,GENERATOR
NM0122/02,6052845,In Salah Gas Field - Reg - Teg - Krechba,In Salah Gas Field - Teg,NM0122/02,,N/A,SGT-100 PACKAGE C2S M2C E1C DC GB,TRAIN_NM0122/02,SGT-100-1S,2010-08-28,2012-08-27,2008-01-07,Operating,SGT-100,DZ,Call Off Contract Goods and Services,ADHOC,I4-002,10,GENERATOR
BC000154U01,6553201,TAP Albania,TAP Albania,BC000154U01,NC0448/01,NC0448/01,SGT-400 PKG (LCN NC0448) C3C M1S EX HC,TRAIN_BC000154U01,SGT-400,2021-03-30,2023-03-29,2016-02-01,Operating,SGT-400,AL,Preventive LTP – Includes RDS,LTP,I4-008,,COMPRESSOR
BD000183U02,6490634,CNOOC-Huanyan PH1,Huanguan PH1,BD000183U02,NC0380/02,NC0380/02,SGT-400(LCN NC0380) C3S M2S E2S DS GB HP,TRAIN_BD000183U02,SGT-400,2015-11-19,2017-05-18,2014-02-28,Operating,SGT-400,CN,Preventive LTP,LTP,I4-008,30,COMPRESSOR
NC0033/10,6039098,PDVSA - Los Morros - ICO Los Morros,"ICO, Los Morros",NC0033/10,,HI6011 1550011,SGT-400 (HOU HI6011) M2C E1C DC GB,TRAIN_NC0033/10,SGT-400,,,2006-03-01,Not Running – Not Preserved,SGT-400,VE,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
S540/01,6038784,Dirección Provincial de Energía -Tierra del Fuego - Central Termoeléctrica Ushuaia (DPE) - Ushuaia,Ushuaia,S540/01,,N/A,SGT-200 PACKAGE CX EX GB,TRAIN_S540/01,SGT-200-1S,1995-01-01,1996-02-01,1994-01-01,Operating,SGT-200,AR,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
S2H4/02,6050089,OPS Angola - FPSO Ngoma - Block 15,N'Goma FPSO,S2H4/02,,N/A,SGT-200 PACKAGE C3C M2C E2S DC GB HC,TRAIN_S2H4/02,SGT-200-1S,2003-01-01,2004-09-13,2001-12-01,Operating,SGT-200,AO,Preventive LTP – Includes RDS,LTP,I4-005,10,GENERATOR
NC0211/02,6144125,Shwe Platform,SHWE,NC0211/02,,N/A,SGT-400 PACKAGE C3S M2C E2S DC GB,TRAIN_NC0211/02,SGT-400,2013-10-27,2015-04-26,2015-03-01,Operating,SGT-400,MM,Frame Agreement – Includes RDS,ADHOC,I4-008,30,COMPRESSOR
NR0117/02,6048074,UEP Beta GmbH [formerly OMV (Pakistan) Exploration Gesellschaft m.b.H.)- Sawan Gas Field,UEP Sawan,NR0117/02,,N/A,SGT-200 PACKAGE C2S M2C E1C DC GB,TRAIN_NR0117/02,SGT-200-2S,2010-05-04,2011-05-03,2008-01-01,Operating,SGT-200,PK,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
M550/01,6144578,HRL - Energy Brix Power Station,Victoria,M550/01,,N/A,SGT-100 PACKAGE - RETIRED GB,TRAIN_M550/01,SGT-100-1S,1995-08-10,1996-08-10,1994-08-10,Not Running – Not Preserved,SGT-100,AU,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
HI6080/01,6681770,New Fortress Energy - Gulf of Mexico (Mex),FAST LNG,BD001037U01,NA0529/01,NA0529/01 BD000750,SGT-400 PACKAGE CX,TRAIN_HI6080/01,SGT-400,2024-01-01,2024-12-30,2021-06-01,Installation & Commissioning,SGT-400,MX,No Contract or Agreement,ADHOC,I4-008,,GENERATOR
BD000107U01,6187167,Musandam Gas Plant - OOCEP,Musandam,BD000107U01,NA0331/01,NA0331/01,SGT-400 (LCN NA0331) C1S M1C E1C DS GB,TRAIN_BD000107U01,SGT-400,2015-08-13,2016-08-12,2012-07-02,Not Running – Not Preserved,SGT-400,OM,No Contract or Agreement,ADHOC,I4-008,30,GENERATOR
R633/02,6047901,OKEA - Draugen Field,Draugen GT200,R633/02,9080/186,N/A,SGT-200-2S PACKAGE C2S M2C EX DC,TRAIN_R633/02,SGT-200-2S,1997-01-01,1998-04-01,1995-12-01,Operating,SGT-200,NO,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
S8E1/01,6038505,PEP-Cantarell Offshore-APC-Akal B,Akal B (Offshore),S8E1/01,S8E1/01,N/A,SGT-200 PACKAGE C2S EX,TRAIN_S8E1/01,SGT-200-1S,1999-05-01,2000-07-05,1998-05-01,Operating,SGT-200,MX,Frame Agreement,ADHOC,I4-005,10,GENERATOR
HI6073/02,6681796,CSV MIDSTREAM Solutions Corp,Karr Paramount,HI6073/02,NW0524/02 - BD000716U02,NW0524/02 BD000716,SGT-300 PKG (LCN NW0524) C3C M2C EX DC,TRAIN_HI6073/02,SGT-300-1S,2020-06-25,2021-06-24,2018-10-31,Operating,SGT-300,CA,Corrective LTP – Includes RDS,LTP,I4-021,,GENERATOR
M8E8/01,6055112,"Kayaba, Gifukita (HPS)","Kayaba, Gifukita",M8E8/01,,N/A,SGT-100 C3S M2C E1C DC GB,TRAIN_M8E8/01,SGT-100-1S,1998-06-17,1999-06-19,1997-12-19,Scrapped,SGT-100,JP,,,I4-002,10,GENERATOR
W2E7/01,6045150,Total E&P -  OML58 (Obite / Obagi),Obite,W2E7/01,,N/A,SGT-300 PACKAGE C3S M2C E2S DC GB,TRAIN_W2E7/01,SGT-300,2002-01-01,2006-06-26,2001-01-01,Operating,SGT-300,NG,Resident Engineer,ADHOC,I4-021,10,GENERATOR
S401/01,6141683,Dummy Account: Asia Pacific - Japan,Yokohama Nissin Oilio,S401/01,,N/A,SGT-200 PACKAGE,TRAIN_S401/01,SGT-200,1994-01-01,1995-02-02,1993-09-01,Scrapped,SGT-200,JP,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
S2B4/01,6137993,National Iranian Oil Company (NIOC),Dorood Generator,S2B4/01,,N/A,SGT-200 PACKAGE CX M1C E1C DS,TRAIN_S2B4/01,SGT-200-1S,2003-11-18,2004-11-18,2002-11-18,Operating,SGT-200,IR,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
BF000078U08,6553249,Bid Boland,Bid Boland II (Refrigerant compressor train),BF000078U03,NC0452/03,NC0452/03,SGT-400 PKG (Lincoln Scope NC0452) CX EX,TRAIN_BF000078U08,SGT-400,,,2016-01-31,Operating,SGT-400,IR,No Contract or Agreement,ADHOC,I4-008,,COMPRESSOR
M628/01,6054569,IKPP Tangerang - PT. Dian Swastatika Sentosa Power Station,Tangerang 1,M628/01,,N/A,SGT-100 PACKAGE C2S M2C EX DC GB,TRAIN_M628/01,SGT-100-1S,1996-01-01,1997-04-28,1995-09-01,Operating,SGT-100,ID,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NM0541/01,6690197,Qatar Gas Company Ltd,Qatar Gas,NM0541/01,BD000824U01,BD000824,SGT-100 PACKAGE C3S,TRAIN_NM0541/01,SGT-100-2S,2023-11-10,2025-11-09,2019-12-01,Operating,SGT-100,QA,,,I4-002,,GENERATOR
80/830603,6044367,Enci BV IJmuiden,Ijmuiden 1,80/830603,,N/A,SGT-200 PACKAGE GB,TRAIN_80/830603,SGT-200-1S,1986-06-09,1987-06-09,1985-06-09,Not Running – Not Preserved,SGT-200,NL,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NW0043/03,6112074,Dummy Account: Europe - Russia,TOMSKNEFT,NW0043/03,NW0043/03,N/A,SGT-300 PACKAGE Project cancelled,TRAIN_NW0043/03,TEMPEST,2005-10-21,2007-04-21,2004-06-17,Cancelled,SGT-300,RU,No Contract or Agreement,ADHOC,I4-007,10,GENERATOR
M558/01,6038140,Contour Global Solutions Rio Ltda.,Brahma 3,M558/01,,N/A,SGT-100 PACKAGE C3S EX GB,TRAIN_M558/01,SGT-100-1S,1996-01-01,1996-05-13,1995-01-01,Operating,SGT-100,BR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NU0024/02,6047199,Lukoil KGPZ Kotovo,Siberia 1 & 2,NU0024/02,,N/A,SGT-100-2S PACKAGE C2S M1C E1C DS GB,TRAIN_NU0024/02,SGT-100-2S,2004-01-01,2007-02-15,2003-10-01,Operating,SGT-100,RU,Frame Agreement,ADHOC,I4-003,30,COMPRESSOR
NC0162/02,6111993,"Dummy Account: Middle East - Iran, Islamic Republic of",Kharg Feed Gas,NC0162/02,,N/A,SGT-400 PACKAGE M1C E1C DS,TRAIN_NC0162/02,SGT-400,,,2009-04-01,Storage,SGT-400,IR,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
W9G4/01,6049698,IREN ENERGIA (S.E.I. Cento),SEI - Cento 1,W9G4/01,,N/A,SGT-300 PACKAGE GB,TRAIN_W9G4/01,SGT-300,2000-12-06,2001-08-01,1999-12-06,Not Running – Preserved,SGT-300,IT,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
NU0215/03,6044316,Caspian Pipeline Consortium (CPC-R) - APS4A,CPC APS4-A,NU0215/03,NU0215/03,N/A,SGT-100-2S C3C M2C E1C DC GB HC,TRAIN_NU0215/03,SGT-100-2S,2016-12-29,2018-12-28,2010-05-01,Operating,SGT-100,RU,Frame Agreement – Includes RDS,ADHOC,I4-003,30,COMPRESSOR
BD000311U01,6553267,ZNGT- Jing de Zhen,Jing De Zhen Phase II,BD000311U01,NA0449/01,NA0449/01,SGT-400 PKG (LCN SCOPE NA0449) C3S EX GB,TRAIN_BD000311U01,SGT-400,2016-09-26,2017-09-25,2015-12-31,Operating,SGT-400,CN,No Contract or Agreement,ADHOC,I4-008,,GENERATOR
NA0016/03,6011873,Alyeska - Pump Station 04,PS4 SGT-400 #2,NA0016/03,HI6001/03,1550001,SGT-400 PKG (HI6001) CX M2S E2S DS GB,TRAIN_NA0016/03,SGT-400,2009-06-01,2011-05-30,2004-04-01,Operating,SGT-400,US,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
NM0069/03,6054664,Chunxiao Platform - CNOOC China Ltd.,Chunxiao Platform,NM0069/03,,N/A,SGT-100 PACKAGE C2S M2C E1C DC GB,TRAIN_NM0069/03,SGT-100-1S,2004-09-24,2006-04-14,2003-06-24,Operating,SGT-100,CN,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
R008/01,6032780,Darent Power Limited - Sandwich,Pfizer 1,J215/01,R008/01,N/A,SGT-200 PACKAGE WAS J215/01,TRAIN_R008/01,SGT-200-1S,1995-01-02,1996-01-02,1994-01-02,Scrapped,SGT-200,GB,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
BD001291U02,6706961,Riverton Power Station,,BD001291U02,NA1291/02,,,,SGT-400,2025-10-01,2026-09-29,2023-12-01,Order,SGT-400,US,,,,,
W8F0/02,6108660,"Dummy Account: Middle East - Iran, Islamic Republic of",Salman,W8F0/02,,,,,Tempest - RW,1998-09-01,1999-09-01,1997-09-01,Cancelled,SGT-300,IR,No Contract or Agreement,ADHOC,,,
M445/01,6055987,Japan - Misc,Takuma,M445/01,,,,,SGT-100-1S,1994-01-01,1995-01-01,1993-01-01,Scrapped,SGT-100,JP,No Contract or Agreement,ADHOC,,,
SGTEQUIPMENT07,6703839,Gowrings Continental B.V.,,SGTEQUIPMENT07,,,,,SGT-200,,,,Pre-Order,SGT-200,NL,,,,,
BD000140U01,6180257,Dana Petroleum Limited - Western Isles Development Project,Western Isles development project,BD000140U01,NA0346/01,NA0346/01,SGT-400 PKG (NA0346) C3S M1S EX GB,TRAIN_BD000140U01,SGT-400,2016-09-12,2017-09-11,2013-02-28,Operating,SGT-400,GB,Other and 24/7 Global Helpdesk and/or RDS are not included,ADHOC,I4-008,10,GENERATOR
U0C1/01,6079544,QatarEnergy Iddel Shargi North Dome (ISND) - PS1 Offshore Platform,QP / PS1,U0C1/01,,N/A,SGT-100-2S PACKAGE C3S M2C E1C DC GB,TRAIN_U0C1/01,SGT-100-2S,2000-01-01,2002-06-19,1996-08-01,Operating,SGT-100,QA,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
M0E7/02,6043922,Batıçim Enerji,M0E6/01-Rm326-Gt1,M0E7/02,,N/A,SGT-100 PACKAGE - MOTHBALLED GB,TRAIN_M0E7/02,SGT-100-1S,2002-03-07,2003-03-07,2001-03-07,Scrapped,SGT-100,TR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S583/01,6054629,Asahi Chemical,Asahi Chemical #1,S583/01,,N/A,SGT-200 PACKAGE - RETIRED GB,TRAIN_S583/01,SGT-200-1S,1996-01-01,1996-10-31,1995-06-01,Not Running – Not Preserved,SGT-200,JP,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
U2C8/01,6075479,SBM OFFSHORE - FPSO Brasil CONTA INATIVA,SBM Brasil,U2C8/01,,N/A,SGT-100-2S PACKAGE C2S,TRAIN_U2C8/01,SGT-100-2S,2002-09-19,2003-09-19,2001-09-19,Scrapped,SGT-100,BR,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
HI4071/03,6154000,Gas Transmission Co Ltd,GTCL Elanga (HGO / Hyundai Engineering),HI4071/03,NU0298/03,NU0298/03,SGT-1002S PKG(LCN NU0298)C3S M2C EX DC,TRAIN_HI4071/03,SGT-100-2S,2016-03-28,2018-04-27,2012-02-28,Operating,SGT-100,BD,Preventive LTP – Includes RDS,LTP,I4-003,30,COMPRESSOR
S750/01,6038134,Petróleo Brasileiro S/A - Petrobras - E&P/SSE/UN-BC/ATP-MRL/FPSO P35,FPSO P35,S750/01,S750/01B,N/A,SGT-200 PACKAGE EX GB,TRAIN_S750/01,SGT-200-1S,1997-05-22,1998-07-01,1996-01-01,Not Running – Preserved,SGT-200,BR,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
HI6068/02,6680133,Southern California Gas Company-Blythe,SoCalGas Blythe_Worley Parsons,HI6068/02,NJ0518/02 - BD000593U02,NJ0518/02 BD000593,SGT-300-2S PKG (LCN NJ0518) CX M2C EX DC,TRAIN_HI6068/02,SGT-300-2S,2021-12-31,2022-11-29,2017-12-31,Operating,SGT-300,US,Frame Agreement,ADHOC,I4-022,,COMPRESSOR
NA0016/02,6011874,Alyeska - Pump Station 03,PS3 SGT-400 #2,NA0016/02,HI6001/02,1550001,SGT-400 PKG (HOU HI6001)CX M2S E2S DS GB,TRAIN_NA0016/02,SGT-400,2007-11-01,2009-10-31,2004-04-01,Operating,SGT-400,US,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
M760/01,6032495,BBC - British Broadcasting Co,BBC 2,M760/01,,N/A,SGT-100 PACKAGE - MOTHBALLED,TRAIN_M760/01,SGT-100-1S,1998-01-01,2001-10-17,1997-03-01,Scrapped,SGT-100,GB,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M231/02,6044340,AB Mauri Italy S.p.A. - Casteggio Lieviti S.r.l.,GBI,M231/02,,N/A,SGT-100 PACKAGE CX M2C EX DC GB,TRAIN_M231/02,SGT-100-1S,1993-04-01,1994-04-01,1992-04-01,Operating,SGT-100,IT,Preventive LTP,LTP,I4-002,10,GENERATOR
BD001025U01,6695205,CNOOC-Wenchang,HGGT Wenchang II,BD001025U01,NA0561/01,NA0561/01,SGT-400 PACKAGE (NA0561/01) C3S M1S,TRAIN_BD001025U01,SGT-400,2023-11-13,2025-01-12,2021-06-01,Operating,SGT-400,CN,Preventive LTP,LTP,I4-008,,GENERATOR
NA0017/04,6047967,OAO Uralkali-Berezniki-OAO URALKALI,OAO URALKALI,NA0017/04,NA0017/04,N/A,SGT-400 C3S M2S E2S DS GB,TRAIN_NA0017/04,SGT-400,2013-01-24,2014-01-24,2005-04-01,Operating,SGT-400,RU,Preventive LTP,LTP,I4-008,10,GENERATOR
NW1279/01,6706960,Centrax Ltd.,Centrax 026 (British Sugar Cantley),NW1279/01,BD001279U01,BD001279U01,SGT-300-1S PACKAGE,TRAIN_NW1279/01,SGT-300,2024-09-01,2026-09-29,2023-12-01,Order,SGT-300,GB,,,I4-007,,
R809/01,6032918,Petrofac Facilities,Kittiwake 1,R809/01,,N/A,SGT-200 PACKAGE C2S M2C EX DC GB,TRAIN_R809/01,SGT-200-1S,1988-11-01,1989-10-31,1987-01-01,Operating,SGT-200,GB,Call off Agreement / Support LTP,ADHOC,I4-005,10,GENERATOR
NA0288/01,6156036,Centrax Ltd.,Centrax 001 (Verseo),NA0288/01,NA0288/01,N/A,SGT-400 PACKAGE C3C EX GB HC,TRAIN_NA0288/01,SGT-400,2012-11-07,2013-11-06,2011-12-31,Operating,SGT-400,GB,Corrective LTP – Includes RDS,LTP,I4-008,10,GENERATOR
NR0034/01,216542,The Boeing Company,Boeing St. Louis,NR0034/01,HOU,HI4050 1405022,SGT-200 PACKAGE <C M2C E2S DC GB 2021,TRAIN_NR0034/01,SGT-200-2S,2005-05-13,2006-01-23,2004-03-01,Operating,SGT-200,US,Corrective LTP – Includes RDS,LTP,I4-006,30,COMPRESSOR
HI6096/02,6690206,PETRONAS Carigali Sdn Bhd - Sarawak Operations - E11P-B PLATFORM,Petronas Kasawari,HI6096/02,NJ0552/02 - BD000913U02,NJ0552/02 BD000913,SGT-300-2S PACKAGE (NJ0552) CX,TRAIN_HI6096/02,SGT-300-2S,2024-02-01,2027-01-30,2020-03-01,Installation & Commissioning,SGT-300,MY,,,I4-022,,COMPRESSOR
NA0020/01,6011869,Riverbay Corporation,RIVERBAY,NA0020/01,HOU,1405043,SGT-400 (HI4043) C3C M2C E2S DC GB HC,TRAIN_NA0020/01,SGT-400,2008-07-01,2009-06-30,2006-01-01,Operating,SGT-400,US,Corrective LTP – Includes RDS,LTP,I4-008,10,GENERATOR
NR0041/03,6032714,WINGAS Storage UK Ltd. - Saltfleetby,Saltfleetby,NR0041/03,NR0041/03,N/A,SGT-200-2S PACKAGE,TRAIN_NR0041/03,SGT-200-2S,,,2006-05-01,Cancelled,SGT-200,GB,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NW0108/03,6079504,Altera-Ocyan - FPSO Cidade de Itajai,SGT Charlie,NW0108/03,,N/A,SGT-300 C3C M2C E2S DC GB,TRAIN_NW0108/03,SGT-300-1S,2012-11-05,2013-11-04,2007-06-18,Operating,SGT-300,BR,Preventive LTP – Includes RDS,LTP,I4-021,10,GENERATOR
NM0122/01,6052846,In Salah Gas Field - Reg - Teg - Krechba,In Salah Gas Field - Teg,NM0122/01,,N/A,SGT-100 PACKAGE C2S M2C E1C DC GB,TRAIN_NM0122/01,SGT-100-1S,2010-12-14,2012-12-13,2008-01-07,Operating,SGT-100,DZ,Call Off Contract Goods and Services,ADHOC,I4-002,10,GENERATOR
A0K8/01,6051163,ADNOC Offshore -ABK,Khuff Gas (PSP),A0K8/01,,N/A,SGT-400 PACKAGE C3S E2C M2C DC =GB,TRAIN_A0K8/01,SGT-400,2002-01-08,2003-01-08,2001-01-08,Operating,SGT-400,AE,Framework Agreement Goods and Services,ADHOC,I4-008,10,GENERATOR
S011/01,6043652,"Azuliber 1, S.L.",Azuliber,S011/01,,N/A,SGT-200 PACKAGE GB,TRAIN_S011/01,SGT-200-1S,1991-01-01,1992-02-07,1990-03-01,Not Running – Not Preserved,SGT-200,ES,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NA0478/01,6666271,Centrax Ltd.,Centrax 015 (Saillat),NA0478/01,BD000420U01,BD000420,SGT-400 PACKAGE C4S EX GB HC,TRAIN_NA0478/01,SGT-400,2018-11-08,2019-11-07,2017-06-30,Operating,SGT-400,GB,Corrective LTP – Includes RDS,LTP,I4-008,,GENERATOR
A0C4/02,6141713,National Iranian Gas Company (NIGC) (HQ),Tabriz 2,A0C4/02,,N/A,SGT-400 PACKAGE CX M2S E1C DS <GB,TRAIN_A0C4/02,SGT-400,2001-08-10,2002-08-10,2000-08-10,Operating,SGT-400,IR,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
NS0121/02,6046462,Nitramonia BC,ZNGT Romania,NS0121/02,,N/A,SGT-200 PACKAGE CX MX EX DX,TRAIN_NS0121/02,SGT-200-1S DLE,2013-01-14,2014-01-13,2007-12-01,Operating,SGT-200,RO,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NS0451/02,6553254,BP West Nile Delta,BP West Nile Delta,NS0451/02,BD000321U02,N/A,SGT-200 PACKAGE C3S M2C E2S DC GB,TRAIN_NS0451/02,SGT-200-1S,2019-07-26,2021-07-25,2016-02-28,Operating,SGT-200,EG,Preventive LTP – Includes RDS,LTP,I4-005,,GENERATOR
M656/03,6142066,National Iranian Oil Company (NIOC) HQ,Aboozar Platform,M656/03,,N/A,SGT-100 PACKAGE C2S M2C EX DC GB,TRAIN_M656/03,SGT-100-1S,1999-01-01,2003-08-24,1996-01-01,Operating,SGT-100,IR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S540/02,6038785,Dirección Provincial de Energía -Tierra del Fuego - Central Termoeléctrica Ushuaia (DPE) - Ushuaia,Ushuaia,S540/02,,N/A,SGT-200 PACKAGE CX EX GB,TRAIN_S540/02,SGT-200-1S,1995-01-01,1996-02-09,1994-01-01,Operating,SGT-200,AR,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
W728/02,6045115,Jannah Hunt Oil Company,Dahab Substation 2 (Ex VPK),W728/02,9060/007,N/A,SGT-300 PACKAGE CX GB,TRAIN_W728/02,SGT-300,,,1996-04-01,Installation & Commissioning,SGT-300,YE,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
M241/01,6059167,Italcogeneración S.A.,Italgen S.A.,M241/01,,N/A,SGT-100 PACKAGE - MOTHBALLED GB,TRAIN_M241/01,SGT-100-1S,1992-10-26,1993-10-26,1992-04-01,Scrapped,SGT-100,ES,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M636/01,6047029,Laiterie du Val d'Ancenis (LVA)-Nantes-Ancenis,Ancenis,M636/01,,N/A,SGT-100 PACKAGE C2S GB,TRAIN_M636/01,SGT-100-1S,1997-10-06,1998-10-06,1996-10-06,Not Running – Not Preserved,SGT-100,FR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M661/01,6141676,Dummy Account: Asia Pacific - Japan,Kashima Gtpp 1,M661/01,,N/A,SGT-100 PACKAGE - WOODGROUP - SCRAPPED,TRAIN_M661/01,SGT-100,1997-01-01,1998-06-13,1996-06-01,Scrapped,SGT-100,JP,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M514/01,6047384,Wintershall DEA GmbH - Mittelplate - Offshore Platform,Mittelplatte (offshore),M514/01,,N/A,SGT-100 PACKAGE - C2S M2C EX DC GB,TRAIN_M514/01,SGT-100-1S,1995-01-01,1996-09-01,1994-01-01,Operating,SGT-100,DE,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S8D5/01,6038506,PEP-Cantarell Offshore-APC-Akal B,Akal B (Offshore),S8D5/01,S8D5/01,N/A,SGT-200 PACKAGE C1C EX,TRAIN_S8D5/01,SGT-200-1S,2000-01-15,2001-01-14,1999-01-15,Operating,SGT-200,MX,Frame Agreement,ADHOC,I4-005,10,GENERATOR
M2D4/01,6048966,Burullus (Rashpetco),Burullus,M2D4/01,,N/A,SGT-100 PACKAGE C2S GB,TRAIN_M2D4/01,SGT-100-1S,2002-05-01,2004-02-14,2001-05-01,Not Running – Not Preserved,SGT-100,EG,,,I4-002,10,GENERATOR
NR0117/04,6048076,UEP Beta GmbH [formerly OMV (Pakistan) Exploration Gesellschaft m.b.H.)- Sawan Gas Field,UEP Sawan,NR0117/04,,N/A,SGT-200 PACKAGE C3S M2C E1C DC GB,TRAIN_NR0117/04,SGT-200-2S,2010-05-04,2011-05-03,2008-01-01,Operating,SGT-200,PK,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
M106/01,6141689,Dummy Account: Asia Pacific - Japan,Osaka Sanyo,M106/01,,N/A,SGT-100 PACKAGE - SCRAPPED,TRAIN_M106/01,SGT-100-1S,1993-11-15,1994-11-15,1992-01-01,Scrapped,SGT-100,JP,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S2D0/01,6050092,Single Buoy Moorings - FPSO Serpentina,Power Gen A,S2D0/01,,N/A,SGT-200 PACKAGE C4S M2C E1C DC GB HS,TRAIN_S2D0/01,SGT-200-1S,2002-01-01,2004-09-13,2001-01-01,Operating,SGT-200,GQ,Preventive LTP – Includes RDS,LTP,I4-005,10,GENERATOR
M613/01,6048299,Pamesa,Pamesa,M613/01,,N/A,SGT-100 PACKAGE - DEACTIVATED GB,TRAIN_M613/01,SGT-100-1S,1996-09-01,1996-11-21,1995-09-01,Not Running – Not Preserved,SGT-100,ES,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M3C4/03,6054811,"PFI, Asaka (HPS)",PFI,M3C4/03,NM0064/03,N/A,SGT-100 C3S M2C E2S DC GB,TRAIN_M3C4/03,SGT-100-1S,2005-07-01,2005-04-01,2001-11-30,Operating,SGT-100,JP,Corrective LTP – Includes RDS,LTP,I4-002,10,GENERATOR
M580/01,6038831,Chevron Argentina S.R.L. (ex-Sullair Argentina S.A.) - El Trapial,San Jorge 1,M580/01,,N/A,SGT-100 PACKAGE C3S M2C E1C DC GB,TRAIN_M580/01,SGT-100-1S,1996-06-12,1997-06-12,1995-06-12,Operating,SGT-100,AR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
BD000129U03,6362605,Nizhnevolzhskneft Ltd./Filanovsky Field,Filanovsky LSP-3,BD000129U03,NA0335/03,NA0335/03,SGT-400 (LCN NA0335) C3C M2S EX GB HC,TRAIN_BD000129U03,SGT-400,2017-04-30,2021-05-30,2012-12-31,Operating,SGT-400,RU,Preventive LTP,LTP,I4-008,10,GENERATOR
NR0033/10,6053136,Sonatrach TRC - OZ2,OZ2,NR0033/10,R3D8/10,N/A,SGT-200-2S PACKAGE CX M2C E1C DC,TRAIN_NR0033/10,SGT-200-2S,2004-01-06,2007-03-06,2003-01-06,Operating,SGT-200,DZ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
BC000154U03,6553203,TAP Albania,TAP Albania,BC000154U03,NC0448/03,NC0448/03,SGT-400 PKG (LCN NC0448) C3C M1S E HC,TRAIN_BC000154U03,SGT-400,2021-03-30,2023-03-29,2016-02-01,Operating,SGT-400,AL,Preventive LTP – Includes RDS,LTP,I4-008,,COMPRESSOR
NS0451/01,6553251,BP West Nile Delta,BP West Nile Delta,NS0451/01,BD000321U01,N/A,SGT-200 PACKAGE C3S M2C E2S DC GB,TRAIN_NS0451/01,SGT-200-1S,2019-07-20,2021-07-19,2016-02-28,Operating,SGT-200,EG,Preventive LTP – Includes RDS,LTP,I4-005,,GENERATOR
NM0463/01,6553600,Green Logix NV,Farm Frites,NM0463/01,BD000359U01,BD000359,SGT-100 PACKAGE C3S M2C E1C DC,TRAIN_NM0463/01,SGT-100-1S,2017-11-23,2018-11-22,2016-06-01,Operating,SGT-100,BE,Corrective LTP – Includes RDS,LTP,I4-002,,GENERATOR
A0G8/01,6032484,National Gas - Nether Kellet,NG Nether Kellett 1,A0G8/01,NC0003/01,N/A,SGT-400 PACKAGE C1S M2C E1C DC GB,TRAIN_A0G8/01,SGT-400,2002-10-02,2003-10-02,2001-10-02,Operating,SGT-400,GB,Frame Agreement,ADHOC,I4-008,30,COMPRESSOR
S040/01,6032604,International Paper Ltd - Inverurie Mill,Inverurie Mill 1,S040/01,,N/A,SGT-200 PACKAGE GB,TRAIN_S040/01,SGT-200-1S,1991-09-03,1992-09-01,1990-01-01,Scrapped,SGT-200,GB,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NC0033/07,6039095,PDVSA - Los Morros - ICO Los Morros,"ICO, Los Morros",NC0033/07,HI6011,HI6011 1550011,SGT-400 (HOU HI6011) M2C E1C DC GB,TRAIN_NC0033/07,SGT-400,,,2007-04-13,Not Running – Not Preserved,SGT-400,VE,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
BD001113U01,6696231,Consolidated Edison Co. of New York Inc.,ASTORIA LNG,BD001113U01,NU0569/01,NU0569/01,SGT-100-2s PACKAGE,TRAIN_BD001113U01,SGT-100-2S,2025-05-01,2026-04-29,2022-03-31,Order,SGT-100,US,,,I4-003,,
NU0026/01,6138009,Chamran Pump Station (NIORDC),Chamran,NU0026/01,NU0026/01,N/A,SGT-100-2S PACKAGE C2S M2C EX DC GB,TRAIN_NU0026/01,SGT-100-2S,,,2004-03-01,Operating,SGT-100,IR,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
M412/01,6046078,Carl Macher GmbH & Co. KG - Köditz,Hof Energie (LTP),M412/01,9100/078,N/A,SGT-100 PACKAGE C2S M2C EX DC GB,TRAIN_M412/01,SGT-100-1S,1994-01-01,1994-11-22,1993-09-01,Operating,SGT-100,DE,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
LS0002/02,6043403,AL KARKARA - Doha - AL KARKARA,AL KARKARA,LS0002/02,S505/02,S505/02,SGT-200 - EX S505/01 C3S M2C E2S DC GB,TRAIN_LS0002/02,SGT-200-1S,2008-04-16,2009-10-16,2004-04-28,Operating,SGT-200,QA,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
U0A3/02,6044331,Caspian Pipeline Consortium (CPC-R) - Kropotkin,CPC Kropotkin,U0A3/02,,N/A,SGT-100-2S PACKAGE C3S M2C E1C DC GB,TRAIN_U0A3/02,SGT-100-2S,2001-01-22,2003-12-14,1999-04-23,Operating,SGT-100,RU,Frame Agreement – Includes RDS,ADHOC,I4-003,30,COMPRESSOR
R934/01,6051037,Thyssengas GmbH - Hünxe,RWE Hunxe,R934/01,,N/A,SGT-200-2S PACKAGE,TRAIN_R934/01,SGT-200-2S,1990-01-01,1992-06-15,1989-01-01,Not Running – Not Preserved,SGT-200,DE,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
M8D6/01,6038133,EnergyWorks do Brasil Ltda - Jacareí - Femsa (Plant fully decommissioned),Energy Works 2,M8D6/01,,N/A,SGT-100 PACKAGE - MOTHBALLED GB,TRAIN_M8D6/01,SGT-100-1S,1998-01-01,1999-03-23,1997-03-01,Not Running – Not Preserved,SGT-100,BR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NC0162/04,6111991,"Dummy Account: Middle East - Iran, Islamic Republic of",Kharg Feed Gas,NC0162/04,,N/A,SGT-400 PACKAGE M1C E1C DS,TRAIN_NC0162/04,SGT-400,,,2009-04-01,Storage,SGT-400,IR,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
M2F6/01,6054769,"Komatsu, Oyama","Komatsu, Oyama",M2F6/01,,N/A,SGT-100 PACKAGE C2S M1C E1C DS GB,TRAIN_M2F6/01,SGT-100-1S,2000-05-08,2001-05-08,1999-11-10,Not Running – Not Preserved,SGT-100,JP,Frame Agreement,ADHOC,I4-002,10,GENERATOR
HI6081/01,6694920,"Esentia Compression Solutions, S. de R.L. de C.V. -  San Juan",Fermaca Kratos - San Juan 1,HI6081/01,NC0530/01 / BD000751U01,NC0530/01 BD000751,SGT-400 PKG (LCN NC0530) C3S EX DC,TRAIN_HI6081/01,SGT-400,2024-02-01,2025-01-30,2019-03-01,Installation & Commissioning,SGT-400,MX,,,I4-008,,COMPRESSOR
R2E4/03,6053164,Sonatrach TRC - OZ2,OZ2,R2E4/03,,N/A,SGT-200-2S PACKAGE M2C E1C DC 2002,TRAIN_R2E4/03,SGT-200-2S,2002-09-19,2006-03-29,2001-09-19,Operating,SGT-200,DZ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
S125/03,6032807,Shell UK - Nelson Platform,Nelson Field 3,S125/03,,N/A,SGT-200 PACKAGE C1S M2C E1C DC GB 2016,TRAIN_S125/03,SGT-200-1S,1991-01-01,2014-03-01,1990-01-01,Operating,SGT-200,GB,Frame Agreement,ADHOC,I4-005,10,GENERATOR
BD000042U01,6104690,VCNG Power,VCNG Power,BD000042U01,NA0242/01,NA0242/01,SGT-400 (NA0242) C3C M2C E2S DC GB HC,TRAIN_BD000042U01,SGT-400,2016-02-03,2018-02-02,2011-01-01,Operating,SGT-400,RU,Preventive LTP,LTP,I4-008,10,GENERATOR
HI6051/02,6437585,NUVOIL S.A. DE C.V.,TREN 2,HI6051/02,NC0375/02,NC0375/02,SGT-400 (LCN NC0375) C3S M2S E2S DS GB,TRAIN_HI6051/02,SGT-400,2016-08-14,2017-08-13,2013-09-30,Operating,SGT-400,MX,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
NW0244/02,6150318,Halfaya Oil Field (PetroChina),CNPC,NW0244/02,,N/A,SGT-300 PACKAGE C3S M2C E2S DC GB,TRAIN_NW0244/02,SGT-300,2013-02-11,2015-02-10,2011-03-12,Operating,SGT-300,IQ,RDS,ADHOC,I4-021,10,GENERATOR
S2A5/02,6051105,Zarubezhneft–Production Kharyaga LLC,Kharyaga 2 #1 Thru 4,S2A5/02,,N/A,SGT-200 PACKAGE <C M1C E2S DS GB 2022,TRAIN_S2A5/02,SGT-200-1S,2002-01-01,2004-03-29,2001-02-01,Operating,SGT-200,RU,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
BF000063U02,6544045,Nawara OMV (GTP/CPF),Nawara Gas Treatment Plant (GTP),BF000063U02,NC0416/02,NC0416/02,SGT-400 PKG (LCN NC0416) C3S M2S E1C DS,TRAIN_BF000063U02,SGT-400,2020-06-29,2022-06-28,2015-03-31,Operating,SGT-400,TN,Other and 24/7 Global Helpdesk and/or RDS included,ADHOC,I4-008,30,COMPRESSOR
BC000166U06,6662744,Palayesh Parsian Sepehr Company,Mohr Parsian,BC000166U06,NU0476/02,NU0467/02,SGT-100-2S PACKAGE(NU0467)CX M1S EX,TRAIN_BC000166U06,SGT-100-2S,,,2016-06-30,Operating,SGT-100,IR,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
HI6064/03,6553357,"Esentia Pipeline de Occidente, S. de R.L. de C.V - Villa de Reyes",Villa de Reyes,HI6064/03,NC0469/01,NC0469/01,SGT-400 PKG (LCN NC0469) C3S M2C EX DC,TRAIN_HI6064/03,SGT-400,2020-11-01,2024-10-30,2016-06-30,Operating,SGT-400,MX,Preventive LTP – Includes RDS,LTP,I4-008,,COMPRESSOR
NA0509/01,6673460,"AECC Gas Turbine (Zhuzhou) Co., Ltd.  (ZNGT)",ZNGT NanJing Pukou,NA0509/01,BD000561U01,BD000561,SGT-400 PACKAGE (BD000561) C3S M1S EX GB,TRAIN_NA0509/01,SGT-400,2024-03-01,2026-02-27,2017-11-30,Installation & Commissioning,SGT-400,CN,No Contract or Agreement,ADHOC,I4-008,,GENERATOR
BC000072U02,6541055,GAZ-SYSTEM S.A. - Compressor station Jeleniów II,Gazociagów,BC000072U02,NU0391/02,NU0391/02,SGT-100-2S PKG(LCN NU0391)C1S M1C E2S DS,TRAIN_BC000072U02,SGT-100-2S,2016-10-19,2018-10-18,2014-02-28,Operating,SGT-100,PL,Preventive LTP,LTP,I4-003,30,COMPRESSOR
HI4066/02,6039042,Distributed Power Solutions (DPS) formally ERS-CAT,Trailer Mounted Generator Set,HI4066/02,,NA0209/02 HI4057,SGT-400 PACKAGE (NA0209) CANCELLED,TRAIN_HI4066/02,SGT-400,,,2019-03-21,Not Running – Preserved,SGT-400,US,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
NR0033/06,6058267,Sonatrach TRC - OZ2,OZ2,NR0033/06,R3D8/06,N/A,SGT-200-2S PACKAGE CX M2C E1C DC,TRAIN_NR0033/06,SGT-200-2S,2004-01-01,2007-03-07,2003-01-06,Operating,SGT-200,DZ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
M8J8/01,6048558,ConocoPhillips Eldfisk 2/7 E,Eldfisk 3- 5 Standby,M8J8/01,,N/A,SGT-100 PACKAGE C2S M1C E1C DS GB,TRAIN_M8J8/01,SGT-100-1S,1999-01-01,1999-12-30,1998-01-01,Operating,SGT-100,NO,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NC0337/02,6197320,Uz-Kor Chemical,UGCC Akchalak,NC0337/02,,BF000008,SGT-400 PACKAGE C3C M2S E2S DS GB HC,TRAIN_NC0337/02,SGT-400,2015-07-30,2016-07-29,2012-12-31,Operating,SGT-400,UZ,Preventive LTP – Includes RDS,LTP,I4-008,30,COMPRESSOR
BD000731U01,6682266,Botas - Kuzey Marmara,Kuzey Marmara,BD000731U01,NC0527/01 - BC000294U01,NC0527/01,SGT-400 PACKAGE C3S M1S,TRAIN_BD000731U01,SGT-400,2023-12-01,,2018-12-31,Installation & Commissioning,SGT-400,TR,No Contract or Agreement,ADHOC,I4-008,,COMPRESSOR
S8K8/05,6053098,Sarir Oil Operations - Jakhira Field,Jakhira 3,S8K8/05,,N/A,SGT-200 PACKAGE CX M2C EX DC GB,TRAIN_S8K8/05,SGT-200-1S,1999-03-01,2000-03-09,1998-03-01,Operating,SGT-200,LY,Other and 24/7 Global Helpdesk and/or RDS included,ADHOC,I4-005,10,GENERATOR
M579/01,6050102,Fillattice,Fillattice,M579/01,,N/A,SGT-100 PACKAGE - SITE SHUT GB,TRAIN_M579/01,SGT-100-1S,1997-02-01,1997-09-29,1996-02-01,Scrapped,SGT-100,IT,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NS0125/03,6046466,AMURCO,ZNGT Romania,NS0125/03,,N/A,SGT-200 PACKAGE,TRAIN_NS0125/03,SGT-200-1S DLE,2014-04-26,2015-04-25,2008-07-01,Not Running – Not Preserved,SGT-200,RO,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NA0137/01,6043573,"ASFALTOS ESPAÑOLES, S.A. (ASESA) - TARRAGONA",ASFALTOS ESPANOLES SA,NA0137/01,NA0137/01,N/A,SGT-400 PACKAGE C3S M2C E2S DC GB HC,TRAIN_NA0137/01,SGT-400,2010-04-09,2011-04-08,2008-05-01,Operating,SGT-400,ES,Corrective LTP – Includes RDS,LTP,I4-008,10,GENERATOR
S8A3/02,6141716,National Iranian Oil Company (NIOC) HQ,Sirri Island,S8A3/02,,N/A,SGT-200 PACKAGE M2C EX DC GB 2010,TRAIN_S8A3/02,SGT-200-1S,1998-01-01,2003-11-13,1997-04-01,Operating,SGT-200,IR,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NS0226/01,6104656,Chrysaor  Petroleum - Judy Riser Platform,Conoco Phillips,NS0226/01,,N/A,SGT-200 PACKAGE C3S M2C E2S DC GB,TRAIN_NS0226/01,SGT-200-1S,2014-01-22,2016-01-21,2010-07-01,Operating,SGT-200,GB,Call off Agreement / Support LTP,ADHOC,I4-005,10,GENERATOR
M8G3/01,6032753,INEOS - Newton Aycliffe,Inovyn 2,M8G3/01,,N/A,SGT-100 PACKAGE C2S M2C E2S DC GB,TRAIN_M8G3/01,SGT-100-1S,1999-02-01,1999-09-17,1998-03-01,Operating,SGT-100,GB,Corrective LTP – Includes RDS,LTP,I4-002,10,GENERATOR
M543/01,217903,Fortistar Methane Group - Mallard Lake Power Plant,Gas Recovery Systems Inc. Mallard Lake,M543/01,9100/143,N/A,SGT-100 PACKAGE C2S,TRAIN_M543/01,SGT-100-1S,1997-07-10,1998-07-10,1996-07-10,Scrapped,SGT-100,US,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NW0490/02,6665285,"AECC Gas Turbine (Zhuzhou) Co., Ltd.  (ZNGT)",ZNGT Orient Champion Paper,NW0490/02,BD000463U02,BD000463,SGT-300 PACKAGE C3C M2C EX DC HP,TRAIN_NW0490/02,SGT-300-1S,2018-02-11,2020-02-10,2017-03-01,Operating,SGT-300,CN,Preventive LTP,LTP,I4-021,,GENERATOR
M118/02,6141699,Beco - Beco Units,Coval/Beco,M118/02,,N/A,SGT-100 PACKAGE - SITE SHUT,TRAIN_M118/02,SGT-100-1S,1991-01-01,1992-07-01,1990-01-01,Scrapped,SGT-100,IT,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
R375/02,6055587,GAIL (India) Ltd - Auraiya,Gail Aurayia,R375/02,,N/A,SGT-200-2S PACKAGE C1S M2C EX DC 2023,TRAIN_R375/02,SGT-200-2S,2015-08-07,2015-07-08,2015-04-01,Operating,SGT-200,IN,Other and 24/7 Global Helpdesk and/or RDS included,ADHOC,I4-006,30,COMPRESSOR
NC0016/05,6134882,National Iranian Oil Company (NIOC),South Pars Phase 8,NC0016/05,,N/A,SGT-400 PACKAGE CX M2C E1C DC GB,TRAIN_NC0016/05,SGT-400,2005-08-01,2009-07-30,2004-01-01,Operating,SGT-400,IR,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
BD000151U02,6436245,ZNGT - Aotou,Aotou 02,BD000151U02,NA0369/02,NA0369/02,SGT-400 (LCN NA0369) CX M1C E2S,TRAIN_BD000151U02,SGT-400,2015-08-05,2017-08-04,2013-09-30,Operating,SGT-400,CN,No Contract or Agreement,ADHOC,I4-008,,GENERATOR
NC0031/03,6054838,ONGC B&S BCPA2 Platform,OIL AND NATURAL GAS COMMISSION (ONGC),NC0031/03,,N/A,SGT-400 PACKAGE C3S M2C E2S DC GB,TRAIN_NC0031/03,SGT-400,2009-05-26,2010-05-25,2015-03-01,Operating,SGT-400,IN,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
M605/01,217904,Fortistar Methane Group - Mallard Lake Power Plant,Gas Recovery Systems Inc Mallard Lake,M605/01,9100/172,N/A,SGT-100 PACKAGE - DEACTIVATED GB,TRAIN_M605/01,SGT-100-1S,2000-03-09,2001-03-09,1999-03-09,Scrapped,SGT-100,US,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NU0025/02,6138010,Veresk Company (NIORDC),Veresk,NU0025/02,,N/A,SGT-100-2S PACKAGE - STORAGE GB,TRAIN_NU0025/02,SGT-100-2S,,,2004-03-31,Storage,SGT-100,IR,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
BD000304U01,6541614,Bangchak Petroleum Public Company Limited,Bangchak Refinery,BD000304U01,NA0440/01,NA0440/01,SGT-400 (LCN NA0440) C3C M2S EX GB HC,TRAIN_BD000304U01,SGT-400,2017-05-01,2018-04-30,2016-02-01,Operating,SGT-400,TH,Preventive LTP – Includes RDS,LTP,I4-008,,GENERATOR
BF000132U01,6680136,"Uzbekistan GTL JV, LLC / OOO",Uchkir_Premium Enerdzhi Uzbekneftegaz,BF000132U01,NC0519/01,NC0519/01,SGT-400 PKG (LCN NC0519) C3S M1S EX,TRAIN_BF000132U01,SGT-400,2021-04-30,2023-04-29,2018-06-30,Operating,SGT-400,UZ,Preventive LTP – Includes RDS,LTP,I4-008,,COMPRESSOR
R9E1/01,6038102,3R POTIGUAR S.A.,"Ubarrana, Guamare",R9E1/01,,N/A,SGT-200-2S PACKAGE EX,TRAIN_R9E1/01,SGT-200-2S,2000-07-20,2001-07-19,1998-08-23,Not Running – Not Preserved,SGT-200,BR,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NU1260/01,6706965,Oleoductos del Valle - Oldelval - Duplicar Plus,Oldelval Duplicar Plus,NU1260/01,BD001257U04,BD001260U01,SGT-100-2S PACKAGE,TRAIN_NU1260/01,SGT-100-2S,2025-09-01,2026-08-30,2023-12-01,Order,SGT-100,AR,,,I4-003,,
NC0247/08,0,,,,,N/A,SGT-400 SPARE - CORE ONLY,SANTOSGL,,,,,,,,,,I4-008,,COMPRESSOR
NA0488/01,0,,,,,N/A,SGT-400 PACKAGE (N2 LER PROTOTYPE),,,,,,,,,,,I4-008,,GENERATOR
NW0538/02,0,,,,,BD000769,SGT-300 PACKAGE (CANCELLED),,,,,,,,,,,,,
NC0229/01,0,,,,,N/A,SGT-400 PACKAGE (CANCELLED CONTRACT?),,,,,,,,,,,I4-008,,
BD001041U01,6696246,Mellitah Oil & Gas B.V - Sabratha Platform,Sabratha Platform,BD001041U01,NC0562/01,NC0562/01,SGT-400 PACKAGE (NC0562/01),TRAIN_BD001041U01,SGT-400,2024-12-01,2026-11-29,2021-10-01,Order,SGT-400,LY,,,I4-008,,COMPRESSOR
BD000445U01,0,,,,,NA0486/01,SGT-400 PKG (LINCOLN SCOPE NA0486) GB,TRAIN_BD000445U01,,,,,,,,,,I4-008,,GENERATOR
J0G2/01,6050991,TETRAPAK MANUFACTURING LIMITED - JEDDAH,TETRAPAK MANUFACTURING LIMITED,J0G2/01,80/84028-02,80/84028-02,SGT-200 PACKAGE M1C E1C GB,TRAIN_J0G2/01,SGT-200-1S,2001-01-06,2002-01-06,2000-01-06,Storage,SGT-200,SA,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NR0095/01,0,,,,,N/A,SGT-200 PACKAGE,TRAIN_NR0095/01,,,,,,,,,,I4-006,30,COMPRESSOR
W2F1/03,6055457,"Petronet LNG Ltd., Dahej",Dahej Port Lng 3,W2F1/03,,N/A,SGT-300 PACKAGE C2S M2C E2S DC GB,TRAIN_W2F1/03,SGT-300,2015-01-01,2015-01-01,2015-01-01,Operating,SGT-300,IN,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
W0G6/02,6045143,Qatar Petroleum - Halul Island,Halul Island 5,W0G6/02,,N/A,SGT-300 PACKAGE C3S M2R E1C DS,TRAIN_W0G6/02,SGT-300,2004-01-01,2005-07-13,2002-06-01,Not Running – Preserved,SGT-300,QA,Call Off Contract Goods and Services,ADHOC,I4-021,10,GENERATOR
S9C3/01,6054917,"Aisin AW HQ, Mikawa Anjo",Aishin AW HQ #1,S9C3/01,,N/A,SGT-200 PACKAGE - SCRAPPED,TRAIN_S9C3/01,SGT-200-1S,1997-10-31,2001-02-26,1997-05-31,Scrapped,SGT-200,JP,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
R804/01,0,,,,,N/A,SGT-200 PACKAGE - NEVER RAN,TRAIN_R804/01,,,,,,,,,,I4-006,30,
NW0228/02,6150830,Kuwait Oil Company - Ahmadi,SAIPEM,NW0228/02,,BD000030,SGT-300-1S PACKAGE C3S M1S EX,TRAIN_NW0228/02,SGT-300,2020-01-01,2020-12-30,2010-10-01,Operating,SGT-300,KW,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
NM0313/03,0,,,,,N/A,SGT-100 PACKAGE - CANCELLED,TRAIN_NM0313/03,,,,,,,,,,I4-001,,GENERATOR
NA0017/03,6047966,OAO Uralkali-Berezniki-OAO URALKALI,OAO URALKALI,NA0017/03,NA0017/03,N/A,SGT-400 C3S M2S E2S DS GB,TRAIN_NA0017/03,SGT-400,2015-06-19,2016-02-27,2005-04-01,Operating,SGT-400,RU,Preventive LTP,LTP,I4-008,10,GENERATOR
NU0029/01,6045186,ENAGAS DENIA,Enagas Denia,NU0029/01,,N/A,SGT-100-2S PACKAGE C2S M1C E1C DS GB,TRAIN_NU0029/01,SGT-100-2S,2014-09-25,2015-09-24,2006-11-01,Operating,SGT-100,ES,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
BD000446U01,0,,,,,NA0487/01,SGT-400 PKG (LINCOLN SCOPE NA0487) GB,TRAIN_BD000446U01,,,,,,,,,,I4-008,,GENERATOR
R743/01,217051,COLUMBIA GAS TRANSMISSION LLC,Columbia Gas Strasburg 2,R743/01,9080/218,N/A,SGT-200-2S PACKAGE,TRAIN_R743/01,SGT-200-2S,1997-01-01,1999-09-01,1996-07-01,Scrapped,SGT-200,US,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NC0021/11,0,,,,,N/A,SGT-400 SPARE CORE?,TRAIN_NC0021/11,,,,,,,,,,I4-008,30,COMPRESSOR
BD000110U01,6241644,Ithaca - FPF-1,Ithaca Stella,BD000110U01,NW0329/01,NW0329/01,SGT-300PKG(LCNSCOPENW0329)C3S M1C E1C DS,TRAIN_BD000110U01,SGT-300,2016-06-17,2017-12-16,2013-01-31,Operating,SGT-300,GB,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
M9C6/01,6054501,"Nitto Denko, Toyohashi (HPS)",Toyohashi,M9C6/01,,N/A,SGT-100 PACKAGE C2S M2C EX DC GB,TRAIN_M9C6/01,SGT-100-1S,2003-05-01,2004-04-30,2000-12-23,Scrapped,SGT-100,JP,,,I4-002,10,GENERATOR
R8A7/03,0,,,,,N/A,SGT-200-2S PACKAGE - CANCELLED ORDER,TRAIN_R8A7/03,,,,,,,,,,I4-004,,COMPRESSOR
S145/02,6050076,Silo Rothensee GmbH & Co. KG Standort Hamburg-Hamb,ADM Hamburg GT2,S145/02,,N/A,SGT-200 PACKAGE,TRAIN_S145/02,SGT-200-1S,1992-01-01,1993-09-17,1991-01-01,Scrapped,SGT-200,DE,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
W9C2/02,6032830,West Fraser Europe Ltd,Forest Products Stirling 2,W9C2/02,,N/A,SGT-300 C4S M2C E1C GB DC HS,TRAIN_W9C2/02,SGT-300,1999-03-01,2001-02-01,1998-10-01,Operating,SGT-300,GB,Corrective LTP – Includes RDS,LTP,I4-021,10,GENERATOR
NU0029/02,6045184,ENAGAS DENIA,Enagas Denia,NU0029/02,,N/A,SGT-100-2S PACKAGE C2S M1C E1C DS GB,TRAIN_NU0029/02,SGT-100-2S,2014-10-04,2015-10-03,2006-11-01,Operating,SGT-100,ES,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
BD000442U01,0,,,,,NA0483/01,SGT-400 PKG (LINCOLN SCOPE NA0483) GB,TRAIN_BD000442U01,,,,,,,,,,I4-008,,GENERATOR
BD000486U01,0,,,,,NA0500/01,SGT-400 PKG (LINCOLN SCOPE NA0500) GB,TRAIN_BD000486U01,,,,,,,,,,I4-008,,GENERATOR
BD000345U01,0,,,,,NA0457/01,SGT-400 - SEE PACKAGE NA0564/01,TRAIN_BD000345U01,,,,,,,,,,I4-008,10,GENERATOR
NC0229/02,0,,,,,N/A,SGT-400 PACKAGE (CANCELLED CONTRACT?),,,,,,,,,,,I4-008,,
NC0229/03,0,,,,,N/A,SGT-400 PACKAGE (CANCELLED CONTRACT?),,,,,,,,,,,I4-008,,
M530/01,217902,Fortistar Methane Group - Arbor Hills Landfill,Gas Recovery Systems Inc. Michigan,M530/01,9100/120,N/A,SGT-100 PACKAGE C2S EX GB,TRAIN_M530/01,SGT-100-1S,1999-01-27,2000-01-27,1994-05-01,Operating,SGT-100,US,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
R741/01,6049278,Sakhalin Energy Investment Company Ltd. - Molikpaq,Sakhalin Energy Investment Co,R741/01,,N/A,SGT-200 PACKAGE C2S EX,TRAIN_R741/01,SGT-200-2S,1997-06-05,1998-06-04,1996-04-01,Operating,SGT-200,RU,Framework Agreement Goods and Services,ADHOC,I4-006,30,COMPRESSOR
NM0071/02,6049836,Severnaya Neft-Usinsk-Khasireiskoye Oil Field Nort,Khasireiskoye Oil Field North West Siberia,NM0071/02,,N/A,SGT-100 C3C M2C E1C DC GB HC,TRAIN_NM0071/02,SGT-100-1S,2004-01-11,2006-01-04,2003-05-24,Operating,SGT-100,RU,Preventive LTP,LTP,I4-002,10,GENERATOR
NA0446/03,0,,,,,N/A,SGT-400 PACKAGE (CANCELLED),,,,,,,,,,,I4-008,,GENERATOR
HI4078/02,6437682,Yacimientos Petroliferos Fiscales Bolivianos YPFB - Rio Grande,GNL Rio Grande (Sener Ingenieria y Sistemas),HI4078/02,NW0365/02,HI4078/02,SGT-300 PKG(LCN NW0365) <C M1C E2S,TRAIN_HI4078/02,SGT-300,2015-12-30,2018-12-29,2013-07-31,Not Running – Not Preserved,SGT-300,BO,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
BD000129U01,6362603,Nizhnevolzhskneft Ltd./Filanovsky Field,Filanovsky LSP-1,BD000129U01,NA0335/01,NA0335/01,SGT-400 PKG (NA0335) C3C M2S EX GB HC,TRAIN_BD000129U01,SGT-400,2017-04-30,2021-05-30,2013-01-31,Operating,SGT-400,RU,Preventive LTP,LTP,I4-008,10,GENERATOR
A2G9/01,6141863,NIPC (HQ),KPC,A2G9/01,NC0010/01,N/A,SGT-400 PACKAGE C3S M2C E1C DC GB,TRAIN_A2G9/01,SGT-400,2003-08-02,2004-08-02,2002-08-02,Operating,SGT-400,IR,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
W8G5/01,6045205,Energetyka Cieplna Opolszczyzny (ECO) Opole,Opole,W8G5/01,,N/A,SGT-300 PACKAGE C2S M2C DC GB,TRAIN_W8G5/01,SGT-300,1999-01-01,2000-12-01,1998-01-01,Operating,SGT-300,PL,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
BD000330U01,0,,,,,NW0453/01,SGT-300 SPARE CORE (LCN NW0453),TRAIN_BD000330U01,,,,,,,,,,I4-021,,GENERATOR
M377/02,6046938,Krasnodar Heat and Power-Krasnodar-Krasnodar,Krasnodar 2,M377/02,,N/A,SGT-100 PACKAGE C2S GB,TRAIN_M377/02,SGT-100-1S,1995-01-01,1996-01-01,1993-09-01,Not Running – Not Preserved,SGT-100,RU,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NA0373/03,0,,,,,N/A,SGT-400 PACKAGE GB,TRAIN_NA0373/03,,,,,,,,,,I4-008,10,GENERATOR
NS0351/02,6368133,ZNGT- Jing de Zhen,ZNGT Jing de Zhen,NS0351/02,,N/A,SGT-200 PACKAGE GB,TRAIN_NS0351/02,SGT-200-1S,2014-01-11,2015-01-10,2013-06-30,Not Running – Not Preserved,SGT-200,CN,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M8E7/01,6054502,"Nitto Denko, Toyohashi (HPS)",Toyohashi # 1,M8E7/01,,N/A,SGT-100 PACKAGE C2S M1C E1C GB,TRAIN_M8E7/01,SGT-100-1S,2003-05-15,2004-05-14,2001-01-22,Not Running – Not Preserved,SGT-100,JP,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NW0161/01,0,,,,,N/A,SGT-300 PKG WORK STOCK ORDER N TYPE,TRAIN_NW0161/01,,,,,,,,,,I4-021,10,GENERATOR
W2E0/01,0,,,,,N/A,SGT-300 PACKAGE - UNKNOWN,TRAIN_W2E0/01,,,,,,,,,,I4-021,10,GENERATOR
M531/01,217900,Fortistar Methane Group - Arbor Hills Landfill,Gas Recovery Systems Inc. Michigan,M531/01,9100/121,N/A,SGT-100 PACKAGE - CX,TRAIN_M531/01,SGT-100-1S,1998-03-09,1999-03-09,1997-03-09,Operating,SGT-100,US,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
U705/01,6138019,Chamran Pump Station (NIORDC),Chamran,U705/01,,N/A,SGT-100-2S PACKAGE C2S M1S EX GB,TRAIN_U705/01,SGT-100-2S,1998-11-30,1999-11-30,1997-04-01,Operating,SGT-100,IR,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
NA0113/02,6054986,"Maruti Suzuki India Ltd., Manesar",Maruti Suzuki Phase II,NA0113/02,NA0113/02,N/A,SGT-400 PACKAGE,TRAIN_NA0113/02,SGT-400,2009-02-02,2010-02-01,2007-09-01,Scrapped,SGT-400,IN,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
BD000346U01,0,,,,,NA0458/01,SGT-400 - SEE PACKAGE NA0564/02,TRAIN_BD000346U01,,,,,,,,,,I4-008,10,GENERATOR
BD000487U01,0,,,,,NA0501/01,SGT-400 PKG (LINCOLN SCOPE NA0501) GB,TRAIN_BD000487U01,,,,,,,,,,I4-008,,GENERATOR
BD000484U01,0,,,,,NA0498/01,SGT-400 PKG (LINCOLN SCOPE NA0498) GB,TRAIN_BD000484U01,,,,,,,,,,I4-008,,GENERATOR
NA0445/03,0,,,,,N/A,SGT-400 PACKAGE (CANCELLED),,,,,,,,,,,I4-008,,GENERATOR
NA0446/01,0,,,,,N/A,SGT-400 PACKAGE (CANCELLED),,,,,,,,,,,I4-008,,GENERATOR
BD000142U01,0,,,,,N/A,SGT-400 PACKAGE - CANCELLED ORDER,TRAIN_BD000142U01,,,,,,,,,,,,
TABRIZSPARE,0,,,,,N/A,TABRIZ SPARE,TRAIN_TABRIZSPARE,,,,,,,,,,I4-008,,
M649/01,6038525,PEP-Cantarell Offshore-APC-Ek A,EK-A SMC (Offshore),M649/01,M649/01,N/A,SGT-100 C2S EX GB,TRAIN_M649/01,SGT-100-1S,1997-01-01,1998-06-04,1996-09-01,Operating,SGT-100,MX,Frame Agreement,ADHOC,I4-002,10,GENERATOR
NW0538/01,0,,,,,BD000769,SGT-300 PACKAGE (CANCELLED),,,,,,,,,,,,,
NW0385/02,0,,,,,N/A,SGT-300 PACKAGE - CANCELLED,TRAIN_NW0385/02,,,,,,,,,,I4-021,10,GENERATOR
NR0107/02,6038152,Transportadora Associada de Gás S/A - TAG - Ecomp de Aracruz,SITI - GASENE,NR0107/02,HI6017/02,HI6017 1550017,SGT-200 PACKAGE C2S M2S E2S DS GB,TRAIN_NR0107/02,SGT-200-2S,2010-09-30,2011-09-29,2007-05-01,Operating,SGT-200,BR,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
BD000347U01,0,,,,,NA0459/01,SGT-400 PKG (Lincoln Scope NA0459) GB,TRAIN_BD000347U01,,,,,,,,,,I4-008,10,GENERATOR
NC0018/03,0,,,,,N/A,SGT-400 SPARE CORE?,TRAIN_NC0018/03,,,,,,,,,,I4-008,30,COMPRESSOR
NC0090/01,0,,,,,N/A,SGT-400 PACKAGE PROJECT ON HOLD,TRAIN_NC0090/01,,,,,,,,,,I4-008,30,COMPRESSOR
NW0038/01,6049694,Sedamyl,Sedamyl RW,NW0038/01,,N/A,SGT-300 C3C M2C E2S DC GB,TRAIN_NW0038/01,SGT-300,2004-05-13,2005-01-23,2004-02-01,Scrapped,SGT-300,IT,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
M603/02,6079658,Nova Energy - Edgecumbe,Nova Energy,M603/02,,N/A,SGT-100 PACKAGE C3S M2C DC GB,TRAIN_M603/02,SGT-100-1S,1996-05-06,1997-05-06,1995-05-06,Operating,SGT-100,NZ,Corrective LTP – Includes RDS,LTP,I4-002,10,GENERATOR
NW0350/02,0,,,,,N/A,SGT-300 PACKAGE,TRAIN_NW0350/02,,,,,,,,,,I4-021,10,GENERATOR
HI4066/13,6039053,PDVSA - Div Faja - Unidades Moviles Div Faja,PDVSA Mobile Power,HI4066/13,NA0209/13,NA0209/13 HI4057,SGT-400 PACKAGE (NA0209) CANCELLED,TRAIN_HI4066/13,SGT-400,,,2010-03-04,Cancelled,SGT-400,VE,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
NC0102/03,0,,,,,N/A,SGT-400 PACKAGE PROJECT ON HOLD,TRAIN_NC0102/03,,,,,,,,,,I4-008,30,COMPRESSOR
M662/01,6055064,Hibiscus Oil & Gas Malaysia Limited*,Kota Kinabalu 02,M662/01,,N/A,SGT-100 PACKAGE <C EX GB,TRAIN_M662/01,SGT-100-1S,1997-01-01,1998-06-04,1995-09-01,Operating,SGT-100,MY,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NJ0406/01,6541473,Rospan International,Rospan,NJ0406/01,,N/A,SGT-300 2S PACKAGE CX M2C E1C DC,TRAIN_NJ0406/01,SGT-300-2S,2021-11-01,2023-10-30,2014-09-30,Operating,SGT-300,RU,Preventive LTP,LTP,I4-022,,COMPRESSOR
S125/02,6032808,Shell UK - Nelson Platform,Nelson Field 2,S125/02,,N/A,SGT-200 PACKAGE C2C M2C E1C DC GB 2023,TRAIN_S125/02,SGT-200-1S,1991-01-01,2014-03-01,1990-01-01,Operating,SGT-200,GB,Frame Agreement,ADHOC,I4-005,10,GENERATOR
R0H6/02,6051102,TotalEnergies E&P Nederland BV,Total E&P Nederland BV K5PK,R0H6/02,9080/368,N/A,SGT-200-2S PACKAGE <C M1C E1C DS GB 2023,TRAIN_R0H6/02,SGT-200-2S,2001-01-01,2004-02-27,2000-01-01,Operating,SGT-200,NL,Frame Agreement – Includes RDS,ADHOC,I4-006,30,COMPRESSOR
NC0102/02,0,,,,,N/A,SGT-400 PACKAGE PROJECT ON HOLD,TRAIN_NC0102/02,,,,,,,,,,I4-008,30,COMPRESSOR
NR0107/07,6038194,Transportadora Associada de Gás S/A - TAG - EComp de Prado,SITI - GASENE,NR0107/07,HI6017/07,HI6017 1550017,SGT-200 PACKAGE C2S M2S E2S DS GB,TRAIN_NR0107/07,SGT-200-2S,2010-10-30,2011-10-29,2007-05-01,Operating,SGT-200,BR,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
U3B6/01,0,,,,,N/A,SGT-100-2S PACKAGE - CANCELLED,,,,,,,,,,,I4-003,,COMPRESSOR
U3B6/02,0,,,,,N/A,SGT-100-2S PACKAGE - CANCELLED,,,,,,,,,,,I4-003,,COMPRESSOR
M721/01,217074,GreenField Ethanol,Greenfield Ethanol Chatham Ontario Canada,M721/01,9100/218,N/A,SGT-100 PACKAGE C2S EX GB,TRAIN_M721/01,SGT-100-1S,1997-01-01,1998-02-28,1996-07-01,Operating,SGT-100,CA,Other and 24/7 Global Helpdesk and/or RDS included,ADHOC,I4-002,10,GENERATOR
R8C2/01,6038105,3R POTIGUAR S.A.,Ubarrana,R8C2/01,,N/A,SGT-200-2S PACKAGE EX,TRAIN_R8C2/01,SGT-200-2S,1999-02-28,2000-02-27,1997-01-01,Not Running – Preserved,SGT-200,BR,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
R456/01,220560,TC Energy - NGTL - Saddle Hills Station 1441,TC Energy NGTL Saddle Hills Stn.1441A2,R456/01,9080/126,N/A,SGT-200-2S PACKAGE CX EX,TRAIN_R456/01,SGT-200-2S,1994-11-27,1995-05-27,1993-04-01,Operating,SGT-200,CA,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
S525/01,6032778,Syngenta Huddersfield Limited,Dalkia Huddersfield,S525/01,,N/A,SGT-200 PACKAGE C4S M2C E1C DC GB HS,TRAIN_S525/01,SGT-200-1S,1995-01-01,1996-06-16,1994-03-01,Operating,SGT-200,GB,Corrective LTP – Includes RDS,LTP,I4-005,10,GENERATOR
S452/01,6032901,BP -  Andrew,Andrew Field 1,S452/01,,N/A,SGT-200 C3S M2C E1C DC GB,TRAIN_S452/01,SGT-200-1S,1996-01-17,1997-01-15,1993-12-01,Operating,SGT-200,GB,Frame Agreement,ADHOC,I4-005,10,GENERATOR
BD000214U02,6541046,Pakistan Petroleum Limited (PPL) - Adhi Gas,Presson Descon,BD000214U02,NW0393/02,NW0393/02,SGT-300 (LCN NW0393) C3S M2S E2S DS,TRAIN_BD000214U02,SGT-300,2016-02-10,2017-02-09,2014-04-30,Operating,SGT-300,PK,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
NW0216/01,6044605,Daleel Petroleum Company LLC,DALEEL,NW0216/01,,N/A,SGT-300 C4S M2C E1C DC GB HS,TRAIN_NW0216/01,SGT-300,2012-09-03,2013-09-02,2010-05-01,Operating,SGT-300,OM,Preventive LTP,LTP,I4-021,10,GENERATOR
NC0102/01,0,,,,,N/A,SGT-400 PACKAGE PROJECT ON HOLD,TRAIN_NC0102/01,,,,,,,,,,I4-008,30,COMPRESSOR
NW0051/01,0,,,,,N/A,ENHANCED SGT-300 - CORE ONLY,TRAIN_NW0051/01,,,,,,,,,,I4-021,10,GENERATOR
S424/02,6044837,Dupont-Grand Duchy-Contern,Contern 1,S424/02,9080/138,N/A,SGT-200 PACKAGE - SITE EMPTY GB,TRAIN_S424/02,SGT-200-1S,1994-01-01,1995-05-21,1993-09-01,Scrapped,SGT-200,LU,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
S716/01,6059164,Cobane A.I.E.,Tarragona Power,S716/01,,N/A,SGT-200 PACKAGE M2C EX DC GB,TRAIN_S716/01,SGT-200-1S,1997-05-01,1999-03-01,1996-05-01,Not Running – Not Preserved,SGT-200,ES,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NA0540/02,6684573,CNOOC-WZ12,WZ-12 (HGGT),NA0540/02,BD000823U02,BD000823,SGT-400 PACKAGE (BD000823) C3S M1S EX,TRAIN_NA0540/02,SGT-400,2021-08-01,2022-09-30,2019-11-12,Operating,SGT-400,CN,Preventive LTP,LTP,I4-008,,GENERATOR
S443/02,6050039,ASSALA GABON - Rabi Field,Rabi Field 2,S443/02,,N/A,SGT-200 PACKAGE C1S M1C E2S DS GB 2023,TRAIN_S443/02,SGT-200-1S,1995-01-01,1995-08-07,1993-12-01,Operating,SGT-200,GA,Frame Agreement,ADHOC,I4-005,10,GENERATOR
NR0037/01,6038854,Total Austral S.A. - San Roque,San Roque,NR0037/01,,1405040,SGT-200-2S PACKAGE M1C E2S DS 2014,TRAIN_NR0037/01,SGT-200-2S,2005-07-28,2006-07-28,2004-05-01,Operating,SGT-200,AR,Other and 24/7 Global Helpdesk and/or RDS are not included,ADHOC,I4-006,30,COMPRESSOR
NW0244/01,6150317,Halfaya Oil Field (PetroChina),CNPC,NW0244/01,,N/A,SGT-300 C3S M2C E1C DC GB,TRAIN_NW0244/01,SGT-300,2013-02-11,2015-02-10,2011-03-01,Operating,SGT-300,IQ,RDS,ADHOC,I4-021,10,GENERATOR
S9A1/01,6032496,Kronospan Ltd,Kronospan 2,S9A1/01,,N/A,SGT-200 C3S M2C E1C DC GB,TRAIN_S9A1/01,SGT-200-1S,1994-01-01,1999-10-03,1993-09-01,Operating,SGT-200,GB,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
R809/03,6032920,Petrofac Facilities,Kittiwake 3,R809/03,,N/A,SGT-200 PACKAGE CX M2C EX DC GB,TRAIN_R809/03,SGT-200-1S,1988-12-15,1989-07-01,1987-01-01,Operating,SGT-200,GB,Call off Agreement / Support LTP,ADHOC,I4-005,10,GENERATOR
NW0100/01,6046661,"GRODNO AZOT, JSC / AO",GRODNO AZOT,NW0100/01,,N/A,SGT-300 PACKAGE C3S M2C E1C DC GB,TRAIN_NW0100/01,SGT-300,2008-11-13,2010-11-12,2007-02-01,Operating,SGT-300,BY,Preventive LTP,LTP,I4-021,10,GENERATOR
NM0088/01,6052842,Sonatrach DP - Touat Refinery,Cpecc Algeria Oc-Touat,NM0088/01,,N/A,SGT-100 PACKAGE C3S M2C E1C DC GB,TRAIN_NM0088/01,SGT-100-1S,2008-08-03,2010-08-02,2006-09-20,Not Running – Not Preserved,SGT-100,DZ,Frame Agreement,ADHOC,I4-002,10,GENERATOR
NR0243/01,0,,,,,N/A,SGT-200 PACKAGE - NO CONTRACT INST.,TRAIN_NR0243/01,,,,,,,,,,I4-004,,COMPRESSOR
NA0306/01,0,,,,,N/A,SGT-400 PACKAGE GB,TRAIN_NA0306/01,,,,,,,,,,I4-008,10,GENERATOR
NA0445/04,0,,,,,N/A,SGT-400 PACKAGE (CANCELLED),,,,,,,,,,,I4-008,,GENERATOR
BD000183U01,6490633,CNOOC-Huanyan PH1,Huanguan PH1,BD000183U01,NC0380/01,NC0380/01,SGT-400 (LCN NC0380) C1S M2S E2S DS GB,TRAIN_BD000183U01,SGT-400,2015-09-26,2017-03-25,2014-02-28,Operating,SGT-400,CN,Preventive LTP,LTP,I4-008,30,COMPRESSOR
BF000078U19,6673465,Bid Boland,Bid Boland,BF000078U19,,NU0496/02,SGT-100-2S PACKAGE (NU0496)CX EX,TRAIN_BF000078U19,SGT-100-2S,,,2017-07-31,Operating,SGT-100,IR,No Contract or Agreement,ADHOC,I4-003,,COMPRESSOR
W9G2/01,0,,,,,N/A,SGT-300 PACKAGE - CANCELLED,,,,,,,,,,,I4-021,10,GENERATOR
BF000003U01,6364385,TUBA & ZUBAIR-1,TUBA & ZUBAIR-1,BF000003U01,NC0339/01,NC0339/01,SGT-400 (LCN NC0339) C3C M2S E1C DS GB,TRAIN_BF000003U01,SGT-400,2017-02-05,2018-02-04,2013-03-31,Operating,SGT-400,IQ,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
NJ0462/01,6553184,Centrax Ltd.,Centrax 006 (Cofely Ahlstrom - Bordeaux),NJ0462/01,BD000356U01,BD000356,SGT-300-2S PACKAGE CX M1S EX,TRAIN_NJ0462/01,SGT-300-2S,2017-02-28,2018-02-27,2016-05-31,Operating,SGT-300,GB,Corrective LTP – Includes RDS,LTP,I4-022,,GENERATOR
M364/02,6047526,Mohn media Mohndruck GmbH - Gütersloh,Mohn Media GT2 (LTP),M364/02,9100/067,N/A,SGT-100 PACKAGE C3S M2C E2S DC GB,TRAIN_M364/02,SGT-100-1S,1994-01-01,1994-12-05,1993-09-01,Operating,SGT-100,DE,Corrective LTP – Includes RDS,LTP,I4-002,10,GENERATOR
NR0159/04,6038181,Transportadora Associada de Gás S/A - TAG - EComp de Coari,SITI Petrobras Juarana,NR0159/04,6024,HI6024,SGT-200 PACKAGE C1S M2S E2S DS GB,TRAIN_NR0159/04,SGT-200-2S,2010-11-30,2011-11-29,2009-01-01,Operating,SGT-200,BR,No Contract or Agreement,ADHOC,I4-006,,COMPRESSOR
NW0053/01,0,,,,,N/A,SGT-300 PACKAGE - ORDER CANCELLED,TRAIN_NW0053/01,,,,,,,,,,I4-021,10,GENERATOR
HI6039/03,6197311,YPFB Refineria - PSLCV Gran Chaco,Gran Chaco,HI6039/03,NA0279/03,NA0279/03,SGT-400 (LCN NA0279) CX M2S E2S DS GB,TRAIN_HI6039/03,SGT-400,2015-06-01,2016-05-30,2013-03-25,Operating,SGT-400,BO,RDS,ADHOC,I4-008,10,GENERATOR
NW0538/03,0,,,,,BD000769,SGT-300 PACKAGE (CANCELLED),,,,,,,,,,,,,
HI6037/06,6083360,GLNG - Roma-HCS-02,Roma 02,HI6037/06,NC0247/06,NC0247/06,SGT-400 (LCN NC0247) C3S M2C E2S DC GB,TRAIN_HI6037/06,SGT-400,2015-06-30,2016-10-29,2011-03-31,Operating,SGT-400,AU,Call off Agreement / Support LTP,ADHOC,I4-008,30,COMPRESSOR
NA0446/02,0,,,,,N/A,SGT-400 PACKAGE (CANCELLED),,,,,,,,,,,I4-008,,GENERATOR
NC0337/01,6197319,Uz-Kor Chemical,UGCC Akchalak,NC0337/01,,BF000008,SGT-400 (BF000008) C3C M2S E2S DS GB HC,TRAIN_NC0337/01,SGT-400,2015-08-28,2016-08-27,2013-03-25,Operating,SGT-400,UZ,Preventive LTP – Includes RDS,LTP,I4-008,30,COMPRESSOR
BD000483U01,0,,,,,NA0497/01,SGT-400 PKG (LINCOLN SCOPE NA0497) GB,TRAIN_BD000483U01,,,,,,,,,,I4-008,,GENERATOR
NC0454/01,0,,,,,N/A,SGT-400 SPARE - CORE ONLY,TRAIN_NC0454/01,,,,,,,,,,I4-008,,COMPRESSOR
NC0090/03,0,,,,,N/A,SGT-400 PACKAGE PROJECT ON HOLD,TRAIN_NC0090/03,,,,,,,,,,I4-008,30,COMPRESSOR
BD000348U01,0,,,,,NA0460/01,SGT-400 - CANCELLED SEE NA0571/01 GB,TRAIN_BD000348U01,,,,,,,,,,I4-008,10,GENERATOR
BC000153U01,6553204,TAP Greece,TAP Greece,BC000153U01,NC0447/01,NC0447/01,SGT-400 PKG (LCN NC0447) C3C M1S EX HC,TRAIN_BC000153U01,SGT-400,2021-03-30,2023-03-29,2016-02-01,Operating,SGT-400,GR,Preventive LTP – Includes RDS,LTP,I4-008,,COMPRESSOR
BC000116U05,6541471,GAZ-SYSTEM S.A. - Rembelszczyzna,Rembelszczyzna,BC000116U05,NR0420/02 - HI6060/02,NR0420/02,SGT-200 PKG (LCN NR0420) C2S M1S EX,TRAIN_BC000116U05,SGT-200-2S,2022-04-02,2023-04-01,2014-12-31,Operating,SGT-200,PL,Preventive LTP,LTP,I4-006,30,COMPRESSOR
S9D5/01,6045247,ENGRO Polymer & Chemical - Karachi (Peam),ENGRO Chemicals,S9D5/01,,N/A,SGT-200 PACKAGE C2S M2C EX DC GB,TRAIN_S9D5/01,SGT-200-1S,1999-01-01,1999-03-23,1998-09-01,Operating,SGT-200,PK,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NR0033/15,6053154,Sonatrach TRC - OZ2,OZ2,NR0033/15,R3D8/15,N/A,SGT-200-2S PACKAGE CX M2C E1C DC,TRAIN_NR0033/15,SGT-200-2S,2004-01-06,2007-03-07,2003-01-06,Operating,SGT-200,DZ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
BD000140U03,6364424,Dana Petroleum Limited - Western Isles Development Project,Western Isles development project,BD000140U03,NA0346/03,NA0346/03,SGT-400 PKG (NA0346) CX M1S EX GB,TRAIN_BD000140U03,SGT-400,2016-09-12,2017-09-11,2013-02-28,Operating,SGT-400,GB,Other and 24/7 Global Helpdesk and/or RDS are not included,ADHOC,I4-008,10,GENERATOR
S8D3/01,0,,,,,N/A,SGT-200 PACKAGE (CANCELLED),,,,,,,,,,,,,
NA0445/01,0,,,,,N/A,SGT-400 PACKAGE (CANCELLED),,,,,,,,,,,I4-008,,GENERATOR
NA0017/02,6047965,OAO Uralkali-Berezniki-OAO URALKALI,OAO URALKALI,NA0017/02,NA0017/02,N/A,SGT-400 C3S M2S E2S DS GB,TRAIN_NA0017/02,SGT-400,2008-01-01,2008-12-30,2005-04-01,Operating,SGT-400,RU,Preventive LTP,LTP,I4-008,10,GENERATOR
BD000443U01,0,,,,,NA0484/01,SGT-400 PKG (LINCOLN SCOPE NA0484) GB,TRAIN_BD000443U01,,,,,,,,,,I4-008,,GENERATOR
NA0373/04,0,,,,,N/A,SGT-400 PACKAGE GB,TRAIN_NA0373/04,,,,,,,,,,I4-008,10,GENERATOR
S237/02,6032746,Spirit - Barrow in Furness - Onshore AGT,Barrow in Furness 2,S237/02,,N/A,SGT-200 PACKAGE,TRAIN_S237/02,SGT-200-1S,1993-08-12,1994-08-11,1992-01-01,Scrapped,SGT-200,GB,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M427/01,6046873,Arla Foods Akafa - Svenstrup,Arla Foods A/S,M427/01,9100/085,N/A,SGT-100 PACKAGE C2S GB,TRAIN_M427/01,SGT-100-1S,1997-01-01,1998-04-27,1995-09-01,Not Running – Not Preserved,SGT-100,DK,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
W0D7/01,6044226,Dalkia Bethune at Firestone,Firestone,W0D7/01,W0D7/01,N/A,SGT-300 PACKAGE C2S M2C E2S DC GB HP,TRAIN_W0D7/01,SGT-300-1S,2001-06-30,2002-09-01,2000-06-30,Operating,SGT-300,FR,Preventive LTP,LTP,I4-021,10,GENERATOR
M2H1/01,0,,,,,N/A,SGT-100 PACKAGE - CANCELLED,,,,,,,,,,,I4-001,,GENERATOR
M8L8/01,6038520,PEP-Cantarell Offshore-APC-Akal L,Akal L (Offshore),M8L8/01,M8L8/01,N/A,SGT-100 C3S M1S EX,TRAIN_M8L8/01,SGT-100-1S,1999-01-01,2000-01-24,1998-01-01,Operating,SGT-100,MX,Frame Agreement,ADHOC,I4-002,10,GENERATOR
R536/02,6051098,TotalEnergies E&P Nederland BV,Total E&P Nederland BV K6,R536/02,9080/177,N/A,SGT-200 PACKAGE C2S M2C DC,TRAIN_R536/02,SGT-200-2S,1996-01-01,1997-08-01,1995-09-01,Operating,SGT-200,NL,Frame Agreement – Includes RDS,ADHOC,I4-006,30,COMPRESSOR
NC0093/03,0,,,,,N/A,SGT-400 PACKAGE - UNKNOWN,TRAIN_NC0093/03,,,,,,,,,,I4-008,30,COMPRESSOR
80/87305-03,6032925,Petrofac Facilities Management - Ivanhoe Rob Roy Platform,Ivanhoe Rob Roy 3,80/87305-03,,N/A,SGT-200 PACKAGE GB,TRAIN_80/87305-03,SGT-200-1S,1989-10-04,1990-10-04,1987-01-01,Scrapped,SGT-200,GB,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M9D2/01,6141687,Dummy Account: Asia Pacific - Japan,"FHP, Kyushu Unit #2",M9D2/01,,N/A,SGT-100 PACKAGE - SCRAPPED,TRAIN_M9D2/01,SGT-100-1S,1999-01-01,2001-11-28,1998-01-01,Scrapped,SGT-100,JP,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NC0111/01,6046710,Pakistan Petroleum Limited (PPL) - Kandhkot,PPL - Kandhkot,NC0111/01,NC0111/01,N/A,SGT-400 PACKAGE C3S M2C E2S DC GB,TRAIN_NC0111/01,SGT-400,2010-03-14,2011-03-13,2007-08-04,Operating,SGT-400,PK,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
S635/03,6032838,Phillips 66 Limited - Humber Refinery,Conoco Phillips 3,S635/03,,N/A,SGT-200 PACKAGE M2C DC GB,TRAIN_S635/03,SGT-200-1S,1996-01-01,1997-11-08,1995-12-01,Storage,SGT-200,GB,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
BF000087U03,6662742,Bahrain National Gas Company (Banagas),Banagas,BF000087U03,NC0461/03,NC0461/03,SGT-400 (LCN NC0461) C3S M1S EX,TRAIN_BF000087U03,SGT-400,2018-11-05,2019-11-04,2016-06-30,Operating,SGT-400,BH,Preventive LTP,LTP,I4-008,,COMPRESSOR
S452/03,6032903,BP -  Andrew,Andrew Field 3,S452/03,,N/A,SGT-200 PACKAGE C1S M2C E2S DC GB 2013,TRAIN_S452/03,SGT-200-1S,1996-01-10,1997-01-08,1993-12-01,Operating,SGT-200,GB,Frame Agreement,ADHOC,I4-005,10,GENERATOR
NW0385/01,0,,,,,N/A,SGT-300 PACKAGE - CANCELLED,TRAIN_NW0385/01,,,,,,,,,,I4-021,10,GENERATOR
R807/01,6054934,Taiyo Oil,Taiyo Oil # 1,R807/01,,N/A,SGT-200 PACKAGE,TRAIN_R807/01,SGT-200-1S,2003-12-09,2004-12-08,2000-12-20,Scrapped,SGT-200,JP,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
BD000444U01,0,,,,,NA0485/01,SGT-400 PKG (LINCOLN SCOPE NA0485) GB,TRAIN_BD000444U01,,,,,,,,,,I4-008,,GENERATOR
NA0097/01,0,,,,,1405043,SGT-400 SPARE CORE GB,TRAIN_NA0097/01,,,,,,,,,,I4-008,10,GENERATOR
HI4067/09,6083354,GLNG - Roma-HCS-02,Roma 02,HI4067/09,NA0246/09,NA0246/09,SGT-400 (LCN NA0246) C1S M1C E1C GB,TRAIN_HI4067/09,SGT-400,2015-07-30,2016-10-29,2011-03-31,Not Running – Preserved,SGT-400,AU,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
S305/01,6043651,"Azuliber 1, S.L.",Azuliber,S305/01,,N/A,SGT-200 PACKAGE C1S M2C EX DC GB,TRAIN_S305/01,SGT-200-1S,1993-01-01,1994-08-17,1992-06-01,Not Running – Not Preserved,SGT-200,ES,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NW0538/04,0,,,,,BD000769,SGT-300 PACKAGE (CANCELLED),,,,,,,,,,,,,
W0G1/01,6050767,Tagol-Lisboa-Tagol,Tagol,W0G1/01,WOG1/01,N/A,SGT-300 C3C M2C E2S DC GB,TRAIN_W0G1/01,SGT-300,2002-01-01,2003-04-14,2001-01-01,Not Running – Preserved,SGT-300,PT,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
R0H6/03,6051101,TotalEnergies E&P Nederland BV,Total E&P Nederland BV K5PK,R0H6/03,9080/0366,N/A,SGT-200-2S PACKAGE C2S M1C E1C DS GB,TRAIN_R0H6/03,SGT-200-2S,2001-01-01,2004-02-27,2000-11-01,Operating,SGT-200,NL,Frame Agreement – Includes RDS,ADHOC,I4-006,30,COMPRESSOR
BD000339U01,0,,,,,NA0456/01,SGT-400 PACKAGE (NA0456) CANCELLED,,,,,,,,,,,I4-008,,GENERATOR
A0C4/01,6141714,National Iranian Gas Company (NIGC) (HQ),Tabriz 1,A0C4/01,,N/A,SGT-400 PACKAGE CX M2S E1C DS <GB,TRAIN_A0C4/01,SGT-400,2001-08-10,2002-08-10,2000-08-10,Operating,SGT-400,IR,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
A2F0/02,6032487,National Gas - Kings Lynn,NG Kings Lynn 2,A2F0/02,NC0009/02,N/A,SGT-400 PACKAGE CX M2C E1C DC GB,TRAIN_A2F0/02,SGT-400,2004-05-03,2005-05-03,2003-05-03,Operating,SGT-400,GB,Frame Agreement,ADHOC,I4-008,30,COMPRESSOR
BD000485U01,0,,,,,NA0499/01,SGT-400 PKG (LINCOLN SCOPE NA0499) GB,TRAIN_BD000485U01,,,,,,,,,,I4-008,,GENERATOR
NC0102/04,0,,,,,N/A,SGT-400 PACKAGE PROJECT ON HOLD,TRAIN_NC0102/04,,,,,,,,,,I4-008,30,COMPRESSOR
M721/02,0,,,,,N/A,SGT-100 PACKAGE,TRAIN_M721/02,,,,,,,,,,I4-002,,GENERATOR
NM0313/02,0,,,,,N/A,SGT-100 PACKAGE - CANCELLED,TRAIN_NM0313/02,,,,,,,,,,I4-001,,GENERATOR
BC000008U01,6362832,Al-Khafji Joint Operations - KJO-ISV,Hout,BC000008U01,NC0340/01,NC0340/01,SGT-400 PKG(LCNScopeNC0340)C3S M1S EX GB,TRAIN_BC000008U01,SGT-400,,,2012-12-31,Installation & Commissioning,SGT-400,SA,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
NR0033/05,6053137,Sonatrach TRC - OZ2,OZ2,NR0033/05,R3D8/05,N/A,SGT-200-2S PACKAGE CX M2C E1C DC,TRAIN_NR0033/05,SGT-200-2S,2004-01-06,2007-03-07,2003-01-06,Operating,SGT-200,DZ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
W2C4/01,6038113,AmBev S/A - Jaguariuna,Ambev 1,W2C4/01,,1405010,SGT-300 PACKAGE C2S M1C E1C DS GB,TRAIN_W2C4/01,SGT-300-1S,2002-01-01,2002-12-30,2001-04-01,Operating,SGT-300,BR,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
R331/01,6056168,Energy Prima Ltd.,Battle Creek Michigan,R331/01,,N/A,SGT-200 PACKAGE -  SCRAPPED,TRAIN_R331/01,SGT-200-1S,1988-01-01,2008-11-30,1987-01-01,Scrapped,SGT-200,BD,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M207/02,6032739,Tioxide - Grimsby,Ex Tioxide Grimsby 2,M207/02,,N/A,SGT-100 PACKAGE - SITE SHUT,TRAIN_M207/02,SGT-100-1S,1992-01-01,1993-01-01,1991-01-01,Scrapped,SGT-100,GB,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NU0213/02,6044319,Caspian Pipeline Consortium (CPC-R) - Komsomolsk,CPC Komsomolsk,NU0213/02,,N/A,SGT-100-2S C3C M2C E2S DC GB HC,TRAIN_NU0213/02,SGT-100-2S,2014-04-10,2016-04-09,2010-05-01,Operating,SGT-100,RU,Frame Agreement – Includes RDS,ADHOC,I4-003,30,COMPRESSOR
M205/01,6032832,Novartis - Grimsby,Novartis 1,M205/01,,N/A,SGT-100 PACKAGE C3C M2C E1C DC GB HC,TRAIN_M205/01,SGT-100-1S,1992-01-01,1993-01-01,1991-01-01,Not Running – Not Preserved,SGT-100,GB,Preventive LTP,LTP,I4-002,10,GENERATOR
NA0445/02,0,,,,,N/A,SGT-400 PACKAGE (CANCELLED),,,,,,,,,,,I4-008,,GENERATOR
NA0445/05,0,,,,,N/A,SGT-400 PACKAGE (CANCELLED),,,,,,,,,,,I4-008,,GENERATOR
U1A8/01,6038869,Oleoductos del Valle - Oldelval - Pichimahuida,Pichimahuida,U1A8/01,,N/A,SGT-100-2S PACKAGE C3S M1C E1C DS GB,TRAIN_U1A8/01,SGT-100-2S,1999-01-01,2003-11-20,1996-01-01,Operating,SGT-100,AR,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
NC0553/05,0,,,,,BD000950U05,SGT-400 - SPARE CORE,TRAIN_NC0553/05,,,,,,,,,,I4-008,,COMPRESSOR
R0H1/02,6038847,Total Austral S.A. - Cañadon Alfa,Canadon Alfa,R0H1/02,,N/A,SGT-200-2S PACKAGE <C M1C E1C DS 2023,TRAIN_R0H1/02,SGT-200-2S,1999-01-01,2005-01-23,1998-02-01,Operating,SGT-200,AR,Other and 24/7 Global Helpdesk and/or RDS are not included,ADHOC,I4-006,30,COMPRESSOR
BD000063U01,6085219,Cefla - Leonardo - Cameri,Cefla Alenia,BD000063U01,NW0230/01 / NW0250/01,NW0250/01,SGT-300 PKG(LCN NW0250) C3C M2S E2S DS,TRAIN_BD000063U01,SGT-300,2013-11-21,2015-11-20,2011-08-22,Operating,SGT-300,IT,Corrective LTP,LTP,I4-021,10,GENERATOR
NC0090/02,0,,,,,N/A,SGT-400 PACKAGE PROJECT ON HOLD,TRAIN_NC0090/02,,,,,,,,,,I4-008,30,COMPRESSOR
NS0125/09,6046472,Donau Chem,ZNGT Romania,NS0125/09,,N/A,SGT-200 PACKAGE,TRAIN_NS0125/09,SGT-200-1S DLE,,,2008-07-01,Not Running – Not Preserved,SGT-200,RO,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NW0350/01,0,,,,,N/A,SGT-300 PACKAGE,TRAIN_NW0350/01,,,,,,,,,,I4-021,10,GENERATOR
S571/01,6043650,"Azuliber 1, S.L.",Azuliber,S571/01,,N/A,SGT-200 PACKAGE C2S M2C EX DC GB,TRAIN_S571/01,SGT-200-1S,1996-01-01,1996-10-06,1995-09-01,Not Running – Not Preserved,SGT-200,ES,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
U706/01,6138012,National Iranian Oil Refining & Product Distribution Company (NIORDC),Sabzab,U706/01,,N/A,SGT-100-2S PACKAGE C2S M1S EX GB,TRAIN_U706/01,SGT-100-2S,1998-05-20,1999-04-01,1997-04-01,Operating,SGT-100,IR,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
R124/01,6085401,STORAG ETZEL GmbH,IVG Etzel,R124/01,9080/085,N/A,SGT-200-2S PACKAGE <C M2C DC 2020,TRAIN_R124/01,SGT-200-2S,1992-01-01,1992-12-30,1990-01-01,Operating,SGT-200,DE,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NU0570/04,6701792,Oleoductos del Valle - Oldelval - Vivaldi,Oldelval Vivaldi,NU0570/04,BD001155U04,,,,SGT-100-2S,2025-02-01,2026-01-30,2022-09-01,Order,SGT-100,AR,,,,,
SGT (DK) run rate,6552999,Siemens A/S,,SGT (DK) run rate,,,,,SGT-100,,,,Cancelled,SGT-100,DK,No Contract or Agreement,ADHOC,,,
NW0216/02,6044606,Daleel Petroleum Company LLC,DALEEL,NW0216/02,,N/A,SGT-300 PACKAGE C3S M2C E2S DC GB,TRAIN_NW0216/02,SGT-300,2012-03-03,2013-03-02,2010-05-01,Operating,SGT-300,OM,Preventive LTP,LTP,I4-021,10,GENERATOR
W2B0/01,1021664,iPR Pharmaceuticals,Canovanas 1 & 2,W2B0/01,2001/049,1405004,SGT-300 PACKAGE,TRAIN_W2B0/01,SGT-300,2002-01-01,2003-03-30,2001-01-01,Scrapped,SGT-300,PR,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
80/86259-01,6032480,Solenis plc - Bradford,CIBA Speciality Chemicals Ltd 1,80/86259-01,,N/A,SGT-200-1S C4S M2C E1C DC HS,TRAIN_80/86259-01,SGT-200-1S,1988-05-07,1989-05-07,1987-05-07,Operating,SGT-200,GB,Corrective LTP – Includes RDS,LTP,I4-005,10,GENERATOR
M722/01,6055063,Hibiscus Oil & Gas Malaysia Limited*,Kota Kinabalu 01,M722/01,,N/A,SGT-100 PACKAGE C2S EX GB,TRAIN_M722/01,SGT-100-1S,1997-01-01,1998-03-18,1996-09-01,Operating,SGT-100,MY,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
HI4083/02,6541052,Cenovus Energy Inc. - St. John's,Husky,HI4083/02,NA0403/02,NA0403/02,SGT-400 PACKAGE (EX LCN NA0403)CX M1S GB,TRAIN_HI4083/02,SGT-400,2024-06-01,2025-05-30,2014-06-30,Installation & Commissioning,SGT-400,CA,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
NM0071/01,6049835,Severnaya Neft-Usinsk-Khasireiskoye Oil Field Nort,Khasireiskoye Oil Field North West Siberia,NM0071/01,,N/A,SGT-100 C3S M2C E1C DC GB,TRAIN_NM0071/01,SGT-100-1S,2004-01-11,2006-01-04,2003-05-24,Operating,SGT-100,RU,Preventive LTP,LTP,I4-002,10,GENERATOR
S614/02,6141678,Dummy Account: Asia Pacific - Japan,Miyakojima 2,S614/02,,N/A,SGT-200 PACKAGE - SCRAPPED,TRAIN_S614/02,SGT-200,1998-03-01,2001-03-01,1997-01-01,Scrapped,SGT-200,JP,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
S8A0/02,6137988,Dummy Account: Asia Pacific - Malaysia,Petronas Carigali Sdn Bhd - Sa,S8A0/02,,N/A,SGT-200 PACKAGE,TRAIN_S8A0/02,SGT-200-1S,1961-01-01,1962-01-01,1960-01-01,Scrapped,SGT-200,MY,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NR0159/03,6038180,Transportadora Associada de Gás S/A - TAG - EComp de Coari,SITI Petrobras Juarana,NR0159/03,6024,HI6024,SGT-200 PKG(HOU HI6024)C2S M2S E2S DS GB,TRAIN_NR0159/03,SGT-200-2S,2010-11-30,2011-11-29,2009-01-01,Operating,SGT-200,BR,No Contract or Agreement,ADHOC,I4-006,,COMPRESSOR
R535/01,220566,Dummy Account: North America - Canada,Transcanada Pipelines,R535/01,,N/A,SGT-200-2S PACKAGE,TRAIN_R535/01,SGT-200-2S,1998-08-13,2000-02-13,1990-01-01,Unknown,SGT-200,CA,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NR0033/07,6058268,Sonatrach TRC - OZ2,OZ2,NR0033/07,R3D8/07,N/A,SGT-200-2S PACKAGE CX M2C E1C DC,TRAIN_NR0033/07,SGT-200-2S,2004-01-01,2007-03-07,2003-01-06,Operating,SGT-200,DZ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
M324/01,6142629,Dummy Account: Africa,Victoria A Station,M324/01,,N/A,SGT-100 PACKAGE M2C EX DC,TRAIN_M324/01,SGT-100,1993-01-01,1994-09-06,1992-01-01,Scrapped,SGT-100,ZA,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NJ0554/01,6692148,Shaanxi Gas-Anbian station,HGGT Shannxi Gas Anbian Stn,NJ0554/01,BD000941U01,BD000894 BD000941,SGT-300-2S PACKAGE CX M2C DC,TRAIN_NJ0554/01,SGT-300-2S,2022-03-17,2024-03-16,2020-09-01,Operating,SGT-300,CN,No Contract or Agreement,ADHOC,I4-022,,COMPRESSOR
S2C7/01,6075478,SBM OFFSHORE - FPSO Brasil CONTA INATIVA,SBM Brasil,S2C7/01,,N/A,SGT-200 PACKAGE,TRAIN_S2C7/01,SGT-200-1S,2003-01-01,2003-12-14,2001-12-01,Scrapped,SGT-200,BR,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
BD001041U02,6696247,Mellitah Oil & Gas B.V - Sabratha Platform,Sabratha Platform,BD001041U02,NC0562/02,NC0562/02,SGT-400 PACKAGE (NC0562/02),TRAIN_BD001041U02,SGT-400,2024-12-01,2026-11-29,2021-10-01,Order,SGT-400,LY,,,I4-008,,COMPRESSOR
R2E5/02,6053139,Sonatrach TRC - OZ2,OZ2,R2E5/02,,N/A,SGT-200-2S PACKAGE M2C E1C DC 2002,TRAIN_R2E5/02,SGT-200-2S,2002-09-19,2006-03-29,2001-09-19,Operating,SGT-200,DZ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
M610/01,6141684,"Shanghai Chemical Industry Park Industrial Gas Co.,Ltd",Hitachi Displays. Ex Mobara,M610/01,,N/A,SGT-100 PACKAGE - RETIRED GB,TRAIN_M610/01,SGT-100-1S,1999-01-01,2000-04-13,1998-01-01,Not Running – Not Preserved,SGT-100,CN,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M3B1/02,6049271,Floaters SPA - FPSO Mystras,FPSO Mystras,M3B1/02,,N/A,SGT-100 C3S M1C E2S DS GB,TRAIN_M3B1/02,SGT-100-1S,2003-06-01,2004-07-02,2002-06-01,Operating,SGT-100,NG,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
HI4066/15,6039055,PDVSA - Div Faja - Unidades Moviles Div Faja,PDVSA Mobile Power,HI4066/15,NA0209/15,NA0209/15 HI4057,SGT-400 PACKAGE (NA0209) CANCELLED,TRAIN_HI4066/15,SGT-400,,,2010-03-04,Cancelled,SGT-400,VE,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
R9H6/01,6043239,BP/AIOC - Chirag Platform,Chirag Platform 2,R9H6/01,,N/A,SGT-200-2S C3S M2C E1C DC,TRAIN_R9H6/01,SGT-200-2S,1999-03-01,2001-04-19,1998-03-01,Not Running – Preserved,SGT-200,AZ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
HI6049/01,6362602,J. Supor & Son Trucking & Rigging Co.(SGT Dummy Account GO TO THE EQUIPMENT PAGE AND MOVE UNITS TO CORRECT ACCOUNT).,Dong Hjere,HI6049/01,NR0354/01,NR0354/01,SGT-200 PACKAGE (LINCOLN SCOPE NR0354),TRAIN_HI6049/01,SGT-200-2S,,,2013-08-01,Storage,SGT-200,US,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
M3C4/02,6054812,"PFI, Asaka (HPS)",PFI,M3C4/02,NM0064/02,N/A,SGT-100 PACKAGE C2S M2C E2S DC GB,TRAIN_M3C4/02,SGT-100-1S,1984-09-01,2005-04-01,1982-12-01,Operating,SGT-100,JP,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
R2E3/04,6053148,Sonatrach TRC - OZ2,OZ2,R2E3/04,,N/A,SGT-200-2S PACKAGE M2C E1C DC 2002,TRAIN_R2E3/04,SGT-200-2S,2002-09-19,2006-03-29,2001-09-19,Operating,SGT-200,DZ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
HI6030/02RW,6104646,ONGC B&S B193 Platform,ONGC - SIME DARBY - B193,HI6030/02RW,NW0223/02,NW0223/02,SGT-300(LCN NW0223)C3C M2C E2S DC,TRAIN_HI6030/02RW,SGT-300,2012-06-01,2013-05-31,2010-08-01,Operating,SGT-300,IN,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
M202/02,6050099,Videocolor,Videocolor TG2,M202/02,,N/A,SGT-100 PACKAGE - MOTHBALLED GB,TRAIN_M202/02,SGT-100-1S,1992-04-01,1993-04-01,1991-04-01,Scrapped,SGT-100,IT,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
W2G0/02,6141776,National Iranian Oil Company (NIOC),Nowrooz Platform,W2G0/02,,N/A,SGT-300 PACKAGE C2S M2C E2S DC GB,TRAIN_W2G0/02,SGT-300,2002-01-21,2004-07-29,2001-11-27,Operating,SGT-300,IR,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
BF000087U01,6662740,Bahrain National Gas Company (Banagas),Banagas,BF000087U01,NC0461/01,NC0461/01,SGT-400 (LCN NC0461) C4S M1S EX,TRAIN_BF000087U01,SGT-400,2018-09-23,2019-09-22,2016-06-30,Operating,SGT-400,BH,Preventive LTP,LTP,I4-008,,COMPRESSOR
M708/06,6142069,National Iranian Gas Company,Farashband S1,M708/06,,N/A,SGT-100 PACKAGE C2S M2C EX DC GB,TRAIN_M708/06,SGT-100-1S,1997-01-01,1998-03-25,1996-01-01,Operating,SGT-100,IR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S626/01,6062648,"N & E,  Tokushima (DIR)",N & E Tokushima,S626/01,,N/A,SGT-200 PACKAGE <C M2C E1C DC GB 2022,TRAIN_S626/01,SGT-200-1S,1988-10-01,1989-10-01,1987-12-01,Operating,SGT-200,JP,Preventive LTP – Includes RDS,LTP,I4-005,10,GENERATOR
BC000652U02,6700706,New Fortress Energy - Gulf of Mexico (Mex),FAST LNG II,BD001117U02,NA0567/02,BD001117U02 NA0567/02,SGT-400 PACKAGE (LCN NA0567),TRAIN_BC000652U02,SGT-400,2025-11-01,2026-10-30,2022-06-30,Order,SGT-400,MX,No Contract or Agreement,ADHOC,I4-008,,
M374/02,6048298,Pamesa,Pamesa,M374/02,,N/A,SGT-100 PACKAGE C2S GB,TRAIN_M374/02,SGT-100-1S,1994-05-01,1994-10-31,1993-05-01,Not Running – Not Preserved,SGT-100,ES,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NS0121/01,6046461,Nitramonia BC,ZNGT Romania,NS0121/01,,N/A,SGT-200 PACKAGE CX MX EX DX,TRAIN_NS0121/01,SGT-200-1S DLE,2013-01-14,2014-01-13,2007-12-01,Operating,SGT-200,RO,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
HI6039/01,6197309,YPFB Refineria - PSLCV Gran Chaco,Gran Chaco,HI6039/01,NA0279/01,NA0279/01,SGT-400 (LCN NA0279) C3S M2S E2S DS GB,TRAIN_HI6039/01,SGT-400,2015-04-01,2016-03-30,2011-10-31,Operating,SGT-400,BO,RDS,ADHOC,I4-008,10,GENERATOR
R128/01,6032512,Shell UK Ltd - Sole Pit Clipper Platform,Sole Pit Clipper 2,R128/01,,N/A,SGT-200-2S PACKAGE C2S M1C E1C DS,TRAIN_R128/01,SGT-200-1S,1992-07-07,1993-07-06,1990-07-01,Operating,SGT-200,GB,Frame Agreement,ADHOC,I4-006,30,COMPRESSOR
S923/02,6032499,Greenray Turbines (Lincoln) Ltd.,Greenwich Refineries 2,S923/02,,N/A,SGT-200 PACKAGE,TRAIN_S923/02,SGT-200,1990-01-01,1990-12-25,1989-01-01,Scrapped,SGT-200,GB,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NA0105/01,6048694,J/V AKTOR S.A. – AVAX S.A./Joint Operation & Maintenance of WWTP,PSYTTALIA ISLAND,NA0105/01,,N/A,SGT-400 PACKAGE C3S M2C E1C DC GB,TRAIN_NA0105/01,SGT-400,2009-06-18,2010-06-17,2007-06-01,Operating,SGT-400,GR,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
80/87305-02,6032923,Petrofac Facilities Management - Ivanhoe Rob Roy Platform,Ivanhoe Rob Roy 2,80/87305-02,,N/A,SGT-200 PACKAGE GB,TRAIN_80/87305-02,SGT-200-1S,1988-04-04,1989-04-04,1987-01-01,Scrapped,SGT-200,GB,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M226/02,6052126,Yalova Elyaf-Yalova Istanbul,Yalova Istanbul,M226/02,,N/A,SGT-100 PACKAGE - SCRAPPED,TRAIN_M226/02,SGT-100-1S,1992-07-01,1993-08-26,1991-07-01,Scrapped,SGT-100,TR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S601/02,6032843,Westinghouse,Springfields 2,S601/02,,N/A,SGT-200 C3C M2C E1C DC GB HC,TRAIN_S601/02,SGT-200-1S,1996-01-01,1996-11-18,1995-08-01,Operating,SGT-200,GB,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
W3B9/02,6055234,"Shell Hazira, LNG Terminal, Surat",Shell Hazira 2,W3B9/02,NW0032/02,N/A,SGT-300 PACKAGE C2S M1C E1C DS GB,TRAIN_W3B9/02,SGT-300,2005-04-06,2006-04-16,2002-10-01,Operating,SGT-300,IN,Other and 24/7 Global Helpdesk and/or RDS included,ADHOC,I4-021,10,GENERATOR
A0G9/01,6032483,National Gas - Cambridge,NG Cambridge Comp Stn,A0G9/01,NC0004,N/A,SGT-400 PACKAGE C3S M2C E1C DC GB,TRAIN_A0G9/01,SGT-400,2003-07-12,2004-07-12,2002-07-12,Operating,SGT-400,GB,Other and 24/7 Global Helpdesk and/or RDS are not included,ADHOC,I4-008,30,COMPRESSOR
NA1239/01,,,,,,,SGT-400 PACKAGE,TRAIN_NA1239/01,,,,,,,,,,,,
U0B7/01,6054726,Zhong Yuan Oil & Gas,Zhong Yuan,U0B7/01,,N/A,SGT-100-2S PACKAGE - DEACTIVATED GB,TRAIN_U0B7/01,SGT-100-2S,2001-08-13,2003-03-30,1999-04-02,Scrapped,SGT-100,CN,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
M0F0/01,6043365,Phosphat Kaboodan Chemical Co,M0F0/01-Rm319-Gt,M0F0/01,,N/A,SGT-100 PACKAGE - SCRAPPED,TRAIN_M0F0/01,SGT-100-1S,2003-06-04,2004-06-04,2002-06-04,Scrapped,SGT-100,IR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NR0198/01,6045279,Open Grid Europe GmbH; Verdichterstation Werne,Werne ME3 & ME4,NR0198/01,,N/A,SGT-200 PACKAGE C2S M1C E1C DS GB,TRAIN_NR0198/01,SGT-200-2S,2013-03-22,2016-03-21,2010-01-23,Operating,SGT-200,DE,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
M202/01,6050100,Videocolor,Videocolor TG1,M202/01,,N/A,SGT-100 PACKAGE - MOTHBALLED GB,TRAIN_M202/01,SGT-100-1S,1992-03-01,1993-03-01,1991-03-01,Scrapped,SGT-100,IT,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M2C2/01,6038106,Compania Cervecera AmBev Dominicana C. por A,Ambev 2 Thru 6,M2C2/01,,N/A,SGT-100 PACKAGE - MOTHBALLED GB,TRAIN_M2C2/01,SGT-100-1S,2001-09-19,2003-01-03,2001-04-01,Scrapped,SGT-100,DO,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NR0115/03,6052847,Waha Oil Co - Faregh Field,Faregh,NR0115/03,,N/A,SGT-200-2S PACKAGE C2S M2C E1C DC GB,TRAIN_NR0115/03,SGT-200-2S,2019-11-20,2020-11-19,2008-01-31,Operating,SGT-200,LY,Other - please specify,ADHOC,I4-006,30,COMPRESSOR
U2D1/01,6050095,Single Buoy Moorings - FPSO Serpentina,Water Injection A,U2D1/01,,N/A,SGT-100-2S PCKGE C3C M2C E1C DC GB HC,TRAIN_U2D1/01,SGT-100-2S,2002-11-15,2004-09-13,2001-12-01,Operating,SGT-100,GQ,Preventive LTP – Includes RDS,LTP,I4-003,30,COMPRESSOR
NM0314/03,6197318,OGDCL - UCH Gas II,,NM0314/03,,N/A,SGT-100 C3S<C M1C E2S DS GB,TRAIN_NM0314/03,SGT-100,2016-10-27,2017-10-26,2012-09-30,Operating,SGT-100,PK,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NU0118/01,6157240,Waha Oil Co - Faregh Field,WAHA OIL CO,NU0118/01,,N/A,SGT-100-2S PACKAGE C2S M2C E1C DC GB,TRAIN_NU0118/01,SGT-100-2S,2019-11-20,2020-11-19,2008-01-01,Operating,SGT-100,LY,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
BD000042U04,6104687,VCNG Power,VCNG Power,BD000042U04,NA0242/04,NA0242/04,SGT-400(LCN NA0242) C3C M2C E2S DC GB HC,TRAIN_BD000042U04,SGT-400,2016-02-06,2018-02-05,2011-01-01,Operating,SGT-400,RU,Preventive LTP,LTP,I4-008,10,GENERATOR
NC0326/04,6197130,Botas - Eskisehir,Botas  Eskisehir,NC0326/04,,N/A,SGT-400 PACKAGE C3S M2S E2S DS,TRAIN_NC0326/04,SGT-400,2016-04-08,2017-04-07,2012-07-31,Operating,SGT-400,TR,RDS,ADHOC,I4-008,30,COMPRESSOR
NJ0471/01,6662754,Centrax Ltd.,Centrax 008 (MM Karton NL),NJ0471/01,BD000387U01,BD000387,SGT-300-2S PACKAGE C2S MX EX DX,TRAIN_NJ0471/01,SGT-300-2S,2017-07-13,2019-07-12,2016-06-30,Operating,SGT-300,GB,Frame Agreement,ADHOC,I4-022,,GENERATOR
M622/02,6141088,Dummy Account: Asia Pacific - Thailand,Map Ta Phut Fertilizer 2,M622/02,,N/A,SGT-100 PACKAGE - SCRAPPED,TRAIN_M622/02,SGT-100-1S,1996-01-01,1997-04-20,1995-12-01,Scrapped,SGT-100,TH,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M0K6/01,6032851,William Grant & Sons Distillers Ltd  (Girvan),William Grants Distillers,M0K6/01,,N/A,SGT-100 PACKAGE C3S< M2C E1C DC GB,TRAIN_M0K6/01,SGT-100-1S,2001-01-01,2003-06-29,2000-12-01,Operating,SGT-100,GB,Corrective LTP – Includes RDS,LTP,I4-002,10,GENERATOR
BD000110U02,6241645,Ithaca - FPF-1,Ithaca Stella,BD000110U02,NW0329/02,NW0329/02,SGT-300PKG(LCN SCP NW0329)C3S M1C E1C DS,TRAIN_BD000110U02,SGT-300,2016-06-30,2017-12-30,2013-01-31,Operating,SGT-300,GB,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
NU0215/01,6044314,Caspian Pipeline Consortium (CPC-R) - APS4A,CPC APS4-A,NU0215/01,NU0215/01,N/A,SGT-100-2S C3S M2C E1C DC GB,TRAIN_NU0215/01,SGT-100-2S,2017-02-05,2019-02-04,2010-05-01,Operating,SGT-100,RU,Frame Agreement – Includes RDS,ADHOC,I4-003,30,COMPRESSOR
S583/02,6054630,Asahi Chemical,Asahi Chemical #2,S583/02,,N/A,SGT-200 PACKAGE,TRAIN_S583/02,SGT-200-1S,2014-08-25,2015-08-25,2014-02-26,Scrapped,SGT-200,JP,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
BD000090U01,6258186,Greif,Greif I,BD000090U01,NW0286/01,NW0286/01,SGT-300 PKG (Lincoln Scope NW0286),TRAIN_BD000090U01,SGT-300,,,2012-03-31,Scrapped,SGT-300,TM,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
M467/01,6141696,Dummy Account: Asia Pacific - Japan,Suzuki Kosai,M467/01,,N/A,SGT-100 PACKAGE - SCRAPPED,TRAIN_M467/01,SGT-100,1995-01-01,1996-01-01,1994-08-01,Scrapped,SGT-100,JP,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S611/01,6032813,Irving Oil  - Whitegate Refinery (Midleton),Irish Refiners,S611/01,,N/A,SGT-200 PACKAGE C2S M2C EX DC GB,TRAIN_S611/01,SGT-200-1S,1996-01-01,1997-06-19,1995-09-01,Operating,SGT-200,IE,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NC0029/02,6054943,ONGC B&S BCPB2 Platform,Oil and Natural Gas Corporatio,NC0029/02,,N/A,SGT-400 PACKAGE C3S M2C E2S DC GB,TRAIN_NC0029/02,SGT-400,2008-02-29,2009-02-28,2006-05-01,Operating,SGT-400,IN,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
BD000115U01,6079655,RWE Generation SE; HKW Dortmund - Dortmund,RWE Dortmund,BD000115U01,NA0320/01,NA0320/01,SGT-400 PACKAGE C1S M2C E2S DC - SOLD,TRAIN_BD000115U01,SGT-400,2014-09-30,2016-09-29,2012-07-01,Scrapped,SGT-400,DE,No Contract or Agreement,ADHOC,I4-008,30,GENERATOR
S8K8/02,6053096,Sarir Oil Operations - Jakhira Field,Jakhira 5,S8K8/02,,N/A,SGT-200 PACKAGE CX M2C EX DC GB,TRAIN_S8K8/02,SGT-200-1S,1999-03-01,2000-01-19,1998-03-01,Operating,SGT-200,LY,Other and 24/7 Global Helpdesk and/or RDS included,ADHOC,I4-005,10,GENERATOR
R825/01,6048301,Papelera de Aralar-Guipuzcoa-Papelera Aralar,Papelera Aralar,R825/01,,N/A,SGT-200 PACKAGE GB,TRAIN_R825/01,SGT-200-1S,1988-03-01,1990-01-16,1987-03-01,Not Running – Not Preserved,SGT-200,ES,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
HI6047/02,6302450,SemGas A SemGroup Company,,HI6047/02,NR0332/02,NR0332/02,SGT-200 (LCN NR0332) C2S M1C E1C DS,TRAIN_HI6047/02,SGT-200-2S,2015-04-01,2016-04-01,2012-09-30,Operating,SGT-200,US,No Contract or Agreement,ADHOC,I4-006,,COMPRESSOR
LS0002/01,6043402,AL KARKARA - Doha - AL KARKARA,AL KARKARA,LS0002/01,S505/01,S505/01,SGT-200 - EX S505/01 C3S M2C E2S DC GB,TRAIN_LS0002/01,SGT-200-1S,2007-04-16,2008-10-16,2004-04-28,Operating,SGT-200,QA,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
BC000166U05,6662743,Palayesh Parsian Sepehr Company,Mohr Parsian,BC000166U05,NU0476/01,NU0467/01,SGT-100-2S PACKAGE(NU0467)CX M1S EX,TRAIN_BC000166U05,SGT-100-2S,,,2016-06-30,Operating,SGT-100,IR,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
M8B3/01,6054363,Weizhou W121  - CNOOC China Ltd.,Weizhou Oil Field,M8B3/01,,N/A,SGT-100 PACKAGE <C EX GB,TRAIN_M8B3/01,SGT-100-1S,1997-11-19,1998-11-18,1997-01-01,Operating,SGT-100,CN,Framework Agreement Goods and Services,ADHOC,I4-002,10,GENERATOR
M416/02,6048078,Omv/Petrom-Petromar Subsidiary,Petrom 2,M416/02,,N/A,SGT-100 PACKAGE C2S M2C EX DC GB,TRAIN_M416/02,SGT-100-1S,1994-07-01,1995-05-07,1993-07-01,Operating,SGT-100,RO,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NA0537/01,6684570,CNOOC-CFD64,HGGT CFD6-4,NA0537/01,BD000798U01,BD000798,SGT-400 PACKAGE (BD000798) C3S M1S EX,TRAIN_NA0537/01,SGT-400,2021-04-14,2022-06-13,2019-08-21,Operating,SGT-400,CN,Preventive LTP,LTP,I4-008,,GENERATOR
NA0017/01,6047964,OAO Uralkali-Berezniki-OAO URALKALI,OAO Uralkali,NA0017/01,NA0017/01,N/A,SGT-400 C3C M2C E2S DC GB HC,TRAIN_NA0017/01,SGT-400,2008-01-01,2008-12-30,2005-04-01,Operating,SGT-400,RU,Preventive LTP,LTP,I4-008,10,GENERATOR
S2C7/02,6075477,SBM OFFSHORE - FPSO Brasil CONTA INATIVA,SBM Brasil,S2C7/02,,N/A,SGT-200 PACKAGE,TRAIN_S2C7/02,SGT-200-1S,2003-01-01,2003-12-14,2001-12-01,Scrapped,SGT-200,BR,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
BF000120U01,6673467,OGC - Salalah LPG,,BF000120U01,NW0512/01,NW0512/01,SGT-300 PKG (LCN NW0512) C3S M1S EX,TRAIN_BF000120U01,SGT-300,2020-10-01,2022-09-30,2017-09-30,Operating,SGT-300,OM,Preventive LTP – Includes RDS,LTP,I4-021,,GENERATOR
S350/01,6032707,Chrysaor  Petroleum - Judy Platform,Judy Joanne 1,S350/01,,N/A,SGT-200 PACKAGE C4S M2C E1C DC GB HS,TRAIN_S350/01,SGT-200-1S,1994-01-01,1995-04-04,1993-01-01,Operating,SGT-200,GB,Call off Agreement / Support LTP,ADHOC,I4-005,10,GENERATOR
M8B5/01,6054366,Weizhou Terminal  - CNOOC China Ltd.,Weizhou Oil Field,M8B5/01,,N/A,SGT-100 PACKAGE C2S EX GB,TRAIN_M8B5/01,SGT-100-1S,1997-12-15,1998-12-14,1997-05-01,Operating,SGT-100,CN,Framework Agreement Goods and Services,ADHOC,I4-002,10,GENERATOR
80/800669,6047211,Mayr Melnhof Eerbeek B.V.,Nijmegen,80/800669,,N/A,SGT-200 PACKAGE GB,TRAIN_80/800669,SGT-200-1S,1985-07-05,1986-07-05,1982-07-05,Not Running – Not Preserved,SGT-200,NL,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
R9H6/02,6043238,BP/AIOC - Chirag Platform,Chirag Platform 1,R9H6/02,,N/A,SGT-200 C3S M2C E1C DC,TRAIN_R9H6/02,SGT-200-2S,1999-03-01,2001-04-19,1998-03-01,Not Running – Preserved,SGT-200,AZ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
M228/01,6043592,ATEL-Biella,ATEL,M228/01,,N/A,SGT-100 PACKAGE - MOTHBALLED GB,TRAIN_M228/01,SGT-100-1S,1994-09-10,1995-09-10,1993-09-10,Scrapped,SGT-100,IT,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
R147/01,6053167,Mellitah Oil & Gas B.V - Bu Attiffel Field,Mellitah Oil & Gas - Oil Division,R147/01,,N/A,SGT-200 PACKAGE C2S M1S EX,TRAIN_R147/01,SGT-200-2S,1992-01-01,1994-01-01,1991-01-01,Operating,SGT-200,LY,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NU1257/01,6706962,Oleoductos del Valle - Oldelval - Duplicar Plus,Oldelval Duplicar Plus,NU1257/01,BD001257U01,BD001257U01,SGT-100-2S PACKAGE,TRAIN_NU1257/01,SGT-100-2S,2025-08-01,2026-07-30,2023-12-01,Order,SGT-100,AR,,,I4-003,,
R2B2/03,6137996,National Iranian Oil Company (NIOC),Dorood Pumps,R2B2/03,,N/A,SGT-200-2S PACKAGE M1C E1C DS 2002,TRAIN_R2B2/03,SGT-200-2S,2003-10-01,2004-10-01,2002-10-01,Operating,SGT-200,IR,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
R3C9/01,6053107,Sarir Oil Operations - Nahkla Station,Wintershall Nakhla,R3C9/01,NR0029/01,N/A,SGT-200 PACKAGE,TRAIN_R3C9/01,SGT-200-2S,2003-11-01,2007-01-11,2002-11-01,Not Running – Preserved,SGT-200,LY,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
S455/02,216276,Anheuser Busch - Newark Brewery,Anheuser Busch Newark,S455/02,,N/A,SGT-200 PACKAGE GB,TRAIN_S455/02,SGT-200-1S,1994-01-01,1996-12-29,1993-01-01,Not Running – Not Preserved,SGT-200,US,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NA0426/02,6541131,Ocensa - La Granjita,,NA0426/02,,HI4088,SGT-400 (HOU 4088) C3S M2S E1C DS GB,TRAIN_NA0426/02,SGT-400,2016-05-15,2017-05-14,2014-12-01,Operating,SGT-400,CO,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
NR0107/04,6038192,Transportadora Associada de Gás S/A - TAG - Ecomp de Piuma,SITI - GASENE,NR0107/04,HI6017/04,HI6017 1550017,SGT-200 PACKAGE C2S M2S E1C DS GB,TRAIN_NR0107/04,SGT-200-2S,2010-09-30,2011-09-29,2007-05-01,Operating,SGT-200,BR,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
W646/01,6052262,Golden Sugar Refinery,Luleburgaz 2,W646/01,,N/A,SGT-300 PACKAGE C3S M2C DC GB,TRAIN_W646/01,SGT-300,1997-07-01,1999-02-01,1996-07-01,Operating,SGT-300,NG,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
R2D3/01,6137994,National Iranian Oil Company (NIOC),Dorood Comp,R2D3/01,,N/A,SGT-200-2S PACKAGE M1C E1C DS 2004,TRAIN_R2D3/01,SGT-200-2S,2003-09-01,2004-09-01,2002-09-01,Operating,SGT-200,IR,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
BD000199U02,6490641,Siba,SIBA,BD000199U02,NW0390/02,NW0390/02,SGT-300 PKG (Lincoln scope NW0390)C3S EX,TRAIN_BD000199U02,SGT-300,2018-06-06,2019-06-05,2014-05-31,Operating,SGT-300,IQ,Other and 24/7 Global Helpdesk and/or RDS included,ADHOC,I4-021,10,GENERATOR
M0E9/01,6043923,Batıçim Enerji,M0E7/01-Rm325-Gt3,M0E9/01,,N/A,SGT-100 PACKAGE - MOTHBALLED GB,TRAIN_M0E9/01,SGT-100-1S,2003-05-02,2004-05-02,2002-05-02,Scrapped,SGT-100,TR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S635/04,6032841,Phillips 66 Limited - Humber Refinery,Conoco Phillips 4,S635/04,,N/A,SGT-200 PACKAGE M2C EX DC GB,TRAIN_S635/04,SGT-200-1S,1996-01-01,1997-11-15,1995-12-01,Not Running – Preserved,SGT-200,GB,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NR0117/01,6048073,UEP Beta GmbH [formerly OMV (Pakistan) Exploration Gesellschaft m.b.H.)- Sawan Gas Field,UEP Sawan,NR0117/01,,N/A,SGT-200 PACKAGE CX M2C E1C DC GB,TRAIN_NR0117/01,SGT-200-2S,2010-05-04,2011-05-03,2008-01-01,Operating,SGT-200,PK,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
M118/01,6141697,Beco - Beco Units,Coval/Beco,M118/01,,N/A,SGT-100 PACKAGE - SITE SHUT,TRAIN_M118/01,SGT-100-1S,1991-01-01,1992-07-01,1990-01-01,Scrapped,SGT-100,IT,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NC0291/01,6154030,ONGC - Sagar Samrat  (Mercator),SAGAR SAMRAT ONGC,NC0291/01,BFF00413U01,N/A,SGT-400 PACKAGE C3S M2C E1C DC,TRAIN_NC0291/01,SGT-400,2024-01-01,2024-12-30,2012-02-28,Installation & Commissioning,SGT-400,IN,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
U2C5/01,6045255,ENI S.p.A. - DIME Crotone,ENI Crotone,U2C5/01,,N/A,SGT-100-2S C2S M2C E1C DC GB,TRAIN_U2C5/01,SGT-100-2S,2002-06-26,2010-11-15,2001-06-26,Operating,SGT-100,IT,Framework Agreement Goods and Services,ADHOC,I4-003,30,COMPRESSOR
A2B5/02,218714,MATEP,Matep 2,A2B5/02,HOU,1405008,SGT-400(HOU HI4008) C3C M2C E2S DC GB HC,TRAIN_A2B5/02,SGT-400,2003-09-02,2004-09-02,2002-01-01,Operating,SGT-400,US,Corrective LTP – Includes RDS,LTP,I4-008,10,GENERATOR
R2E5/01,6053150,Sonatrach TRC - OZ2,OZ2,R2E5/01,,N/A,SGT-200-2S PACKAGE M2C E1C DC 2002,TRAIN_R2E5/01,SGT-200-2S,2002-09-19,2006-03-29,2001-09-19,Operating,SGT-200,DZ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NW0490/01,6665284,"AECC Gas Turbine (Zhuzhou) Co., Ltd.  (ZNGT)",ZNGT Orient Champion Paper,NW0490/01,BD000463U01,BD000463,SGT-300 PACKAGE C3S M2C EX DC,TRAIN_NW0490/01,SGT-300-1S,2018-02-11,2020-02-10,2017-03-01,Operating,SGT-300,CN,Preventive LTP,LTP,I4-021,,GENERATOR
NJ0406/02,6541474,Rospan International,Rospan,NJ0406/02,,N/A,SGT-300 2S PACKAGE CX M2C E1C DC,TRAIN_NJ0406/02,SGT-300-2S,2021-11-01,2023-10-30,2014-09-30,Operating,SGT-300,RU,Preventive LTP,LTP,I4-022,,COMPRESSOR
NU0570/03,6701791,Oleoductos del Valle - Oldelval - Vivaldi,Oldelval Vivaldi,NU0570/03,BD001155U03,,,,SGT-100-2S,2025-02-01,2026-01-30,2022-09-01,Order,SGT-100,AR,,,,,
BD001171U01,6701793,New Fortress Energy - Gulf of Mexico (Mex),FAST LNG PHASE 4,BD001171U01,NA0573/01,,,,SGT-400,2025-06-01,2026-05-30,2022-09-01,Order,SGT-400,MX,No Contract or Agreement,ADHOC,,,
NM0414/01,6544046,Nawara OMV (GTP/CPF),Nawara Gas Treatment Plant (GTP),NM0414/01,BF000063U03,N/A,SGT-100 PACKAGE C3C M1C E1C DS HC,TRAIN_NM0414/01,SGT-100-1S,2020-06-29,2022-06-28,2015-03-31,Operating,SGT-100,TN,Other and 24/7 Global Helpdesk and/or RDS included,ADHOC,I4-002,10,GENERATOR
R820/01,6144204,Visy Pulp and Paper Pty Ltd,Visy Paper,R820/01,,N/A,SGT-200 PACKAGE,TRAIN_R820/01,SGT-200,1987-12-31,1990-05-31,1985-12-31,Scrapped,SGT-200,AU,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
W2F1/02,6055456,"Petronet LNG Ltd., Dahej",Dahej Port Lng 2,W2F1/02,,N/A,SGT-300 PACKAGE C2S M2C E2S DC GB,TRAIN_W2F1/02,SGT-300,2015-01-01,2015-01-01,2015-01-01,Operating,SGT-300,IN,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
NC0326/02,6197128,Botas - Eskisehir,Botas  Eskisehir,NC0326/02,,N/A,SGT-400 PACKAGE C3S M2S E2S DS,TRAIN_NC0326/02,SGT-400,2016-04-08,2017-04-07,2012-07-31,Operating,SGT-400,TR,RDS,ADHOC,I4-008,30,COMPRESSOR
HI6074/01,6680740,Mubadala Energy,Pegaga Mubadala,HI6074/01,BD000717U01,NW0526/01 BD000717,SGT-300 PACKAGE (NW0526) C3S M1S EX,TRAIN_HI6074/01,SGT-300-1S,2022-09-23,2024-05-22,2018-09-30,Operating,SGT-300,MY,Frame Agreement,ADHOC,I4-021,,GENERATOR
M623/01,6051176,MICHELIN / Plant operated by E.ON Business Solutions S.r.l.,Cerkeskoy,M623/01,,N/A,SGT-100 PACKAGE C2S M2C EX DC GB,TRAIN_M623/01,SGT-100-1S,1996-01-12,1997-01-12,1995-01-12,Operating,SGT-100,IT,Corrective LTP,LTP,I4-002,10,GENERATOR
NR0033/11,6053138,Sonatrach TRC - OZ2,OZ2,NR0033/11,R3D8/11,N/A,SGT-200-2S PACKAGE CX M2C E1C DC,TRAIN_NR0033/11,SGT-200-2S,2004-01-06,2007-03-07,2003-01-06,Operating,SGT-200,DZ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NR0037/02,6038855,Total Austral S.A. - San Roque,San Roque,NR0037/02,,1405040,SGT-200 PACKAGE C1S M1C E2S DS,TRAIN_NR0037/02,SGT-200-2S,2005-07-28,2006-07-28,2004-05-01,Operating,SGT-200,AR,Other and 24/7 Global Helpdesk and/or RDS are not included,ADHOC,I4-006,30,COMPRESSOR
NM0069/01,6054665,Chunxiao Platform - CNOOC China Ltd.,Chunxiao Platform,NM0069/01,,N/A,SGT-100 PACKAGE C2S M2C E1C DC GB,TRAIN_NM0069/01,SGT-100-1S,2004-09-24,2006-04-14,2003-06-24,Operating,SGT-100,CN,Frame Agreement,ADHOC,I4-002,10,GENERATOR
M0B1/02,6044338,Caspian Pipeline Consortium (CPC-R) - APS4A,CPC APS4-A,M0B1/02,,N/A,SGT-100 PACKAGE C3S M2C E1C DC GB,TRAIN_M0B1/02,SGT-100-1S,2001-06-08,2002-06-08,2000-06-08,Operating,SGT-100,RU,Frame Agreement – Includes RDS,ADHOC,I4-002,10,GENERATOR
S8D1/01,6038504,PEP-Cantarell Offshore-APC-Akal B,Akal B (Offshore),S8D1/01,S8D1/01,N/A,SGT-200 PACKAGE,TRAIN_S8D1/01,SGT-200-1S,1999-02-18,2000-02-17,1998-02-18,Not Running – Not Preserved,SGT-200,MX,Frame Agreement,ADHOC,I4-005,10,GENERATOR
R136/01,6032860,Ineos - FPS Limited,BP Kinneil 2,R136/01,,N/A,SGT-200-2S PACKAGE C1S M2C E1C DC 2023,TRAIN_R136/01,SGT-200-2S,1993-11-07,1994-11-06,1991-02-01,Operating,SGT-200,GB,Frame Agreement,ADHOC,I4-006,30,COMPRESSOR
HI6073/01,6678931,CSV MIDSTREAM Solutions Corp,Karr Paramount,HI6073/01,NW0524/01 - BD000716U01,NW0524/01 BD000716,SGT-300 PKG (LCN NW0524) C3S M2C EX DC,TRAIN_HI6073/01,SGT-300-1S,2020-06-25,2021-06-24,2018-10-31,Operating,SGT-300,CA,Corrective LTP – Includes RDS,LTP,I4-021,,GENERATOR
80/85113-01,216603,Hilcorp Alaska LLC - Endicott,Ruston 4501 Endicott Field,80/85113-01,9080/0286-2,N/A,SGT-200 PACKAGE CX M2C E1C DC,TRAIN_80/85113-01,SGT-200-1S,1986-01-01,1987-01-01,1984-01-01,Operating,SGT-200,US,Frame Agreement,ADHOC,I4-005,10,GENERATOR
NM0072/02,6138006,Dummy Account: Middle East,Sonatrach,NM0072/02,,N/A,SGT-100 PACKAGE - ORDER CANCELLED,TRAIN_NM0072/02,SGT-100-1S,1961-01-01,1962-01-01,1960-01-01,Cancelled,SGT-100,AF,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
U0A2/01,6044327,Caspian Pipeline Consortium (CPC-R) - Komsomolsk,CPC Komsomolsk,U0A2/01,,N/A,SGT-100-2S PACKAGE C3S< M2C E1C GB DC,TRAIN_U0A2/01,SGT-100-2S,2000-11-13,2003-12-14,1999-04-23,Operating,SGT-100,RU,Frame Agreement – Includes RDS,ADHOC,I4-003,30,COMPRESSOR
NR0107/05,6038153,Transportadora Associada de Gás S/A - TAG - Ecomp de Aracruz,SITI - GASENE,NR0107/05,HI6017/05,HI6017 1550017,SGT-200 PACKAGE C2S M2S E2S DS GB,TRAIN_NR0107/05,SGT-200-2S,2010-09-30,2011-09-29,2007-05-01,Operating,SGT-200,BR,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
S8A3/01,6141717,National Iranian Oil Company (NIOC) HQ,Sirri Island,S8A3/01,,N/A,SGT-200 PACKAGE CX M2C EX DC GB,TRAIN_S8A3/01,SGT-200-1S,1998-01-01,2003-11-13,1997-04-01,Operating,SGT-200,IR,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NA0474/01,6662755,Centrax Ltd.,Centrax 010 (Airbus),NA0474/01,BD000414U01,BD000414,SGT-400 C3C HC M1S EX,TRAIN_NA0474/01,SGT-400,2017-11-15,2018-11-14,2016-09-30,Operating,SGT-400,GB,Corrective LTP – Includes RDS,LTP,I4-008,,GENERATOR
R222/02,6053410,Sonatrach DP - Mesdar,SONATRACH DIVISION PRODUCTION,R222/02,,N/A,SGT-200-TS PACKAGE,TRAIN_R222/02,SGT-200-2S,1992-09-01,1992-12-30,1991-09-01,Not Running – Not Preserved,SGT-200,DZ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
BD000129U02,6362604,Nizhnevolzhskneft Ltd./Filanovsky Field,Filanovsky LSP-2,BD000129U02,NA0335/02,NA0335/02,SGT-400 (LCN NA0335) C3C M2S EX GB HC,TRAIN_BD000129U02,SGT-400,2017-04-30,2021-05-30,2013-01-31,Operating,SGT-400,RU,Preventive LTP,LTP,I4-008,10,GENERATOR
HI6051/04,6437649,NUVOIL S.A. DE C.V.,TREN 4,HI6051/04,NC0375/04,NC0375/04,SGT-400 (LCN NC0375) C3S M2S E2S DS GB,TRAIN_HI6051/04,SGT-400,2016-08-14,2017-08-13,2013-09-30,Operating,SGT-400,MX,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
NU0027/03,6044136,Botas - Hanak,Botas Petroleum Pipeline Corpo,NU0027/03,,N/A,SGT-100-2S C3S M2C E1C DC GB,TRAIN_NU0027/03,SGT-100-2S,2010-05-30,2012-05-29,2006-03-01,Operating,SGT-100,TR,RDS,ADHOC,I4-003,30,COMPRESSOR
NC0021/03,6039148,PDVSA - Santa Rosa - Planta de Gas Anaco,"Anaco, Santa Rosa",NC0021/03,NC0021/03,HI6006 1550006,SGT-400 (HOU HI6006) C1C M2C E1C DC GB,TRAIN_NC0021/03,SGT-400,,,2005-06-01,Not Running – Not Preserved,SGT-400,VE,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
M3C5/01,6055514,"Chubu Airport, Nagoya",Chubu Airport,M3C5/01,NM0065/01,N/A,SGT-100 PACKAGE C3C M2C E1C DC GB HC,TRAIN_M3C5/01,SGT-100-1S,1988-10-01,2002-10-01,1987-12-01,Scrapped,SGT-100,JP,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S561/02,6046494,IPSA-Newcastle-East Lancs Paper,East Lancs Paper 12,S561/02,,N/A,SGT-200 PACKAGE Ex East Lancs Paper GB,TRAIN_S561/02,SGT-200-1S,1996-01-01,1996-11-11,1995-02-01,Not Running – Preserved,SGT-200,ZA,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NW0108/02,6079503,Altera-Ocyan - FPSO Cidade de Itajai,SGT Bravo,NW0108/02,,N/A,SGT-300 CX M2C E2S DC GB,TRAIN_NW0108/02,SGT-300-1S,2012-11-05,2013-11-04,2007-06-18,Operating,SGT-300,BR,Preventive LTP – Includes RDS,LTP,I4-021,10,GENERATOR
BD000057U01,6104634,Golden Sugar Refinery,Golden Sugar,BD000057U01,NA0264/01,NA0264/01,SGT-400 (LCN NA0264) C3S M2S E1C DS GB,TRAIN_BD000057U01,SGT-400,2014-04-08,2016-03-28,2011-05-01,Operating,SGT-400,NG,Other and 24/7 Global Helpdesk and/or RDS included,ADHOC,I4-008,10,GENERATOR
R2E4/01,6053140,Sonatrach TRC - OZ2,OZ2,R2E4/01,,N/A,SGT-200-2S PACKAGE M2C E1C DC 2002,TRAIN_R2E4/01,SGT-200-2S,2002-09-19,2006-03-29,2001-09-19,Operating,SGT-200,DZ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
M8C8/01,6043486,Kommunekemi A/S - Nyborg,Nyborg 97,M8C8/01,9100/239,N/A,SGT-100 PACKAGE - SKID EMPTY GB,TRAIN_M8C8/01,SGT-100-1S,1998-01-01,1999-08-03,1997-06-01,Not Running – Preserved,SGT-100,DK,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
U2H5/02,6049604,OPS Angola - FPSO Ngoma - Block 15,N'Goma FPSO,U2H5/02,,N/A,SGT-100-2S PACKAGE <C M2C E1C DC GB,TRAIN_U2H5/02,SGT-100-2S,2003-01-01,2004-09-13,2001-06-01,Operating,SGT-100,AO,Preventive LTP – Includes RDS,LTP,I4-003,30,COMPRESSOR
NA0148/02,6111995,"Kinder Morgan - Gulf LNG - Pascagoula, MS",SITI Gulf LNG,NA0148/02,,HI4052,SGT-400 (HOU HI4052) C3S M2S E1C DS GB,TRAIN_NA0148/02,SGT-400,2011-10-06,2013-04-05,2008-08-01,Operating,SGT-400,US,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
R121/01,6032897,Chrysaor Holdings - Everest Platform,BG Everest 1,R121/01,,N/A,SGT-200-2S PACKAGE CX M2C EX DC,TRAIN_R121/01,SGT-200-2S,1992-03-27,1993-03-26,1990-06-01,Operating,SGT-200,GB,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
BD000778U03,6683225,Velika Plana,Velika Plana,BD000778U03,NJ0535/03,NJ0535/03,SGT-300-2S PACKAGE (LCN NJ0535) CX M1S,TRAIN_BD000778U03,SGT-300-2S,2021-12-01,2023-11-30,2019-06-30,Operating,SGT-300,RS,No Contract or Agreement,ADHOC,I4-022,,COMPRESSOR
W3C8/01,6053123,Mellitah Oil & Gas B.V - Sabratha Platform,"AGIP GAS/Sabratha platform, NC41",W3C8/01,,N/A,SGT-300 PACKAGE C2S M1C E2S DS,TRAIN_W3C8/01,SGT-300,2003-11-26,2007-05-21,2002-11-26,Operating,SGT-300,LY,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
BC000154U02,6553202,TAP Albania,TAP Albania,BC000154U02,NC0448/02,NC0448/02,SGT-400 PKG (LCN NC0448) C3S M1S E,TRAIN_BC000154U02,SGT-400,2021-03-30,2023-03-29,2016-02-01,Operating,SGT-400,AL,Preventive LTP – Includes RDS,LTP,I4-008,,COMPRESSOR
NW0195/03,6044249,Burullus (Rashpetco),Burullus Phase VII,NW0195/03,,N/A,SGT-300 N TYPE C3C M2C E2S DC GB HC,TRAIN_NW0195/03,SGT-300,2012-01-20,2013-01-19,2009-12-01,Operating,SGT-300,EG,Preventive LTP,LTP,I4-021,10,GENERATOR
M0C3/01,6048423,Dana Petroleum Netherlands B.V. - De Ruyter,Dana Petroleum GT1,M0C3/01,,N/A,SGT-100 TURB EFICIENCY C2S M1C E1C DS GB,TRAIN_M0C3/01,SGT-100-1S,2001-08-08,2002-08-08,2000-08-08,Operating,SGT-100,NL,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M2B8/01,6038124,AmBev S/A - Nova Rio,Ambev Rio,M2B8/01,,1405010,SGT-100 PACKAGE - MOTHBALLED,TRAIN_M2B8/01,SGT-100-1S,2002-09-19,2005-01-23,2001-09-19,Not Running – Not Preserved,SGT-100,BR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S710/02,6032849,ENGIE - Humber Energy,Humber Energy 2,S710/02,,N/A,SGT-200 PACKG C1C M2C E1C DC GB 2022 HP,TRAIN_S710/02,SGT-200-1S,1997-01-01,1997-12-15,1996-03-01,Operating,SGT-200,GB,Corrective LTP – Includes RDS,LTP,I4-005,10,GENERATOR
NC0553/01,6692149,"Kazancompressormash, OJSC / Kazankompressormash, OAO",GPN Zapolyarie Pestsovoye BCS,NC0553/01,BD000950U01,BD000950,SGT-400 PACKAGE CX M1S,TRAIN_NC0553/01,SGT-400,,,2020-09-01,Installation & Commissioning,SGT-400,RU,,,I4-008,,COMPRESSOR
NW0046/01,6049832,Severnaya Neft-Usinsk-Khasireiskoye Oil Field Nort,SEVERNAYA NEFT,NW0046/01,,N/A,SGT-300 C4S M2C E1C GB DC HS,TRAIN_NW0046/01,SGT-300,2006-03-14,2008-08-27,2004-10-01,Operating,SGT-300,RU,Preventive LTP,LTP,I4-021,10,GENERATOR
S8K8/03,6053095,Sarir Oil Operations - Jakhira Field,Jakhira 2,S8K8/03,,N/A,SGT-200 PACKAGE CX M2C EX DC GB,TRAIN_S8K8/03,SGT-200-1S,1999-03-01,2000-02-09,1998-03-01,Operating,SGT-200,LY,Other and 24/7 Global Helpdesk and/or RDS included,ADHOC,I4-005,10,GENERATOR
S129/03,6032506,Dragon LNG UK Ltd - Milford Haven,Milford Haven 2 - 3,S129/03,,N/A,SGT-200 PACKAGE GB,TRAIN_S129/03,SGT-200-1S,1992-01-01,1993-04-05,1990-01-01,Scrapped,SGT-200,GB,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M369/01,6144214,BHP Pty Ltd,Onslow 1,M369/01,,N/A,SGT-100 PACKAGE - SCRAPPED,TRAIN_M369/01,SGT-100,1992-12-31,1994-10-16,1991-12-31,Scrapped,SGT-100,AU,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S609/01,6050555,Sucrerie Lesaffre Freres-Nangis-Lesaffre - Nangis,Lesaffre - Nangis,S609/01,,N/A,SGT-200 PACKAGE C3S M2C DC GB,TRAIN_S609/01,SGT-200-1S,1996-07-01,1997-03-07,1995-07-01,Operating,SGT-200,FR,Preventive LTP,LTP,I4-005,10,GENERATOR
BC000008U02,6362833,Al-Khafji Joint Operations - KJO-ISV,Hout,BC000008U02,NC0340/02,NC0340/02,SGT-400 PKG(LCNScpNC0340) C3S M1S EX GB,TRAIN_BC000008U02,SGT-400,,,2012-12-31,Installation & Commissioning,SGT-400,SA,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
A0B8/04,6046508,Iraq Mobile,A 3,NA0011/02,A0B8/04,N/A,SGT-400 PACKAGE,TRAIN_A0B8/04,SGT-400,2002-01-08,2003-01-08,2001-01-08,Scrapped,SGT-400,IQ,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
HI4072/03,6156035,NEOM Saudi Arabia,Cardon IV,HI4072/03,NA0289/03,NA0289/03,SGT-400 PACKAGE(LCN SCOPE NA0289),TRAIN_HI4072/03,SGT-400,,,2011-12-31,Storage,SGT-400,SA,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
W8A9/01,6032826,Nem/Abr - Roquette UK Limited,Roquette 1,W8A9/01,,N/A,SGT-300 PACKAGE C3S M1C E2S DS GB,TRAIN_W8A9/01,SGT-300,1998-01-01,1998-12-31,1997-03-01,Not Running – Not Preserved,SGT-300,GB,,,I4-021,10,GENERATOR
A0G8/02,6032485,National Gas - Nether Kellet,NG Nether Kellett 2,A0G8/02,NC0003/02,N/A,SGT-400 PACKAGE CX M2C E1C DC GB,TRAIN_A0G8/02,SGT-400,2002-10-02,2003-10-02,2001-10-02,Operating,SGT-400,GB,Frame Agreement,ADHOC,I4-008,30,COMPRESSOR
NA0239/02,6089547,Altera-Ocyan - FPSO Cidade de Itajai,SGT Echo,NA0239/02,,N/A,SGT-400 C3C M2C E2S DC GB HC,TRAIN_NA0239/02,SGT-400,2012-11-03,2013-11-02,2010-11-01,Operating,SGT-400,BR,Preventive LTP – Includes RDS,LTP,I4-008,10,GENERATOR
BF000126U02,6680139,Energean Oil & Gas FPSO - Karish and Tanin Fields,Energean Karish Tanin FPSO,BF000126U02,NA0522/02,NA0522/02,SGT-400 PKG (LNC SCOPE NA0522) C3S EX GB,TRAIN_BF000126U02,SGT-400,2022-11-15,2023-11-14,2018-07-31,Operating,SGT-400,IL,Other and 24/7 Global Helpdesk and/or RDS included,ADHOC,I4-008,,GENERATOR
M112/02,6032794,Thames Water - Beckton Sewage Treatment Works,Beckton Sewage 2,M112/02,,N/A,SGT-100 PACKAGE - MOTHBALLED GB,TRAIN_M112/02,SGT-100-1S,1992-12-20,1993-12-20,1991-12-20,Scrapped,SGT-100,GB,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
A3B4/02,6032489,National Gas - Avonbridge,NG Bathgate 2,A3B4/02,NC0013/02,N/A,SGT-400 PACKAGE <C M2C E1C GB DC,TRAIN_A3B4/02,SGT-400,2002-02-09,2003-02-09,2001-02-09,Not Running – Preserved,SGT-400,GB,Frame Agreement,ADHOC,I4-008,30,COMPRESSOR
NM0290/02,6156038,Aeroport Pulkovo OAO,Pulkovo Aiport,NM0290/02,,N/A,SGT-100 PACKAGE C3S M2C E1C DC GB HP,TRAIN_NM0290/02,SGT-100-1S,2014-07-12,2016-07-11,2013-01-21,Operating,SGT-100,RU,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
R633/01,6047902,OKEA - Draugen Field,Draugen GT100,R633/01,9080/185,N/A,SGT-200-2S PACKAGE CX M2C EX DC,TRAIN_R633/01,SGT-200-2S,1996-01-01,1997-12-01,1995-12-01,Operating,SGT-200,NO,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
R2E3/05,6053159,Sonatrach TRC - OZ2,OZ2,R2E3/05,,N/A,SGT-200-2S PACKAGE M2C E1C DC 2002,TRAIN_R2E3/05,SGT-200-2S,2004-01-01,2006-03-29,2001-09-19,Operating,SGT-200,DZ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NU0025/01,6138007,Veresk Company (NIORDC),Chamran,NU0025/01,,N/A,SGT-100-2S PACKAGE - STORAGE GB,TRAIN_NU0025/01,SGT-100-2S,,,2004-03-31,Storage,SGT-100,IR,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
80/87311-01,6032801,James Cropper - Kendal,James Cropper,80/87311-01,,N/A,SGT-200 PACKAGE CX M2C E1C DC HS,TRAIN_80/87311-01,SGT-200-1S,1989-06-11,1990-06-11,1987-01-01,Operating,SGT-200,GB,Corrective LTP – Includes RDS,LTP,I4-005,10,GENERATOR
S707/01,6055132,"Terumo, Kofu",Terumo Kofu,S707/01,,N/A,SGT-200 PACKAGE - SOLD TO WOODGROUP,TRAIN_S707/01,SGT-200-1S,1997-05-01,1998-02-04,1996-03-01,Scrapped,SGT-200,JP,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
S753/01,6038137,Petróleo Brasileiro S/A - Petrobras - E&P/SSE/UN-BC/ATP-MRL/FPSO P35,FPSO P35,S753/01,,N/A,SGT-200 PACKAGE EX GB 2007,TRAIN_S753/01,SGT-200-1S,1997-01-01,1998-07-01,1996-01-01,Operating,SGT-200,BR,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M521/01,6055452,"Denso, Daian",Denso Daian,M521/01,,N/A,SGT-100 PACKAGE C2S GB,TRAIN_M521/01,SGT-100-1S,1995-01-01,1996-06-29,1994-01-01,Not Running – Not Preserved,SGT-100,JP,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M377/01,6046937,Krasnodar Heat and Power-Krasnodar-Krasnodar,Krasnodar 1,M377/01,,N/A,SGT-100 PACKAGE - DEACTIVATED GB,TRAIN_M377/01,SGT-100-1S,1995-01-01,1996-01-01,1993-09-01,Not Running – Not Preserved,SGT-100,RU,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NA0134/01,6112084,Dummy Account: Middle East - Syria,EBLA POWER GEN,NA0134/01,NA0134/01,N/A,SGT-400 PACKAGE <C M2C E1C DS GB,TRAIN_NA0134/01,SGT-400,2010-03-16,2010-08-30,2008-05-01,Not Running – Not Preserved,SGT-400,SY,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
NM0314/02,6197317,OGDCL - UCH Gas II,,NM0314/02,,N/A,SGT-100 C3S<C M1C E2S DS GB,TRAIN_NM0314/02,SGT-100,2016-10-27,2017-10-26,2012-09-30,Operating,SGT-100,PK,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NC0017/02,6051094,TotalEnergies Angola - FPSO Girassol - Block 17,FPSO GIRASSOL,NC0017/02,,N/A,SGT-400 PACKAGE C3S M2C E2S DC GB,TRAIN_NC0017/02,SGT-400,2008-06-01,2009-11-29,2004-05-01,Operating,SGT-400,AO,Framework Agreement Goods and Services,ADHOC,I4-008,30,COMPRESSOR
NA0581/01,6704652,"AECC Gas Turbine (Zhuzhou) Co., Ltd.  (ZNGT)",ZNGT Guangan Jianping Paper,NA0581/01,BD001229U01,BD001229,SGT-400 PACKAGE,TRAIN_NA0581/01,SGT-400,2024-06-01,2025-05-30,2023-03-01,Order,SGT-400,CN,,,I4-008,,
U705/02,6138013,Chamran Pump Station (NIORDC),Chamran,U705/02,,N/A,SGT-100-2S PACKAGE C2S M1S EX GB,TRAIN_U705/02,SGT-100-2S,1998-12-16,1997-04-01,1997-04-01,Operating,SGT-100,IR,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
R121/04,6032895,Chrysaor Holdings - Lomond Platform,BG Lomond 2,R121/04,,N/A,SGT-200-2S PACKAGE <C M2C EX DC,TRAIN_R121/04,SGT-200-2S,1992-05-13,1993-05-12,1990-09-01,Not Running – Preserved,SGT-200,GB,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NC0203/01,6052879,Sonatrach DP - Rhoude Nouss,Rhoude Nouss,NC0203/01,,N/A,SGT-400 PACKAGE C3S M2R E2S GB DS,TRAIN_NC0203/01,SGT-400,2014-05-20,2017-05-19,2010-03-01,Not Running – Not Preserved,SGT-400,DZ,Frame Agreement,ADHOC,I4-008,30,COMPRESSOR
S929/01,6141474,Tarragona Power CCPP,Tarragona Power Estropeada,S929/01,,N/A,SGT-200 PACKAGE,TRAIN_S929/01,SGT-200,1990-01-01,1990-09-23,1989-09-01,Not Running – Not Preserved,SGT-200,ES,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NU0215/02,6044315,Caspian Pipeline Consortium (CPC-R) - APS4A,CPC APS4-A,NU0215/02,NU0215/02,N/A,SGT-100-2S PACKAGE C3S M2C E1C DC GB,TRAIN_NU0215/02,SGT-100-2S,2016-12-29,2018-12-28,2010-05-01,Operating,SGT-100,RU,Frame Agreement – Includes RDS,ADHOC,I4-003,30,COMPRESSOR
BD000639U02,6680135,Hindustan Urvarak & Rasayan Limited - Gorakhpur,HURL Gorakhpur for Toyo,BD000639U02,,NA0521/02,SGT-400 PKG(LCNScpNA0521) C3S M1S EX GB,TRAIN_BD000639U02,SGT-400,2022-05-08,2022-08-31,2018-06-30,Operating,SGT-400,IN,Preventive LTP – Includes RDS,LTP,I4-008,,GENERATOR
NR0033/01,6053162,Sonatrach TRC - OZ2,OZ2 PIPELINE,NR0033/01,R3D8/01,N/A,SGT-200-2S PACKAGE CX M2C E1C DC,TRAIN_NR0033/01,SGT-200-2S,2004-01-06,2007-03-07,2003-01-06,Operating,SGT-200,DZ,No Contract or Agreement,ADHOC,I4-006,,COMPRESSOR
NR0033/02,6053144,Sonatrach TRC - OZ2,OZ2,NR0033/02,R3D8/02,N/A,SGT-200-2S PACKAGE CX M2C E1C DC,TRAIN_NR0033/02,SGT-200-2S,2004-01-06,2007-03-07,2003-01-06,Operating,SGT-200,DZ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NU1258/01,6706963,Oleoductos del Valle - Oldelval - Duplicar Plus,Oldelval Duplicar Plus,NU1258/01,BD001257U02,BD001258U01,SGT-100-2S PACKAGE,TRAIN_NU1258/01,SGT-100-2S,2025-08-01,2026-07-30,2023-12-01,Order,SGT-100,AR,,,I4-003,,
R321/03,6032933,Gas Networks Ireland - Brighouse Bay,Brighouse Bay 3,R321/03,,N/A,SGT-200-2S PACKAGE C3S M2C DC,TRAIN_R321/03,SGT-200-2S,1995-04-01,1996-03-30,1993-08-09,Operating,SGT-200,GB,Preventive LTP,LTP,I4-006,30,COMPRESSOR
NC0016/02,6134885,National Iranian Oil Company (NIOC),South Pars Phase 6,NC0016/02,,N/A,SGT-400 PACKAGE CX M2C E1C DC GB,TRAIN_NC0016/02,SGT-400,2005-08-01,2009-07-30,2004-01-01,Operating,SGT-400,IR,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
BF000079U01,6552740,Botas - Hanak,Botas Hanak,BF000079U01,NC0443/01,NC0443/01,SGT-400 PKG (LCNScope NC0443) C3S M1S EX,TRAIN_BF000079U01,SGT-400,2018-04-04,2019-04-03,2015-12-01,Operating,SGT-400,TR,No Contract or Agreement,ADHOC,I4-008,,COMPRESSOR
M541/01,217907,Fortistar Methane Group - Pine Bend Landfill Power Plant,Gas Recovery Systems Inc. Pinebend,M541/01,9100/141,N/A,SGT-100 PACKAGE,TRAIN_M541/01,SGT-100-1S,1996-01-01,1996-06-24,1995-07-01,Scrapped,SGT-100,US,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M425/01,6049695,Sedamyl,Sedamyl RM,M425/01,,N/A,SGT-100 PACKAGE - MOTHBALLED GB,TRAIN_M425/01,SGT-100-1S,1994-09-01,1999-03-30,1993-09-01,Scrapped,SGT-100,IT,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S113/01,6048842,Qatar Petroleum - Doha - Dukhan Power Station,Dukhan Power Station,S113/01,,N/A,SGT-200 PACKAGE GB,TRAIN_S113/01,SGT-200-1S,1991-01-01,1992-03-25,1990-01-01,Not Running – Not Preserved,SGT-200,QA,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
S553/01,6062657,"Bridgestone, Tokyo","Bridgestone, Tokyo",S553/01,,N/A,SGT-200 PACKAGE - SCRAPPED,TRAIN_S553/01,SGT-200-1S,1996-01-01,1996-11-27,1995-03-01,Scrapped,SGT-200,JP,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
BF000003U03,6364387,TUBA & ZUBAIR-1,,BF000003U03,NC0339/03,NC0339/03,SGT-400 (LCN NC0339) CX M2S E1C DS GB,TRAIN_BF000003U03,SGT-400,2017-03-01,2018-02-28,2013-03-31,Operating,SGT-400,IQ,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
NC0126/04,6038725,TGP1 Chiquintirca,Chiquintirca,NC0126/04,HI6015/04,HI6015 1550019,SGT-400(HOU HI6015) C4S M2C E2S DC GB HS,TRAIN_NC0126/04,SGT-400,2010-06-08,2011-06-07,2008-01-18,Operating,SGT-400,PE,Preventive LTP – Includes RDS,LTP,I4-008,30,COMPRESSOR
NA0106/02,6163781,Sirri Island,Sirri Island,NA0106/02,NA0106/02,N/A,SGT-400 PACKAGE CX M2C E1C DC GB,TRAIN_NA0106/02,SGT-400,2010-11-30,2012-11-29,2007-07-01,Operating,SGT-400,IR,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
M0A7/01,6044324,Caspian Pipeline Consortium (CPC-K) - Atyrau,CPC Atyrau,M0A7/01,,N/A,SGT-100 PACKAGE <C M2C E1C DC GB,TRAIN_M0A7/01,SGT-100-1S,2000-03-08,2001-03-08,1999-03-08,Not Running – Not Preserved,SGT-100,KZ,Frame Agreement – Includes RDS,ADHOC,I4-002,10,GENERATOR
NS0125/04,6046467,AMURCO,ZNGT Romania,NS0125/04,,N/A,SGT-200 PACKAGE  C1S,TRAIN_NS0125/04,SGT-200-1S DLE,2014-04-27,2015-04-26,2008-07-01,Not Running – Not Preserved,SGT-200,RO,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M231/01,6044341,AB Mauri Italy S.p.A. - Casteggio Lieviti S.r.l.,VOGHERA,M231/01,,N/A,SGT-100 PACKAGE - CX M2C EX DC GB,TRAIN_M231/01,SGT-100-1S,1993-09-01,1994-09-01,1992-09-01,Operating,SGT-100,IT,Preventive LTP,LTP,I4-002,10,GENERATOR
NA0020/02,6011870,Riverbay Corporation,RIVERBAY,NA0020/02,HOU,1405043,SGT-400 (HI4043) C4S M2C E2S DC GB HS,TRAIN_NA0020/02,SGT-400,2008-07-01,2009-06-30,2006-01-01,Operating,SGT-400,US,Corrective LTP – Includes RDS,LTP,I4-008,10,GENERATOR
NC0553/03,6692151,"Kazancompressormash, OJSC / Kazankompressormash, OAO",GPN Zapolyarie Pestsovoye BCS,NC0553/03,BD000950U03,BD000950,SGT-400 PACKAGE CX M1S,TRAIN_NC0553/03,SGT-400,,,2020-09-01,Installation & Commissioning,SGT-400,RU,,,I4-008,,COMPRESSOR
NW0155/01,6059527,Monomeros Colombo Venezolanos S.A.,Monomeros,NW0155/01,,HI4054 1405054,SGT-300 (HOU HI4054/01)C3C M1C E2S DS HC,TRAIN_NW0155/01,SGT-300,2010-07-22,2011-07-21,2008-12-28,Operating,SGT-300,CO,RDS,ADHOC,I4-021,10,GENERATOR
HI4066/16,6039056,PDVSA - Div Faja - Unidades Moviles Div Faja,PDVSA Mobile Power,HI4066/16,NA0209/16,NA0209/16 HI4057,SGT-400 PACKAGE (NA0209) CANCELLED,TRAIN_HI4066/16,SGT-400,,,2010-03-04,Cancelled,SGT-400,VE,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
M8B4/01,6054362,Weizhou W121  - CNOOC China Ltd.,Weizhou Oil Field,M8B4/01,,N/A,SGT-100 PACKAGE C3S EX GB,TRAIN_M8B4/01,SGT-100-1S,1997-11-10,1998-11-09,1997-08-01,Operating,SGT-100,CN,Framework Agreement Goods and Services,ADHOC,I4-002,10,GENERATOR
U706/04,6138020,National Iranian Oil Refining & Product Distribution Company (NIORDC),Sabzab,U706/04,,N/A,SGT-100-2S PACKAGE C1S M1S EX GB,TRAIN_U706/04,SGT-100-2S,1998-08-24,1999-08-24,1997-04-01,Operating,SGT-100,IR,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
BF000078U09,6553250,Bid Boland,Bid Boland II (Refrigerant compressor train),BF000078U04,NC0452/04,NC0452/04,SGT-400 PKG (Lincoln Scope NC0452) CX EX,TRAIN_BF000078U09,SGT-400,,,2016-01-31,Operating,SGT-400,IR,No Contract or Agreement,ADHOC,I4-008,,COMPRESSOR
HI6096/01,6690205,PETRONAS Carigali Sdn Bhd - Sarawak Operations - E11P-B PLATFORM,Petronas Kasawari,HI6096/01,NJ0552/01 - BD000913U01,NJ0552/01 BD000913,SGT-300-2S PACKAGE (NJ0552) CX,TRAIN_HI6096/01,SGT-300-2S,2024-02-01,2027-01-30,2020-03-01,Installation & Commissioning,SGT-300,MY,,,I4-022,,COMPRESSOR
R810/01,6032921,Petrofac Facilities,Shell UK,R810/01,,,,,SGT-200-2S,1989-02-12,1991-05-01,1988-11-01,Not Running – Not Preserved,SGT-200,GB,No Contract or Agreement,ADHOC,,,
NA0555/02,6692143,IGSA S.A. de C.V.,IGSA - Central TLACO,NA0555/02,BD000949U02,BD000949,SGT-400 PACKAGE M1S,TRAIN_NA0555/02,SGT-400,2024-12-01,2025-11-29,2020-09-01,Order,SGT-400,MX,No Contract or Agreement,ADHOC,I4-008,,GENERATOR
NM0313/01,6197313,OGDCL - Dakhni - Oil and Gas Development Corporation,,NM0313/01,NM0313/01,N/A,SGT-100 C3S M1C E2S DS GB,TRAIN_NM0313/01,SGT-100,2016-12-06,2017-12-05,2013-06-30,Operating,SGT-100,PK,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NR0107/08,6038195,Transportadora Associada de Gás S/A - TAG - EComp de Prado,SITI - GASENE,NR0107/08,HI6017/08,HI6017 1550017,SGT-200 PACKAGE C2S M2S E2S DS GB,TRAIN_NR0107/08,SGT-200-2S,2010-10-30,2011-10-29,2007-05-01,Not Running – Not Preserved,SGT-200,BR,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NC0201/01,6052880,Sonatrach DP - Rhoude Nouss,Rhoude Nouss,NC0201/01,,N/A,SGT-400 C3S M2C E2S GB DC,TRAIN_NC0201/01,SGT-400,2015-02-20,2017-02-19,2010-03-01,Operating,SGT-400,DZ,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
R8C3/01,6038848,Total Austral S.A. - Cañadon Alfa,Canadon Alfa,R8C3/01,,N/A,SGT-200-2S C2S M1S EX,TRAIN_R8C3/01,SGT-200-2S,1999-01-01,1999-12-31,1998-02-01,Operating,SGT-200,AR,Other and 24/7 Global Helpdesk and/or RDS are not included,ADHOC,I4-006,30,COMPRESSOR
NC0337/03,6197321,Uz-Kor Chemical,UGCC Akchalak,NC0337/03,,BF000008,SGT-400 (BF000008) C3C M2S E2S DS GB HC,TRAIN_NC0337/03,SGT-400,2015-09-03,2016-09-02,2012-12-31,Operating,SGT-400,UZ,Preventive LTP – Includes RDS,LTP,I4-008,30,COMPRESSOR
BD000194U01,6459416,ZNGT - Tianguan,ZNGT Tianguan,BD000194U01,NA0345/01,NA0345/01,SGT-400 PKG (LINCOLN SCOPE NA0345) GB,TRAIN_BD000194U01,SGT-400,,,2014-01-31,Storage,SGT-400,CN,No Contract or Agreement,ADHOC,I4-008,,GENERATOR
U0A2/02,6044326,Caspian Pipeline Consortium (CPC-R) - Komsomolsk,CPC Komsomolsk,U0A2/02,,N/A,SGT-100-2S C3C M2C E1C DC GB HC,TRAIN_U0A2/02,SGT-100-2S,2000-11-12,2003-12-14,1999-04-23,Operating,SGT-100,RU,Frame Agreement – Includes RDS,ADHOC,I4-003,30,COMPRESSOR
U0F5/02,6049284,Sakhalin Energy Investment Company Ltd. - Molikpaq,Sakhalin Island,U0F5/02,,N/A,SGT-100-2S PACKAGE CX M1C E1C DS GB,TRAIN_U0F5/02,SGT-100-2S,2001-08-09,2004-02-27,2000-06-09,Operating,SGT-100,RU,Framework Agreement Goods and Services,ADHOC,I4-003,30,COMPRESSOR
U8E9/02,6038835,Oleoductos del Valle - Oldelval - Allen,Allen,U8E9/02,,N/A,SGT-100-2S PACKAGE C2S M1S EX GB,TRAIN_U8E9/02,SGT-100-2S,1999-01-01,1999-12-31,1998-01-01,Operating,SGT-100,AR,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
NA0016/05,6011871,Alyeska - Pump Station 01,PS1 SGT-400 #1,NA0016/05,HI6001/05,1550001,SGT-400 PKG (HOU HI6001)CX M2S E2S DS GB,TRAIN_NA0016/05,SGT-400,2009-06-01,2011-05-31,2004-07-01,Operating,SGT-400,US,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
NM0081/01,6138005,National Iranian Gas Company,Parsian Refinery,NM0081/01,,N/A,SGT-100 PACKAGE C2S M2C E1C DC GB,TRAIN_NM0081/01,SGT-100-1S,2007-12-10,2008-12-09,2004-01-01,Operating,SGT-100,IR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NA0113/01,6054985,"Maruti Suzuki India Ltd., Manesar",Maruti Suzuki Phase II,NA0113/01,NA0113/01,N/A,SGT-400 PACKAGE M1C,TRAIN_NA0113/01,SGT-400,2009-02-14,2010-02-13,2007-09-01,Scrapped,SGT-400,IN,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
NR0254/01,6158072,NOC - North Oil Company - Iraq,Endeco SpA,NR0254/01,,N/A,SGT-200 PACKAGE M1S EX GB 2012,TRAIN_NR0254/01,SGT-200-2S,2017-08-15,2018-08-14,2011-04-01,Operating,SGT-200,IQ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NW0560/04,6695203,"Hangzhou Turbine Power Group Co., LTD (HTC)",HTC HAINING,NW0560/04,BD001005U04,BD001005,SGT-300 PACKAGE CX MX EX DX,TRAIN_NW0560/04,SGT-300-1S,2024-02-01,2025-07-30,2021-04-01,Installation & Commissioning,SGT-300,CN,,,I4-021,,GENERATOR
NC0018/02,6055772,Total E&P Myanmar,Total E&P,NC0018/02,,N/A,SGT-400 PACKAGE C3S M2C E2S DC GB,TRAIN_NC0018/02,SGT-400,2015-06-03,2015-03-05,2015-03-01,Operating,SGT-400,MM,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
R8F4/01,6032656,Perenco - Offshore,Perenco Thames Platform 1,R8F4/01,,N/A,SGT-200 PACKAGE,TRAIN_R8F4/01,SGT-200-2S,1998-09-01,2000-07-01,1997-09-01,Scrapped,SGT-200,GB,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
S8D2/01,6038503,PEP-Cantarell Offshore-APC-Akal B,Akal B (Offshore),S8D2/01,S8D2/01,N/A,SGT-200 PACKAGE EX 2020,TRAIN_S8D2/01,SGT-200-1S,2000-01-15,2001-01-14,1999-01-15,Operating,SGT-200,MX,Frame Agreement,ADHOC,I4-005,10,GENERATOR
A3B4/01,6032488,National Gas - Avonbridge,NG Bathgate 1,A3B4/01,NC0013/01,N/A,SGT-400 PACKAGE M1C E2S GB DS,TRAIN_A3B4/01,SGT-400,2002-02-09,2003-02-09,2001-02-09,Not Running – Preserved,SGT-400,GB,Frame Agreement,ADHOC,I4-008,30,COMPRESSOR
S0C0/02,6050736,Surgutneftegas-Surgut-Tianskoye,Tianskoye 2,S0C0/02,,N/A,SGT-200 PACKAGE,TRAIN_S0C0/02,SGT-200-1S,2001-08-01,2002-08-12,1999-12-01,Not Running – Not Preserved,SGT-200,RU,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M618/01,6032491,Boots Company - Nottingham,Boots Company 1,M618/01,,N/A,SGT-100 PACKAGE C3C M2C E1C DC GB HC,TRAIN_M618/01,SGT-100-1S,1997-04-02,1998-04-02,1996-04-02,Operating,SGT-100,GB,Corrective LTP – Includes RDS,LTP,I4-002,10,GENERATOR
NA0437/01,6552732,Centrax Ltd.,Centrax 005 (Alteo),NA0437/01,BD000280U01,BD000280,SGT-400 C3C M1S EX HC,TRAIN_NA0437/01,SGT-400,2018-12-04,2019-12-03,2015-09-04,Operating,SGT-400,GB,Corrective LTP – Includes RDS,LTP,I4-008,,GENERATOR
NC0032/02,6112055,National Iranian Oil Company (NIOC),NAR Compressor Station,NC0032/02,,N/A,SGT-400 PACKAGE C3S M2C E1C DC GB,TRAIN_NC0032/02,SGT-400,2009-12-12,2010-05-30,2006-04-01,Operating,SGT-400,IR,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
NA0222/03,6104693,Bhogat (Cairn Energy),Bhogat (Cairn Energy),NA0222/03,,N/A,SGT-400 PACKAGE C3S M2C E1C DC GB,TRAIN_NA0222/03,SGT-400,2015-04-01,2016-03-31,2010-07-01,Operating,SGT-400,IN,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
NR0040/01,6038846,Total Austral S.A. - Cañadon Alfa,SITI - Canadon Alpha,NR0040/01,HI6009 - 1550009,HI6009 1550009,SGT-200-2S PACKAGE <C M1C E2S DS 2021,TRAIN_NR0040/01,SGT-200-2S,2007-07-15,2008-01-01,2005-12-01,Operating,SGT-200,AR,Other and 24/7 Global Helpdesk and/or RDS are not included,ADHOC,I4-006,30,COMPRESSOR
R8A7/02,6032704,Bluewater - Haewene Brim,Pierce Field 2,R8A7/02,,N/A,SGT-200-2S PACKAGE C2S M2C DC,TRAIN_R8A7/02,SGT-200-2S,1998-12-31,2000-08-01,1997-04-01,Operating,SGT-200,GB,Preventive LTP,LTP,I4-006,30,COMPRESSOR
S573/01,6047374,Michelin Bourges - Saint Doulchard,Michelin Bourges,S573/01,,N/A,SGT-200 PACKAGE,TRAIN_S573/01,SGT-200-1S,1997-01-11,1998-08-21,1995-02-01,Scrapped,SGT-200,FR,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NA0197/05,6104628,ONGC Assam-Lakwa Site,Assam Renewal Project,NA0197/05,,N/A,SGT-400 C3C M2C E2S DC,TRAIN_NA0197/05,SGT-400,2015-08-08,2016-08-07,2011-05-01,Operating,SGT-400,IN,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
M2C3/01,6038101,AmBev S/A - Pirai/RJ,Ambev 2 Thru 6,M2C3/01,,N/A,SGT-100 PACKAGE CX GB,TRAIN_M2C3/01,SGT-100-1S,2001-09-19,2003-01-10,2001-04-01,Operating,SGT-100,BR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
BF000078U06,6553247,Bid Boland,Bid Boland II (Acid compressor train),BF000078U01,NC0452/01,NC0452/01,SGT-400 PKG (Lincoln Scope NC0452) CX EX,TRAIN_BF000078U06,SGT-400,,,2016-01-31,Operating,SGT-400,IR,No Contract or Agreement,ADHOC,I4-008,,COMPRESSOR
M339/01,6050809,SWK - Stadtwerke Kaiserslautern GmbH,Technische Werk Kaiserlautern,M339/01,,N/A,SGT-100 PACKAGE - SOLD GB,TRAIN_M339/01,SGT-100,1993-07-27,1994-07-27,1992-04-01,Not Running – Not Preserved,SGT-100,DE,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M708/05,6142074,National Iranian Gas Company,Farashband S1,M708/05,,N/A,SGT-100 PACKAGE C2S M2C EX DC GB,TRAIN_M708/05,SGT-100-1S,1997-01-01,1998-03-25,1996-01-01,Operating,SGT-100,IR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NW0560/05,6695204,"Hangzhou Turbine Power Group Co., LTD (HTC)",HTC HAINING,NW0560/05,BD001005U05,BD001005,SGT-300 PACKAGE CX MX EX DX,TRAIN_NW0560/05,SGT-300-1S,2024-02-01,2025-07-30,2021-04-01,Installation & Commissioning,SGT-300,CN,,,I4-021,,GENERATOR
M0E2/03,6054479,Wenchang Nanhai_West FPSO - CNOOC China Ltd.,Nanhai_West FPSO,M0E2/03,,N/A,SGT-100 PACKAGE C2S M1C E1C DS GB,TRAIN_M0E2/03,SGT-100-1S,2002-01-08,2003-01-08,2001-01-08,Operating,SGT-100,CN,Framework Agreement Goods and Services,ADHOC,I4-002,10,GENERATOR
NR0115/02,6052848,Waha Oil Co - Faregh Field,Faregh,NR0115/02,,N/A,SGT-200-2S PACKAGE C2S M2C E1C DC GB,TRAIN_NR0115/02,SGT-200-2S,2019-11-20,2020-11-19,2008-01-31,Operating,SGT-200,LY,Other - please specify,ADHOC,I4-006,30,COMPRESSOR
M511/01,6032775,Veolia - Grangemouth,Zeneca Grangemouth,M511/01,,N/A,SGT-100 PACKAGE - MOTHBALLED GB,TRAIN_M511/01,SGT-100-1S,1995-01-01,1996-04-04,1994-03-01,Scrapped,SGT-100,GB,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
R130/02,220562,TRANSCANADA PIPELINES LIMITED - MAPLE STATION 130,TC Energy CDN Mainline Maple Stn.130P4,R130/02,9080/064,N/A,SGT-200-2S PACKAGE EX,TRAIN_R130/02,SGT-200-2S,1991-06-15,1994-06-15,1990-03-01,Scrapped,SGT-200,CA,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
BD000778U02,6683224,Velika Plana,Velika Plana,BD000778U02,NJ0535/02,NJ0535/02,SGT-300-2S PACKAGE (LCN NJ0535) CX M1S,TRAIN_BD000778U02,SGT-300-2S,2021-12-01,2023-11-30,2019-06-30,Operating,SGT-300,RS,No Contract or Agreement,ADHOC,I4-022,,COMPRESSOR
BD000778U01,6683223,Velika Plana,Velika Plana,BD000778U01,NJ0535/01,NJ0535/01,SGT-300-2S PACKAGE (LCN NJ0535) CX M1S,TRAIN_BD000778U01,SGT-300-2S,2021-12-01,2023-11-30,2019-06-30,Operating,SGT-300,RS,No Contract or Agreement,ADHOC,I4-022,,COMPRESSOR
BD000203U02,6490645,Akkas Gas Field - KOGAS B.A,Akkas,BD000203U02,NA0392/02,NA0392/02,SGT-400 PACKAGE (Lincoln Scope NA0392),TRAIN_BD000203U02,SGT-400,2022-12-31,,2014-03-31,Storage,SGT-400,IQ,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
NW0130/01,6045741,Kuwait Oil Company - Ahmadi,Gathering Centre GC-24,NW0130/01,,N/A,SGT-300 PACKAGE C2S M1C E1C DS,TRAIN_NW0130/01,SGT-300,2010-05-01,2011-04-30,2008-04-01,Operating,SGT-300,KW,Call Off Contract Goods and Services,ADHOC,I4-021,10,GENERATOR
M218/01,6141692,Dummy Account: Asia Pacific - Japan,Hitachi Kaigan Works,M218/01,,N/A,SGT-100 PACKAGE - SITE SHUT,TRAIN_M218/01,SGT-100-1S,1992-01-01,1993-01-01,1991-01-01,Scrapped,SGT-100,JP,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
A0K7/01,6051164,ADNOC Offshore -ABK,Khuff Gas (Nkpp),A0K7/01,,N/A,SGT-400 PACKAGE C3C E2C M2C DC GB,TRAIN_A0K7/01,SGT-400,2003-01-06,2004-01-06,2002-01-06,Operating,SGT-400,AE,Framework Agreement Goods and Services,ADHOC,I4-008,10,GENERATOR
BD000731U03,6682268,Botas - Kuzey Marmara,Kuzey Marmara,BD000731U03,NC0527/03 - BC000294U03,NC0527/03 BC000294?,SGT-400 PACKAGE C3S M1S,TRAIN_BD000731U03,SGT-400,2024-03-01,,2018-12-31,Installation & Commissioning,SGT-400,TR,No Contract or Agreement,ADHOC,I4-008,,COMPRESSOR
S927/01,6051921,Skjern Kraftvarme Værk,Vestkraft (Skjern),S927/01,,N/A,SGT-200 PACKAGE,TRAIN_S927/01,SGT-200-1S,1989-09-29,1990-04-01,1988-04-01,Scrapped,SGT-200,DK,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
80/87306-01,6032924,Petrofac Facilities Management - Ivanhoe Rob Roy Platform,Ivanhoe Rob Roy 4,80/87306-01,,N/A,SGT-200-2S PACKAGE,TRAIN_80/87306-01,SGT-200-2S,1990-03-01,1991-03-01,1987-01-01,Scrapped,SGT-200,GB,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
HI6107/01,6681693,Northeast Energy Center,CHARLTON LNG,BD001079U01,NJ0563/01,BC000590 NJ0563/01 BD001079,SGT-300-2S PACKAGE (LCN NJ0563) CX,TRAIN_HI6107/01,SGT-300-2S,2023-12-29,2024-12-28,2021-12-31,Operating,SGT-300,US,Preventive LTP – Includes RDS,LTP,I4-022,,
HI4092/02,6663631,LEPRINO FOODS COMPANY,Leprino foods,HI4092/02,NW0492/02 / BD000479U02,NW0492/02,SGT-300 (LCN NW0492) C3S M2C EX DC HP,TRAIN_HI4092/02,SGT-300,2018-12-01,2019-11-30,2017-04-30,Operating,SGT-300,US,Corrective LTP – Includes RDS,LTP,I4-021,,GENERATOR
HI4062/01,6104692,LUXERVIZA Angola - Soyo Power Plant,BECHTEL ANGOLA SOYO,HI4062/01,NA0240/01,NA0240/01,SGT-400 PKG (Lincoln Scope NA0240)C1C GB,TRAIN_HI4062/01,SGT-400,2017-11-24,2018-11-23,2010-11-01,Not Running – Preserved,SGT-400,AO,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
A0G3/01,6032848,Guernsey El Board - Guernsey,Guernsey,A0G3/01,NA0004/01,N/A,SGT-400 PACKAGE C4S M2C E2S DC GB HC,TRAIN_A0G3/01,SGT-400,2003-03-01,2004-03-01,2002-03-01,Operating,SGT-400,GG,Corrective LTP – Includes RDS,LTP,I4-008,10,GENERATOR
NS0125/05,6046468,Donau Chem,ZNGT Romania,NS0125/05,,N/A,SGT-200 PACKAGE,TRAIN_NS0125/05,SGT-200-1S DLE,,,2008-07-01,Not Running – Not Preserved,SGT-200,RO,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NC0033/03,6039134,PDVSA - Moron - ICO Moron,"ICO, Moron",NC0033/03,,HI6011 1550011,SGT-400 (HOU HI6011) C3S M2C E1C DC GB,TRAIN_NC0033/03,SGT-400,2009-08-31,2010-11-30,2006-03-01,Operating,SGT-400,VE,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
BF000103U03,6665373,PTT Wang Noi Compressor Station,,BF000103U03,NC0489/03,NC0489/03,SGT-400 PACKAGE (LCN NC0489) C3S EX,TRAIN_BF000103U03,SGT-400,2018-12-21,2020-03-20,2017-03-01,Operating,SGT-400,TH,No Contract or Agreement,ADHOC,I4-008,,COMPRESSOR
W2G0/01,6141777,National Iranian Oil Company (NIOC),Nowrooz Platform,W2G0/01,,N/A,SGT-300 PACKAGE C2S M2C E2S DC GB,TRAIN_W2G0/01,SGT-300,2002-01-21,2004-07-29,2001-11-27,Operating,SGT-300,IR,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
NU0215/04,6044317,Caspian Pipeline Consortium (CPC-R) - APS4A,CPC APS4-A,NU0215/04,NU0215/04,N/A,SGT-100-2S C3S M2C E1C DC GB,TRAIN_NU0215/04,SGT-100-2S,2017-02-05,2019-02-04,2010-05-01,Operating,SGT-100,RU,Frame Agreement – Includes RDS,ADHOC,I4-003,30,COMPRESSOR
NA0197/01,6104633,ONGC Assam-Lakwa Site,Assam Renewal Project,NA0197/01,,N/A,SGT-400 PACKAGE C3S M2C E2S DC GB,TRAIN_NA0197/01,SGT-400,2015-07-18,2016-07-17,2011-05-01,Operating,SGT-400,IN,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
A2H3/01,6050088,OPS Angola - FPSO Ngoma - Block 15,N'Goma FPSO,A2H3/01,,N/A,SGT-400 C3C M2C E2S DC GB HC,TRAIN_A2H3/01,SGT-400,2003-06-03,2004-06-03,2002-06-03,Operating,SGT-400,AO,Preventive LTP – Includes RDS,LTP,I4-008,30,COMPRESSOR
NC0033/05,6039011,PDVSA - Altagracia - ICO Altagracia,"ICO, Alta Gracia",NC0033/05,,HI6011 1550011,SGT-400 (HOU HI6011) C1C M2C E1C DC GB,TRAIN_NC0033/05,SGT-400,2014-01-01,2015-03-31,2006-03-01,Not Running – Preserved,SGT-400,VE,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
M918/02,6142292,Direct Worktops - Shildon Co Durham,Ex. Shildon Co Durham,M918/02,,N/A,SGT-100 PACKAGE - SITE SHUT,TRAIN_M918/02,SGT-100-1S,1990-01-01,1991-06-15,1989-01-01,Scrapped,SGT-100,GB,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NU0028/02,6047534,MOL UGS-Hungary,MOL - Hajduszoboszlo,NU0028/02,,N/A,SGT-100-2S PACKAGE - DEACTIVATED GB,TRAIN_NU0028/02,SGT-100-2S,2009-03-27,2012-03-26,2005-12-01,Not Running – Not Preserved,SGT-100,HU,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
W9C2/01,6032831,West Fraser Europe Ltd,Forest Products Stirling 1,W9C2/01,,N/A,SGT-300 PACKAGE C3C M2C E1C DC HC,TRAIN_W9C2/01,SGT-300,1999-01-23,2001-05-01,1998-10-01,Operating,SGT-300,GB,Corrective LTP – Includes RDS,LTP,I4-021,10,GENERATOR
S826/03,6050775,Talisman Energy-Norway-Gyda Field,Gyda Field,S826/03,,N/A,SGT-200 PACKAGE GB,TRAIN_S826/03,SGT-200-1S,1988-01-01,1990-01-10,1987-01-01,Scrapped,SGT-200,NO,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NA0581/03,6704654,"AECC Gas Turbine (Zhuzhou) Co., Ltd.  (ZNGT)",ZNGT Guangan Jianping Paper,NA0581/03,BD001229U03,BD001229,SGT-400 PACKAGE,TRAIN_NA0581/03,SGT-400,2024-09-01,2025-08-30,2023-03-01,Order,SGT-400,CN,,,I4-008,,
W725/01,6046703,Kale Seramik-Canakkale,Kalebodur Can,W725/01,,N/A,SGT-300 PACKAGE C3C M2C DC GB,TRAIN_W725/01,SGT-300,2000-03-05,2001-10-01,1999-01-01,Operating,SGT-300,TR,Corrective LTP – Includes RDS,LTP,I4-021,10,GENERATOR
W733/01,6052261,Golden Sugar Refinery,Luleburgaz 1,W733/01,,N/A,SGT-300 PACKAGE C3S M2C DC GB,TRAIN_W733/01,SGT-300,1997-07-01,1999-08-01,1996-07-01,Operating,SGT-300,NG,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
S0K9/01,6054916,"Aisin AW HQ, Mikawa Anjo",Aisin AW HQ Unit #2,S0K9/01,,N/A,SGT-200 PACKAGE - SCRAPPED,TRAIN_S0K9/01,SGT-200-1S,2002-01-01,2003-05-02,2000-01-01,Scrapped,SGT-200,JP,No Contract or Agreement,ADHOC,I4-005,,GENERATOR
S237/01,6032745,Spirit - Barrow in Furness - Onshore AGT,Barrow in Furness 1,S237/01,,N/A,SGT-200 PACKAGE,TRAIN_S237/01,SGT-200-1S,1993-08-12,1994-08-11,1992-01-01,Scrapped,SGT-200,GB,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NC0093/02,6049986,SPDC - Gbaran Ubie,Gbaran Ubie Compressor Set,NC0093/02,NC0093/02,N/A,SGT-400 PACKAGE C3C M2C E2S DC GB HC,TRAIN_NC0093/02,SGT-400,2010-08-18,2011-08-17,2006-08-25,Operating,SGT-400,NG,Frame Agreement,ADHOC,I4-008,30,COMPRESSOR
NR0104/02,6038185,Petróleo Brasileiro S/A - Petrobras - G&E/UN-APGN/UTGC (Fase III),SITI - CACIMBAS PHASE III,NR0104/02,HI6016 - 1550016,HI6016 1550016,SGT-200 PACKAGE C2S M1C E1C DS GB,TRAIN_NR0104/02,SGT-200-2S,2009-05-01,2010-04-30,2007-03-01,Operating,SGT-200,BR,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
M719/03,6047187,Cheminova A/S (now FMC) - Harboøre,Harboore 3,M719/03,9100/215,N/A,SGT-100 PACKAGE C2S GB,TRAIN_M719/03,SGT-100-1S,1997-01-01,1998-03-06,1996-07-01,Not Running – Not Preserved,SGT-100,DK,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M719/01,6047188,Cheminova A/S (now FMC) - Harboøre,Harboore 1,M719/01,9100/196,N/A,SGT-100 PACKAGE C2S GB,TRAIN_M719/01,SGT-100-1S,1997-01-01,1998-03-05,1996-07-01,Not Running – Not Preserved,SGT-100,DK,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
HI6047/01,6150332,SemGas A SemGroup Company,CHESAPEAKE ENERGY,HI6047/01,NR0332/01,NR0332/01,SGT-200 (LCN NR0332) C2S M1C E1C DS,TRAIN_HI6047/01,SGT-200-2S,2015-04-01,2016-04-01,2012-09-30,Operating,SGT-200,US,No Contract or Agreement,ADHOC,I4-006,,COMPRESSOR
M313/01,6141690,Dummy Account: Asia Pacific - Japan,Tokyo,M313/01,,N/A,SGT-100 PACKAGE - SCRAPPED,TRAIN_M313/01,SGT-100-1S,1993-05-13,1994-05-13,1992-01-01,Scrapped,SGT-100,JP,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
A0B8/01,6079556,Iraq Mobile,A 1,A0B8/01,,N/A,SGT-400 PACKAGE C1S M1C,TRAIN_A0B8/01,SGT-400,2002-08-01,2003-08-01,2001-08-01,Scrapped,SGT-400,IQ,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
M9J0/01,6038843,Tecpetrol - Comodoro Rivadavia - El Tordillo,El Tordillo 4,M9J0/01,,N/A,SGT-100 PACKAGE C3S M2C DC GB,TRAIN_M9J0/01,SGT-100-1S,1996-01-01,2000-08-15,1994-03-01,Not Running – Preserved,SGT-100,AR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NM0122/03,6052844,In Salah Gas Field - Reg - Teg - Krechba,In Salah Gas Field - Reg,NM0122/03,,N/A,SGT-100 PACKAGE C2S M2C E1C DC GB,TRAIN_NM0122/03,SGT-100-1S,2010-12-14,2012-12-13,2008-01-07,Operating,SGT-100,DZ,Call Off Contract Goods and Services,ADHOC,I4-002,10,GENERATOR
NU0546/01,6690198,Caspian Pipeline Consortium (CPC-R) - Komsomolsk,CPC Debottleneck Komsomolsk,NU0546/01,BD000860U01,BD000860,SGT-100-2S  C3S  PACKAGE,TRAIN_NU0546/01,SGT-100-2S,2023-01-10,2025-01-09,2019-12-01,Operating,SGT-100,RU,,,I4-003,,COMPRESSOR
BD000639U01,6680134,Hindustan Urvarak & Rasayan Limited - Gorakhpur,HURL Gorakhpur for Toyo,BD000639U01,NA0521/01,NA0521/01,SGT-400 PKG (LCN NA0521) CX M1S EX GB,TRAIN_BD000639U01,SGT-400,2022-05-08,2022-08-31,2018-06-30,Operating,SGT-400,IN,Preventive LTP – Includes RDS,LTP,I4-008,,GENERATOR
NA0101/01,6054984,"Maruti Suzuki India Ltd., Manesar",Maruti,NA0101/01,,N/A,SGT-400 PACKAGE <C M1C,TRAIN_NA0101/01,SGT-400,2008-05-05,2009-05-04,2007-03-03,Scrapped,SGT-400,IN,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
M762/01,6038840,Tecpetrol - Comodoro Rivadavia - El Tordillo,El Tordillo 4,M762/01,,N/A,SGT-100 PACKAGE C3S M2C DC GB,TRAIN_M762/01,SGT-100-1S,2000-01-01,2000-09-28,1999-01-01,Not Running – Preserved,SGT-100,AR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
BD000042U03,6104688,VCNG Power,VCNG Power,BD000042U03,NA0242/03,NA0242/03,SGT-400(LCN NA0242) C3C M2C E2S DC GB HC,TRAIN_BD000042U03,SGT-400,2016-02-04,2018-02-03,2011-01-01,Operating,SGT-400,RU,Preventive LTP,LTP,I4-008,10,GENERATOR
NR0041/02,6032713,WINGAS Storage UK Ltd. - Saltfleetby,Saltfleetby,NR0041/02,NR0041/02,N/A,SGT-200-2S PACKAGE,TRAIN_NR0041/02,SGT-200-2S,,,2006-05-01,Cancelled,SGT-200,GB,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
M625/01,6042928,Roulunds Fabrikker (former) - Odense S,Odense,M625/01,9100/177,N/A,SGT-100 PACKAGE C2S GB,TRAIN_M625/01,SGT-100-1S,1997-02-03,1998-02-03,1996-02-03,Not Running – Not Preserved,SGT-100,DK,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
R0H1/01,6038850,Total Austral S.A. - Cañadon Alfa,Canadon Alfa,R0H1/01,,N/A,SGT-200 PACKAGE <C M1C E1C DS 2022,TRAIN_R0H1/01,SGT-200-2S,2001-01-01,2005-01-23,2000-10-01,Operating,SGT-200,AR,Other and 24/7 Global Helpdesk and/or RDS are not included,ADHOC,I4-006,30,COMPRESSOR
S2A2/01,6055786,Single Buoy Moorings - FPSO Falcon,SBM Falcon,S2A2/01,,N/A,SGT-200 PACKAGE GB,TRAIN_S2A2/01,SGT-200-1S,2003-01-01,2003-12-14,2001-06-01,Not Running – Not Preserved,SGT-200,SG,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
HI4067/05,6083350,GLNG - Fairview-HCS-05 North,Fairview 05,HI4067/05,NA0246/05,NA0246/05,SGT-400 (LCN NA0246) C1S M1C E1C GB,TRAIN_HI4067/05,SGT-400,2014-12-31,2016-10-29,2010-12-31,Not Running – Preserved,SGT-400,AU,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
NA0222/01,6104695,Bhogat (Cairn Energy),Bhogat (Cairn Energy),NA0222/01,,N/A,SGT-400 PACKAGE C3S M2C E1C DC GB,TRAIN_NA0222/01,SGT-400,2014-08-19,2015-08-18,2010-07-01,Operating,SGT-400,IN,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
NA0207/01,6108615,"Petronet LNG Ltd., Kochi",KOCHI LNG,NA0207/01,,N/A,SGT-400 PACKAGE C3S M2C E1C DC GB,TRAIN_NA0207/01,SGT-400,2014-02-11,2016-02-10,2010-04-01,Not Running – Preserved,SGT-400,IN,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
M8L7/01,6038519,PEP-Cantarell Offshore-APC-Akal L,Akal L (Offshore),M8L7/01,M8L7/01,N/A,SGT-100 PACKAGE EX,TRAIN_M8L7/01,SGT-100-1S,2000-01-01,2001-09-15,1998-02-01,Operating,SGT-100,MX,Frame Agreement,ADHOC,I4-002,10,GENERATOR
U0A3/01,6044330,Caspian Pipeline Consortium (CPC-R) - Kropotkin,CPC Kropotkin,U0A3/01,,N/A,SGT-100-2S C4S M2C E1C DC GB HS,TRAIN_U0A3/01,SGT-100-2S,2000-05-11,2003-12-14,1999-04-23,Operating,SGT-100,RU,Frame Agreement – Includes RDS,ADHOC,I4-003,30,COMPRESSOR
HI4066/08,6039048,PDVSA - Div Faja - Unidades Moviles Div Faja,PDVSA Mobile Power,HI4066/08,NA0209/08,NA0209/08 HI4057,SGT-400 PACKAGE (NA0209) CANCELLED,TRAIN_HI4066/08,SGT-400,,,2010-03-10,Cancelled,SGT-400,VE,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
HI4066/10,6039050,PDVSA - Div Faja - Unidades Moviles Div Faja,PDVSA Mobile Power,HI4066/10,NA0209/10,NA0209/10 HI4057,SGT-400 PACKAGE (NA0209) CANCELLED,TRAIN_HI4066/10,SGT-400,,,2010-03-04,Cancelled,SGT-400,VE,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
M704/01,6032842,E.ON - Queens Med Centre,Queens Med Centre,M704/01,M704/01,N/A,SGT-100 PACKAGE <C M2C EX DC GB,TRAIN_M704/01,SGT-100-1S,1998-01-01,1998-07-07,1997-04-01,Operating,SGT-100,GB,Corrective LTP – Includes RDS,LTP,I4-002,10,GENERATOR
HI4070/02,6103793,"RUE ""Mogilevenergo""",Mogilev RK-3,HI4070/02,NW0275/02,NW0275/02,SGT-300 (LCN NW0275) CX M2C E2S DC GB,TRAIN_HI4070/02,SGT-300,2013-12-19,2015-12-18,2011-09-30,Operating,SGT-300,BY,Preventive LTP,LTP,I4-021,10,GENERATOR
M9C7/01,6054825,"Sanei Sugar, Nagoya",Sanei Sugar,M9C7/01,,N/A,SGT-100 PACKAGE - SCRAPPED,TRAIN_M9C7/01,SGT-100-1S,2003-01-01,2003-06-13,1998-06-14,Scrapped,SGT-100,JP,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M236/01,6049245,Saint Exupery Airport-Lyon-Satolas,Satolas,M236/01,,N/A,SGT-100 PACKAGE - MOTHBALLED GB C1S,TRAIN_M236/01,SGT-100-1S,1993-06-01,1998-01-01,1992-06-01,Operating,SGT-100,FR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M522/01,6032855,E.ON - Ah Marks - Bradford,A H Marks,M522/01,,N/A,SGT-100 PACKAGE C2S M2C EX DC GB,TRAIN_M522/01,SGT-100-1S,1996-06-27,1997-06-25,1995-06-27,Operating,SGT-100,GB,Corrective LTP – Includes RDS,LTP,I4-002,10,GENERATOR
NU0202/02,6052876,Sonatrach DP - Rhoude Nouss,Rhoude Nouss,NU0202/02,,N/A,SGT-100-2S <C M2C E1C DC GB,TRAIN_NU0202/02,SGT-100-2S,2015-02-20,2017-02-19,2010-03-01,Operating,SGT-100,DZ,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
W2H8/01,6048348,Papier- u. Kartonfabrik Varel GmbH & Co. KG - Varel,Varel (LTP),W2H8/01,9160/056,N/A,SGT-300 PACKAGE C3S M2C E2S DC GB,TRAIN_W2H8/01,SGT-300,2003-07-01,2004-02-14,2002-05-01,Operating,SGT-300,DE,Corrective LTP – Includes RDS,LTP,I4-021,10,GENERATOR
R415/01,6053437,Sonatrach DP - Alrar,SONATRACH DIVISION PRODUCTION,R415/01,,N/A,SGT-200-2S PACKAGE CX M2C E1C DC,TRAIN_R415/01,SGT-200-2S,1994-01-01,1996-07-01,1992-12-01,Operating,SGT-200,DZ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
80/84028-02,6141334,Dummy Account: Africa,Port Huron,80/84028-02,,,,,SGT-200-1S,1985-01-01,1986-01-01,1984-01-01,Scrapped,SGT-200,ZA,No Contract or Agreement,ADHOC,,,
R8C4/01B,6080474,3R POTIGUAR S.A.,"Guamare, Brazil",R8C4/01B,R8C4/01,,,,SGT-200-2S,2000-03-01,2000-12-01,1998-03-01,Not Running – Preserved,SGT-200,BR,No Contract or Agreement,ADHOC,,,
M575/01,6047376,MICHELIN / Plant operated by E.ON Business Solutions S.r.l.,Michelin Tours,M575/01,,N/A,SGT-100 PACKAGE - SITE SHUT,TRAIN_M575/01,SGT-100-1S,1995-07-08,1996-07-08,1994-07-08,Scrapped,SGT-100,IT,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NM0081/03,6138003,National Iranian Gas Company,Parsian Refinery,NM0081/03,,N/A,SGT-100 PACKAGE C2S M2C E1C DC GB,TRAIN_NM0081/03,SGT-100-1S,2007-12-01,2008-11-30,2004-01-01,Operating,SGT-100,IR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NC0021/02,6039147,PDVSA - Santa Rosa - Planta de Gas Anaco,"Anaco, Santa Rosa",NC0021/02,NC0021/02,HI6006 1550006,SGT-400 (HOU HI6006) C1S M2C E1C DC GB,TRAIN_NC0021/02,SGT-400,,,2005-06-01,Not Running – Not Preserved,SGT-400,VE,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
HI4066/03,6039043,PDVSA - Div Faja - Unidades Moviles Div Faja,PDVSA Mobile Power,HI4066/03,NA0209/03,NA0209/03 HI4057,SGT-400 PACKAGE (NA0209) CANCELLED,TRAIN_HI4066/03,SGT-400,,,2010-03-10,Cancelled,SGT-400,VE,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
M359/02,219126,26th Ward Wpcp,New York City Department of Environmental Protection 26Th Ward Street,M359/02,,N/A,SGT-100 PACKAGE RETIRED GB,TRAIN_M359/02,SGT-100-1S,1993-01-01,1994-09-29,1992-01-01,Not Running – Not Preserved,SGT-100,US,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NA0537/02,6684571,CNOOC-CFD64,HGGT CFD6-4,NA0537/02,BD000798U02,BD000798,SGT-400 PACKAGE (BD000798) C3S M1S EX,TRAIN_NA0537/02,SGT-400,2021-04-14,2022-06-13,2019-08-21,Operating,SGT-400,CN,Preventive LTP,LTP,I4-008,,GENERATOR
NR0198/02,6045280,Open Grid Europe GmbH; Verdichterstation Werne,Werne ME3 & ME4,NR0198/02,,N/A,SGT-200 PACKAGE C1S M1C E1C DS GB,TRAIN_NR0198/02,SGT-200-2S,2014-12-18,2017-12-17,2010-01-15,Operating,SGT-200,DE,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
W728/01,6045114,Jannah Hunt Oil Company,Dahab Substation 1 (Ex VPK),W728/01,9160/006,N/A,SGT-300 PACKAGE GB CX,TRAIN_W728/01,SGT-300,,,1996-04-01,Installation & Commissioning,SGT-300,YE,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
BD000184U02,6436967,GHANA TEMA POWERVOLTA RIVER AUTHORITY,Ghana,BD000184U02,NA0378/02,NA0378/02,SGT-400 PKG (LCN NA0378) C3S M2S EX GB,TRAIN_BD000184U02,SGT-400,2016-04-25,2017-04-24,2013-11-30,Operating,SGT-400,GH,Resident Engineer,ADHOC,I4-008,,GENERATOR
NC0021/07,6039152,PDVSA - Santa Rosa - Planta de Gas Anaco,"Anaco, Santa Rosa",NC0021/07,NC0021/07,HI6006 1550006,SGT-400 (HOU HI6006) C1S M2C E1C DC GB,TRAIN_NC0021/07,SGT-400,,,2006-03-01,Storage,SGT-400,VE,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
NM0080/01,6054623,"Sumitomo Rubber, Shirakawa (TKM)",Sumitomo Rubber Shirakawa,NM0080/01,,N/A,SGT-100 PACKAGE C2S M2C E1C DC GB,TRAIN_NM0080/01,SGT-100-1S,2004-07-01,2005-07-01,2001-08-31,Operating,SGT-100,JP,Corrective LTP – Includes RDS,LTP,I4-002,10,GENERATOR
NM0085/01,6054771,"Sumitomo Rubber, Osaka",Sunitomo Rubber Izumiotsu,NM0085/01,,N/A,SGT-100 PACKAGE C2S GB,TRAIN_NM0085/01,SGT-100-1S,1996-12-27,2007-01-01,1996-06-30,Not Running – Not Preserved,SGT-100,JP,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NC0312/01,6197323,GASCADE Gastransport GmbH; VS Lippe - Bad Salzuflen,Wingas Lippe GT3,NC0312/01,,N/A,SGT-400 PACKAGE C4S M2S E2S DS HS,TRAIN_NC0312/01,SGT-400,2015-05-01,2020-06-30,2012-05-31,Operating,SGT-400,DE,Other and 24/7 Global Helpdesk and/or RDS included,ADHOC,I4-008,30,COMPRESSOR
BD000203U03,6490646,Akkas Gas Field - KOGAS B.A,Akkas,BD000203U03,NA0392/03,NA0392/03,SGT-400 PACKAGE (Lincoln Scope NA0392),TRAIN_BD000203U03,SGT-400,2022-11-30,,2014-03-31,Storage,SGT-400,IQ,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
BD000214U01,6541045,Pakistan Petroleum Limited (PPL) - Adhi Gas,Presson Descon,BD000214U01,NW0393/01,NW0393/01,SGT-300 PKG(LCN NW0393) C3S M2S E2S DS,TRAIN_BD000214U01,SGT-300,2016-02-10,2017-02-09,2014-04-30,Operating,SGT-300,PK,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
R9M8/01,216166,Alliance Pipeline - Blueberry Hill,Alliance Pipelines Blueberry Hill Compressor Station Alberta Canada,R9M8/01,9080/344,N/A,SGT-200-2S PACKAGE <C EX 2006,TRAIN_R9M8/01,SGT-200-2S,2000-05-24,2002-12-15,1999-09-01,Operating,SGT-200,CA,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
M519/01,6032776,Veolia - Eli Lilly Company Ltd,Eli Lilly 1,M519/01,,N/A,SGT-100 PACKAGE C3S M2C E1C DC GB,TRAIN_M519/01,SGT-100-1S,1996-05-09,1997-05-09,1995-05-09,Operating,SGT-100,GB,Corrective LTP – Includes RDS,LTP,I4-002,10,GENERATOR
NS0125/06,6046469,Donau Chem,ZNGT Romania,NS0125/06,,N/A,SGT-200 PACKAGE,TRAIN_NS0125/06,SGT-200-1S DLE,,,2008-07-01,Not Running – Not Preserved,SGT-200,RO,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
W9J4/01,6046702,Kale Seramik-Canakkale,Canakkale 1,W9J4/01,,N/A,SGT-300 PACKAGE C3S M2C EX DC GB,TRAIN_W9J4/01,SGT-300,1997-06-01,2001-05-01,1996-06-01,Operating,SGT-300,TR,Corrective LTP – Includes RDS,LTP,I4-021,10,GENERATOR
NA0544/01,6690201,CNOOC-LF14,HGGT LF14-4 Lufeng,NA0544/01,BD000854U01,BD000854,SGT-400 PACKAGE (BD000854) C3S M1S EX,TRAIN_NA0544/01,SGT-400,2023-06-26,2024-08-25,2020-01-01,Operating,SGT-400,CN,No Contract or Agreement,ADHOC,I4-008,,GENERATOR
NC0111/03,6046711,Pakistan Petroleum Limited (PPL) - Kandhkot,PPL - Kandhkot,NC0111/03,,N/A,SGT-400 PACKAGE C3S M2C E2S DC GB,TRAIN_NC0111/03,SGT-400,2010-03-14,2011-03-13,2007-08-04,Operating,SGT-400,PK,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
M437/01,6079569,Liberty OneSteel - Whyalla Steelworks,OneSteel Whyalla,M437/01,,N/A,SGT-100 PACKAGE C2S M2C EX DC GB,TRAIN_M437/01,SGT-100-1S,1994-12-31,2015-01-05,1994-02-28,Operating,SGT-100,AU,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
W3B8/01,6083344,Occidental Oman - Wadi Latham,Occidental Oman - Wadi Latham,W3B8/01,NW0031/01,N/A,SGT-300 C3S M1C E2S DS GB,TRAIN_W3B8/01,SGT-300,2002-01-01,2006-04-30,2001-08-01,Operating,SGT-300,OM,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
NA0528/01,6682260,Centrax Ltd.,Centrax 019 (Unigra S.r.L),NA0528/01,BD000747U01,BD000747,SGT-400 PACKAGE CX GB EX,TRAIN_NA0528/01,SGT-400,2021-10-21,2022-10-20,2019-04-30,Operating,SGT-400,GB,Corrective LTP,LTP,I4-008,,GENERATOR
NA0019/01,6055214,"Bridgestone, Hikone (TKM)",BRIDGESTONE HIKONE,NA0019/01,NA0019/01,N/A,SGT-400 C3C M2C E2S DC GB HC,TRAIN_NA0019/01,SGT-400,2003-05-15,2004-05-14,2000-11-30,Operating,SGT-400,JP,Corrective LTP – Includes RDS,LTP,I4-008,10,GENERATOR
M0J3/01,6045155,Total E&P - FSO Unity,FSO Unity,M0J3/01,,N/A,SGT-100 PACKAGE C2S M1C E1C DS GB,TRAIN_M0J3/01,SGT-100-1S,2002-01-01,2004-03-29,2001-06-01,Operating,SGT-100,NG,Resident Engineer,ADHOC,I4-002,10,GENERATOR
NW0055/01,6049833,Severnaya Neft-Usinsk-Khasireiskoye Oil Field Nort,SEVERNAYA NEFT,NW0055/01,,N/A,SGT-300 C3C M2C E1C DC GB HC,TRAIN_NW0055/01,SGT-300,2006-11-01,2007-09-11,2006-02-02,Operating,SGT-300,RU,Preventive LTP,LTP,I4-021,10,GENERATOR
W9M4/01,6032779,Darent Power Limited - Sandwich,Pfizer 2,W9M4/01,,N/A,SGT-300 PACKAGE M1C E1C DS,TRAIN_W9M4/01,SGT-300,2000-01-01,2001-10-01,1999-05-01,Scrapped,SGT-300,GB,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
NA0564/01,6700703,21st Century Technologies Ltd,21st Century Technologies - LEKKI,NA0564/01,BD001084U01,BD001084 (EX BD000345),SGT-400 PACKAGE (BD001084) M1S,TRAIN_NA0564/01,SGT-400,2024-11-01,2025-10-30,2022-01-31,Order,SGT-400,NG,,,I4-008,,GENERATOR
BF000073U01,6553198,Total E&P UK - Culzean Platform,Culzean Export Train 1,BF000073U01,NC0444/01,NC0444/01,SGT-400 (LCN NC0444) C3S M1S EX,TRAIN_BF000073U01,SGT-400,2019-08-12,2021-08-11,2016-02-01,Operating,SGT-400,GB,Call off Agreement / Support LTP,ADHOC,I4-008,10,COMPRESSOR
S645/03,6052178,Yıldız Entegre - İzmit,Inegol 2,S645/03,,N/A,SGT-200 PACKAGE M2C EX DC GB,TRAIN_S645/03,SGT-200-1S,1997-01-01,1997-03-23,1996-01-01,Not Running – Not Preserved,SGT-200,TR,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
S452/02,6032902,BP -  Andrew,Andrew Field 2,S452/02,,N/A,SGT-200 PACKAGE C3S M2C E2S DC GB,TRAIN_S452/02,SGT-200-1S,1996-01-17,1997-01-15,1993-12-01,Operating,SGT-200,GB,Frame Agreement,ADHOC,I4-005,10,GENERATOR
NA0194/01,6048302,Papelera de Aralar-Spain-Papel Aralar,Papel Aralar,NA0194/01,,N/A,SGT-400 PACKAGE C3C M2C E2S DC GB HC,TRAIN_NA0194/01,SGT-400,2011-05-31,2012-05-30,2009-12-01,Operating,SGT-400,ES,Corrective LTP – Includes RDS,LTP,I4-008,10,GENERATOR
HI4077/01,6180255,Jameson Energy Centre,KMEA,HI4077/01,NA0352/01,NA0352/01,SGT-400 (LCN NA0352) C3S M2S E2S DS GB,TRAIN_HI4077/01,SGT-400,2014-06-30,2015-05-29,2013-09-30,Operating,SGT-400,US,Other - please specify,ADHOC,I4-008,10,GENERATOR
HI4066/09,6039049,PDVSA - Div Faja - Unidades Moviles Div Faja,PDVSA Mobile Power,HI4066/09,NA0209/09,NA0209/09 HI4057,SGT-400 PACKAGE (NA0209) CANCELLED,TRAIN_HI4066/09,SGT-400,,,2010-03-04,Cancelled,SGT-400,VE,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
80/84021-01,6048943,Rak White Cement - Ras Al Khaimah,Rak White Cement Factory,80/84021-01,,N/A,SGT-200 PACKAGE GB,TRAIN_80/84021-01,SGT-200-1S,1986-11-05,1987-11-05,1985-11-05,Not Running – Not Preserved,SGT-200,AE,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NC0023/02,6133294,Foroozan - Compressor station,Foroozan,NC0023/02,,N/A,SGT-400 PACKAGE C1S M1C E1C DS GB,TRAIN_NC0023/02,SGT-400,,,2005-09-01,Storage,SGT-400,IR,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
NA0480/01,6666273,Centrax Ltd.,Centrax 014 (Enecogen),NA0480/01,BD000422U01,BD000422,SGT-400 PACKAGE C3S MX EX DX GB,TRAIN_NA0480/01,SGT-400,2018-09-19,2021-09-18,2017-06-30,Operating,SGT-400,GB,No Contract or Agreement,ADHOC,I4-008,,GENERATOR
80/85113-04,216606,Hilcorp Alaska LLC - Endicott,Ruston 4504 Endicott Field,80/85113-04,9080/0486-1,N/A,SGT-200 PACKAGE CX M2C E1C DC GB,TRAIN_80/85113-04,SGT-200-1S,1987-08-03,1988-08-03,1986-08-03,Operating,SGT-200,US,Frame Agreement,ADHOC,I4-005,10,GENERATOR
NR0033/12,6058266,Sonatrach TRC - OZ2,OZ2,NR0033/12,R3D8/12,N/A,SGT-200-2S PACKAGE CX M2C E1C DC,TRAIN_NR0033/12,SGT-200-2S,2004-01-01,2007-03-07,2003-01-06,Operating,SGT-200,DZ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NA0509/02,6673461,"AECC Gas Turbine (Zhuzhou) Co., Ltd.  (ZNGT)",ZNGT NanJing Pukou,NA0509/02,BD000561U02,BD000561,SGT-400 PACKAGE (BD000561) C3S M1S EX GB,TRAIN_NA0509/02,SGT-400,2024-03-01,2026-02-27,2017-11-30,Installation & Commissioning,SGT-400,CN,No Contract or Agreement,ADHOC,I4-008,,GENERATOR
NA0476/01,6664662,Centrax Ltd.,Centrax 012 (Besançon),NA0476/01,BD000418U01,BD000418,SGT-400 PACKAGE C3S GB,TRAIN_NA0476/01,SGT-400,2018-03-01,2019-02-28,2016-10-31,Operating,SGT-400,GB,Corrective LTP – Includes RDS,LTP,I4-008,,GENERATOR
NC0126/03,6038724,TGP1 Chiquintirca,Chiquintirca,NC0126/03,HI6015/03,HI6015 1550019,SGT-400(HOU HI6015) C3C M2C E2S DC GB HC,TRAIN_NC0126/03,SGT-400,2010-04-23,2011-04-22,2008-01-18,Operating,SGT-400,PE,Preventive LTP – Includes RDS,LTP,I4-008,30,COMPRESSOR
BC000652U01,6700705,New Fortress Energy - Gulf of Mexico (Mex),FAST LNG II,BD001117U01,NA0567/01,BD001117U01 NA0567/01,SGT-400 PACKAGE (LCN NA0567),TRAIN_BC000652U01,SGT-400,2025-11-01,2026-10-30,2022-06-30,Order,SGT-400,MX,No Contract or Agreement,ADHOC,I4-008,,
NR0107/10,6038193,Transportadora Associada de Gás S/A - TAG - Ecomp de Piuma,SITI - GASENE,NR0107/10,HI6017/10,HI6017 1550017,SGT-200 PACKAGE C2S M2S E2S DS GB,TRAIN_NR0107/10,SGT-200-2S,2010-10-30,2011-10-29,2007-05-01,Operating,SGT-200,BR,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NS0191/02,6056053,ZNGT - Zouping COG,Zouping COG--ZNGT,NS0191/02,NS0232/02,N/A,SGT-200 PACKAGE GB,TRAIN_NS0191/02,SGT-200-1S,2011-07-13,2012-07-12,2009-08-30,Not Running – Not Preserved,SGT-200,CN,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NU0547/01,6690199,Caspian Pipeline Consortium (CPC-K) - Atyrau,CPC Debottleneck Atyrau,NU0547/01,BD000861U01,BD000861,SGT-100-2S PACKAGE C3S M1S EX,TRAIN_NU0547/01,SGT-100-2S,2023-01-24,2025-01-23,2020-01-01,Operating,SGT-100,KZ,,,I4-003,,COMPRESSOR
M2F4/01,6032755,APCL Whetstone - Alstom,Alstom Power Tech Centre,M2F4/01,,N/A,SGT-100 PACKAGE - SKID EMPTY,TRAIN_M2F4/01,SGT-100,2003-01-30,2005-10-31,2002-01-30,Scrapped,SGT-100,GB,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S635/02,6032840,Phillips 66 Limited - Humber Refinery,Conoco Phillips 2,S635/02,,N/A,SGT-200 PACKAGE M2C EX DC GB 2012,TRAIN_S635/02,SGT-200-1S,1996-01-01,1997-10-24,1995-12-01,Operating,SGT-200,GB,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
S2A5/03,6051104,Zarubezhneft–Production Kharyaga LLC,Kharyaga 2 #1 Thru 4,S2A5/03,,N/A,SGT-200 PACKAGE <C M1C E2S DS GB 2021,TRAIN_S2A5/03,SGT-200-1S,2002-01-01,2004-03-29,2001-02-01,Operating,SGT-200,RU,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M336/01,6051197,Trt Textiles-San Martino BA - Verona-TRT,TRT,M336/01,,N/A,SGT-100 PACKAGE - MOTHBALLED GB,TRAIN_M336/01,SGT-100-1S,1993-06-01,1995-02-27,1992-06-01,Scrapped,SGT-100,IT,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
U9M2/01,6138011,NIPC (HQ),KPC,U9M2/01,,N/A,SGT-100-2S PACKAGE C2S M1C E1C DS GB,TRAIN_U9M2/01,SGT-100-2S,2000-03-24,2001-04-01,1999-04-01,Operating,SGT-100,IR,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
M654/01,6141677,Dummy Account: Asia Pacific - Japan,Fuji Factory,M654/01,,N/A,SGT-100 PACKAGE - SCRAPPED,TRAIN_M654/01,SGT-100,1997-01-01,1998-08-08,1996-06-01,Scrapped,SGT-100,JP,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NA0540/01,6684572,CNOOC-WZ12,WZ-12 (HGGT),NA0540/01,BD000823U01,BD000823,SGT-400 PACKAGE (BD000823) C3S M1S EX,TRAIN_NA0540/01,SGT-400,2021-07-25,2022-09-24,2019-11-12,Operating,SGT-400,CN,Preventive LTP,LTP,I4-008,,GENERATOR
NW0039/02,6141709,National Iranian Oil Company (NIOC),Salman Offshore,NW0039/02,,N/A,SGT-300 PACKAGE C2S M1C E1C DS GB,TRAIN_NW0039/02,SGT-300,2011-02-14,2011-06-13,2004-03-01,Operating,SGT-300,IR,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
M0E6/01,6043920,Batıçim Enerji,M0E8/01-Rm318-Gt1,M0E6/01,,N/A,SGT-100 PACKAGE - MOTHBALLED GB,TRAIN_M0E6/01,SGT-100-1S,2002-03-07,2003-03-07,2001-03-07,Scrapped,SGT-100,TR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M314/01,6032608,Wood Group - WGLIT Test Berth,WGLIT Test Berth,M314/01,,N/A,SGT-100 PACKAGE - SOLD GB,TRAIN_M314/01,SGT-100-1S,1994-01-01,1994-12-11,1993-06-01,Not Running – Not Preserved,SGT-100,GB,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NC0145/02,6112053,Foroozan - Compressor station,Foroozan - Compressor station,NC0145/02,,N/A,SGT-400 PACKAGE <C M1C E1C DS,TRAIN_NC0145/02,SGT-400,,,2008-12-01,Storage,SGT-400,IR,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
NW0045/01,6044569,Sajaa Gas,CRESCENT PETROLEUM COMPANY INC,NW0045/01,,N/A,SGT-300 PACKAGE,TRAIN_NW0045/01,SGT-300,2006-02-01,2007-02-17,2004-08-12,Not Running – Not Preserved,SGT-300,AE,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
NR0039/02,6112077,Kharg Aboozer,Aboozar - Kharg Gas,NR0039/02,NR0039/02,N/A,SGT-200-2S PACKAGE,TRAIN_NR0039/02,SGT-200-2S,,,2005-02-24,Storage,SGT-200,IR,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
S423/01,6141476,Skyline Turbine Services B.V.,Uficsa,S423/01,,N/A,SGT-200 PACKAGE GB,TRAIN_S423/01,SGT-200,1994-01-01,1995-04-16,1993-03-01,Storage,SGT-200,NL,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
HI6052RM/01,6437667,Larsen & Toubro Infotech Limited-Mumbai,SAGAR Pragatti,HI6052/01 RM,NM0367/01,NM0367/01,SGT-100 (LCN NM0367) C3S M2C EX DC,TRAIN_HI6052RM/01,SGT-100-1S,,,2013-09-30,Not Running – Preserved,SGT-100,IN,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M8B6/01,6054367,Weizhou Terminal  - CNOOC China Ltd.,Weizhou Oil Field,M8B6/01,,N/A,SGT-100 PACKAGE CX EX GB,TRAIN_M8B6/01,SGT-100-1S,1997-11-28,1998-11-27,1997-08-01,Operating,SGT-100,CN,Framework Agreement Goods and Services,ADHOC,I4-002,10,GENERATOR
R124/02,6085402,STORAG ETZEL GmbH,IVG Etzel,R124/02,9080/090,N/A,SGT-200 PACKAGE C3S M2C DC,TRAIN_R124/02,SGT-200-2S,1991-08-17,1992-12-01,1990-04-01,Operating,SGT-200,DE,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NC0126/02,6038723,TGP1 Chiquintirca,Chiquintirca,NC0126/02,HI6015/02,HI6015 1550019,SGT-400 (HOU HI6015) C3S M2C E2S DC GB,TRAIN_NC0126/02,SGT-400,2010-09-15,2011-09-14,2008-01-01,Operating,SGT-400,PE,Preventive LTP – Includes RDS,LTP,I4-008,30,COMPRESSOR
NR0107/11,6038197,Transportadora Associada de Gás S/A - TAG - EComp de Prado,SITI - GASENE,NR0107/11,HI6017/11,HI6017 1550017,SGT-200 PACKAGE C2S M2S E2S DS GB,TRAIN_NR0107/11,SGT-200-2S,2010-09-30,2011-09-29,2007-05-01,Operating,SGT-200,BR,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
M745/01,6144804,Chevron - Gorgon LNG,Chevron Barrow Island,M745/01,,N/A,SGT-100 PACKAGE - SCRAPPED,TRAIN_M745/01,SGT-100-1S,1996-12-31,1998-04-10,1996-02-29,Scrapped,SGT-100,AU,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
U8L1/01,6038880,Oleoductos del Valle - Oldelval - Zorrilla,Zorrilla,U8L1/01,,N/A,SGT-100-2S PACKAGE CX EX,TRAIN_U8L1/01,SGT-100-2S,1999-01-01,2001-06-01,1996-01-01,Operating,SGT-100,AR,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
R2E3/03,6053161,Sonatrach TRC - OZ2,OZ2,R2E3/03,,N/A,SGT-200-2S PACKAGE M2C E1C DC 2002,TRAIN_R2E3/03,SGT-200-2S,2002-09-19,2006-03-29,2001-09-19,Operating,SGT-200,DZ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
R2E4/04,6053146,Sonatrach TRC - OZ2,OZ2,R2E4/04,,N/A,SGT-200-2S PACKAGE M2C E1C DC 2002,TRAIN_R2E4/04,SGT-200-2S,2002-09-19,2006-03-29,2001-09-19,Operating,SGT-200,DZ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
S640/01,6050105,Skagen-Skagen-Skagen,Skagen,S640/01,9080/189,N/A,SGT-200 PACKAGE M2C EX DC GB 2001,TRAIN_S640/01,SGT-200-1S,1996-06-28,1997-06-28,1996-04-01,Operating,SGT-200,DK,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NC0303/02,6258189,HABANIYAH,HABANIYAH,NC0303/02,BCD01114U02,N/A,SGT-400 PACKAGE C1C,TRAIN_NC0303/02,SGT-400,,,2012-03-31,Storage,SGT-400,IQ,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
HI6053/04,6459629,Akkas Gas Field - KOGAS B.A,Akkas Gas Field,HI6053/04,NU0388/04,NU0388/04,SGT-100-2S PKG(LCN SCOPE NU0388),TRAIN_HI6053/04,SGT-100-2S,2022-12-31,,2014-01-31,Storage,SGT-100,IQ,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
R319/01,6032516,Shell UK Ltd - Sole Pit Clipper Platform,Sole Pit Clipper 4,R319/01,,N/A,SGT-200-2S C3S M2C E1C DC,TRAIN_R319/01,SGT-200-2S,1993-01-01,1997-02-01,1992-01-01,Not Running – Not Preserved,SGT-200,GB,Frame Agreement,ADHOC,I4-006,30,COMPRESSOR
M9D1/01,6141686,Dummy Account: Asia Pacific - Japan,"FHP, Kyushu Unit #1",M9D1/01,,N/A,SGT-100 PACKAGE - SCRAPPED,TRAIN_M9D1/01,SGT-100-1S,1999-01-01,2001-11-28,1998-01-01,Scrapped,SGT-100,JP,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NA0479/01,6666272,Centrax Ltd.,Centrax 016 (Citrique Belge),NA0479/01,BD000421U01,BD000421,SGT-400 PACKAGE C3C EX GB HC,TRAIN_NA0479/01,SGT-400,2019-12-18,2020-12-17,2017-06-30,Operating,SGT-400,GB,Corrective LTP – Includes RDS,LTP,I4-008,,GENERATOR
W3C0/01,6055229,"Shell Hazira, LNG Terminal, Surat",Shell Hazira 3,W3C0/01,NW0033/01,N/A,SGT-300 PACKAGE C2S M1C E1C DS GB,TRAIN_W3C0/01,SGT-300,2003-11-13,2005-01-23,2002-10-16,Operating,SGT-300,IN,Other and 24/7 Global Helpdesk and/or RDS included,ADHOC,I4-021,10,GENERATOR
BF000143U01,6696244,Al Maham Engineering Projects Co. Ltd,Zubair II,BD000848U01,NC0542/01,NC0542/01 BD000848,SGT-400 PACKAGE (LCN NC0542) C3S M1S,TRAIN_BF000143U01,SGT-400,2024-01-01,2024-12-30,2019-10-01,Installation & Commissioning,SGT-400,IQ,,,I4-008,,COMPRESSOR
NR0104/04,6038187,Petróleo Brasileiro S/A - Petrobras - G&E/UN-APGN/UTGC (Fase III),SITI - CACIMBAS PHASE III,NR0104/04,HI6016 - 1550016,HI6016 1550016,SGT-200 PACKAGE M1C E1C DS GB 2007,TRAIN_NR0104/04,SGT-200-2S,2010-12-30,2011-12-29,2007-03-01,Operating,SGT-200,BR,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
U0A1/02,6044322,Caspian Pipeline Consortium (CPC-K) - Atyrau,CPC Atyrau,U0A1/02,,N/A,SGT-100-2S PACKAGE C4S M2C E1C DC GB HS,TRAIN_U0A1/02,SGT-100-2S,2000-04-23,2003-12-30,1999-04-23,Operating,SGT-100,KZ,Frame Agreement – Includes RDS,ADHOC,I4-003,30,COMPRESSOR
HI4066/04,6039044,PDVSA - Div Faja - Unidades Moviles Div Faja,PDVSA Mobile Power,HI4066/04,NA0209/04,NA0209/04 HI4057,SGT-400 PACKAGE (NA0209) CANCELLED,TRAIN_HI4066/04,SGT-400,,,2010-03-10,Cancelled,SGT-400,VE,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
80/84021-02,6048941,Rak White Cement - Ras Al Khaimah,Rak White Cement,80/84021-02,,N/A,SGT-200 PACKAGE GB,TRAIN_80/84021-02,SGT-200-1S,1985-02-05,1986-02-05,1984-02-05,Not Running – Not Preserved,SGT-200,AE,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M0E1/01,6138000,Petróleo Brasileiro S/A - Petrobras - AB/RE/Refinaria Potiguar Clara Camarão - RPCC,Petrobras Guamare,M0E1/01,,N/A,SGT-100 PACKAGE - SCRAPPED,TRAIN_M0E1/01,SGT-100-2S,2003-10-14,2004-10-14,2002-10-14,Scrapped,SGT-100,BR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NA0571/01,6701769,F. Hoffmann-La Roche AG,La Roche,NA0571/01,BD001157U01,BD001157 BD000348 BD001084,SGT-400 PACKAGE CX M1S,TRAIN_NA0571/01,SGT-400,2023-04-21,2025-04-20,2022-09-01,Operating,SGT-400,CH,,,I4-008,10,GENERATOR
HI6041/02,6145221,TGP2 KP127,Kp-127,HI6041/02,NC0296/02,NC0296/02,SGT-400 (LCN NC0296) C3C M2C EX DC GB,TRAIN_HI6041/02,SGT-400,2016-04-20,2018-04-19,2012-01-01,Operating,SGT-400,PE,Preventive LTP – Includes RDS,LTP,I4-008,30,COMPRESSOR
NW0195/02,6044248,Burullus (Rashpetco),Burullus Phase VII,NW0195/02,,N/A,SGT-300 N TYPE C3C M2C E2S DC GB HC,TRAIN_NW0195/02,SGT-300,2011-12-01,2012-11-30,2009-12-01,Operating,SGT-300,EG,Preventive LTP,LTP,I4-021,10,GENERATOR
NR0107/09,6038196,Transportadora Associada de Gás S/A - TAG - EComp de Prado,SITI - GASENE,NR0107/09,HI6017/09,HI6017 1550017,SGT-200 PACKAGE C2S M2S E2S DS GB,TRAIN_NR0107/09,SGT-200-2S,2010-10-30,2011-10-29,2007-05-01,Operating,SGT-200,BR,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
S101/01,6032835,Idem Papers,IDEM Belgium,S101/01,9080/059,N/A,SGT-200 PACKAGE GB,TRAIN_S101/01,SGT-200-1S,1991-01-01,1992-03-25,1988-01-01,Cancelled,SGT-200,BE,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M622/01,6141089,Dummy Account: Asia Pacific - Thailand,Map Ta Phut Fertilizer 1,M622/01,,N/A,SGT-100 PACKAGE - SCRAPPED,TRAIN_M622/01,SGT-100-1S,1996-01-01,1996-04-28,1995-12-01,Scrapped,SGT-100,TH,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M311/01,6047900,Equinor Troll A Platform,"Troll Field, North Sea",M311/01,,N/A,SGT-100 PACKAGE C1S M1C EX GB,TRAIN_M311/01,SGT-100-1S,1993-01-01,1994-08-25,1992-01-01,Operating,SGT-100,NO,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NA0270/03,6368136,YPFB Refineria - PSLCV Gran Chaco,,NA0270/03,,,,,SGT-400,2020-01-01,2020-01-01,2011-10-01,Cancelled,SGT-400,BO,No Contract or Agreement,ADHOC,,,
NW0155/02,6059528,Monomeros Colombo Venezolanos S.A.,Monomeros,NW0155/02,,HI4054 1405054,SGT-300 (HOU HI4054/02) M1C E1C DS,TRAIN_NW0155/02,SGT-300,2010-07-22,2011-07-21,2008-12-28,Not Running – Preserved,SGT-300,CO,RDS,ADHOC,I4-021,10,GENERATOR
NW0098/01,6047024,"PLASTIVERD PET RECICLADO, S.A.",La Seda,NW0098/01,,N/A,SGT-300 PACKAGE CX M1C E1C DS GB,TRAIN_NW0098/01,SGT-300,2008-06-13,2009-05-30,2007-02-01,Operating,SGT-300,ES,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
M309/04,6079811,Bumi Armada Claire FPSO,Griffin Field FPSO,M309/04,,N/A,SGT-100 PACKAGE C2S GB,TRAIN_M309/04,SGT-100-1S,1992-12-31,2014-07-30,1991-12-31,Not Running – Not Preserved,SGT-100,MY,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NA0143/03,6159817,Hayan Petroleum Company,Jihar Petrofac,NA0143/03,,N/A,SGT-400 PACKAGE <C M1C E1C DS GB,TRAIN_NA0143/03,SGT-400,2010-12-08,2012-06-07,2008-10-01,Not Running – Preserved,SGT-400,SY,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
NR0096/01,6054355,"CNOOC-Guangdong LNG C0., Ltd.",MAIN WORKS,NR0096/01,HI6014 - 1550014,HI6014 1550014,SGT-200 PACKAGE,TRAIN_NR0096/01,SGT-200-2S,2008-04-15,2009-04-14,2006-08-01,Not Running – Preserved,SGT-200,CN,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
BC000116U06,6541472,GAZ-SYSTEM S.A. - Rembelszczyzna,Rembelszczyzna,BC000116U06,NR0420/03 - HI6060/03,NR0420/03,SGT-200 PKG (LCN NR0420) C2S M1S EX,TRAIN_BC000116U06,SGT-200-2S,2022-04-02,2023-04-01,2014-12-31,Operating,SGT-200,PL,Preventive LTP,LTP,I4-006,30,COMPRESSOR
BF000119U02,6673474,OGC - Salalah LPG,,BF000119U02,NC0511/02,NC0511/02,SGT-400 (NC0511) C3C M1S EX HC,TRAIN_BF000119U02,SGT-400,2021-03-25,2023-03-24,2017-09-30,Operating,SGT-400,OM,Preventive LTP – Includes RDS,LTP,I4-008,,COMPRESSOR
NW0560/02,6695201,"Hangzhou Turbine Power Group Co., LTD (HTC)",HTC HAINING,NW0560/02,BD001005U02,BD001005,SGT-300 PACKAGE CX MX EX DX,TRAIN_NW0560/02,SGT-300-1S,2024-03-01,2025-08-30,2021-04-01,Installation & Commissioning,SGT-300,CN,,,I4-021,,GENERATOR
NA0581/02,6704653,"AECC Gas Turbine (Zhuzhou) Co., Ltd.  (ZNGT)",ZNGT Guangan Jianping Paper,NA0581/02,BD001229U02,BD001229,SGT-400 PACKAGE,TRAIN_NA0581/02,SGT-400,2024-09-01,2025-08-30,2023-03-01,Order,SGT-400,CN,,,I4-008,,
NU0212/02,6044313,Caspian Pipeline Consortium (CPC-K) - Atyrau,CPC Atyrau,NU0212/02,,N/A,SGT-100-2S PACKAGE <C M2C E1C DC GB,TRAIN_NU0212/02,SGT-100-2S,2015-06-30,2017-05-30,2010-05-01,Operating,SGT-100,KZ,Frame Agreement – Includes RDS,ADHOC,I4-003,30,COMPRESSOR
NA0106/01,6163782,Sirri Island,Sirri Island,NA0106/01,NA0106/01,N/A,SGT-400 PACKAGE CX M2C E1C DC GB,TRAIN_NA0106/01,SGT-400,2010-11-30,2011-07-30,2007-07-01,Operating,SGT-400,IR,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
BD000055U01,6084965,Simplot - Ulverstone,Simplot,BD000055U01,NW0261/01,NW0261/01,SGT-300 (NW0261) C3S M2C E1C DC GB,TRAIN_BD000055U01,SGT-300,2012-11-16,2013-11-15,2011-05-31,Operating,SGT-300,AU,Corrective LTP – Includes RDS,LTP,I4-021,10,GENERATOR
M0J3/02,6045154,Total E&P - FSO Unity,FSO Unity,M0J3/02,,N/A,SGT-100 PACKAGE C1C M1S GB,TRAIN_M0J3/02,SGT-100-1S,2002-01-01,2004-03-29,2001-06-01,Operating,SGT-100,NG,Resident Engineer,ADHOC,I4-002,10,GENERATOR
J0G2/02,6050992,TETRAPAK MANUFACTURING LIMITED - JEDDAH,TETRAPAK MANUFACTURING LIMITED,J0G2/02,80/84028-03,80/84028-01,SGT-200 PACKAGE M1C E1C GB,TRAIN_J0G2/02,SGT-200-1S,2001-01-06,2002-01-06,2000-01-06,Storage,SGT-200,SA,No Contract or Agreement,ADHOC,I4-005,,GENERATOR
W0J0/01,6055155,"Kashima Power, Kashima",Kashima Power Unit #2,W0J0/01,,N/A,SGT-300 PACKAGE GB,TRAIN_W0J0/01,SGT-300,1993-09-01,1994-09-01,1992-12-23,Not Running – Preserved,SGT-300,JP,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
R617/01,6045727,Gasgrid Oy - Mäntsälä,Mäntsälä GT2,R617/01,9080/196,N/A,SGT-200-2S PACKAGE M2C E1C DC 1996,TRAIN_R617/01,SGT-200-2S non-DLE,1997-01-01,1997-12-31,1995-09-01,Operating,SGT-200,FI,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NR0033/09,6053153,Sonatrach TRC - OZ2,OZ2,NR0033/09,R3D8/09,N/A,SGT-200-2S PACKAGE CX M2C E1C DC,TRAIN_NR0033/09,SGT-200-2S,2004-01-06,2007-03-07,2003-01-06,Operating,SGT-200,DZ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NU0024/01,6047198,Lukoil KGPZ Kotovo,Siberia 1 & 2,NU0024/01,,N/A,SGT-100-2S PACKAGE CX M1C E1C DS GB,TRAIN_NU0024/01,SGT-100-2S,2004-01-01,2007-02-15,2003-10-01,Operating,SGT-100,RU,Frame Agreement,ADHOC,I4-003,30,COMPRESSOR
S505/02,6141134,Dummy Account: Europe - Norway,Gamma West 2,S505/02,,N/A,REFURBISHED SGT-200 SEE LS0002/02,TRAIN_S505/02,SGT-200,1996-01-01,1997-01-01,1994-12-01,Scrapped,SGT-200,NO,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NM0074/01,6050474,Norsk Hydro Engineering Work,Norsk Hydro Engineering Work,NM0074/01,,N/A,SGT-100 PACKAGE CANCELLED,TRAIN_NM0074/01,SGT-100,2005-10-10,2006-10-10,2005-02-23,Cancelled,SGT-100,NO,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NC0259/01,6197589,OPS Angola - FPSO Ngoma - Block 15,N'Goma FPSO,NC0259/01,,N/A,SGT-400 C4S M2S E2S DS GB HS,TRAIN_NC0259/01,SGT-400,2016-01-27,2017-01-26,2011-07-31,Operating,SGT-400,AO,Preventive LTP – Includes RDS,LTP,I4-008,30,COMPRESSOR
S2D0/02,6050093,Single Buoy Moorings - FPSO Serpentina,Power Gen B,S2D0/02,,N/A,SGT-200 PACKAGE <C M2C E1C DC GB 2023,TRAIN_S2D0/02,SGT-200-1S,2002-01-01,2004-09-13,2001-01-01,Operating,SGT-200,GQ,Preventive LTP – Includes RDS,LTP,I4-005,10,GENERATOR
HI8016/01,6104644,ATP Cheviot,ATP Cheviot,HI8016/01,NR0238/01,NR0238/01,SGT-200 PKG (LINCOLN SCOPE NR0238),TRAIN_HI8016/01,SGT-200-2S,2013-09-01,2014-08-31,2011-01-01,Scrapped,SGT-200,GB,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
S330/01,6049073,Nuova Riwal Cermiche SRL - Maranello,RiWal,S330/01,,N/A,SGT-200 PACKAGE GB,TRAIN_S330/01,SGT-200-1S,1993-06-01,1994-09-13,1992-06-01,Not Running – Not Preserved,SGT-200,IT,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NM0342/01,6197133,Neptune E&P UK - Cygnus,Neptune Energy Cygnus Platform,NM0342/01,NM0342/01,N/A,SGT-100 PACKAGE C3C M2C E1C DC GB HC,TRAIN_NM0342/01,SGT-100-1S,2016-11-07,2018-11-06,2012-12-31,Operating,SGT-100,GB,Preventive LTP – Includes RDS,LTP,I4-002,10,GENERATOR
S381/02,6032818,Cristal Global - Millennium Chemicals Stallingborough,Stallingborough 2,S381/02,,N/A,SGT-200 PACKAGE CX M2C EX DC GB,TRAIN_S381/02,SGT-200-1S,1995-01-01,1996-02-09,1993-09-01,Operating,SGT-200,GB,,,I4-005,10,GENERATOR
NW0560/03,6695202,"Hangzhou Turbine Power Group Co., LTD (HTC)",HTC HAINING,NW0560/03,BD001005U03,BD001005,SGT-300 PACKAGE CX MX EX DX,TRAIN_NW0560/03,SGT-300-1S,2024-03-01,2025-08-30,2021-04-01,Installation & Commissioning,SGT-300,CN,,,I4-021,,GENERATOR
NM0314/01,6197316,OGDCL - UCH Gas II,,NM0314/01,,N/A,SGT-100 C3S<C M1C E2S DS GB,TRAIN_NM0314/01,SGT-100,2016-10-27,2017-10-26,2012-09-30,Operating,SGT-100,PK,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NA0018/01,6054517,"Otsuka Chemicals, Tokushima (TKM)",Otsuka Chemicals,NA0018/01,,N/A,SGT-400 C3C M2C E2S DC GB HC,TRAIN_NA0018/01,SGT-400,1997-06-14,2007-01-01,1996-12-16,Scrapped,SGT-400,JP,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
NM0069/02,6054663,Chunxiao Platform - CNOOC China Ltd.,Chunxiao Platform,NM0069/02,,N/A,SGT-100 PACKAGE C2S M2C E1C DC GB,TRAIN_NM0069/02,SGT-100-1S,2004-09-24,2006-04-14,2003-06-24,Operating,SGT-100,CN,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
W2F1/01,6055455,"Petronet LNG Ltd., Dahej",Dahej Port Lng 1,W2F1/01,,N/A,SGT-300 PACKAGE C3S M2C E2S DC GB,TRAIN_W2F1/01,SGT-300,2004-01-01,2005-01-01,2002-12-01,Operating,SGT-300,IN,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
80/830639,6144101,BHP - Nickel West Kalgoorlie Smelter,Nickle West Kalgoorlie,80/830639,,N/A,SGT-200 PACKAGE,TRAIN_80/830639,SGT-200-1S,1981-12-31,1982-12-31,1980-12-31,Scrapped,SGT-200,AU,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NM0070/01,6047039,QatarEnergy - Maydan Mahzam - PS2 Offshore Platform,QP PS2,NM0070/01,,N/A,SGT-100 PACKAGE C3S M2C E2S DC,TRAIN_NM0070/01,SGT-100-1S,2004-01-01,2007-12-31,2003-09-01,Operating,SGT-100,QA,Call Off Contract Goods and Services,ADHOC,I4-002,10,GENERATOR
BF000061U01,6544038,Beineu-Shymkent Gas Pipeline LLP,Beineu-Bozoy Shymkent,BF000061U01,NC0428/01,NC0428/01,SGT-400 (LCN NC0428) C3C M1S EX,TRAIN_BF000061U01,SGT-400,2016-07-10,2017-07-09,2015-05-31,Operating,SGT-400,KZ,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
M8E6/01,6054539,Ajinomoto General Foods,"AGF, Suzuka",M8E6/01,,N/A,SGT-100 C4S M2C E1C DC GB HC,TRAIN_M8E6/01,SGT-100-1S,1983-02-01,1998-05-01,1980-10-01,Operating,SGT-100,JP,Corrective LTP – Includes RDS,LTP,I4-002,10,GENERATOR
NC0031/01,6054836,ONGC B&S BCPA2 Platform,OIL AND NATURAL GAS COMMISSION (ONGC),NC0031/01,,N/A,SGT-400 C3S M2C E2S DC GB,TRAIN_NC0031/01,SGT-400,2009-05-26,2010-05-25,2006-03-01,Operating,SGT-400,IN,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
BD000069U02,6103797,Brunei Shell Petroleum - Champion 7,Champion 7 - Brunei Shell Petroleum,BD000069U02,NA0273/02,NA0273/02,SGT-400(LCN NA0273) C4S M2S E2S DS GB HS,TRAIN_BD000069U02,SGT-400,2018-05-22,2019-05-21,2011-09-30,Operating,SGT-400,BN,Preventive LTP – Includes RDS,LTP,I4-008,10,GENERATOR
NC0145/01,6112054,Foroozan - Compressor station,Foroozan - Compressor station,NC0145/01,,N/A,SGT-400 PACKAGE C1C M2S E1C DS,TRAIN_NC0145/01,SGT-400,,,2008-12-01,Storage,SGT-400,IR,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
U2A3/01,6142732,Single Buoy Moorings - FPSO Falcon,FPSO Falcon,U2A3/01,,N/A,SGT-100-2S PACKAGE <C GB,TRAIN_U2A3/01,SGT-100-2S,2003-01-01,2003-12-14,2001-12-01,Not Running – Not Preserved,SGT-100,SG,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
S443/03,6050040,ASSALA GABON - Rabi Field,Rabi Field,S443/03,,N/A,SGT-200 PACKAGE C2S M1C E2S DS GB,TRAIN_S443/03,SGT-200-1S,1994-08-08,1995-08-07,1993-12-01,Operating,SGT-200,GA,Frame Agreement,ADHOC,I4-005,10,GENERATOR
NA0532/01,6682261,Centrax Ltd.,Centrax 020 (Opel Espana),NA0532/01,BD000752U01,BD000752,SGT-400 PACKAGE GB EX C3S,TRAIN_NA0532/01,SGT-400,2019-10-24,2020-10-23,2019-04-30,Operating,SGT-400,GB,Corrective LTP – Includes RDS,LTP,I4-008,,GENERATOR
R845/01,6050056,Avebe Dobbestroom,Ter Apelkanal 1,R845/01,,N/A,SGT-200 PACKAGE M2C DC GB,TRAIN_R845/01,SGT-200-1S,1990-01-01,1990-07-25,1989-01-01,Not Running – Not Preserved,SGT-200,NL,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NA0016/04,6011872,Alyeska - Pump Station 03,PS3 SGT-400 #1,NA0016/04,HI6001/04,1550001,SGT-400 PACKAGE CX M2S E2S DS GB,TRAIN_NA0016/04,SGT-400,2009-06-01,2011-05-30,2004-04-01,Operating,SGT-400,US,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
M466/01,6047251,Total E&P Danmark A/S - Tyra West (new SGT business),Tyra West Platform,M466/01,S 9100/111,N/A,SGT-100 PACKAGE <C GB,TRAIN_M466/01,SGT-100-1S,1997-02-02,1998-02-02,1996-02-02,Scrapped,SGT-100,DK,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NC0021/08,6039153,PDVSA - Santa Rosa - Planta de Gas Anaco,"Anaco, Santa Rosa",NC0021/08,NC0021/08,HI6006 1550006,SGT-400 (HOU HI6006) C1S M2C E1C DC GB,TRAIN_NC0021/08,SGT-400,,,2005-06-01,Storage,SGT-400,VE,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
HI6074/03,6680742,Mubadala Energy,Pegaga Mubadala,HI6074/03,BD000717U03,NW0525/02 BD000717,SGT-300 PACKAGE C3S M1S EX,TRAIN_HI6074/03,SGT-300-1S,2022-09-23,2024-05-22,2018-09-30,Operating,SGT-300,MY,Frame Agreement,ADHOC,I4-021,,GENERATOR
R2B2/02,6137995,National Iranian Oil Company (NIOC),Dorood Pumps,R2B2/02,,N/A,SGT-200-2S PACKAGE M1C E1C DS 2002,TRAIN_R2B2/02,SGT-200-2S,2003-08-30,2004-08-30,2002-08-30,Operating,SGT-200,IR,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
R209/01,6032814,GlaxoSmithKline (GSK) - Glaxo Wellcome,Glaxo Wellcome 1,R209/01,,N/A,SGT-200 PACKAGE To Wood Group GB,TRAIN_R209/01,SGT-200-1S,1988-01-01,1988-12-30,1987-01-01,Scrapped,SGT-200,GB,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M362/01,6032845,St Georges Hospital,St Georges Hospital,M362/01,,N/A,SGT-100 PACKAGE <C GB,TRAIN_M362/01,SGT-100-1S,1993-01-01,1994-10-25,1992-01-01,Scrapped,SGT-100,GB,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
W2D5/01,6090461,Cerveceria Polar C.A. - Planta San Joaquin,Planta San Joaquin Edo Carabobo,W2D5/01,HI4065,HI4065 1405016,SGT-300 PKG (PKGD AS HI4065/01) GB,TRAIN_W2D5/01,SGT-300,2014-11-11,2020-11-30,2002-01-01,Not Running – Preserved,SGT-300,VE,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
W7B1/01,6141682,Dummy Account: Asia Pacific - Japan,Takuma Stock Core,W7B1/01,,N/A,SGT-300 PACKAGE - SPARE CORE,TRAIN_W7B1/01,Tempest,1997-01-01,1998-07-01,1996-01-01,Scrapped,SGT-300,JP,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
S524/01,6050799,Tate & Lyle Nederland B.V.,Amylum,S524/01,,N/A,SGT-200 PACKAGE <C M2C EX DC GB 2022,TRAIN_S524/01,SGT-200-1S,1983-01-01,1996-10-06,1982-01-01,Operating,SGT-200,NL,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M0C3/02,6048424,Dana Petroleum Netherlands B.V. - De Ruyter,Dana Petroleum GT2,M0C3/02,,N/A,SGT-100 TURB EFICIENCY C2S M1C E1C DS GB,TRAIN_M0C3/02,SGT-100-1S,2001-09-08,2002-09-08,2000-09-08,Operating,SGT-100,NL,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S122/03,6054913,Petronas Carigali - Baronia Platform,Baronia Platform,S122/03,,N/A,SGT-200 PACKAGE GB,TRAIN_S122/03,SGT-200-1S,1991-01-01,1992-10-05,1990-09-01,Not Running – Preserved,SGT-200,MY,Frame Agreement,ADHOC,I4-005,10,GENERATOR
U8L0/01,6038873,Oleoductos del Valle - Oldelval - Rio Colorado,Rio Colorado,U8L0/01,,N/A,SGT-100-2S PACKAGE CX MX EX DX,TRAIN_U8L0/01,SGT-100-2S,2002-01-01,2003-07-06,2001-01-01,Operating,SGT-100,AR,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
R8C9/01,6032942,Bluewater - Haewene Brim,Pierce Field 3,R8C9/01,,N/A,SGT-200-2S PACKAGE EX,TRAIN_R8C9/01,SGT-200-2S,1997-12-31,2000-04-01,1996-12-31,Not Running – Not Preserved,SGT-200,GB,Preventive LTP,LTP,I4-006,30,COMPRESSOR
NC0326/03,6197129,Botas - Eskisehir,Botas  Eskisehir,NC0326/03,,N/A,SGT-400 PACKAGE C3S M2S E2S DS,TRAIN_NC0326/03,SGT-400,2016-04-08,2017-04-07,2012-07-31,Operating,SGT-400,TR,RDS,ADHOC,I4-008,30,COMPRESSOR
R2G2/03,6045746,GRTGaz - Voisines,"GRT Gaz de France, VOISINES",R2G2/03,NR0025/03,N/A,SGT-200-2S PACKAGE C1S M2C E2S DC GB,TRAIN_R2G2/03,SGT-200-2S,2002-01-21,2004-11-13,2001-12-04,Operating,SGT-200,FR,Frame Agreement,ADHOC,I4-006,30,COMPRESSOR
S8A0/01,6137989,Dummy Account: Asia Pacific - Malaysia,Petronas Carigali Sdn Bhd - Sa,S8A0/01,,N/A,SGT-200 PACKAGE,TRAIN_S8A0/01,SGT-200-1S,1981-01-01,1982-01-01,1980-01-01,Scrapped,SGT-200,MY,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
HI6041/04,6490643,TGP2 KP127,Kp-127,HI6041/04,NC0376/01,NC0376/01,SGT-400 (LCN NC0376) C3S M2C E2S DC GB,TRAIN_HI6041/04,SGT-400,2016-05-23,2018-05-22,2014-06-30,Operating,SGT-400,PE,Preventive LTP – Includes RDS,LTP,I4-008,30,COMPRESSOR
M3B1/01,6049270,Floaters SPA - FPSO Mystras,FPSO Mystras,M3B1/01,,N/A,SGT-100 PACKAGE CX M2S E2S DS GB,TRAIN_M3B1/01,SGT-100-1S,2003-06-01,2004-07-02,2002-06-01,Operating,SGT-100,NG,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
BD000220U01,6541056,Rosetti Petrobaltic,Rosetti Petrobaltic,BD000220U01,NW0398/01,NW0398/01,SGT-300 PACKAGE (LCN SCOPE NW0398)C3S EX,TRAIN_BD000220U01,SGT-300,2020-07-21,2021-07-20,2014-06-24,Operating,SGT-300,PL,No Contract or Agreement,ADHOC,I4-021,,GENERATOR
W8K5/01,6055156,"Kashima Power, Kashima",Kashima Power Unit #1,W8K5/01,,N/A,SGT-300 PACKAGE M2C EX DC GB,TRAIN_W8K5/01,SGT-300,1995-09-24,1996-09-25,1995-03-28,Scrapped,SGT-300,JP,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
M8C5/01,6045141,Total E&P -  OML58 (Obite / Obagi),Obite TGA,M8C5/01,,N/A,SGT-100 C2S M1S EX GB HP,TRAIN_M8C5/01,SGT-100-1S,1998-01-01,1999-01-28,1997-03-01,Operating,SGT-100,NG,Resident Engineer,ADHOC,I4-002,10,GENERATOR
80/87305-01,6032922,Petrofac Facilities Management - Ivanhoe Rob Roy Platform,Ivanhoe Rob Roy 1,80/87305-01,,N/A,SGT-200 PACKAGE GB,TRAIN_80/87305-01,SGT-200-1S,1988-04-28,1989-04-28,1987-01-01,Scrapped,SGT-200,GB,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NR0104/03,6038186,Petróleo Brasileiro S/A - Petrobras - G&E/UN-APGN/UTGC (Fase III),SITI - CACIMBAS PHASE III,NR0104/03,HI6016 - 1550016,HI6016 1550016,SGT-200 PACKAGE  C1S M1C E1C DS GB,TRAIN_NR0104/03,SGT-200-2S,2010-12-30,2011-12-29,2007-03-01,Operating,SGT-200,BR,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
S2A2/02,6055785,Single Buoy Moorings - FPSO Falcon,SBM Falcon,S2A2/02,,N/A,SGT-200 PACKAGE GB,TRAIN_S2A2/02,SGT-200-1S,2003-01-01,2003-12-14,2001-06-01,Not Running – Not Preserved,SGT-200,SG,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NC0029/01,6054942,ONGC B&S BCPB2 Platform,Oil and Natural Gas Corporatio,NC0029/01,,N/A,SGT-400 C3S M2C E2S DC GB,TRAIN_NC0029/01,SGT-400,2008-02-29,2009-02-28,2006-05-01,Operating,SGT-400,IN,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
80/85114-01,216607,Hilcorp Alaska LLC - Endicott,Ruston 1907 Endicott Field,80/85114-01,9080/1185-2,N/A,SGT-200-2S PACKAGE CX M1C E1C DS,TRAIN_80/85114-01,SGT-200-2S,1986-03-03,1987-03-03,1985-03-03,Operating,SGT-200,US,Frame Agreement,ADHOC,I4-006,30,COMPRESSOR
R375/01,6055586,GAIL (India) Ltd - Auraiya,Gail Aurayia,R375/01,,N/A,SGT-200-2S C1S M2C EX DC 2024,TRAIN_R375/01,SGT-200-2S,2015-08-07,2015-07-08,2015-04-01,Operating,SGT-200,IN,Other and 24/7 Global Helpdesk and/or RDS included,ADHOC,I4-006,30,COMPRESSOR
W8J1/01,6051171,"Teplarna Kyjov, a.s. (Kyjov)",Kyjov 2,W8J1/01,,N/A,SGT-300 PACKAGE C2S M2C EX DC GB,TRAIN_W8J1/01,SGT-300,1999-01-01,2000-10-01,1998-01-01,Operating,SGT-300,CZ,Preventive LTP,LTP,I4-021,10,GENERATOR
NA0092/01,6049987,SPDC - Gbaran Ubie,Shell Gbaran Ubie GTG1,NA0092/01,,N/A,SGT-400 PACKAGE C3S POIadd M2C E2S DC GB,TRAIN_NA0092/01,SGT-400,2010-06-25,2011-06-22,2006-08-01,Operating,SGT-400,NG,Frame Agreement,ADHOC,I4-008,10,GENERATOR
NA0016/01,6069816,Alyeska - Pump Station 04,PS4 SGT-400 #1,NA0016/01,HI6001/01,1550001,SGT-400 PACKAGE CX M2S E2S DS GB,TRAIN_NA0016/01,SGT-400,2007-11-01,2009-10-31,2004-04-01,Operating,SGT-400,US,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
NC0032/01,6112056,National Iranian Oil Company (NIOC),NAR Compressor Station,NC0032/01,,N/A,SGT-400 PACKAGE C3S M2C E1C DC GB,TRAIN_NC0032/01,SGT-400,2010-03-22,2010-05-30,2006-04-01,Operating,SGT-400,IR,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
U2H5/01,6050097,OPS Angola - FPSO Ngoma - Block 15,N'Goma FPSO,U2H5/01,,N/A,SGT100-2S PACKAGE C4S M2C E1C DC GB HS,TRAIN_U2H5/01,SGT-100-2S,2003-01-01,2004-09-13,2001-06-01,Operating,SGT-100,AO,Preventive LTP – Includes RDS,LTP,I4-003,30,COMPRESSOR
M577/01,6043549,Arla Foods Arinco - Videbaek,Arla Foods,M577/01,9100/161,N/A,SGT-100 PACKAGE C2S GB,TRAIN_M577/01,SGT-100-1S,1997-09-03,1998-09-03,1996-09-03,Not Running – Not Preserved,SGT-100,DK,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
W8G4/01,6044514,BASF Health & Care Products France SAS - Boussens,Boussens,W8G4/01,,N/A,SGT-300 PACKAGE GB,TRAIN_W8G4/01,SGT-300,2000-12-01,2001-05-01,1999-02-01,Not Running – Not Preserved,SGT-300,FR,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
BF000078U20,6673466,Bid Boland,Bid Boland,BF000078U20,,NU0496/03,SGT-100-2S PACKAGE (NU0496)CX EX,TRAIN_BF000078U20,SGT-100-2S,,,2017-07-31,Operating,SGT-100,IR,No Contract or Agreement,ADHOC,I4-003,,COMPRESSOR
R450/01,6047256,Total E&P Danmark A/S - Gorm (new SGT business),Gorm F- 3,R450/01,9080/150,N/A,SGT-200-2S PACKAGE C1S M1S EX,TRAIN_R450/01,SGT-200-2S,2001-11-01,2002-11-01,2000-11-01,Scrapped,SGT-200,DK,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
S024/01,6044201,BP OIL REFINERIA DE CASTELLON,BP,S024/01,S024/01,N/A,SGT-200 PACKAGE C1S M2C E2S DC GB 2021,TRAIN_S024/01,SGT-200-1S,1990-05-01,1992-02-10,1989-05-01,Operating,SGT-200,ES,Preventive LTP,LTP,I4-005,10,GENERATOR
M464/01,218482,Toronto Photographic Film and Paper,Kodak Toronto Canada,M464/01,,N/A,SGT-100 PACKAGE - SKID EMPTY,TRAIN_M464/01,SGT-100-1S,1995-02-07,1996-02-07,1994-02-07,Scrapped,SGT-100,CA,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
R744/01,6049282,Sakhalin Energy Investment Company Ltd. - Molikpaq,Sakhalin Island,R744/01,,N/A,SGT-200-2S PACKAGE EX 2010,TRAIN_R744/01,SGT-200-2S,1998-02-28,1999-08-01,1996-04-01,Operating,SGT-200,RU,Framework Agreement Goods and Services,ADHOC,I4-006,30,COMPRESSOR
M933/01,6059165,"Keraben, S.A.",Gres de Nules,M933/01,,N/A,SGT-100 PACKAGE - SCRAPPED,TRAIN_M933/01,SGT-100-1S,1990-07-14,1991-07-14,1989-04-01,Scrapped,SGT-100,ES,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NC0111/02,6080071,Pakistan Petroleum Limited (PPL) - Kandhkot,PPL - Kandhkot,NC0111/02,,N/A,SGT-400 C3S M2C E2S DC GB,TRAIN_NC0111/02,SGT-400,2010-03-14,2011-03-13,2007-08-04,Operating,SGT-400,PK,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
NA0470/01,6088536,Centrax Ltd.,Centrax 007 (UEM Metz),NA0470/01,BD000386U01,BD000386,SGT-400 PACKAGE C3C EX HC GB,TRAIN_NA0470/01,SGT-400,2017-11-28,2018-11-27,2016-06-30,Operating,SGT-400,GB,Corrective LTP – Includes RDS,LTP,I4-008,,GENERATOR
NC0016/03,6134884,National Iranian Oil Company (NIOC),South Pars Phase 7,NC0016/03,,N/A,SGT-400 PACKAGE C3S M2C E1C DC GB,TRAIN_NC0016/03,SGT-400,2005-08-01,2009-07-30,2004-01-01,Operating,SGT-400,IR,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
BD000731U02,6682267,Botas - Kuzey Marmara,Kuzey Marmara,BD000731U02,NC0527/02 - BC000294U02,NC0527/02 BC000294?,SGT-400 PACKAGE C3S M1S,TRAIN_BD000731U02,SGT-400,2023-12-01,,2018-12-31,Installation & Commissioning,SGT-400,TR,No Contract or Agreement,ADHOC,I4-008,,COMPRESSOR
S021/01,6032490,Birmingham University  - University (Group),Birmingham University,S021/01,,N/A,SGT-200 PKG - SOLD TO TURB. EFF. GB,TRAIN_S021/01,SGT-200-1S,1990-01-01,1991-11-23,1988-01-01,Scrapped,SGT-200,GB,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NW0427/03,6541631,"PAREX RESOURCES COLOMBIA LTD SUCURSAL (BOGOTA, D.C.)",MASA-Ocensa,NW0427/03,HI4088/09,HI4088,SGT-300 PACKAGE C3S HP,TRAIN_NW0427/02,SGT-300,2023-07-01,2024-06-29,2014-12-01,Operating,SGT-300,CO,No Contract or Agreement,ADHOC,I4-021,,GENERATOR
BD000157U01,6180003,INGREDION COLOMBIA S.A.,"Ingredion, Colombia",BD000157U01,NA0366/01,NA0366/01,SGT-400 (LCN NA0366) C4S M2S EX GB HS,TRAIN_BD000157U01,SGT-400,2015-02-12,2016-02-12,2013-08-31,Operating,SGT-400,CO,Corrective LTP – Includes RDS,LTP,I4-008,30,GENERATOR
S635/01,6032839,Phillips 66 Limited - Humber Refinery,Conoco Phillips 1,S635/01,,N/A,SGT-200 PACKAGE M2C DC GB,TRAIN_S635/01,SGT-200-1S,1996-01-01,1997-10-24,1995-12-01,Storage,SGT-200,GB,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
S752/01,6038136,Petróleo Brasileiro S/A - Petrobras - E&P/SSE/UN-BC/ATP-MRL/FPSO P35,FPSO P35,S752/01,,N/A,SGT-200 PACKAGE EX GB,TRAIN_S752/01,SGT-200-1S,1997-01-01,1998-07-01,1996-01-01,Not Running – Preserved,SGT-200,BR,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M444/01,6044435,Novo Nordisk A/S - Bagsværd,Novo Nordisk,M444/01,9100/093,N/A,SGT-100 PACKAGE C2S GB,TRAIN_M444/01,SGT-100-1S,1997-01-01,1998-05-18,1996-01-01,Scrapped,SGT-100,DK,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M555/01,6047509,MODES - Shafa,Shafa,M555/01,,N/A,SGT-100 PACKAGE C2S M3S E1C DC GB,TRAIN_M555/01,SGT-100-1S,1997-01-09,1998-01-09,1996-01-09,Not Running – Not Preserved,SGT-100,OM,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
HI4067/08,6083353,GLNG - Roma-HCS-02,Roma 02,HI4067/08,NA0246/08,NA0246/08,SGT-400 (LCN NA0246) <C M1C E1C GB,TRAIN_HI4067/08,SGT-400,2015-05-31,2016-10-29,2011-03-31,Not Running – Preserved,SGT-400,AU,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
S039/01,6053076,Harouge Oil Operations - Ghani Field,Farrud - Ghani,S039/01,,N/A,SGT-200 PACKAGE GB,TRAIN_S039/01,SGT-200-1S,1991-01-01,1992-02-25,1990-01-01,Scrapped,SGT-200,LY,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NW0087/01,6049834,Severnaya Neft-Usinsk-Khasireiskoye Oil Field Nort,SEVERNAYA NEFT,NW0087/01,,N/A,SGT-300 C3C M2C E2S DC GB HP,TRAIN_NW0087/01,SGT-300,2009-07-07,2010-11-29,2006-08-11,Operating,SGT-300,RU,Preventive LTP,LTP,I4-021,10,GENERATOR
M0A6/01,6047166,"Cogeneración LIPSA, S.L.",Cogeneracion Lipsa Sl,M0A6/01,,N/A,SGT-100 PACKAGE C2S M1C GB,TRAIN_M0A6/01,SGT-100-1S,2000-07-06,2001-07-06,1999-04-01,Scrapped,SGT-100,ES,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M603/01,6079657,Nova Energy - Edgecumbe,Nova Energy,M603/01,,N/A,SGT-100 PACKAGE C3C M2C DC GB HC,TRAIN_M603/01,SGT-100-1S,1996-05-06,1997-05-06,1995-05-06,Operating,SGT-100,NZ,Corrective LTP – Includes RDS,LTP,I4-002,10,GENERATOR
HI4070/01,6103794,"RUE ""Mogilevenergo""",Mogilev RK-3,HI4070/01,NW0275/01,NW0275/01,SGT-300 (NW0275) C4S M2C E2S DC GB,TRAIN_HI4070/01,SGT-300,2014-02-12,2016-02-11,2011-09-30,Operating,SGT-300,BY,Preventive LTP,LTP,I4-021,,GENERATOR
R0E4/01,6079562,QatarEnergy - Dukhan NGL4,Dukhan Arab D 1,R0E4/01,,N/A,SGT-200-2S C1S M2C E2S DC,TRAIN_R0E4/01,SGT-200-2S,2001-12-01,2003-12-30,2000-12-01,Operating,SGT-200,QA,Call Off Contract Goods and Services,ADHOC,I4-006,30,COMPRESSOR
R321/02,6032935,Gas Networks Ireland - Brighouse Bay,Brighouse Bay 2,R321/02,,N/A,SGT-200-2S PACKAGE CX M2C DC,TRAIN_R321/02,SGT-200-2S,1993-07-19,1994-07-18,1992-03-01,Operating,SGT-200,GB,Preventive LTP,LTP,I4-006,30,COMPRESSOR
NA0568/01,6692667,"Asia Pacific Fibers, PT Tbk",PT-APF,NA0568/01,BD000968U01,NA0455 BD000968 BD000334,SGT-400 PACKAGE C3S M1S,TRAIN_NA0568/01,SGT-400,2022-03-27,2023-03-26,2021-01-01,Operating,SGT-400,ID,No Contract or Agreement,ADHOC,I4-008,,GENERATOR
NC0020/01,6112083,,,,,N/A,SGT-400 PACKAGE Project Cancelled,TRAIN_NC0020/01,,,,,,,,,,I4-008,30,COMPRESSOR
HI4081/02,6541050,PRO MDF S.A.P.I. DE C.V.,Tabasco,HI4081/02,NA0397/02,,,,SGT-400,,,2014-05-31,Cancelled,SGT-400,MX,No Contract or Agreement,ADHOC,,,
M618/03,6032493,Boots Company - Nottingham,Boots Company 3,M618/03,,N/A,SGT-100 PACKAGE C3S M2C E1C DC GB,TRAIN_M618/03,SGT-100-1S,1997-10-02,1998-10-02,1996-10-02,Operating,SGT-100,GB,Corrective LTP – Includes RDS,LTP,I4-002,10,GENERATOR
HI6080/02,6682258,New Fortress Energy - Gulf of Mexico (Mex),FAST LNG,BD001037U02,NA0529/02,NA0529/02 BD000750,SGT-400 PACKAGE CX,TRAIN_HI6080/02,SGT-400,2024-01-01,2024-12-30,2021-06-01,Installation & Commissioning,SGT-400,MX,No Contract or Agreement,ADHOC,I4-008,,GENERATOR
R147/03,6053165,Mellitah Oil & Gas B.V - Bu Attiffel Field,Mellitah Oil & Gas - Oil Division,R147/03,,N/A,SGT-200 PACKAGE C2S M1S EX,TRAIN_R147/03,SGT-200-2S,1992-01-01,1993-01-01,1991-01-01,Operating,SGT-200,LY,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
BF000061U03,6544040,Beineu-Shymkent Gas Pipeline LLP,Beineu-Bozoy Shymkent,BF000061U03,NC0428/03,NC0428/03,SGT-400 (LCN NC0428) C3S M1S EX,TRAIN_BF000061U03,SGT-400,2016-08-10,2017-08-09,2015-05-31,Operating,SGT-400,KZ,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
R023/01,6047257,Total E&P Danmark A/S - Gorm (new SGT business),Gorm F- 1,R023/01,9080/049,N/A,SGT-200-2S PACKAGE C1S M2C EX DC 2000?,TRAIN_R023/01,SGT-200-2S,1992-01-01,1992-08-01,1991-01-01,Operating,SGT-200,DK,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
S546/01,6044229,Brødrene Hartmann-Tønder,Tonder 2,S546/01,ESN9080/162,N/A,SGT-200 PACKAGE CX M2C EX DC GB,TRAIN_S546/01,SGT-200-1S,1995-01-01,1996-06-28,1994-12-01,Operating,SGT-200,DK,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
HI6052RM/02,6437668,Larsen & Toubro Infotech Limited-Mumbai,SAGAR Pragatti,HI6052/02 RM,NM0367/02,NM0367/02,SGT-100 PACKAGE(LCN NM0367)C3S M2C EX DC,TRAIN_HI6052RM/02,SGT-100-1S,,,2013-09-30,Not Running – Preserved,SGT-100,IN,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
HI4087/01,6541632,CFE - Santa Rosalía,Santa Rosalia,HI4087/01,NA0422/01,NA0422/01,SGT-400 (LCN NA0422) CX M2S E1C DS GB,TRAIN_HI4087/01,SGT-400,2015-05-27,2016-05-26,2015-01-01,Operating,SGT-400,MX,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
BD000214U03,6541047,Pakistan Petroleum Limited (PPL) - Adhi Gas,Presson Descon,BD000214U03,NW0393/03,NW0393/03,SGT-300 (LCN NW0393) C2S M2S E2S DS,TRAIN_BD000214U03,SGT-300,2016-02-10,2017-02-09,2014-04-30,Operating,SGT-300,PK,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
M565/01,6044635,Arla Foods Danmark Protein - Nr. Vium,Arla Foods A/S,M565/01,9100/155,N/A,SGT-100 PACKAGE C2S GB,TRAIN_M565/01,SGT-100-1S,1996-01-01,1996-12-06,1995-03-01,Not Running – Not Preserved,SGT-100,DK,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
HI4067/06,6083351,GLNG - Fairview-HCS-05 North,Fairview 05,HI4067/06,NA0246/06,NA0246/06,SGT-400 (LCN NA0246) <C M1C E1C GB,TRAIN_HI4067/06,SGT-400,2015-05-31,2016-10-29,2011-03-31,Not Running – Preserved,SGT-400,AU,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
R0B9/01,6059998,Alliance Pipeline - Blueberry Hill,Blueberry Hill Station,R0B9/01,9080/347,N/A,SGT-200 PACKAGE - SPARE CORE,TRAIN_R0B9/01,SGT-200-2S,2000-05-01,2001-05-01,1999-05-01,Not Running – Not Preserved,SGT-200,CA,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
R2E4/05,6053147,Sonatrach TRC - OZ2,OZ2,R2E4/05,,N/A,SGT-200-2S PACKAGE M2C E1C DC 2008,TRAIN_R2E4/05,SGT-200-2S,2002-09-19,2006-03-29,2001-09-19,Operating,SGT-200,DZ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NR0115/01,6052849,Waha Oil Co - Faregh Field,Faregh,NR0115/01,,N/A,SGT-200-2S PACKAGE C2S M2C E1C DC GB,TRAIN_NR0115/01,SGT-200-2S,2019-11-20,2020-11-19,2008-01-31,Operating,SGT-200,LY,Other - please specify,ADHOC,I4-006,30,COMPRESSOR
S739/01,6045680,Fredericia Bryggeri A/S - Frederica,Fredericia,S739/01,,N/A,SGT-200 PACKAGE GB,TRAIN_S739/01,SGT-200-1S,1998-01-01,1999-01-23,1996-01-01,Scrapped,SGT-200,DK,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NA0142/01,6112058,PJSC - Kiev Cardboard and Paper Mill,Kievsky Paper Mill,NA0142/01,,BD000022U01,SGT-400 (FSP BD000022) CX M1C E1C DS GB,TRAIN_NA0142/01,SGT-400,2013-05-01,2014-04-30,2008-07-18,Operating,SGT-400,UA,Frame Agreement,ADHOC,I4-008,10,GENERATOR
M358/02,219127,Hunts Point Wpcp,New York City Department of Environmental Protection Hunts Point,M358/02,NA,N/A,SGT-100 PACKAGE RETIRED GB,TRAIN_M358/02,SGT-100-1S,1993-01-01,1994-07-13,1992-01-01,Not Running – Not Preserved,SGT-100,US,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M226/01,6052125,Yalova Elyaf-Yalova Istanbul,Yalova Istanbul,M226/01,,N/A,SGT-100 PACKAGE - SCRAPPED,TRAIN_M226/01,SGT-100-1S,1992-09-01,1993-09-01,1991-09-01,Scrapped,SGT-100,TR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S787/01,6045282,Croda Nederland B.V.,Croda,S787/01,,N/A,SGT-200 PACKAGE GB,TRAIN_S787/01,SGT-200-1S,1998-07-01,1999-08-07,1997-08-08,Not Running – Not Preserved,SGT-200,NL,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
S418/01,6043993,BASF SONATRACH Propamchem S.A. - Tarragona,Bayer,S418/01,,N/A,SGT-200 PACKAGE GB,TRAIN_S418/01,SGT-200-1S,1994-01-01,1995-03-02,1993-01-01,Scrapped,SGT-200,ES,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NC0199/01,6052883,Sonatrach DP - Gassi Touil,Gassi Touil,NC0199/01,,N/A,SGT-400 PACKAGE C3C M2C E2S DC GB,TRAIN_NC0199/01,SGT-400,2014-01-31,2014-12-30,2010-01-01,Operating,SGT-400,DZ,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
NU0545/01,6690200,Caspian Pipeline Consortium (CPC-R) - APS4A,CPC Debottleneck APS4A,NU0545/01,BD000859U01,BD000859,SGT-100-2S PACKAGE C3S,TRAIN_NU0545/01,SGT-100-2S,2023-01-10,2025-01-09,2019-12-01,Operating,SGT-100,RU,,,I4-003,,COMPRESSOR
M708/03,6142068,National Iranian Gas Company,Pataveh S2,M708/03,,N/A,SGT-100 PACKAGE C2S M2C EX DC GB,TRAIN_M708/03,SGT-100-1S,1998-01-01,1999-01-26,1996-01-01,Operating,SGT-100,IR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S225/02,6141751,National Iranian Oil Company (NIOC) HQ,Sirri Island,S225/02,,N/A,SGT-200 PACKAGE CX M2C EX DC GB,TRAIN_S225/02,SGT-200-1S,1992-04-30,1993-04-28,1991-11-01,Operating,SGT-200,IR,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
R331/03,6056170,Energy Prima Ltd.,Battle Creek Michigan,R331/03,,N/A,SGT-200 PACKAGE -  SCRAPPED,TRAIN_R331/03,SGT-200-1S,1988-01-01,2008-11-30,1987-01-01,Scrapped,SGT-200,BD,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NC0211/03,6144126,Shwe Platform,SHWE,NC0211/03,,N/A,SGT-400 C3S M2C E2S DC GB,TRAIN_NC0211/03,SGT-400,2013-10-14,2015-04-13,2010-03-18,Operating,SGT-400,MM,Frame Agreement – Includes RDS,ADHOC,I4-008,30,COMPRESSOR
M8L3/01,6038517,PEP-Cantarell Offshore-APC-Akal L,Akal L (Offshore),M8L3/01,M8L3/01,N/A,SGT-100 - SKID EMPTY M1S,TRAIN_M8L3/01,SGT-100-1S,1997-06-05,1998-06-04,1996-05-01,Not Running – Not Preserved,SGT-100,MX,Frame Agreement,ADHOC,I4-002,10,GENERATOR
NM0082/02,6138001,Foroozan - Compressor station,Foroozan Platform,NM0082/02,,N/A,SGT-100 PACKAGE - STORAGE GB,TRAIN_NM0082/02,SGT-100-1S,,,2004-01-01,Not Running – Preserved,SGT-100,IR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M539/02,6038841,Tecpetrol - Comodoro Rivadavia - El Tordillo,El Tordillo 2,M539/02,,N/A,SGT-100 PACKAGE GB,TRAIN_M539/02,SGT-100-1S,1998-01-01,1999-05-09,1997-01-01,Not Running – Preserved,SGT-100,AR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NW0146/02,6045252,FPSO Firenze Baleine Field (ex Aquila - Brindisi) - Saipem for ENI Cote d'Ivoire,ENI Floaters Firenze FPSO - Ex Saipem Aquila,NW0146/02,NW0146/02,N/A,SGT-300 PACKAGE C2S M1C E1C DS GB,TRAIN_NW0146/02,SGT-300,,,2008-10-01,Installation & Commissioning,SGT-300,CI,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
R8A7/01,6032692,Bluewater - Haewene Brim,Pierce Field 1,R8A7/01,,N/A,SGT-200-2S PACKAGE CX M2C DC,TRAIN_R8A7/01,SGT-200-2S,1998-12-31,2000-07-15,1997-01-01,Operating,SGT-200,GB,Preventive LTP,LTP,I4-006,30,COMPRESSOR
HI6037/04,6083358,GLNG - Fairview-HCS-05 North,Fairview 05,HI6037/04,NC0247/04,NC0247/04,SGT-400 (LCN NC0247) C3S M2C E2S DC GB,TRAIN_HI6037/04,SGT-400,2014-12-31,2016-10-29,2011-03-31,Operating,SGT-400,AU,Call off Agreement / Support LTP,ADHOC,I4-008,30,COMPRESSOR
NW0559/02,6695197,Azzawiya Oil Refining Company,Azzawiya,NW0559/02,BD000974U02,BD000974,SGT-300 PACKAGE M1S,TRAIN_NW0559/02,SGT-300-1S,2025-10-01,2026-09-29,2021-01-01,Order,SGT-300,LY,,,I4-021,,GENERATOR
U2D1/03,6050094,Single Buoy Moorings - FPSO Serpentina,Water Injection C,U2D1/03,,N/A,SGT-100-2S PACKAGE C3S M2C E2S GB DC HP,TRAIN_U2D1/03,SGT-100-2S,2002-11-15,2004-09-13,2001-12-01,Operating,SGT-100,GQ,Preventive LTP – Includes RDS,LTP,I4-003,30,COMPRESSOR
S919/01,6141473,Torras Domenech - Flassa,Toden,S919/01,,N/A,SGT-200 PACKAGE,TRAIN_S919/01,SGT-200,1991-01-01,1991-07-16,1988-07-01,Scrapped,SGT-200,ES,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
S735/01,6032811,UCC - Dow Silicones Barry,Dow Corning 1,S735/01,,N/A,SGT-200 PACKAGE C1S M2C EX DC GB,TRAIN_S735/01,SGT-200-1S,1997-01-01,1998-09-24,1996-07-01,Operating,SGT-200,GB,Corrective LTP,LTP,I4-005,10,GENERATOR
S765/01,6032772,Tate & Lyle - Silvertown,Silvertown,S765/01,,N/A,SGT-200 PACKAGE C1S M2C DC GB,TRAIN_S765/01,SGT-200-1S,1997-01-01,1998-09-02,1996-01-01,Operating,SGT-200,GB,Corrective LTP – Includes RDS,LTP,I4-005,10,GENERATOR
NW0048/01,6045861,GHANA TEMA POWERVOLTA RIVER AUTHORITY,GHANA TEMA POWER,NW0048/01,,1405028,SGT-300 PACKAGE C3S M1C E2S DS GB,TRAIN_NW0048/01,SGT-300,2010-06-30,2011-06-29,2007-12-01,Operating,SGT-300,GH,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
NA0172/03,6108648,Restricted Location - RNA (AMENTUM),SITI H&A,NA0172/03,4055,HI4055,SGT-400 (HOU HI4055) C3S M2C E2S DC GB,TRAIN_NA0172/03,SGT-400,2013-03-26,2018-03-25,2009-05-01,Operating,SGT-400,US,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
M542/01,217906,Fortistar Methane Group - Pine Bend Landfill Power Plant,Gas Recovery Systems Inc. Pinebend,M542/01,9100/142,N/A,SGT-100 PACKAGE C2S GB,TRAIN_M542/01,SGT-100-1S,1995-12-06,1994-12-06,1994-12-06,Scrapped,SGT-100,US,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NW0154/04,6108651,Reshadat Sadra,Reshadat Sadra,NW0154/04,,N/A,SGT-300 PACKAGE GB,TRAIN_NW0154/04,SGT-300,,,2009-07-01,Storage,SGT-300,IR,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
M417/01,6048079,Omv/Petrom-Petromar Subsidiary,Petrom 3,M417/01,,N/A,SGT-100 PACKAGE C2S M2C EX DC GB,TRAIN_M417/01,SGT-100-1S,1994-07-01,1995-03-29,1993-07-01,Operating,SGT-100,RO,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M720/01,6049281,Sakhalin Energy Investment Company Ltd. - Molikpaq,Sakhalin - Phase II,M720/01,,N/A,SGT-100 PACKAGE C2S EX GB,TRAIN_M720/01,SGT-100-1S,1997-01-01,1998-06-04,1996-09-01,Operating,SGT-100,RU,Framework Agreement Goods and Services,ADHOC,I4-002,10,GENERATOR
NA0197/03,6104629,ONGC Assam-Lakwa Site,Assam Renewal Project,NA0197/03,NA0197/03,N/A,SGT-400 PACKAGE C3S M2C E2S DC GB,TRAIN_NA0197/03,SGT-400,2016-08-25,2017-08-24,2011-05-01,Operating,SGT-400,IN,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
NM0083/01,6112080,Dummy Account: Europe,TRELLEBORG,NM0083/01,NM0083/01,N/A,SGT-100 PACKAGE - CANCELLED ORDER,TRAIN_NM0083/01,SGT-100,2006-09-14,2008-03-14,2005-09-14,Cancelled,SGT-100,GB,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
HI4062/02,6104691,LUXERVIZA Angola - Soyo Power Plant,BECHTEL ANGOLA SOYO,HI4062/02,NA0240/02,NA0240/02,SGT-400 PKG (Lincoln Scope NA0240)C1C GB,TRAIN_HI4062/02,SGT-400,2017-11-24,2018-11-23,2010-11-01,Not Running – Preserved,SGT-400,AO,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
BD000042U05,6104686,VCNG Power,VCNG Power,BD000042U05,NA0242/05,NA0242/05,SGT-400(LCN NA0242) C3C M2C E2S DC GB HC,TRAIN_BD000042U05,SGT-400,2016-03-30,2018-02-27,2011-01-01,Operating,SGT-400,RU,Preventive LTP,LTP,I4-008,10,GENERATOR
W9G3/01,6055579,"Gujarat Narmada Valley Chemicals, Bharuch",GNFC,W9G3/01,,N/A,SGT-300 PACKAGE GB,TRAIN_W9G3/01,SGT-300,1999-12-01,2000-12-01,1996-01-01,Scrapped,SGT-300,IN,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
R818/01,6141335,Dummy Account: North America - United States,Union Carbide Bound Brook,R818/01,,N/A,SGT-200 PACKAGE - Wood Group,TRAIN_R818/01,SGT-200,1990-01-01,1990-12-31,1989-01-01,Scrapped,SGT-200,US,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M205/02,6032833,Novartis - Grimsby,Novartis 2,M205/02,,N/A,SGT-100 PACKAGE C3S M2C DC GB,TRAIN_M205/02,SGT-100-1S,1992-01-01,1993-01-01,1991-01-01,Not Running – Not Preserved,SGT-100,GB,Preventive LTP,LTP,I4-002,10,GENERATOR
HI8016/02,6104643,ATP Cheviot,ATP Chevoit,HI8016/02,NR0238/02,NR0238/02,SGT-200 PKG (LINCOLN SCOPE NR0238),TRAIN_HI8016/02,SGT-200-2S,2013-09-01,2014-08-31,2011-01-01,Scrapped,SGT-200,GB,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
R811/01,6061949,TSP Complex Ltd.,Chittagong,R811/01,,N/A,SGT-200 PACKAGE,TRAIN_R811/01,SGT-200-1S,1988-01-01,1989-01-01,1987-01-01,Not Running – Not Preserved,SGT-200,BD,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
R8J9/01,6038465,PLOG-CPTG Atasta Onshore-Atasta,Atasta,R8J9/01,R8J9/01,N/A,SGT-200-2S PACKAGE,TRAIN_R8J9/01,SGT-200-2S,1998-06-01,1999-12-01,1998-01-01,Scrapped,SGT-200,MX,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NA0473/01,6088538,Centrax Ltd.,Centrax 009 (Palm Paper),NA0473/01,BD000388U01,BD000388,SGT-400 C3C M1S EX HC,TRAIN_NA0473,SGT-400,2017-11-15,2018-11-14,2016-08-31,Operating,SGT-400,GB,Preventive LTP – Includes RDS,LTP,I4-008,,GENERATOR
NW0047/01,6079567,Santos - Jackson,Santos Jackson,NW0047/01,,N/A,SGT-300 PACKAGE C2S M2C E1C DC GB,TRAIN_NW0047/01,SGT-300,2008-04-28,2009-04-26,2007-11-30,Operating,SGT-300,AU,Framework Agreement Goods and Services,ADHOC,I4-021,10,GENERATOR
NA0548/01,6690215,Centrax Ltd.,Centrax 022 (Arla),NA0548/01,BD000867U01,BD000867,SGT-400 PACKAGE C3S MX EX DX HP,TRAIN_NA0548/01,SGT-400,2021-11-05,2022-11-04,2020-02-01,Operating,SGT-400,GB,Corrective LTP – Includes RDS,LTP,I4-008,,GENERATOR
NR0117/03,6048075,UEP Beta GmbH [formerly OMV (Pakistan) Exploration Gesellschaft m.b.H.)- Sawan Gas Field,UEP Sawan,NR0117/03,,N/A,SGT-200 PACKAGE <C M2C E1C DC GB 2022,TRAIN_NR0117/03,SGT-200-2S,2010-05-04,2011-05-03,2008-01-01,Operating,SGT-200,PK,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
S3A1/01,6053055,Sarir Oil Operations - Jakhira Field,Wintershall Libya,S3A1/01,NS0025/01,N/A,SGT-200 PACKAGE CX M1C E1C DS,TRAIN_S3A1/01,SGT-200-1S,2003-05-15,2004-05-15,2002-05-15,Operating,SGT-200,LY,Other and 24/7 Global Helpdesk and/or RDS included,ADHOC,I4-005,10,GENERATOR
M580/02,6038832,Chevron Argentina S.R.L. (ex-Sullair Argentina S.A.) - El Trapial,San Jorge 2,M580/02,,N/A,SGT-100 PACKAGE CX M2C E1C DC GB,TRAIN_M580/02,SGT-100-1S,1996-01-01,1997-01-20,1995-09-01,Operating,SGT-100,AR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M416/01,6048080,Omv/Petrom-Petromar Subsidiary,Petrom 1,M416/01,,N/A,SGT-100 PACKAGE C2S M2C EX DC GB,TRAIN_M416/01,SGT-100-1S,1994-07-01,1995-05-07,1993-07-01,Operating,SGT-100,RO,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S140/01,6043926,Bavaria N.V.-Lieshout,Lieshout,S140/01,,N/A,SGT-200 PACKAGE M2C EX DC GB 2012,TRAIN_S140/01,SGT-200-1S,1992-01-01,1993-09-06,1991-01-01,Operating,SGT-200,NL,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
S125/01,6032806,Shell UK - Nelson Platform,Nelson Field 1,S125/01,,N/A,SGT-200 PACKAGE M2C E1C DC GB,TRAIN_S125/01,SGT-200-1S,1991-01-01,2014-03-01,1990-01-01,Not Running – Not Preserved,SGT-200,GB,Frame Agreement,ADHOC,I4-005,10,GENERATOR
NW0100/02,6046662,"GRODNO AZOT, JSC / AO",GRODNO AZOT,NW0100/02,,N/A,SGT-300 PACKAGE C3S M2C E1C DC GB,TRAIN_NW0100/02,SGT-300,2008-11-13,2010-11-12,2007-02-01,Operating,SGT-300,BY,Preventive LTP,LTP,I4-021,10,GENERATOR
BD000135U01,6435826,Halfaya Oil Field (PetroChina),Halfaya - Phase 2,BD000135U01,NA0353/01,NA0353/01,SGT-400 (LCN NA0353) C3C M2S E2S DS GB,TRAIN_BD000135U01,SGT-400,2017-04-30,2019-04-29,2013-09-30,Operating,SGT-400,IQ,Frame Agreement,ADHOC,I4-008,10,GENERATOR
NC0126/01,6038722,TGP1 Chiquintirca,Chiquintirca,NC0126/01,HI6015/01,HI6015 1550019,SGT-400 (HI6015) C4S M2C E2S DC GB HS,TRAIN_NC0126/01,SGT-400,2010-04-01,2011-03-31,2008-01-01,Operating,SGT-400,PE,Preventive LTP – Includes RDS,LTP,I4-008,30,COMPRESSOR
S8K8/04,6057488,Sarir Oil Operations - Jakhira Field,Jakhira 1,S8K8/04,,N/A,SGT-200 PACKAGE CX M2C EX DC GB,TRAIN_S8K8/04,SGT-200-1S,1999-03-01,2000-02-07,1998-03-01,Operating,SGT-200,LY,Other and 24/7 Global Helpdesk and/or RDS included,ADHOC,I4-005,10,GENERATOR
HI4066/11,6039051,PDVSA - Div Faja - Unidades Moviles Div Faja,PDVSA Mobile Power,HI4066/11,NA0209/11,NA0209/11 HI4057,SGT-400 PACKAGE (NA0209) CANCELLED,TRAIN_HI4066/11,SGT-400,,,2010-03-04,Cancelled,SGT-400,VE,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
M438/01,6079568,Liberty OneSteel - Whyalla Steelworks,OneSteel Whyalla,M438/01,,N/A,SGT-100 PACKAGE C2S GB,TRAIN_M438/01,SGT-100-1S,1994-03-23,1996-03-22,1993-03-23,Not Running – Not Preserved,SGT-100,AU,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M0F1/01,6043363,Akenerji Uşak,M0E6/02-Rm324-Gt2,M0F1/01,,N/A,SGT-100 PACKAGE - SCRAPPED,TRAIN_M0F1/01,SGT-100-1S,2003-03-07,2004-03-07,2002-03-07,Scrapped,SGT-100,TR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M0F6/02,6032730,Star Energy - Humbly Grove,Humbly Grove 2,M0F6/02,,N/A,SGT-100 PACKAGE C2S M1C E1C DS GB,TRAIN_M0F6/02,SGT-100-1S,2001-01-01,2002-06-01,2000-06-01,Operating,SGT-100,GB,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S2B4/02,6137990,National Iranian Oil Company (NIOC),Dorood Generator,S2B4/02,,N/A,SGT-200 PACKAGE M1C E1C DS 2004,TRAIN_S2B4/02,SGT-200-1S,2003-10-15,2004-10-15,2002-10-15,Operating,SGT-200,IR,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
80/87306-02,6032926,Petrofac Facilities Management - Ivanhoe Rob Roy Platform,Ivanhoe Rob Roy 5,80/87306-02,,N/A,SGT-200-2S PACKAGE,TRAIN_80/87306-02,SGT-200-2S,1988-08-03,1989-08-03,1987-01-02,Scrapped,SGT-200,GB,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NW0052/01,6059166,HKJ,Toden A.I.E,NW0052/01,,N/A,SGT-300 PACKAGE GB,TRAIN_NW0052/01,SGT-300,2006-12-01,2015-10-01,2005-06-01,Not Running – Preserved,SGT-300,ES,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
M2D4/02,6048967,Burullus (Rashpetco),Burullus,M2D4/02,,N/A,SGT-100 PACKAGE C2S GB,TRAIN_M2D4/02,SGT-100-1S,2002-05-01,2004-02-14,2001-05-01,Not Running – Not Preserved,SGT-100,EG,,,I4-002,10,GENERATOR
S350/03,6032706,Chrysaor  Petroleum - Judy Platform,Judy Joanne 3,S350/03,,N/A,SGT-200 PACKAGE C2S M2C E1C DC GB,TRAIN_S350/03,SGT-200-1S,1995-01-01,1995-12-15,1993-09-01,Operating,SGT-200,GB,Call off Agreement / Support LTP,ADHOC,I4-005,10,GENERATOR
M0F7/01,6058270,Beta Glass - Agbara Plant,Beta Glass - Agbara,M0F7/01,,N/A,SGT-100 PACKAGE - GB,TRAIN_M0F7/01,SGT-100-1S,2001-01-01,2003-05-01,2000-06-01,Not Running – Preserved,SGT-100,NG,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
BD000286U01,6552734,Total E&P UK - Culzean Platform,Culzean Power Gen A,BD000286U01,NW0442/01,NW0442/01,SGT-300 (LCN NW0442) C3S M1S EX,TRAIN_BD000286U01,SGT-300,2019-05-28,2021-05-27,2015-09-07,Operating,SGT-300,GB,Call off Agreement / Support LTP,ADHOC,I4-021,30,GENERATOR
NA0341/01,6157416,Centrax Ltd.,Centrax 002 (Fenice Tioxide),NA0341/01,NA0341/01,N/A,SGT-400 PACKAGE C4S EX GB HS,TRAIN_NA0341/01,SGT-400,2014-11-24,2015-11-23,2013-01-31,Operating,SGT-400,GB,Corrective LTP – Includes RDS,LTP,I4-008,10,GENERATOR
S766/01,6047459,Mishor-Rotem-Tel Aviv-Mishor-Rotem,Mishor-Rotem,S766/01,,N/A,SGT-200 PACKAGE - DEACTIVATED,TRAIN_S766/01,SGT-200-1S,1998-09-04,1999-04-01,1996-04-01,Scrapped,SGT-200,IL,No Contract or Agreement,ADHOC,I4-005,,GENERATOR
U0F5/01,6049283,Sakhalin Energy Investment Company Ltd. - Molikpaq,Sakhalin Island,U0F5/01,,N/A,SGT-100-2S ETHOS ENRGY C1S M1C E1C DS GB,TRAIN_U0F5/01,SGT-100-2S,2001-08-09,2004-02-27,2000-06-09,Operating,SGT-100,RU,Framework Agreement Goods and Services,ADHOC,I4-003,30,COMPRESSOR
M426/01,6032846,St James Hospital,St James Hospital,M426/01,,N/A,SGT-100 PACKAGE C2S,TRAIN_M426/01,SGT-100-1S,1994-11-29,1995-11-28,1994-06-01,Scrapped,SGT-100,GB,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
HI6051/01,6436971,NUVOIL S.A. DE C.V.,TREN 1,HI6051/01,NC0375/01,NC0375/01,SGT-400 (LCN NC0375) C3S M2S E2S DS GB,TRAIN_HI6051/01,SGT-400,2016-08-14,2017-08-13,2013-09-30,Operating,SGT-400,MX,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
BD000080U01,6157415,Daleel Petroleum Company LLC,Daleel,BD000080U01,NW0280/01,NW0280/01,SGT-300 (NW0280) C4S M2C E1C DC GB,TRAIN_BD000080U01,SGT-300,2014-03-17,2015-03-16,2011-10-31,Operating,SGT-300,OM,Preventive LTP,LTP,I4-021,10,GENERATOR
NU0576/01,,,,,,,SGT-100-2S PACKAGE (BD001155),TRAIN_NU0576/01,,,,,,,,,,I4-003,,
BC000538U03,6682258,New Fortress Energy - Gulf of Mexico (Mex),FAST LNG,BD001037U02,NA0529/02,NA0529/03 BD001037 HI6101,SGT-400 PACKAGE (NA0529) CX,TRAIN_BC000538U03,SGT-400,2024-01-01,2024-12-30,2021-06-01,Installation & Commissioning,SGT-400,MX,No Contract or Agreement,ADHOC,I4-008,,GENERATOR
NW0216/03,6080072,Daleel Petroleum Company LLC,DALEEL,NW0216/03,,N/A,SGT-300 PACKAGE C3C M2C E2S DC GB HC,TRAIN_NW0216/03,SGT-300,2012-04-07,2013-04-06,2010-05-01,Operating,SGT-300,OM,Preventive LTP,LTP,I4-021,10,GENERATOR
S9G0/01,6043351,Airbus-Bouguenais-Nantes,Nantes,S9G0/01,,N/A,SGT-200 PACKAGE,TRAIN_S9G0/01,SGT-200-1S,2000-01-01,2000-12-20,1999-02-01,Scrapped,SGT-200,FR,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
HI4066/14,6039054,PDVSA - Div Faja - Unidades Moviles Div Faja,PDVSA Mobile Power,HI4066/14,NA0209/14,NA0209/14 HI4057,SGT-400 PACKAGE (NA0209) CANCELLED,TRAIN_HI4066/14,SGT-400,,,2010-03-04,Cancelled,SGT-400,VE,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
S826/04,6050774,Talisman Energy-Norway-Gyda Field,Gyda Field,S826/04,,N/A,SGT-200 PACKAGE GB,TRAIN_S826/04,SGT-200-1S,1988-01-01,1990-01-10,1987-01-01,Scrapped,SGT-200,NO,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M329/01,6048325,"Papeteries, Novacare, Mougeot-Laval Sur Vologne-Pa",Papeteries MOUGEOT /NOVACARE,M329/01,,N/A,SGT-100 PACKAGE - SCRAPPED,TRAIN_M329/01,SGT-100-1S,1993-08-18,1994-08-18,1992-04-01,Scrapped,SGT-100,FR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
R206/01,6047259,Total E&P Danmark A/S - Gorm (new SGT business),Dan Bridge,R206/01,9080/095,N/A,SGT-200 PACKAGE CX M2C EX DC GB,TRAIN_R206/01,SGT-200-2S,1993-01-01,1994-05-01,1992-01-01,Operating,SGT-200,DK,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NW0559/01,6695196,Azzawiya Oil Refining Company,Azzawiya,NW0559/01,BD000974U01,BD000974,SGT-300 PACKAGE M1S,TRAIN_NW0559/01,SGT-300-1S,2025-10-01,2026-09-29,2021-01-01,Order,SGT-300,LY,,,I4-021,,GENERATOR
M468/01,6141695,Dummy Account: Asia Pacific - Japan,Contract Number does not exist,M468/01,,N/A,SGT-100 PACKAGE - CANCELLED ORDER,TRAIN_M468/01,SGT-100-1S,1995-08-01,1996-08-01,1994-08-01,Cancelled,SGT-100,JP,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
80/85113-02,216604,Hilcorp Alaska LLC - Endicott,Ruston 4502 Endicott Field,80/85113-02,9080/0386-1,N/A,SGT-200 PACKAGE CX M2C E1C DC,TRAIN_80/85113-02,SGT-200-1S,1986-11-05,1987-11-05,1985-11-05,Operating,SGT-200,US,Frame Agreement,ADHOC,I4-005,10,GENERATOR
NM0414/02,6544047,Nawara OMV (GTP/CPF),Nawara Gas Treatment Plant (GTP),NM0414/02,,,,,SGT-100-2S,2016-11-30,2019-05-30,2015-03-31,Cancelled,SGT-100,TN,No Contract or Agreement,ADHOC,,,
NU0027/01,6044134,Botas - Hanak,Botas Petroleum Pipeline Corpo,NU0027/01,,N/A,SGT-100-2S C1S M2C E1C DC GB,TRAIN_NU0027/01,SGT-100-2S,2010-05-30,2012-05-29,2006-03-01,Operating,SGT-100,TR,RDS,ADHOC,I4-003,30,COMPRESSOR
NW0050/01,6045863,GHANA TEMA POWERVOLTA RIVER AUTHORITY,GHANA TEMA POWER,NW0050/01,,N/A,SGT-300 PACKAGE GB,TRAIN_NW0050/01,SGT-300,2008-12-19,2009-12-18,2007-12-18,Not Running – Not Preserved,SGT-300,GH,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
S030/01,6052305,Packages Ltd - (Ex Zwingen 1),Packages Ltd,S030/01,,N/A,SGT-200 PACKAGE GB,TRAIN_S030/01,SGT-200-1S,1991-08-29,1992-08-27,1989-01-01,Not Running – Not Preserved,SGT-200,PK,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M620/01,6038139,Contour Global Solutions Rio Ltda.,Brahma 2,M620/01,,N/A,SGT-100 PACKAGE C2S EX GB,TRAIN_M620/01,SGT-100-1S,1998-07-06,1999-07-06,1997-07-06,Operating,SGT-100,BR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
80/85109-01,6054515,Mann Oilfield - C ITOH/Mitsubishi / PIC of Burma,Mann Oilfield,80/85109-01,,N/A,SGT-200-2S PACKAGE CX M1S EX,TRAIN_80/85109-01,SGT-200-2S,1984-12-31,1985-12-31,1983-12-31,Operating,SGT-200,MM,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
M8H9/01,6047297,Marathon Oil-Malabo-Punta Europa Power Plant,Punta Europa Power Plant,M8H9/01,,N/A,SGT-100 PACKAGE - SKID EMPTY GB,TRAIN_M8H9/01,SGT-100-1S,1999-01-01,1999-06-06,1997-09-01,Not Running – Preserved,SGT-100,GQ,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
R307/01,6053328,Serept - Sfax,Ashtart Field 2,R307/01,,N/A,SGT-200-2S C3S M2C E2S DC,TRAIN_R307/01,SGT-200-2S,1993-01-01,1994-08-01,1992-01-01,Operating,SGT-200,TN,Call Off Contract Goods and Services,ADHOC,I4-006,30,COMPRESSOR
R809/02,6032919,Petrofac Facilities,Kittiwake 2,R809/02,,N/A,SGT-200 PACKAGE <C M2C EX DC GB 2023,TRAIN_R809/02,SGT-200-1S,1988-12-15,1989-06-15,1987-01-01,Operating,SGT-200,GB,Call off Agreement / Support LTP,ADHOC,I4-005,10,GENERATOR
BD000140U02,6364389,Dana Petroleum Limited - Western Isles Development Project,Western Isles development project,BD000140U02,NA0346/02,NA0346/02,SGT-400 PKG (NA0346) C3S M1S EX GB HP,TRAIN_BD000140U02,SGT-400,2016-09-12,2017-09-11,2013-02-28,Operating,SGT-400,GB,Other and 24/7 Global Helpdesk and/or RDS are not included,ADHOC,I4-008,10,GENERATOR
A2B6/01,6032774,Engie - Macclesfield,Zeneca Macclesfield,A2B6/01,NA0005/01,N/A,SGT-400 PACKAGE C3S M2S E2S DC GB,TRAIN_A2B6/01,SGT-400,2004-08-01,2005-08-01,2003-08-01,Operating,SGT-400,GB,Corrective LTP – Includes RDS,LTP,I4-008,10,GENERATOR
R301/01,6047258,Total E&P Danmark A/S - Gorm (new SGT business),Gorm F- 2,R301/01,9080/108,N/A,SGT-200-2S PACKAGE M2C EX DC,TRAIN_R301/01,SGT-200-2S,1995-01-01,1995-12-31,1994-05-01,Scrapped,SGT-200,DK,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
R130/01,6142556,TC Energy - CDN Mainline - Kirkwall Station 1301,TC Energy CDN Mainline Kirkwall Stn.1301P5,R130/01,9080/063,N/A,SGT-200-2S PACKAGE CX EX,TRAIN_R130/01,SGT-200-2S,1991-06-05,1992-06-15,1990-01-01,Operating,SGT-200,CA,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
W8F6/01,6044404,SAPPI ITALY OPERATIONS SPA (CARMIGNANO DI BRENTA),Cartiera di Carmignano,W8F6/01,,N/A,SGT-300 C3S M1C E2S DS GB,TRAIN_W8F6/01,SGT-300,1997-12-01,1998-12-31,1996-12-01,Scrapped,SGT-300,IT,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
80/800666,6045690,Frieslandcampina Domo B.V. Borculo,Borculo (Cw) Omersity,80/800666,,N/A,SGT-200 PACKAGE GB,TRAIN_80/800666,SGT-200-1S,1983-08-12,1984-08-12,1981-08-12,Not Running – Preserved,SGT-200,NL,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NR0038/01,6112079,Kharg Bahregansar,Bahregansar,NR0038/01,NR0038/01,N/A,SGT-200-2S PACKAGE,TRAIN_NR0038/01,SGT-200-2S,,,2005-02-24,Storage,SGT-200,IR,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
M0B0/02,6044337,Caspian Pipeline Consortium (CPC-R) - Marine Terminal,CPC Tank Farm,M0B0/02,,N/A,SGT-100 PACKAGE C3S M2C E1C DC GB,TRAIN_M0B0/02,SGT-100-1S,2002-01-08,2003-01-08,2001-01-08,Operating,SGT-100,RU,Frame Agreement – Includes RDS,ADHOC,I4-002,10,GENERATOR
NU0028/03,6047535,MOL UGS-Hungary,MOL - Hajduszoboszlo,NU0028/03,,N/A,SGT-100-2S PACKAGE - DEACTIVATED GB,TRAIN_NU0028/03,SGT-100-2S,2009-03-27,2012-03-26,2005-12-01,Not Running – Not Preserved,SGT-100,HU,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
A0F8/01,6032820,RWE - Huntsman,Huntsman Tioxide,A0F8/01,NA0003/01,N/A,SGT-400 PACKAGE <C M1C,TRAIN_A0F8/01,SGT-400,2002-02-09,2003-02-09,2001-02-09,Scrapped,SGT-400,GB,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
M554/01,6032494,BBC - British Broadcasting Co,BBC 1,M554/01,,N/A,SGT-100 PACKAGE - MOTHBALLED,TRAIN_M554/01,SGT-100-1S,1995-01-01,1996-08-01,1994-12-01,Scrapped,SGT-100,GB,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NM0086/01,6139087,Dummy Account: Asia Pacific - Japan,Sosuco Energy,NM0086/01,,N/A,SGT-100 PACKAGE - CANCELLED ORDER,TRAIN_NM0086/01,SGT-100,2006-06-13,2008-06-13,2006-02-28,Cancelled,SGT-100,JP,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
BF000126U01,6680138,Energean Oil & Gas FPSO - Karish and Tanin Fields,Energean Karish Tanin FPSO,BF000126U01,NA0522/01,NA0522/01,SGT-400 PKG (LCN SCOPE NA0522) C3S EX GB,TRAIN_BF000126U01,SGT-400,2022-07-20,2023-07-19,2018-07-31,Operating,SGT-400,IL,Other and 24/7 Global Helpdesk and/or RDS included,ADHOC,I4-008,,GENERATOR
HI6072/01,6666250,Keyera Energy - Calgary,Cynthia Gas Plant_Power Generation,HI6072/01,NA0523/01 - BD000704U01,NA0523/01 BD000704,SGT-400 PKG(LCN NA0523) C3C M2S EX GB HC,TRAIN_HI6072/01,SGT-400,2020-08-27,2021-08-26,2018-08-31,Operating,SGT-400,CA,Preventive LTP – Includes RDS,LTP,I4-008,,GENERATOR
R2G2/01,6045745,GRTGaz - Voisines,"GRT Gaz de France, VOISINES",R2G2/01,,N/A,SGT-200-2S C2S M2C E2S DC GB,TRAIN_R2G2/01,SGT-200-2S,2003-01-01,2004-11-13,2001-12-04,Operating,SGT-200,FR,Frame Agreement,ADHOC,I4-006,30,COMPRESSOR
NC0553/02,6692150,"Kazancompressormash, OJSC / Kazankompressormash, OAO",GPN Zapolyarie Pestsovoye BCS,NC0553/02,BD000950U02,BD000950,SGT-400 PACKAGE CX M1S,TRAIN_NC0553/02,SGT-400,,,2020-09-01,Installation & Commissioning,SGT-400,RU,,,I4-008,,COMPRESSOR
HI6053/02,6459627,Akkas Gas Field - KOGAS B.A,Akkas Gas Field,HI6053/02,NU0388/02,NU0388/02,SGT-100-2S PKG(LCN SCOPE NU0388),TRAIN_HI6053/02,SGT-100-2S,2022-12-31,,2014-01-31,Storage,SGT-100,IQ,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
NW0491/01,6665286,"AECC Gas Turbine (Zhuzhou) Co., Ltd.  (ZNGT)",ZNGT Wuxi Mashan,NW0491/01,BD000464U01,N/A,SGT-300 PACKAGE,TRAIN_NW0491/01,SGT-300-1S,2024-02-01,2026-01-30,2021-09-01,Order,SGT-300,CN,No Contract or Agreement,ADHOC,I4-021,,GENERATOR
R8C1/01,6038048,Petróleo Brasileiro S/A - Petrobras - E&P/SSE/UN-BC/ATP-MRL/FPSO P35,Fpso P35,R8C1/01,,N/A,SGT-200-2S PACKAGE,TRAIN_R8C1/01,SGT-200-2S,1999-02-28,2000-02-27,1997-03-01,Storage,SGT-200,BR,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NU0118/02,6157241,Waha Oil Co - Faregh Field,WAHA OIL CO,NU0118/02,,N/A,SGT-100-2S PACKAGE C2S M2C E1C DC GB,TRAIN_NU0118/02,SGT-100-2S,2019-11-20,2020-11-19,2008-01-01,Operating,SGT-100,LY,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
HI4077/02,6362834,Jameson Energy Centre,KMEA,HI4077/02,NA0352/02,NA0352/02,SGT-400 (LCN NA0352) C3S M2S E2S DS GB,TRAIN_HI4077/02,SGT-400,2014-06-30,2015-05-29,2013-09-30,Operating,SGT-400,US,Other - please specify,ADHOC,I4-008,10,GENERATOR
NA0426/01,6541130,Ocensa - Paez,,NA0426/01,Unknown Ex-PDVSA,HI4088,SGT-400 (HOU 4088) C3S M2S E1C DS GB,TRAIN_NA0426/01,SGT-400,2016-06-13,2017-06-12,2014-12-01,Operating,SGT-400,CO,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
M457/01,6049693,PIRELLI / Plant operated by E.ON Business Solutions S.r.l.,Secip,M457/01,,N/A,SGT-100 PACKAGE - SCRAPPED,TRAIN_M457/01,SGT-100-1S,1996-01-08,1997-01-08,1995-01-08,Scrapped,SGT-100,IT,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
BF000091U03,6666270,Eni Ghana - San Zule,ENI Ghana,BF000091U03,NC0464/01,NC0464/01,SGT-400 (LCN NC0464) C3S M1S EX,TRAIN_BF000091U03,SGT-400,2018-12-20,2020-12-19,2017-05-31,Operating,SGT-400,GH,Call off Agreement / Support LTP – Includes RDS,ADHOC,I4-008,,COMPRESSOR
R128/02,6032513,Shell UK Ltd - Sole Pit Clipper Platform,Sole Pit Clipper 3,R128/02,,N/A,SGT-200-2S PACKAGE CX M1C E1C DS,TRAIN_R128/02,SGT-200-2S,1993-10-07,1994-10-06,1990-02-01,Operating,SGT-200,GB,Frame Agreement,ADHOC,I4-006,30,COMPRESSOR
R617/02,6045728,Gasgrid Oy - Mäntsälä,Mäntsälä GT1,R617/02,9080/197,N/A,SGT-200-2S PACKAGE M2C E1C DC 2020,TRAIN_R617/02,SGT-200-2S non-DLE,1997-01-01,1997-12-31,1995-09-01,Operating,SGT-200,FI,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
HI4078/01,6437671,Yacimientos Petroliferos Fiscales Bolivianos YPFB - Rio Grande,GNL Rio Grande (Sener Ingenieria y Sistemas),HI4078/01,NW0365/01,HI4078/01,SGT-300 PKG(LCN NW0365) C2S M1C E2S DS,TRAIN_HI4078/01,SGT-300,2015-12-23,2018-12-22,2013-07-31,Operating,SGT-300,BO,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
W3C8/02,6053122,Mellitah Oil & Gas B.V - Sabratha Platform,"AGIP GAS/Sabratha platform, NC41",W3C8/02,,N/A,SGT-300 PACKAGE C3S M1C E2S DS,TRAIN_W3C8/02,SGT-300,2003-11-26,2007-05-21,2002-11-26,Operating,SGT-300,LY,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
S9C4/01,6141680,Dummy Account: Asia Pacific - Japan,Nippon Sheet Glass,S9C4/01,,N/A,SGT-200 PACKAGE - SCRAPPED,TRAIN_S9C4/01,SGT-200,1999-01-01,2002-01-27,1998-01-01,Scrapped,SGT-200,JP,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M520/01,6055088,"Denso, Koda (TKM)",Denso Kouda,M520/01,,N/A,SGT-100 PACKAGE C2S GB,TRAIN_M520/01,SGT-100-1S,1988-03-01,1989-03-01,1987-10-01,Scrapped,SGT-100,JP,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NW0154/02,6108653,Reshadat Sadra,Reshadat Sadra,NW0154/02,,N/A,SGT-300 PACKAGE GB,TRAIN_NW0154/02,SGT-300,,,2009-07-01,Storage,SGT-300,IR,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
M202/03,6050101,Videocolor,Videocolor TG3,M202/03,,N/A,SGT-100 PACKAGE - MOTHBALLED GB,TRAIN_M202/03,SGT-100-1S,1992-07-01,1993-07-01,1991-07-01,Scrapped,SGT-100,IT,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M737/01,6079558,Qatargas - South (Rasgas Alpha - RGA) Offshore Platform,Ras Laffan 6-1,M737/01,,N/A,SGT-100 PACKAGE C2S EX GB,TRAIN_M737/01,SGT-100-1S,1999-01-02,2000-01-02,1996-04-01,Operating,SGT-100,QA,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M120/01,6048922,RADICI YARN S.p.A.,Radicifil (Textile) VdO,M120/01,,N/A,SGT-100 PACKAGE C2S GB,TRAIN_M120/01,SGT-100-1S,1991-02-01,1992-02-01,1990-02-01,Scrapped,SGT-100,IT,,,I4-002,10,GENERATOR
NA0389/02,6490632,ZNGT Liyuan (COG),ZNGT Liyuan (COG),NA0389/02,,BD000202U02,SGT-400(FSP BD000202) C1C M2S E2S GB,TRAIN_NA0389/02,SGT-400,2015-07-22,2016-07-21,2014-03-31,Storage,SGT-400,CN,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
HI6039/02,6197310,YPFB Refineria - PSLCV Gran Chaco,Gran Chaco,HI6039/02,NA0279/02,NA0279/02,SGT-400 (LCN NA0279) C3S M2S E2S DS GB,TRAIN_HI6039/02,SGT-400,2015-10-31,2016-09-29,2011-10-31,Operating,SGT-400,BO,RDS,ADHOC,I4-008,10,GENERATOR
NS0022/01,6043710,Barilla-Foggia-Barilla,Barilla,NS0022/01,,N/A,SGT-200 C3C M2C E2S DC GB HC,TRAIN_NS0022/01,SGT-200-1S,2005-05-24,2005-12-15,2004-03-01,Scrapped,SGT-200,IT,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M0A8/01,6044334,Caspian Pipeline Consortium (CPC-R) - Komsomolsk,CPC Komsomolsk,M0A8/01,,N/A,SGT-100 C3S M2C E1C DC GB,TRAIN_M0A8/01,SGT-100-1S,2002-01-08,2003-01-08,2001-01-08,Operating,SGT-100,RU,Frame Agreement – Includes RDS,ADHOC,I4-002,10,GENERATOR
HI4067/07,6083352,GLNG - Roma-HCS-02,Roma 02,HI4067/07,NA0246/07,NA0246/07,SGT-400 (LCN NA0246) C1S M1C E1C GB,TRAIN_HI4067/07,SGT-400,2015-05-31,2016-10-29,2011-03-31,Not Running – Preserved,SGT-400,AU,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
BD000069U01,6103798,Brunei Shell Petroleum - Champion 7,Champion 7 - Brunei Shell Petroleum,BD000069U01,NA0273/01,NA0273/01,SGT-400 (NA0273) C3C M2S E2S DS GB HC,TRAIN_BD000069U01,SGT-400,2018-05-22,2019-05-21,2011-09-30,Operating,SGT-400,BN,Preventive LTP – Includes RDS,LTP,I4-008,10,GENERATOR
U0A3/03,6044329,Caspian Pipeline Consortium (CPC-R) - Kropotkin,CPC Kropotkin,U0A3/03,,N/A,SGT-100-2S PACKAGE C3C M2C E1C DC GB HC,TRAIN_U0A3/03,SGT-100-2S,2000-11-05,2003-12-14,1999-04-23,Operating,SGT-100,RU,Frame Agreement – Includes RDS,ADHOC,I4-003,30,COMPRESSOR
NC0015/01,6134880,Salman Gas Lift,Salman Platform,NC0015/01,,N/A,SGT-400 PACKAGE <C M2C E1C DC GB,TRAIN_NC0015/01,SGT-400,,,2003-07-24,Storage,SGT-400,IR,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
M435/01,6032847,Aurelius AG - Briar Chemicals Ltd,Bayer Crop Science,M435/01,,N/A,SGT-100 C3S M2C E2S DC GB,TRAIN_M435/01,SGT-100-1S,1995-01-01,1995-12-01,1994-07-01,Not Running – Not Preserved,SGT-100,GB,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M0E9/02,6043361,Batıçim Enerji,M0E9/01-Rm313-Gt5,M0E9/02,,N/A,SGT-100 PACKAGE - MOTHBALLED GB,TRAIN_M0E9/02,SGT-100-1S,2003-05-02,2004-05-02,2002-05-02,Scrapped,SGT-100,TR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NC0201/02,6052877,Sonatrach DP - Rhoude Nouss,Rhoude Nouss,NC0201/02,,N/A,SGT-400 PACKAGE <C M2C E2S GB DC,TRAIN_NC0201/02,SGT-400,2015-02-20,2017-02-19,2010-03-01,Not Running – Not Preserved,SGT-400,DZ,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
NA0123/01,6045859,GHANA TEMA POWERVOLTA RIVER AUTHORITY,GHANA TEMA POWER,NA0123/01,NA0123/01,N/A,SGT-400 PACKAGE C3S M2S EX GB,TRAIN_NA0123/01,SGT-400,2010-04-01,2011-03-31,2007-12-20,Operating,SGT-400,GH,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
S826/02,6050776,Talisman Energy-Norway-Gyda Field,Gyda Field,S826/02,,N/A,SGT-200 PACKAGE GB,TRAIN_S826/02,SGT-200-1S,1988-01-01,1989-12-21,1987-01-01,Scrapped,SGT-200,NO,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NS0125/08,6046471,Donau Chem,ZNGT Romania,NS0125/08,,N/A,SGT-200 PACKAGE,TRAIN_NS0125/08,SGT-200-1S DLE,,,2008-07-01,Not Running – Not Preserved,SGT-200,RO,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M512/01,6144802,Rio Tinto - Weipa,Weipa Kaolin 2,M512/01,,N/A,SGT-100 PACKAGE - SCRAPPED,TRAIN_M512/01,SGT-100-1S,1995-08-12,1996-08-12,1994-08-12,Scrapped,SGT-100,AU,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
HI6094/02,6690203,PETRONAS Carigali Sdn Bhd - Sarawak Operations - E11P-B PLATFORM,Petronas Kasawari,BD000853U02,NW0543/02,NW0543/02 BD000853,SGT-300 PACKAGE (LCN NW0543) CX,TRAIN_HI6094/02,SGT-300-1S,2024-01-01,2026-12-30,2019-12-01,Installation & Commissioning,SGT-300,MY,,,I4-021,,GENERATOR
M3C4/01,6054810,"PFI, Asaka (HPS)",PFI,M3C4/01,NM0064/01,N/A,SGT-100 PACKAGE C3S M2C E2S DC GB,TRAIN_M3C4/01,SGT-100-1S,1998-04-29,2005-04-01,1997-10-31,Operating,SGT-100,JP,Corrective LTP – Includes RDS,LTP,I4-002,10,GENERATOR
BD000291U02,6490639,"Mayak-Energy, LLC",Mayak Penza,BD000291U02,NW0385/02 / NW0438/02,NW0438/02,SGT-300 PKG (Lincoln Scope NW0438)EX C3S,TRAIN_BD000291U02,SGT-300,2018-03-31,2019-02-27,2014-06-30,Operating,SGT-300,RU,No Contract or Agreement,ADHOC,I4-021,,GENERATOR
R910/01,6055521,Sichuan Chemical Company,Sichuan Chemical Works Group,R910/01,,N/A,SGT-200-2S PACKAGE,TRAIN_R910/01,SGT-200-2S,1989-04-25,1993-05-01,1988-04-01,Scrapped,SGT-200,CN,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
BD000135U03,6435828,Halfaya Oil Field (PetroChina),Halfaya - Phase 2,BD000135U03,NA0353/03,NA0353/03,SGT-400 PKG(LCN NA0353) CX M2S E2S DS GB,TRAIN_BD000135U03,SGT-400,2017-04-30,2019-04-29,2013-09-30,Operating,SGT-400,IQ,Frame Agreement,ADHOC,I4-008,10,GENERATOR
NC0021/09,6039154,PDVSA Gas - Maturin - Proyecto Jusepin 200,"Anaco, Santa Rosa",NC0021/09,NC0021/09,HI6006 1550006,SGT-400 (HOU HI6006) C1C M2C E1C DC GB,TRAIN_NC0021/09,SGT-400,2018-08-17,2019-08-16,2005-06-01,Not Running – Preserved,SGT-400,VE,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
M566/01,6043548,Arla Foods Hoco - Holstebro,Arla Foods A/S,M566/01,9100/179,N/A,SGT-100 PACKAGE C2S GB,TRAIN_M566/01,SGT-100-1S,1996-08-27,1997-08-27,1995-04-01,Not Running – Not Preserved,SGT-100,DK,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
U7B0/02,6083390,First Gas - Rotowaro,Vector Rotowaro,U7B0/02,,N/A,SGT-100-2S PACKAGE C2S M2C EX DC GB,TRAIN_U7B0/02,SGT-100-2S,1997-12-17,1998-12-16,1996-12-31,Operating,SGT-100,NZ,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
NJ0450/01,6553264,Rospan International,Rospan,NJ0450/01,BD000316U01,N/A,SGT-300-2S PACKAGE C2S M2C E1C DC,TRAIN_NJ0450/01,SGT-300-2S,2021-07-01,2023-06-29,2016-02-01,Operating,SGT-300,RU,Preventive LTP,LTP,I4-022,,COMPRESSOR
80/86208-01,6056068,CNTIC Liaohe oil field,Liao He,80/86208-01,,N/A,SGT-200-2S PACKAGE,TRAIN_80/86208-01,SGT-200-2S,1986-04-06,1987-04-06,1986-01-01,Scrapped,SGT-200,CN,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
S113/02,6048838,Qatar Petroleum - Doha - Dukhan Power Station,Dukhan Power Station,S113/02,,N/A,SGT-200 PACKAGE GB,TRAIN_S113/02,SGT-200-1S,1991-01-01,1992-03-25,1990-01-01,Not Running – Not Preserved,SGT-200,QA,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M656/02,6142067,National Iranian Oil Company (NIOC) HQ,Aboozar Platform,M656/02,,N/A,SGT-100 PACKAGE C2S M2C EX DC GB,TRAIN_M656/02,SGT-100-1S,1999-01-01,2003-08-24,1996-01-01,Operating,SGT-100,IR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
HI6030/01RT,6104654,ONGC B&S B193 Platform,ONGC - SIME DARBY - B193,HI6030/01 RT,NR0225/01,NR0225/01,SGT-200 PACKAGE <C M2C E2S DC GB 2021,TRAIN_HI6030/01RT,SGT-200-2S,2012-06-01,2013-05-31,2010-08-01,Operating,SGT-200,IN,No Contract or Agreement,ADHOC,I4-006,,COMPRESSOR
R2E5/03,6053163,Sonatrach TRC - OZ2,OZ2,R2E5/03,,N/A,SGT-200-2S PACKAGE M2C E2S DC 2002,TRAIN_R2E5/03,SGT-200-2S,2002-09-19,2006-03-29,2001-09-19,Operating,SGT-200,DZ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
S039/03,6053077,Harouge Oil Operations - Ghani Field,Farrud - Ghani,S039/03,,N/A,SGT-200 PACKAGE GB,TRAIN_S039/03,SGT-200-1S,1991-01-01,1992-02-25,1990-01-01,Scrapped,SGT-200,LY,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
S455/01,216275,Anheuser Busch - Newark Brewery,Anheuser Busch Newark,S455/01,,N/A,SGT-200 PACKAGE GB,TRAIN_S455/01,SGT-200-1S,1994-01-01,1996-12-29,1993-01-01,Not Running – Not Preserved,SGT-200,US,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M634/01,6052127,Yalova Elyaf-Yalova Istanbul,Yalova Istanbul,M634/01,,N/A,SGT-100 PACKAGE - DEACTIVATED,TRAIN_M634/01,SGT-100-1S,1997-05-12,1998-05-12,1996-05-12,Scrapped,SGT-100,TR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
BF000120U02,6673469,OGC - Salalah LPG,,BF000120U02,NW0512/02,NW0512/02,SGT-300 PKG (LCN NW0512) C3S M1S EX,TRAIN_BF000120U02,SGT-300,2020-10-01,2022-09-30,2017-09-30,Operating,SGT-300,OM,Preventive LTP – Includes RDS,LTP,I4-021,,GENERATOR
BF000126U03,6680140,Energean Oil & Gas FPSO - Karish and Tanin Fields,Energean Karish Tanin FPSO,BF000126U03,NA0522/03,NA0522/03,SGT-400 PKG (LNC SCOPE NA0522) C3S EX GB,TRAIN_BF000126U03,SGT-400,2022-09-04,2023-09-03,2018-07-31,Operating,SGT-400,IL,Other and 24/7 Global Helpdesk and/or RDS included,ADHOC,I4-008,,GENERATOR
BD000363U02,6662757,"AECC Gas Turbine (Zhuzhou) Co., Ltd.  (ZNGT)",ZNGT Hengyun,BD000363U02,NA0466/02,NA0466/02,SGT-400 PACKAGE (LCN SCOPE NA0466) EX,TRAIN_BD000363U02,SGT-400,2018-12-12,2020-12-11,2016-08-31,Not Running – Not Preserved,SGT-400,CN,No Contract or Agreement,ADHOC,I4-008,,GENERATOR
BF000143U02,6696245,Al Maham Engineering Projects Co. Ltd,Zubair II,BD000848U02,NC0542/02,NC0542/02 BD000848,SGT-400 PACKAGE (LCN NC0542) C3S M1S,TRAIN_BF000143U02,SGT-400,2024-03-01,2024-12-30,2019-10-01,Installation & Commissioning,SGT-400,IQ,,,I4-008,,COMPRESSOR
M8B0/01,6047986,OGDCL - Uch Gas,Uch Gas Field 3,M8B0/01,,N/A,SGT-100 C2S M2C E1C DC GB,TRAIN_M8B0/01,SGT-100-1S,1998-01-01,1999-12-17,1997-08-01,Operating,SGT-100,PK,Framework Agreement Goods and Services,ADHOC,I4-002,10,GENERATOR
M9M0/01,6054435,ALEMBIC LIMITED,Alembic 3,M9M0/01,,N/A,SGT-100 PACKAGE - MOTHBALLED GB,TRAIN_M9M0/01,SGT-100-1S,2000-07-01,2001-07-01,2000-01-01,Scrapped,SGT-100,IN,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NU0026/02,6138008,Chamran Pump Station (NIORDC),Chamran,NU0026/02,NU0026/02,N/A,SGT-100-2S PACKAGE C2S M2C EX DC GB,TRAIN_NU0026/02,SGT-100-2S,,,2004-03-01,Operating,SGT-100,IR,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
NC0031/05,6054839,ONGC B&S BCPA2 Platform,OIL AND NATURAL GAS COMMISSION (ONGC),NC0031/05,,N/A,SGT-400 PACKAGE C3S M2C E2S DC GB,TRAIN_NC0031/05,SGT-400,2009-07-27,2010-07-26,2015-03-01,Operating,SGT-400,IN,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
M364/01,6047524,Mohn media Mohndruck GmbH - Gütersloh,Mohn Media GT1 (LTP),M364/01,9100/065,N/A,SGT-100 PACKAGE C3S M2C E1C DC GB,TRAIN_M364/01,SGT-100-1S,1994-01-01,1994-11-24,1993-09-01,Operating,SGT-100,DE,Corrective LTP – Includes RDS,LTP,I4-002,10,GENERATOR
M0E2/02,6054478,Wenchang Nanhai_West FPSO - CNOOC China Ltd.,Nanhai_West FPSO,M0E2/02,,N/A,SGT-100 PACKAGE C2S M1C E2S DS GB,TRAIN_M0E2/02,SGT-100-1S,2002-01-08,2003-01-08,2001-01-08,Operating,SGT-100,CN,Framework Agreement Goods and Services,ADHOC,I4-002,10,GENERATOR
NM0078/01,6048970,Burullus (Rashpetco),Burullus,NM0078/01,,N/A,SGT-100 PACKAGE C2S GB,TRAIN_NM0078/01,SGT-100-1S,2005-11-30,2006-11-30,2004-08-01,Not Running – Not Preserved,SGT-100,EG,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S0C0/01,6050735,Surgutneftegas-Surgut-Tianskoye,Tianskoye 1,S0C0/01,,N/A,SGT-200 PACKAGE,TRAIN_S0C0/01,SGT-200-1S,2001-08-01,2002-07-11,1999-12-01,Not Running – Not Preserved,SGT-200,RU,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M9L8/01,6048077,Omv/Petrom-Petromar Subsidiary,Petrom 4,M9L8/01,,N/A,SGT-100 PACKAGE C1S M1C E1C DS,TRAIN_M9L8/01,SGT-100-1S,2001-01-01,2002-08-12,1999-06-01,Operating,SGT-100,RO,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S370/01,6032497,Kronospan Ltd,Kronospan 1,S370/01,,N/A,SGT-200 PACKAGE C2S M2C EX DC GB,TRAIN_S370/01,SGT-200-1S,1999-01-01,2000-02-01,1998-10-01,Operating,SGT-200,GB,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
HI6070/01,6678690,EP Petroecuador / Shushufindi Refinery,Shushufindi (Petro Ecuador) (HI6070),HI6070/01,BD000564U01 - NW0515/01,NW0515/01 BD000564,SGT-300 PACKAGE (LCN SCOPE NW0515) EX,TRAIN_HI6070/01,SGT-300,,,2017-12-31,Storage,SGT-300,EC,No Contract or Agreement,ADHOC,I4-021,,GENERATOR
NW0200/01,6108657,Dummy Account: Europe - Russia,EKATERINBURG WSO,NW0200/01,,,,,SGT-300,2011-10-30,2012-10-29,2010-01-30,Cancelled,SGT-300,RU,No Contract or Agreement,ADHOC,,,
W2D9/01,6059061,Cartones America,Cartones América,W2D9/01,NW0021/01,N/A,SGT-300 PACKAGE C3S M2S E2S DS GB HP,TRAIN_W2D9/01,SGT-300,2005-03-04,2006-09-04,2004-06-15,Operating,SGT-300,CO,Corrective LTP – Includes RDS,LTP,I4-021,10,GENERATOR
S350/02,6032544,Chrysaor  Petroleum - Judy Platform,Judy Joanne 2 L Will,S350/02,,N/A,SGT-200 PACKAGE C3S M2C E1C DC GB 2023,TRAIN_S350/02,SGT-200-1S,1994-01-01,1995-04-04,1993-01-01,Operating,SGT-200,GB,Call off Agreement / Support LTP,ADHOC,I4-005,10,GENERATOR
HI6051/03,6437646,NUVOIL S.A. DE C.V.,TREN 3,HI6051/03,NC0375/03,NC0375/03,SGT-400 (LCN NC0375) C3S M2S E2S DS GB,TRAIN_HI6051/03,SGT-400,2016-08-14,2017-08-13,2013-09-30,Operating,SGT-400,MX,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
M469/01,6141691,Dummy Account: Asia Pacific - Japan,Kyushu,M469/01,,N/A,SGT-100 PACKAGE - DEACTIVATED,TRAIN_M469/01,SGT-100,1992-01-01,1996-10-24,1991-01-01,Scrapped,SGT-100,JP,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S826/01,6050773,Talisman Energy-Norway-Gyda Field,Gyda Field,S826/01,,N/A,SGT-200 PACKAGE GB,TRAIN_S826/01,SGT-200-1S,1991-01-01,1992-01-09,1989-01-10,Scrapped,SGT-200,NO,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M309/03,6079810,Bumi Armada Claire FPSO,Griffin Field FPSO,M309/03,,N/A,SGT-100 PACKAGE C2S GB,TRAIN_M309/03,SGT-100-1S,1992-12-31,2014-07-30,1991-12-31,Not Running – Not Preserved,SGT-100,MY,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NA0197/04,6104630,ONGC Assam-Lakwa Site,Assam Renewal Project,NA0197/04,NA0197/04,N/A,SGT-400 PACKAGE C3C M2C E2S DC GB,TRAIN_NA0197/04,SGT-400,2016-08-25,2017-08-24,2011-05-01,Operating,SGT-400,IN,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
NA0021/01,6112093,Dummy Account: Asia Pacific - Thailand,TT Energy Co.Ltd,NA0021/01,NA0021/01,N/A,SGT-400 PACKAGE (CANCELLED),TRAIN_NA0021/01,SGT-400,2009-06-04,2010-05-30,2007-12-28,Cancelled,SGT-400,TH,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
NA0142/02,6112057,PJSC - Kiev Cardboard and Paper Mill,Kievsky Paper Mill,NA0142/02,,BD000022U02,SGT-400 (BD0000220) CX M2S E2S DS GB,TRAIN_NA0142/02,SGT-400,2013-09-01,2014-08-31,2008-07-18,Operating,SGT-400,UA,Frame Agreement,ADHOC,I4-008,10,GENERATOR
BD000183U03,6490636,CNOOC-Huanyan PH1,Huanguan PH1,BD000183U03,NC0380/03,NC0380/03,SGT-400 (LCN NC0380) C3S M2S E2S DS GB,TRAIN_BD000183U03,SGT-400,2015-12-10,2017-06-09,2014-02-28,Operating,SGT-400,CN,Preventive LTP,LTP,I4-008,30,COMPRESSOR
M668/01,6032836,ENGIE - MOD Whitehall,MOD Whitehall,M668/01,,N/A,SGT-100 PACKAGE C2S,TRAIN_M668/01,SGT-100-1S,1999-01-01,1999-07-08,1996-01-01,Scrapped,SGT-100,GB,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
BF000063U04,6550046,Nawara OMV (GTP/CPF),Nawara Gas Treatment Plant (GTP),BF000063U05,NU0415/02,NU0415/02,SGT-100-2S PKG(LCN NU0415) C3S M1C EX,TRAIN_BF000063U04,SGT-100-2S,2020-06-29,2022-06-28,2015-03-31,Operating,SGT-100,TN,Other and 24/7 Global Helpdesk and/or RDS included,ADHOC,I4-003,30,COMPRESSOR
R8B9/01,6045139,Total E&P -  OML58 (Obite / Obagi),Obagi,R8B9/01,,N/A,SGT-200-2S PACKAGE C3S EX,TRAIN_R8B9/01,SGT-200-2S,1998-01-01,1998-12-31,1997-02-01,Operating,SGT-200,NG,Resident Engineer,ADHOC,I4-006,30,COMPRESSOR
BF000061U02,6544039,Beineu-Shymkent Gas Pipeline LLP,Beineu-Bozoy Shymkent,BF000061U02,NC0428/02,NC0428/02,SGT-400 (LCN NC0428) C3S M1S EX,TRAIN_BF000061U02,SGT-400,2016-07-27,2017-07-26,2015-05-31,Operating,SGT-400,KZ,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
BF000103U02,6665372,PTT Wang Noi Compressor Station,,BF000103U02,NC0489/02,NC0489/02,SGT-400 PACKAGE (LCN NC0489) C3S EX,TRAIN_BF000103U02,SGT-400,2018-12-21,2020-03-20,2017-03-01,Operating,SGT-400,TH,No Contract or Agreement,ADHOC,I4-008,,COMPRESSOR
BD000080U02,6157414,Daleel Petroleum Company LLC,Daleel,BD000080U02,NW0280/02,NW0280/02,SGT-300 (NW0280) C4S M2C E2S DC GB HS,TRAIN_BD000080U02,SGT-300,2014-03-17,2015-03-16,2011-10-31,Operating,SGT-300,OM,Preventive LTP,LTP,I4-021,10,GENERATOR
NC0021/10,6039155,PDVSA Gas - Maturin - Proyecto Jusepin 200,"Anaco, Santa Rosa",NC0021/10,NC0021/10,HI6006 1550006,SGT-400 (HOU HI6006) C1S M2C E1C DC GB,TRAIN_NC0021/10,SGT-400,2018-08-17,2019-08-16,2005-06-01,Not Running – Preserved,SGT-400,VE,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
R8C0/01,6045149,Total E&P -  OML58 (Obite / Obagi),Obagi,R8C0/01,,N/A,SGT-200 PACKAGE C1S EX,TRAIN_R8C0/01,SGT-200-2S,1998-01-01,1998-12-31,1997-02-01,Operating,SGT-200,NG,Resident Engineer,ADHOC,I4-006,30,COMPRESSOR
NM0081/02,6138004,National Iranian Gas Company,Parsian Refinery,NM0081/02,,N/A,SGT-100 PACKAGE C2S M2C E1C DC GB,TRAIN_NM0081/02,SGT-100-1S,2007-12-01,2008-12-01,2004-01-01,Operating,SGT-100,IR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S9J2/01,6044669,Descartes-Descartes-Dalkia - Descartes,Dalkia - Descartes,S9J2/01,,N/A,SGT-200 PACKAGE  C1S,TRAIN_S9J2/01,SGT-200-1S,2000-01-07,2001-03-22,1999-03-01,Scrapped,SGT-200,FR,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
80/84021-03,6048944,Rak White Cement - Ras Al Khaimah,Rak White Cement Factory,80/84021-03,,N/A,SGT-200 PACKAGE GB,TRAIN_80/84021-03,SGT-200-1S,1986-11-01,1987-11-01,1985-11-01,Not Running – Not Preserved,SGT-200,AE,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NM0360/01,6437665,SAGAR Laxmi,SAGAR Pragati_LAXMI_Export Gas Compressor,NM0360/01,HI6052,N/A,SGT-100 PACKAGE GB,TRAIN_NM0360/01,SGT-100-1S,,,2013-11-30,Storage,SGT-100,IN,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
80/87321-01,6046875,Innovio Paper,Knp (Sapi),80/87321-01,,N/A,SGT-200 PACKAGE GB,TRAIN_80/87321-01,SGT-200-1S,1988-08-25,1989-08-25,1987-08-25,Not Running – Not Preserved,SGT-200,NL,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
BF000063U03,6550045,Nawara OMV (GTP/CPF),Nawara Gas Treatment Plant (GTP),BF000063U04,NU0415/01,NU0415/01,SGT-100-2S PKG(LCN NU0415) C3S M1C EX,TRAIN_BF000063U03,SGT-100-2S,2020-06-29,2022-06-28,2015-03-31,Operating,SGT-100,TN,Other and 24/7 Global Helpdesk and/or RDS included,ADHOC,I4-003,30,COMPRESSOR
S016/01,6045897,GRACE Manufacturing GmbH & Co. KG - Worms,Grace GT1,S016/01,,N/A,SGT-200 PACKAGE CX M1S EX GB,TRAIN_S016/01,SGT-200-1S,1991-01-01,1991-07-23,1990-01-01,Operating,SGT-200,DE,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
W3B2/03,6048909,Qatar Petroleum - Halul Island,Hallul Island 2,W3B2/03,NW0029/03,N/A,SGT-300 PACKAGE C3C M2S E2S DS,TRAIN_W3B2/03,SGT-300,2001-01-01,2005-03-30,2000-09-01,Not Running – Preserved,SGT-300,QA,Call Off Contract Goods and Services,ADHOC,I4-021,10,GENERATOR
S638/01,6044279,Resipower S.A,Carlsberg,S638/01,,N/A,SGT-200 PACKAGE,TRAIN_S638/01,SGT-200-1S,1996-12-01,1997-06-18,1995-12-01,Scrapped,SGT-200,PT,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M3B1/03,6049269,Floaters SPA - FPSO Mystras,FPSO Mystras,M3B1/03,,N/A,SGT-100 PACKAGE C2S M1R GB,TRAIN_M3B1/03,SGT-100-1S,2003-06-01,2004-07-02,2002-06-01,Not Running – Not Preserved,SGT-100,NG,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S2A8/01,6038849,Total Austral S.A. - Cañadon Alfa,Canadon Alfa,S2A8/01,,N/A,SGT-200 PACKAGE M1C E2S DS GB 2021,TRAIN_S2A8/01,SGT-200-1S,2001-01-01,2003-12-30,2000-10-01,Operating,SGT-200,AR,Other and 24/7 Global Helpdesk and/or RDS are not included,ADHOC,I4-005,10,GENERATOR
HI4074/01,6154010,ONGC - Sagar Samrat  (Mercator),SAGAR SAMRAT ONGC,HI4074/01,NW0292/01 / BFF00413U03,NW0292/01,SGT-300(LCN NW0292)C3C M2C E2S DC,TRAIN_HI4074/01,SGT-300,2024-03-01,2025-02-27,2012-02-28,Installation & Commissioning,SGT-300,IN,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
NC0162/03,6111992,"Dummy Account: Middle East - Iran, Islamic Republic of",Kharg Feed Gas,NC0162/03,,N/A,SGT-400 PACKAGE M1C E1C DS,TRAIN_NC0162/03,SGT-400,,,2009-04-01,Storage,SGT-400,IR,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
HI4072/02,6156034,NEOM Saudi Arabia,Cardon IV,HI4072/02,NA0289/02,NA0289/02,SGT-400 PACKAGE(LCN SCOPE NA0289),TRAIN_HI4072/02,SGT-400,,,2011-08-31,Storage,SGT-400,SA,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
M149/01,219122,NRCC - Ottawa,National Research Council Ottawa Canada,M149/01,qc13,N/A,SGT-100 PACKAGE C3S EX GB,TRAIN_M149/01,SGT-100-1S,1993-01-01,1994-01-01,1992-01-01,Operating,SGT-100,CA,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
HI4071/01,6153998,Gas Transmission Co Ltd,GTCL Elanga (HGO / Hyundai Engineering),HI4071/01,NU0298/01,NU0298/01,SGT-1002S(NU0298)C3C M2C E2S DC HC,TRAIN_HI4071/01,SGT-100-2S,2016-04-16,2018-05-15,2012-02-28,Operating,SGT-100,BD,Preventive LTP – Includes RDS,LTP,I4-003,30,COMPRESSOR
S844/02,6032829,Cargill PLC - Manchester,Cargill 2,S844/02,,N/A,SGT-200 PACKAGE C1S M2C E1C DC GB 2023,TRAIN_S844/02,SGT-200-1S,1989-01-01,1990-05-31,1988-01-01,Operating,SGT-200,GB,Corrective LTP – Includes RDS,LTP,I4-005,10,GENERATOR
M8A8/01,6054725,"GEMACO - Gemago Co.,Ltd. (Thai Ceramic, SCG)",Saraburi,M8A8/01,,N/A,SGT-100 PACKAGE C2S M1C E1C DS GB,TRAIN_M8A8/01,SGT-100-1S,1998-03-15,1999-03-14,2015-01-01,Not Running – Not Preserved,SGT-100,TH,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
A0G0/01,220831,Enbridge/Aitken Creek Gas Storage ULC,UNOCal Aitken Creek BC Canada,A0G0/01,9170/009,1405001,SGT-400 PACKAGE C3C M2C E2S DC GB HC,TRAIN_A0G0/01,SGT-400,2003-04-07,2004-04-07,2002-05-07,Operating,SGT-400,CA,Corrective LTP – Includes RDS,LTP,I4-008,30,COMPRESSOR
BD000107U03,6187169,Musandam Gas Plant - OOCEP,Musandam,BD000107U03,NA0331/03,NA0331/03,SGT-400 (LCN NA0331) C3S M1C E1C DS GB,TRAIN_BD000107U03,SGT-400,2015-11-18,2016-11-17,2012-07-02,Not Running – Not Preserved,SGT-400,OM,No Contract or Agreement,ADHOC,I4-008,30,GENERATOR
NC0297/02,6154033,Gas Transmission Co Ltd,Hyundai Engineering,NC0297/02,,N/A,SGT-400 C3C M2S E2S DS GB HC,TRAIN_NC0297/02,SGT-400,2017-07-03,2019-08-02,2012-02-28,Operating,SGT-400,BD,Preventive LTP – Includes RDS,LTP,I4-008,30,COMPRESSOR
BD000107U02,6187168,Musandam Gas Plant - OOCEP,Musandam,BD000107U02,NA0331/02,NA0331/02,SGT-400 (LCN NA0331) C1S M1C E1C DS GB,TRAIN_BD000107U02,SGT-400,2015-08-13,2016-08-12,2012-07-02,Not Running – Not Preserved,SGT-400,OM,No Contract or Agreement,ADHOC,I4-008,30,GENERATOR
W2B1/01,6047969,Occidental Oman - Safah,Occidental Oman - Safah Waterflood,W2B1/01,,N/A,SGT-300 PACKAGE C3S M2C E2S DC,TRAIN_W2B1/01,SGT-300,2002-01-01,2004-01-30,2001-08-01,Operating,SGT-300,OM,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
M8F5/01,6046291,INFINEUM ITALIA S.r.l.,Infineum,M8F5/01,,N/A,SGT-100 PACKAGE C2S M1C E1C DS GB,TRAIN_M8F5/01,SGT-100-1S,1998-01-01,1998-03-23,1997-02-01,Operating,SGT-100,IT,Corrective LTP,LTP,I4-002,10,GENERATOR
80/830604,6044366,Enci BV IJmuiden,Ijmuiden,80/830604,,N/A,SGT-200 PACKAGE,TRAIN_80/830604,SGT-200-1S,1984-02-03,1985-02-03,1983-02-03,Scrapped,SGT-200,NL,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
W0G6/03,6045145,Qatar Petroleum - Halul Island,Halul Island 7,W0G6/03,,N/A,SGT-300 PACKAGE C3S M2R E1C DS,TRAIN_W0G6/03,SGT-300,2004-01-01,2005-07-13,2002-06-01,Not Running – Preserved,SGT-300,QA,Call Off Contract Goods and Services,ADHOC,I4-021,10,GENERATOR
M0E8/02,6043362,Akenerji Uşak,M0E7/02-Rm327-Gt4,M0E8/02,,N/A,SGT-100 PACKAGE - SCRAPPED,TRAIN_M0E8/02,SGT-100-1S,2003-05-02,2004-05-02,2002-05-02,Scrapped,SGT-100,TR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
BF000113U02,6675020,ENI - Petrobel _ Zohr field,Zohr,BF000113U02,NA0510/02,NA0510/02,SGT-400 PKG (LCN NA0510) C3C M1S HC GB,TRAIN_BF000113U02,SGT-400,2019-04-21,2021-04-20,2017-09-30,Operating,SGT-400,EG,Corrective LTP,LTP,I4-008,,GENERATOR
NA0572/01,6701788,National Company for Maize Products,NCMP,NA0572/01,BD001154U01,BD001154,SGT-400 PACKAGE M1S,TRAIN_NA0572/01,SGT-400,2024-09-01,2025-08-30,2022-09-01,Order,SGT-400,EG,,,I4-008,,
80/85113-03,216605,Hilcorp Alaska LLC - Endicott,Ruston 4503 Endicott Field,80/85113-03,9080/0386-2,N/A,SGT-200 PACKAGE C1S M2C E1C DC GB 2019,TRAIN_80/85113-03,SGT-200-1S,1987-04-01,1988-04-01,1986-04-01,Operating,SGT-200,US,Frame Agreement,ADHOC,I4-005,10,GENERATOR
M0E8/01,6043364,Akenerji Uşak,M0E9/02-Rm316-Gt6,M0E8/01,,N/A,SGT-100 PACKAGE - SCRAPPED,TRAIN_M0E8/01,SGT-100-1S,2003-05-02,2004-05-02,2002-05-02,Scrapped,SGT-100,TR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
HI4076/01,6368131,PROMAX ENERGIA S.A.P.I. DE C.V.  (MONTERREY),Promax,HI4076/01,NA0355/01,NA0355/01,SGT-400 (NA0355) C3C M2C E2S DC GB HC,TRAIN_HI4076/01,SGT-400,2014-12-08,2015-12-07,2013-06-30,Operating,SGT-400,MX,Corrective LTP – Includes RDS,LTP,I4-008,10,GENERATOR
BF000091U01,6662752,Eni Ghana - San Zule,ENI Ghana,BF000091U01,NC0465/01,NC0465/01,SGT-400 (NC0465) C3C M2C E2S DC HC,TRAIN_BF000091U01,SGT-400,2019-05-13,2021-05-12,2016-08-31,Operating,SGT-400,GH,Preventive LTP – Includes RDS,LTP,I4-008,,COMPRESSOR
NU0575/01,,,,,,,SGT-100-2S PACKAGE (BD001155),TRAIN_NU0575/01,,,,,,,,,,I4-003,,
M8B0/03,6047984,OGDCL - Uch Gas,Uch Gas Field 1,M8B0/03,,N/A,SGT-100 C2S M2C E1C DC GB,TRAIN_M8B0/03,SGT-100-1S,1998-01-01,2000-02-09,1997-08-01,Operating,SGT-100,PK,Framework Agreement Goods and Services,ADHOC,I4-002,10,GENERATOR
NA0430/01,6541145,Ocensa - La Granjita,,NA0430/01,NA0426/04,HI4088,SGT-400 (HOU HI4088) C3S M1R EX GB,TRAIN_NA0430/01,SGT-400,2016-05-13,2017-05-12,2014-12-01,Operating,SGT-400,CO,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
M709/01,6050415,Stadtwerke Marburg GmbH - Marburg,Marburg,M709/01,,N/A,SGT-100 PKG - SERVICED BY STS C2S GB,TRAIN_M709/01,SGT-100-1S,1997-01-01,1997-10-19,1996-03-01,Scrapped,SGT-100,DE,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S912/01,6141475,PAPELERA PENINSULAR SA,Papelera Peninsular,S912/01,,N/A,SGT-200 PACKAGE GB,TRAIN_S912/01,SGT-200,1990-01-01,1990-03-29,1988-09-01,Scrapped,SGT-200,ES,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M118/03,6141698,Beco - Beco Units,Coval/Beco,M118/03,,N/A,SGT-100 PACKAGE - SITE SHUT,TRAIN_M118/03,SGT-100-1S,1991-01-01,1992-07-01,1990-01-01,Scrapped,SGT-100,IT,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NU0214/03,6044321,Caspian Pipeline Consortium (CPC-R) - Kropotkin,CPC Kropotkin,NU0214/03,,N/A,SGT-100-2S PACKAGE C3C M2C E1C DC GB HC,TRAIN_NU0214/03,SGT-100-2S,2014-04-10,2016-04-09,2010-05-01,Operating,SGT-100,RU,Frame Agreement – Includes RDS,ADHOC,I4-003,30,COMPRESSOR
NC0027/01,6047852,SOCAR-Azerbaijan-Gunashli LP,Gunashli,NC0027/01,NC0027/01,N/A,SGT-400 PACKAGE C3S M2C E2S DC GB,TRAIN_NC0027/01,SGT-400,2012-07-20,2013-03-19,2005-09-18,Operating,SGT-400,AZ,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
S8E5/01,6141694,Dummy Account: Asia Pacific - Japan,Aisin AW HQ,S8E5/01,,N/A,SGT-200 PACKAGE,TRAIN_S8E5/01,SGT-200,2000-01-01,2001-09-26,1997-03-01,Scrapped,SGT-200,JP,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
S8K8/01,6053097,Sarir Oil Operations - Jakhira Field,Jakhira 4,S8K8/01,,N/A,SGT-200 PACKAGE CX M2C EX DC GB,TRAIN_S8K8/01,SGT-200-1S,1999-03-01,1999-12-15,1998-03-01,Operating,SGT-200,LY,Other and 24/7 Global Helpdesk and/or RDS included,ADHOC,I4-005,10,GENERATOR
NM0075/01,6055263,"Nihon Canpac , Hanyu (HPS)",Nihon Canpac,NM0075/01,,N/A,SGT-100 PACKAGE C2S M1C E1C DS GB,TRAIN_NM0075/01,SGT-100-1S,2004-07-01,2005-07-01,2001-10-01,Scrapped,SGT-100,JP,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
HI6037/02,6083356,GLNG - Fairview-HCS-04 South,Fairview 04,HI6037/02,NC0247/02,NC0247/02,SGT-400 (LCN NC0247) <C M2C E2S DC GB,TRAIN_HI6037/02,SGT-400,2015-05-31,2016-10-29,2011-03-31,Scrapped,SGT-400,AU,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
HI6064/02,6553356,"Esentia Pipeline de La Laguna, S. de R.L. de C.V",La Laguna,HI6064/02,NC0468/02,NC0468/02,SGT-400 PKG (LCN NC0468) C3S M2C EX DC,TRAIN_HI6064/02,SGT-400,2021-04-01,2025-03-30,2016-06-30,Operating,SGT-400,MX,Preventive LTP – Includes RDS,LTP,I4-008,,COMPRESSOR
NM0084/01,6047895,Equinor Oseberg Øst Platform,OSEBERG,NM0084/01,NM0084/01,N/A,SGT-100 PACKAGE C2S M2C EX DC,TRAIN_NM0084/01,SGT-100-1S,2008-04-01,2010-03-30,2005-02-24,Operating,SGT-100,NO,Frame Agreement,ADHOC,I4-002,10,GENERATOR
NW0195/01,6044247,Burullus (Rashpetco),Burullus Phase VII,NW0195/01,,N/A,SGT-300 N TYPE C3C M2C E1C DC GB HC,TRAIN_NW0195/01,SGT-300,2011-12-01,2012-11-30,2009-12-01,Operating,SGT-300,EG,Preventive LTP,LTP,I4-021,10,GENERATOR
NR0107/01,6038190,Transportadora Associada de Gás S/A - TAG - Ecomp de Piuma,SITI - GASENE,NR0107/01,HI6017/01,HI6017 1550017,SGT-200 PACKAGE C2S M2S E2S DS GB,TRAIN_NR0107/01,SGT-200-2S,2010-09-30,2011-09-29,2007-05-01,Operating,SGT-200,BR,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
HI4067/01,6083346,GLNG - Fairview-HCS-04 South,Fairview 04,HI4067/01,NA0246/01,NA0246/01,SGT-400 (LCN NA0246) <C M1C E1C GB,TRAIN_HI4067/01,SGT-400,2015-05-31,2016-10-29,2011-03-31,Not Running – Preserved,SGT-400,AU,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
W2B1/02,6083345,Occidental Oman - Safah,Occidental Oman - Safah Waterflood,W2B1/02,,N/A,SGT-300 C3S M2C E2S DC,TRAIN_W2B1/02,SGT-300,2004-01-01,2005-01-30,2003-01-01,Operating,SGT-300,OM,Frame Agreement,ADHOC,I4-021,10,GENERATOR
BD000184U03,6437830,GHANA TEMA POWERVOLTA RIVER AUTHORITY,Atholl Energy,BD000184U03,NW0377/01,NW0377/01,SGT-300 PKG (LCN NW0377) C3S M1C E2S DS,TRAIN_BD000184U03,SGT-300,2016-03-08,2017-03-07,2013-11-30,Operating,SGT-300,GH,No Contract or Agreement,ADHOC,I4-021,,GENERATOR
M0D9/02,6142024,National Iranian Oil Company (NIOC) HQ,Balal,M0D9/02,,N/A,SGT-100 PACKAGE C2S M1C E1C DS GB,TRAIN_M0D9/02,SGT-100-1S,2004-03-01,2005-03-02,2003-03-01,Operating,SGT-100,IR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S574/01,6050074,Silo Rothensee GmbH & Co. KG Standort Hamburg-Hamb,ADM Hamburg,S574/01,,N/A,SGT-200 PACKAGE,TRAIN_S574/01,SGT-200-1S,1997-01-01,1999-12-13,1995-08-01,Scrapped,SGT-200,DE,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NC0033/06,6039012,PDVSA - Altagracia - ICO Altagracia,"ICO, Alta Gracia",NC0033/06,,HI6011 1550011,SGT-400 (HOU HI6011) C1C M2C E1C DC GB,TRAIN_NC0033/06,SGT-400,2014-08-01,2015-10-31,2006-03-01,Not Running – Preserved,SGT-400,VE,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
M447/01,6043368,Akzo Nobel Functional Chemicals B.V.,Arnheim,M447/01,,N/A,SGT-100 PACKAGE - DEACTIVATED GB,TRAIN_M447/01,SGT-100-1S,1994-01-01,1996-01-21,1993-12-01,Scrapped,SGT-100,NL,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M719/02,6047189,Cheminova A/S (now FMC) - Harboøre,Harboore 2,M719/02,9100/203,N/A,SGT-100 PACKAGE C2S GB,TRAIN_M719/02,SGT-100-1S,1997-01-01,1998-03-07,1996-07-01,Not Running – Not Preserved,SGT-100,DK,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
W2D6/01,6090462,"Pepsi-Cola Venezuela, C.A. - Caucagua",Planta Caucagua,W2D6/01,HI4064,HI4064 1405016,SGT-300 PKG (PKGD AS HI4064/01) GB,TRAIN_W2D6/01,SGT-300,2015-04-22,2020-06-30,2002-01-01,Not Running – Preserved,SGT-300,VE,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
BD000799U01,6684621,Perenco UK SHARP,Perenco UK SHARP,BD000799U01,NU0534/01,NU0534/01,SGT-100-2S PACKAGE (NU0534/01) C3S,TRAIN_BD000799U01,SGT-100-2S,2023-03-12,2025-03-11,2019-07-31,Operating,SGT-100,GB,,,I4-003,,COMPRESSOR
M451/03,6043693,Fabrica Boliviana de Ceramica S.R.L. (Faboce Ltda.),,M451/03,,N/A,SGT-100 PACKAGE C2S M2C EX DC GB,TRAIN_M451/03,SGT-100-1S,1994-08-01,1996-04-17,1993-08-01,Not Running – Preserved,SGT-100,BO,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S601/01,6032844,Westinghouse,Springfields 1,S601/01,,N/A,SGT-200 PACKAGE <C M2C E1C DC GB 2022,TRAIN_S601/01,SGT-200-1S,1996-01-01,1996-10-28,1995-08-01,Operating,SGT-200,GB,Corrective LTP – Includes RDS,LTP,I4-005,10,GENERATOR
NC0093/01,6049985,SPDC - Gbaran Ubie,Gbaran Ubie Compressor Set,NC0093/01,NC0093/01,N/A,SGT-400 PACKAGE M2C E2S DC GB-C3S,TRAIN_NC0093/01,SGT-400,2010-07-26,2011-07-25,2006-08-25,Operating,SGT-400,NG,Frame Agreement,ADHOC,I4-008,30,COMPRESSOR
NC0017/01,6051093,TotalEnergies Angola - FPSO Girassol - Block 17,FPSO GIRASSOL,NC0017/01,,N/A,SGT-400 C3S M2C E2S DC GB HP,TRAIN_NC0017/01,SGT-400,2008-05-01,2009-10-30,2004-05-01,Operating,SGT-400,AO,Framework Agreement Goods and Services,ADHOC,I4-008,30,COMPRESSOR
M368/02,6144215,BHP Pty Ltd,Onslow 2,M368/02,,N/A,SGT-100 PACKAGE - SKID EMPTY,TRAIN_M368/02,SGT-100,1992-12-31,1995-08-01,1991-12-31,Scrapped,SGT-100,AU,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NC0162/01,6111994,"Dummy Account: Middle East - Iran, Islamic Republic of",Kharg Feed Gas,NC0162/01,,N/A,SGT-400 PACKAGE M1C E1C DS,TRAIN_NC0162/01,SGT-400,,,2009-04-01,Storage,SGT-400,IR,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
NW0043/01,6112076,Dummy Account: Europe - Russia,TOMSKNEFT,NW0043/01,NW0043/01,N/A,SGT-300 PACKAGE Project cancelled,TRAIN_NW0043/01,TEMPEST,2005-10-21,2007-04-21,2004-06-17,Cancelled,SGT-300,RU,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
M708/02,6142071,National Iranian Gas Company,Pataveh S2,M708/02,,N/A,SGT-100 PACKAGE C2S M2C EX DC GB,TRAIN_M708/02,SGT-100-1S,1997-01-21,1998-01-20,1996-01-01,Operating,SGT-100,IR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
A0B8/02,6079557,Iraq Mobile,A 2,A0B8/02,,N/A,SGT-400 PACKAGE,TRAIN_A0B8/02,SGT-400,2002-01-08,2003-01-08,2001-01-08,Scrapped,SGT-400,IQ,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
BC000153U03,6553206,TAP Greece,TAP Greece,BC000153U03,NC0447/03,NC0447/03,SGT-400 PKG (LCN NC0447) C3C M1S EX HC,TRAIN_BC000153U03,SGT-400,2021-03-30,2023-03-29,2016-02-01,Operating,SGT-400,GR,Preventive LTP – Includes RDS,LTP,I4-008,,COMPRESSOR
NR0107/03,6038191,Transportadora Associada de Gás S/A - TAG - Ecomp de Piuma,SITI - GASENE,NR0107/03,HI6017/03,HI6017 1550017,SGT-200 PACKAGE C1S M2S E2S GB,TRAIN_NR0107/03,SGT-200-2S,2010-09-30,2011-09-29,2007-05-01,Not Running – Not Preserved,SGT-200,BR,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
A2A1/02,6055781,Single Buoy Moorings - FPSO Falcon,SBM Falcon,A2A1/02,,N/A,SGT-400 PACKAGE <C GB,TRAIN_A2A1/02,SGT-400,2002-01-08,2003-01-08,2001-01-08,Not Running – Not Preserved,SGT-400,SG,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
R0E4/02,6079563,QatarEnergy - Dukhan NGL4,Dukhan Arab D 2,R0E4/02,,N/A,SGT-200-2S PACKAGE <C M2C E2S DC 2021,TRAIN_R0E4/02,SGT-200-2S,2001-12-01,2003-12-30,2000-12-01,Operating,SGT-200,QA,Call Off Contract Goods and Services,ADHOC,I4-006,30,COMPRESSOR
BC000072U01,6541054,GAZ-SYSTEM S.A. - Compressor station Jeleniów II,Gazociagów,BC000072U01,NU0391/01,NU0391/01,SGT-100-2S PKG(LCN NU0391)C1S M1C E2S DS,TRAIN_BC000072U01,SGT-100-2S,2016-10-19,2018-10-18,2014-02-28,Operating,SGT-100,PL,Call off Agreement / Support LTP,ADHOC,I4-003,30,COMPRESSOR
HI6030/02RT,6104651,ONGC B&S B193 Platform,ONGC - SIME DARBY - B193,HI6030/02 RT,NR0225/02,NR0225/02,SGT-200(LCN NR0225)C3S M2C E2S DC GB,TRAIN_HI6030/02RT,SGT-200-2S,2014-01-31,2015-01-30,2010-08-01,Operating,SGT-200,IN,No Contract or Agreement,ADHOC,I4-006,,COMPRESSOR
S749/01,6039006,Metor S.A. - Complejo Jose - Planta de Metanol,"Metor, Jose",S749/01,,N/A,SGT-200 PACKAGE GB,TRAIN_S749/01,SGT-200,1998-05-05,1999-05-04,1996-02-01,Not Running – Preserved,SGT-200,VE,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M0A7/02,6044325,Caspian Pipeline Consortium (CPC-K) - Atyrau,CPC Atyrau,M0A7/02,,N/A,SGT-100 PACKAGE C3S M2C E1C DC GB,TRAIN_M0A7/02,SGT-100-1S,2000-03-08,2001-03-08,1999-03-08,Not Running – Not Preserved,SGT-100,KZ,Frame Agreement – Includes RDS,ADHOC,I4-002,10,GENERATOR
M0J1/03,6142075,Pars Oil & Gas - South Pars Imd,South Pars Phase1,M0J1/03,M0J1/03,N/A,SGT-100 PACKAGE CX M1C E1C DS GB,TRAIN_M0J1/03,SGT-100-1S,2002-01-01,2004-06-28,2001-06-01,Operating,SGT-100,IR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
HI6041/03,6145222,TGP2 KP127,Kp-127,HI6041/03,NC0296/03,NC0296/03,SGT-400 (LCN NC0296) C3S M2C EX DC GB,TRAIN_HI6041/03,SGT-400,2016-05-04,2018-05-03,2012-01-01,Operating,SGT-400,PE,Preventive LTP – Includes RDS,LTP,I4-008,30,COMPRESSOR
BD000129U04,6362831,Nizhnevolzhskneft Ltd./Filanovsky Field,Filanovsky LSP-4,BD000129U04,NA0335/04,NA0335/04,SGT-400(NA0335) C3C M2S EX GB HC,TRAIN_BD000129U04,SGT-400,2017-04-30,2021-05-30,2013-01-31,Operating,SGT-400,RU,Preventive LTP,LTP,I4-008,10,GENERATOR
S460/01,6046947,KRONOSPAN GmbH - Steinheim,Kronospan Sandebeck,S460/01,9080/147,N/A,SGT-200 PACKAGE,TRAIN_S460/01,SGT-200-1S,1995-01-01,1995-11-17,1994-01-01,Scrapped,SGT-200,DE,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NA0241/01,6059529,"SEAT, S.A. -Martorell - Seat II",Martorell SEAT,NA0241/01,,N/A,SGT-400 PACKAGE C3C M2C E2S DC GB HC,TRAIN_NA0241/01,SGT-400,2012-01-13,2013-01-12,2010-12-01,Operating,SGT-400,ES,Corrective LTP – Includes RDS,LTP,I4-008,10,GENERATOR
R3D8/15,6104697,Dummy Account: Middle East,OZ2 PIPELINE SAHARA TO ARZEW,R3D8/15,,,,,SGT-200-2S,2003-01-01,2004-01-01,2002-01-01,Not Running – Not Preserved,SGT-200,AF,No Contract or Agreement,ADHOC,,,
BD001171U02,6701794,New Fortress Energy - Gulf of Mexico (Mex),FAST LNG PHASE 4,BD001171U02,NA0573/02,,,,SGT-400,2025-06-01,2026-05-30,2022-09-01,Order,SGT-400,MX,No Contract or Agreement,ADHOC,,,
NW0132/01,6050952,Tema-Ghana Tema Power,Ghana Tema Power,NW0132/01,,,,,SGT-300,2011-12-01,2012-11-30,2010-01-01,Cancelled,SGT-300,GH,No Contract or Agreement,ADHOC,,,
M545/01,6141672,Dummy Account: Asia Pacific - Japan,Sumitomo Rubber,M545/01,,,,,SGT-100-1S,2006-01-01,2007-07-01,1994-03-01,Cancelled,SGT-100,JP,No Contract or Agreement,ADHOC,,,
BD001171U03,6701795,New Fortress Energy - Gulf of Mexico (Mex),FAST LNG PHASE 4,BD001171U03,NA0573/03,,,,SGT-400,2025-06-01,2026-05-30,2022-09-01,Order,SGT-400,MX,No Contract or Agreement,ADHOC,,,
W8F0/01,6108661,"Dummy Account: Middle East - Iran, Islamic Republic of",Salman,W8F0/01,,,,,Tempest - RW,1998-09-01,1999-09-01,1997-09-01,Cancelled,SGT-300,IR,No Contract or Agreement,ADHOC,,,
BD001194U03,6704651,New Fortress Energy - Gulf of Mexico (US),FAST LNG PHASE 3,BD001194U03,NA0577/03,,,,SGT-400,,,2022-12-01,Order,SGT-400,US,No Contract or Agreement,ADHOC,,,
R8A4/02,6138016,National Iranian Oil Company (NIOC),Sirri Island,R8A4/02,,N/A,SGT-200-2S PACKAGE M2C DC 2005,TRAIN_R8A4/02,SGT-200-2S,1999-06-03,2000-06-03,1998-06-03,Operating,SGT-200,IR,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
BF000113U01,6675019,ENI - Petrobel _ Zohr field,Zohr,BF000113U01,NA0510/01,NA0510/01,SGT-400 PKG (LCN-NA0510) C3C M1S GB HC,TRAIN_BF000113U01,SGT-400,2019-05-22,2021-05-21,2017-09-30,Operating,SGT-400,EG,Corrective LTP,LTP,I4-008,,GENERATOR
M465/01,6054511,Vietsovpetro (VSP),Vietsovpetro (VSP),M465/01,,N/A,SGT-100 PACKAGE C2S M2C EX DC GB,TRAIN_M465/01,SGT-100-1S,2015-01-01,2015-01-01,2015-01-01,Operating,SGT-100,VN,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
BF000087U02,6662741,Bahrain National Gas Company (Banagas),Banagas,BF000087U02,NC0461/02,NC0461/02,SGT-400 (LCN NC0461) C3S M1S EX,TRAIN_BF000087U02,SGT-400,2018-10-11,2019-10-10,2016-06-30,Operating,SGT-400,BH,Preventive LTP,LTP,I4-008,,COMPRESSOR
NS0032/01,6054427,Shanxi Golden Group,Shanxi Golden Group,NS0032/01,,N/A,SGT-200 PACKAGE - ORDER CANCELLED,TRAIN_NS0032/01,SGT-200-1S,2006-08-11,2008-02-11,2005-08-11,Cancelled,SGT-200,CN,No Contract or Agreement,ADHOC,I4-005,,GENERATOR
M220/01,6141688,Dummy Account: Asia Pacific - Japan,"Toyota, Kyushu",M220/01,,N/A,SGT-100 PACKAGE - SCRAPPED,TRAIN_M220/01,SGT-100-1S,1994-01-01,1995-01-01,1992-01-01,Scrapped,SGT-100,JP,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S939/01,6032866,BP - Miller Platform,Miller Platform,S939/01,,N/A,SGT-200-2S PACKAGE,TRAIN_S939/01,SGT-200-1S,1989-12-31,1990-12-30,1989-01-01,Scrapped,SGT-200,GB,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
R134/01,6079566,Qatar Petroleum - Dukhan Jaleha,Jaleha,R134/01,,N/A,SGT-200-2S PACKAGE C3S M2C E1C DC,TRAIN_R134/01,SGT-200-2S,1997-02-01,1998-09-15,1996-02-01,Operating,SGT-200,QA,Call Off Contract Goods and Services,ADHOC,I4-006,30,COMPRESSOR
NW0042/01,6044538,Sappi Italy Operations S.p.A. - Condino Sappi Europe,Condino,NW0042/01,NW0042/01,N/A,SGT-300 C3C M2C E1C GB DC HC,TRAIN_NW0042/01,SGT-300,2005-06-28,2006-03-24,2004-02-01,Operating,SGT-300,IT,Corrective LTP – Includes RDS,LTP,I4-021,10,GENERATOR
BD000286U03,6552736,Total E&P UK - Culzean Platform,Culzean Power Gen C,BD000286U03,NW0442/03,NW0442/03,SGT-300 (LCN NW0442) C3S M1S EX,TRAIN_BD000286U03,SGT-300,2019-07-25,2021-07-24,2015-09-07,Operating,SGT-300,GB,Call off Agreement / Support LTP,ADHOC,I4-021,30,GENERATOR
U7B0/01,6083389,First Gas - Rotowaro,Vector Rotowaro,U7B0/01,,N/A,SGT-100-2S PACKAGE C2S M2C EX DC GB,TRAIN_U7B0/01,SGT-100-2S,1997-12-17,1998-12-16,1997-01-31,Operating,SGT-100,NZ,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
HI6064/01,6553355,"Esentia Pipeline de La Laguna, S. de R.L. de C.V",La Laguna,HI6064/01,NC0468/01,NC0468/01,SGT-400 PKG (LCN NC0468) C3S M2C EX DC,TRAIN_HI6064/01,SGT-400,2021-04-01,2025-03-30,2016-06-30,Operating,SGT-400,MX,Preventive LTP – Includes RDS,LTP,I4-008,,COMPRESSOR
HI4083/01,6541051,Cenovus Energy Inc. - St. John's,Husky,HI4083/01,NA0403/01,NA0403/01,SGT-400 PACKAGE (EX LCN NA0403)CX M1S GB,TRAIN_HI4083/01,SGT-400,2024-06-01,2025-05-30,2014-06-30,Installation & Commissioning,SGT-400,CA,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
M1A7/01,6049285,Sakhalin Energy Investment Company Ltd. - Molikpaq,Sakhalin Island,M1A7/01,,N/A,SGT-100 - ETHOS ENERGY C2S M1C E1C DS GB,TRAIN_M1A7/01,SGT-100-1S,2002-04-28,2004-05-29,2001-01-26,Operating,SGT-100,RU,Framework Agreement Goods and Services,ADHOC,I4-002,10,GENERATOR
HI6053/03,6459628,Akkas Gas Field - KOGAS B.A,Akkas Gas Field,HI6053/03,NU0388/03,NU0388/03,SGT-100-2S PKG(LCN SCOPE NU0388),TRAIN_HI6053/03,SGT-100-2S,2022-12-31,,2014-01-31,Storage,SGT-100,IQ,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
M2B3/01,6044059,Beta Glass - Agbara Plant,Beta Glass - Agbara,M2B3/01,,N/A,SGT-100 PACKAGE C2S M1C E1C DS GB,TRAIN_M2B3/01,SGT-100-1S,2003-10-14,2004-10-14,2002-10-14,Not Running – Preserved,SGT-100,NG,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NJ0550/01,6692141,Centrax Ltd.,Centrax 023 (Carmignano),NJ0550/01,BD000895U01,BD000895,SGT-300-2S PACKAGE CX MX EX DX,TRAIN_NJ0550/01,SGT-300-2S,2021-11-30,2022-11-29,2020-07-01,Operating,SGT-300,GB,Corrective LTP – Includes RDS,LTP,I4-022,,GENERATOR
HI4092/01,6552922,LEPRINO FOODS COMPANY,Leprino foods,HI4092/01,NW0492/01 / BD000479U01,NW0492/01,SGT-300 (LCN NW0492) C3S M2C EX DC HP,TRAIN_HI4092/01,SGT-300,2018-12-01,2019-11-30,2017-04-30,Operating,SGT-300,US,Corrective LTP – Includes RDS,LTP,I4-021,,GENERATOR
NC0021/06,6039151,PDVSA - Santa Rosa - Planta de Gas Anaco,"Anaco, Santa Rosa",NC0021/06,NC0021/06,HI6006 1550006,SGT-400 (HOU HI6006) <C M2C E1C DC GB,TRAIN_NC0021/06,SGT-400,,,2005-06-01,Not Running – Not Preserved,SGT-400,VE,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
NW0094/02,6055459,"Petronet LNG Ltd., Dahej",Dahej Port Lng 5,NW0094/02,,N/A,SGT-300 PACKAGE C2S M1C E1C DS GB,TRAIN_NW0094/02,SGT-300,2015-09-04,2015-04-08,2015-01-01,Operating,SGT-300,IN,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
BD000191U02,6490648,Groupement TouatGaz (GTG),Touat Gas,BD000191U02,NA0384/02,NA0384/02,SGT-400 C3C PKG(Lincoln Scope NA0384) GB,TRAIN_BD000191U02,SGT-400,2019-10-14,2020-10-13,2014-02-28,Operating,SGT-400,DZ,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
W2A4/02,6055512,PHP_Power Generation Plant Ltd.,PHP Cold Rolling Mills,W2A4/02,,N/A,SGT-300 C3C M2C E2S DC GB HC,TRAIN_W2A4/02,SGT-300,2001-09-19,2015-12-12,2015-03-01,Operating,SGT-300,BD,Corrective LTP – Includes RDS,LTP,I4-021,10,GENERATOR
R209/02,6032815,GlaxoSmithKline (GSK) - Glaxo Wellcome,Glaxo Wellcome 2,R209/02,,N/A,SGT-200 PACKAGE GB,TRAIN_R209/02,SGT-200-1S,1988-01-01,1988-12-30,1987-01-01,Scrapped,SGT-200,GB,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
R331/02,6056169,Energy Prima Ltd.,Battle Creek Michigan,R331/02,,N/A,SGT-200 PACKAGE -  SCRAPPED,TRAIN_R331/02,SGT-200-1S,1988-01-01,2008-11-30,1987-01-01,Scrapped,SGT-200,BD,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NC0029/03,6054944,ONGC B&S BCPB2 Platform,Oil and Natural Gas Corporatio,NC0029/03,,N/A,SGT-400 PACKAGE C3S M2C E2S DC GB,TRAIN_NC0029/03,SGT-400,2008-02-29,2009-02-28,2006-05-01,Operating,SGT-400,IN,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
NM0290/01,6156037,Aeroport Pulkovo OAO,Pulkovo Airport,NM0290/01,,N/A,SGT-100 PACKAGE C3S M2C E1C DC GB,TRAIN_NM0290/01,SGT-100-1S,2015-03-06,2017-03-05,2011-12-31,Operating,SGT-100,RU,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M656/01,6142065,National Iranian Oil Company (NIOC) HQ,Aboozar Platform,M656/01,,N/A,SGT-100 PACKAGE C2S M2C EX DC GB,TRAIN_M656/01,SGT-100-1S,1999-01-01,1999-09-27,1996-01-01,Operating,SGT-100,IR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NC0022/01,6052083,GASCADE Gastransport GmbH; VS Lippe - Bad Salzuflen,Wingas Lippe GT2,NC0022/01,,N/A,SGT-400 PACKAGE C3C M2C E2S DC GB HC,TRAIN_NC0022/01,SGT-400,2006-10-16,2009-10-13,2005-06-01,Operating,SGT-400,DE,Other and 24/7 Global Helpdesk and/or RDS included,ADHOC,I4-008,30,COMPRESSOR
M606/01,6055068,"Lintec, Kumagaya",Lintec # 1,M606/01,,N/A,SGT-100 PACKAGE,TRAIN_M606/01,SGT-100-1S,1996-01-01,1996-12-01,1995-06-01,Scrapped,SGT-100,JP,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NR0089/01,6038182,Petróleo Brasileiro S/A - Petrobras - G&E/UN-APGN/UTGC (Fase II),Cacimbas,NR0089/01,HI6012 - 1550012,HI6012 1550012,SGT-200 PACKAGE M1C E1C DS GB 2007,TRAIN_NR0089/01,SGT-200-2S,2008-07-01,2009-06-30,2006-06-01,Operating,SGT-200,BR,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
M527/01,6541715,Dummy Account: Asia Pacific - India,,M527/01,,N/A,SGT-100 PACKAGE - SKID EMPTY,TRAIN_M527/01,SGT-100,1997-05-01,1998-05-01,1995-05-01,Scrapped,SGT-100,IN,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M556/01,6032752,INEOS - Newton Aycliffe,Inovyn,M556/01,,N/A,SGT-100 PACKAGE C3S M2C E1C DC GB HP,TRAIN_M556/01,SGT-100-1S,1995-02-08,1996-02-08,1994-02-08,Operating,SGT-100,GB,Corrective LTP,LTP,I4-002,10,GENERATOR
NW0560/01,6695200,"Hangzhou Turbine Power Group Co., LTD (HTC)",HTC HAINING,NW0560/01,BD001005U01,BD001005,SGT-300 PACKAGE CX MX EX DX,TRAIN_NW0560/01,SGT-300-1S,2024-03-01,2025-08-30,2021-04-01,Installation & Commissioning,SGT-300,CN,,,I4-021,,GENERATOR
NW0494/01,6666274,"AECC Gas Turbine (Zhuzhou) Co., Ltd.  (ZNGT)",ZNGT Lenzing,NW0494/01,BD000492U01,BD000492,SGT-300 PACKAGE,TRAIN_NW0494/01,SGT-300-1S,2023-12-01,2024-11-29,2021-07-01,Order,SGT-300,CN,No Contract or Agreement,ADHOC,I4-021,,GENERATOR
NC0022/02,6052084,GASCADE Gastransport GmbH; VS Lippe - Bad Salzuflen,Wingas Lippe GT1,NC0022/02,,N/A,SGT-400 PACKAGE C3S M2C E2S DC GB,TRAIN_NC0022/02,SGT-400,2006-10-16,2009-10-13,2005-06-01,Operating,SGT-400,DE,Other and 24/7 Global Helpdesk and/or RDS included,ADHOC,I4-008,30,COMPRESSOR
M8C7/01,6045142,Total E&P -  OML58 (Obite / Obagi),Obite TGC,M8C7/01,,N/A,SGT-100 PACKAGE <C EX GB,TRAIN_M8C7/01,SGT-100-1S,1998-01-01,1999-02-21,1997-03-01,Operating,SGT-100,NG,Resident Engineer,ADHOC,I4-002,10,GENERATOR
BC000652U03,6700707,New Fortress Energy - Gulf of Mexico (Mex),FAST LNG II,BD001117U03,NA0567/03,BD001117U03 NA0567/03,SGT-400 PACKAGE (LCN NA0567),TRAIN_BC000652U03,SGT-400,2025-11-01,2026-10-30,2022-06-30,Order,SGT-400,MX,No Contract or Agreement,ADHOC,I4-008,,
M142/01,6045163,Ringkøbing Fjernvarmeværk,RINGKOBING Fjernvarmeværk,M142/01,,N/A,SGT-100 PACKAGE - SKID EMPTY GB,TRAIN_M142/01,SGT-100-1S,1992-01-01,1993-01-01,1991-01-01,Not Running – Not Preserved,SGT-100,DK,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M309/05,6079812,Bumi Armada Claire FPSO,Griffin Field FPSO,M309/05,,N/A,SGT-100 PACKAGE C2S GB,TRAIN_M309/05,SGT-100-1S,1992-12-31,2014-07-30,1991-12-31,Not Running – Not Preserved,SGT-100,MY,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NW0494/02,6666275,"AECC Gas Turbine (Zhuzhou) Co., Ltd.  (ZNGT)",ZNGT Lenzing,NW0494/02,BD000492U02,BD000492,SGT-300 PACKAGE,TRAIN_NW0494/02,SGT-300-1S,2023-12-01,2024-11-29,2021-07-01,Order,SGT-300,CN,No Contract or Agreement,ADHOC,I4-021,,GENERATOR
U706/03,6138021,National Iranian Oil Refining & Product Distribution Company (NIORDC),Sabzab,U706/03,,N/A,SGT-100-2S PACKAGE C2S M1S EX GB,TRAIN_U706/03,SGT-100-2S,1998-08-24,1999-08-24,1997-04-01,Operating,SGT-100,IR,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
HI6096/03,6690208,PETRONAS Carigali Sdn Bhd - Sarawak Operations - E11P-B PLATFORM,Petronas Kasawari,HI6096/03,NJ0552/03 - BD000913U03,NJ0552/03 BD000913,SGT-300-2S PACKAGE (NJ0552) CX,TRAIN_HI6096/03,SGT-300-2S,2024-02-01,2027-01-30,2020-03-01,Installation & Commissioning,SGT-300,MY,,,I4-022,,COMPRESSOR
M0A8/02,6044328,Caspian Pipeline Consortium (CPC-R) - Komsomolsk,CPC Komsomolsk,M0A8/02,,N/A,SGT-100 C3S M2C E1C DC GB,TRAIN_M0A8/02,SGT-100-1S,2002-01-08,2003-01-08,2001-01-08,Operating,SGT-100,RU,Frame Agreement – Includes RDS,ADHOC,I4-002,10,GENERATOR
BD000191U03,6490649,Groupement TouatGaz (GTG),Touat Gas,BD000191U03,NA0384/03,NA0384/03,SGT-400 PKG(LCNScopeNA0384)C3S M1S EX GB,TRAIN_BD000191U03,SGT-400,2019-10-14,2020-10-13,2014-02-28,Operating,SGT-400,DZ,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
S0H5/01,6047260,Total E&P Danmark A/S - Gorm (new SGT business),Gorm F- Gen,S0H5/01,,N/A,SGT-200 PACKAGE M1C E1C DS GB,TRAIN_S0H5/01,SGT-200-1S,1990-01-01,2002-07-30,1989-01-01,Scrapped,SGT-200,DK,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
BF000056U08,6541176,Sonatrach - ZCINA,ZCINA,BF000056U08,NC0421/02,NC0421/02,SGT-400 PACKAGE C3C M2C E1C DC,TRAIN_BF000056U08,SGT-400,2023-10-01,2025-09-29,2015-01-01,Operating,SGT-400,DZ,No Contract or Agreement,ADHOC,I4-008,,COMPRESSOR
M112/01,6032793,Thames Water - Beckton Sewage Treatment Works,Beckton Sewage 1,M112/01,,N/A,SGT-100 PACKAGE - MOTHBALLED GB,TRAIN_M112/01,SGT-100-1S,1990-01-01,1991-01-01,1988-01-01,Scrapped,SGT-100,GB,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NC0033/08,6039096,PDVSA - Los Morros - ICO Los Morros,"ICO, Los Morros",NC0033/08,HI6011,HI6011 1550011,SGT-400 (HOU HI6011) C1S M2C E1C DC GB,TRAIN_NC0033/08,SGT-400,,,2006-03-01,Not Running – Not Preserved,SGT-400,VE,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
BD000799U02,6684622,Perenco UK SHARP,Perenco UK SHARP,BD000799U02,NU0534/02,NU0534/02,SGT-100-2S PACKAGE (NU0534/02) C3S,TRAIN_BD000799U02,SGT-100-2S,2023-03-12,2025-03-11,2019-07-31,Operating,SGT-100,GB,,,I4-003,,COMPRESSOR
M647/01,6038523,PEP-Cantarell Offshore-APC-Ek A,EK-A SMC (Offshore),M647/01,M647/01,N/A,SGT-100 PACKAGE C1C EX GB,TRAIN_M647/01,SGT-100-1S,1997-01-01,2020-06-22,1996-09-01,Operating,SGT-100,MX,Frame Agreement,ADHOC,I4-002,10,GENERATOR
BF000073U02,6553199,Total E&P UK - Culzean Platform,Culzean Export Train 2,BF000073U02,NC0444/02,NC0444/02,SGT-400 (LCN NC0444) C3C M1S EX HC,TRAIN_BF000073U02,SGT-400,2019-09-26,2021-09-25,2016-02-01,Operating,SGT-400,GB,Call off Agreement / Support LTP,ADHOC,I4-008,10,COMPRESSOR
M9C9/01,6054499,"Toyota Boushoku, Aichi","Toyota Boushoko. Ex Araco, Sanage",M9C9/01,,N/A,SGT-100 PACKAGE C2S M1C GB,TRAIN_M9C9/01,SGT-100-1S,2004-07-16,2005-07-16,1999-11-10,Scrapped,SGT-100,JP,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
80/800667,6046578,Freudenberg & Co. KG - Werk Weinheim,Freudenberg Weinheim,80/800667,80/800667,N/A,SGT-200 PACKAGE GB,TRAIN_80/800667,SGT-200-1S,1988-10-08,1989-10-08,1981-10-08,Not Running – Not Preserved,SGT-200,DE,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
R2G1/01,6045744,Storengy Gournay Sur Arond,"GRT Gaz, Gournay",R2G1/01,,N/A,SGT-200-2S C3S M2C E1C DC GB,TRAIN_R2G1/01,SGT-200-2S,2005-01-01,2005-11-13,2001-12-10,Operating,SGT-200,FR,Preventive LTP,LTP,I4-006,30,COMPRESSOR
NA0103/01,6043237,AHLSTROM-MUNKSJO ITALIA S.p.A.,Ahlstrom Mathi,NA0103/01,NA0103/01,N/A,SGT-400 C3S M2C E2S DC GB,TRAIN_NA0103/01,SGT-400,2008-05-01,2009-04-30,2007-05-01,Not Running – Not Preserved,SGT-400,IT,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
M648/01,6038524,PEP-Cantarell Offshore-APC-Ek A,EK-A SMC (Offshore),M648/01,M648/01,N/A,SGT-100 C1C EX GB,TRAIN_M648/01,SGT-100-1S,1997-06-05,2020-11-22,1996-09-01,Operating,SGT-100,MX,Frame Agreement,ADHOC,I4-002,10,GENERATOR
BF000078U16,6673462,Bid Boland,Bid Boland,BF000078U05,,NC0495/01,SGT-400 PKG (Lincoln Scope NC0495) CX EX,TRAIN_BF000078U16,SGT-400,,,2017-07-31,Operating,SGT-400,IR,No Contract or Agreement,ADHOC,I4-008,,COMPRESSOR
M8D8/01,6047296,Marathon Oil-Malabo-Punta Europa Power Plant,Punta Europa Power Plant,M8D8/01,,N/A,SGT-100 PACKAGE C2S EX GB,TRAIN_M8D8/01,SGT-100-1S,1999-01-01,1999-04-29,1997-09-01,Not Running – Preserved,SGT-100,GQ,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NA0168/01,6056062,"ONGC MH, ICP-R Platform",MHS - ICP-R (Afcons - Gunanusa Joint Venture),NA0168/01,,N/A,SGT-400 PACKAGE CX M2C E2S DC GB,TRAIN_NA0168/01,SGT-400,2012-10-28,2013-10-07,2009-05-01,Operating,SGT-400,IN,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
M9D7/01,6032717,Arbre Energy Eggborough  - Schelde Engineers,Arbre Energy Eggborough,M9D7/01,,N/A,SGT-100 PACKAGE - DEACTIVATED,TRAIN_M9D7/01,SGT-100-1S,1999-10-01,2001-03-23,1998-12-01,Scrapped,SGT-100,GB,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
BF000089U02,6662750,ENI - Petrobel _ Zohr field,Zohr,BF000089U02,NA0472/02,NA0472/02,SGT-400 (NA0472) C3C M2C E1C DC HC,TRAIN_BF000089U02,SGT-400,2018-05-20,2019-05-19,2016-09-30,Operating,SGT-400,EG,Corrective LTP – Includes RDS,LTP,I4-008,,GENERATOR
NA0221/01,6104696,Golden Sugar Refinery,Golden Sugar,NA0221/01,,N/A,SGT-400 PACKAGE C3S M2C E2S DC GB,TRAIN_NA0221/01,SGT-400,2013-02-09,2015-09-15,2010-08-01,Operating,SGT-400,NG,Other and 24/7 Global Helpdesk and/or RDS included,ADHOC,I4-008,10,GENERATOR
NM0165/01,6054516,"Otsuka Pharmaceuticalk, Tokushima",Otsuka Pharmaceutical Factory,NM0165/01,,N/A,SGT-100 PACKAGE C3C M2C E1C DC GB HC,TRAIN_NM0165/01,SGT-100-1S,2004-07-01,2005-07-01,2001-07-30,Operating,SGT-100,JP,Corrective LTP – Includes RDS,LTP,I4-002,10,GENERATOR
NU0212/01,6044311,Caspian Pipeline Consortium (CPC-K) - Atyrau,CPC Atyrau,NU0212/01,,N/A,SGT-100-2S C1S M2C E1C DC GB,TRAIN_NU0212/01,SGT-100-2S,2015-06-30,2017-05-30,2010-05-01,Operating,SGT-100,KZ,Frame Agreement – Includes RDS,ADHOC,I4-003,30,COMPRESSOR
R940/01,6050057,Avebe Dobbestroom,Ter Apelkanal 2,R940/01,,N/A,SGT-200 PACKAGE M2C DC EX 2019,TRAIN_R940/01,SGT-200-2S,1990-01-01,1990-10-01,1989-01-01,Operating,SGT-200,NL,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NA0260/01,6157655,Ethos Energy Group Limited,Marubeni Utility Services,NA0260/01,,N/A,SGT-400 PACKAGE <C M1C E1C GB,TRAIN_NA0260/01,SGT-400,2005-07-01,2006-07-01,2000-09-30,Not Running – Not Preserved,SGT-400,GB,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
M108/01,6137998,ACEITES Y PROTEINAS ACEPROSA,Incomisa,M108/01,,N/A,SGT-100 PACKAGE - SITE SHUT,TRAIN_M108/01,SGT-100-1S,1991-06-04,1992-06-04,1990-04-01,Scrapped,SGT-100,ES,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NC0109/01,6146084,Sasol Komatipoort,Republic of Mozambique Pipeline,NC0109/01,,N/A,SGT-400 C3C M2C E2S DC GB HC,TRAIN_NC0109/01,SGT-400,2010-05-27,2011-11-26,2008-01-01,Operating,SGT-400,ZA,Corrective LTP,LTP,I4-008,30,COMPRESSOR
NS0451/03,6553252,BP West Nile Delta,BP West Nile Delta,NS0451/03,BD000321U03,N/A,SGT-200 PACKAGE C3S M2C E2S DC GB,TRAIN_NS0451/03,SGT-200-1S,2019-07-20,2021-07-19,2016-02-28,Operating,SGT-200,EG,Preventive LTP – Includes RDS,LTP,I4-005,,GENERATOR
M0F7/02,6032731,Star Energy - Humbly Grove,Humbly Grove 3,M0F7/02,,N/A,SGT-100 PACKAGE C2S M1C E1C DS GB,TRAIN_M0F7/02,SGT-100-1S,2001-01-01,2003-09-01,2000-06-01,Operating,SGT-100,GB,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NC0021/04,6039149,PDVSA Gas - Maturin - Proyecto Jusepin 200,"Anaco, Santa Rosa",NC0021/04,NC0021/04,HI6006 1550006,SGT-400 (HOU HI6006) <C M2C E1C DC GB,TRAIN_NC0021/04,SGT-400,2018-08-17,2019-08-16,2005-06-01,Not Running – Preserved,SGT-400,VE,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
S145/01,6050075,Silo Rothensee GmbH & Co. KG Standort Hamburg-Hamb,ADM Hamburg GT1,S145/01,,N/A,SGT-200 PACKAGE,TRAIN_S145/01,SGT-200-1S,1992-01-01,1993-09-17,1991-01-01,Scrapped,SGT-200,DE,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
A0A4/02,6079538,NUVOIL S.A. DE C.V.,BIEP Bulwer Island,A0A4/02,,N/A,SGT-400 PACKAGE C1C GB,TRAIN_A0A4/02,SGT-400,2015-08-10,2015-10-08,1999-04-26,Storage,SGT-400,MX,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
NC0033/04,6039010,PDVSA - Altagracia - ICO Altagracia,"ICO, Alta Gracia",NC0033/04,,HI6011 1550011,SGT-400 (HOU HI6011) C1C M2C E1C DC GB,TRAIN_NC0033/04,SGT-400,2013-12-01,2015-02-28,2006-03-01,Not Running – Preserved,SGT-400,VE,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
M0H9/01,6046154,ICI Pakistan Limited,ICI Soda Ash,M0H9/01,,N/A,SGT-100 PACKAGE C2S GB,TRAIN_M0H9/01,SGT-100-1S,2002-01-01,2004-05-29,2001-09-01,Not Running – Not Preserved,SGT-100,PK,RDS,ADHOC,I4-002,10,GENERATOR
M371/01,6046027,Heineken-St. Petersburg-Heineken,Heineken,M371/01,,N/A,SGT-100 PACKAGE CS2 GB,TRAIN_M371/01,SGT-100-1S,1994-01-01,1994-12-22,1993-06-01,Scrapped,SGT-100,RU,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NC0032/04,6133282,ICOFC,NAR Compressor Station,NC0032/04,,N/A,SGT-400 PACKAGE C3S M2C E1C DC GB,TRAIN_NC0032/04,SGT-400,2009-12-08,2010-05-30,2006-04-01,Operating,SGT-400,IR,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
HI6030/03RW,6104650,ONGC B&S B193 Platform,ONGC - SIME DARBY - B193,HI6030/03RW,NW0223/03,NW0223/03,SGT-300(LCN NW0223) C3S M2C E2S DC,TRAIN_HI6030/03RW,SGT-300,2012-06-01,2013-05-31,2010-08-01,Operating,SGT-300,IN,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
NA0222/02,6104694,Bhogat (Cairn Energy),Bhogat (Cairn Energy),NA0222/02,,N/A,SGT-400 PACKAGE C3S M2C E1C DC GB,TRAIN_NA0222/02,SGT-400,2014-08-19,2015-08-18,2010-07-01,Operating,SGT-400,IN,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
M8B0/02,6047985,OGDCL - Uch Gas,Uch Gas Field 2,M8B0/02,,N/A,SGT-100 PACKAGE C2S M2C E1C DC GB,TRAIN_M8B0/02,SGT-100-1S,1998-01-01,1999-02-17,1997-08-01,Operating,SGT-100,PK,Framework Agreement Goods and Services,ADHOC,I4-002,10,GENERATOR
BF000056U07,6541175,Sonatrach - ZCINA,ZCINA,BF000056U07,NC0421/01,NC0421/01,SGT-400 PACKAGE C3C M2C E1C DC,TRAIN_BF000056U07,SGT-400,2023-10-01,2025-09-29,2015-01-01,Operating,SGT-400,DZ,No Contract or Agreement,ADHOC,I4-008,,COMPRESSOR
NA0370/01,6436509,Centrax Ltd.,Centrax 003 (FICOBEL - Lanxess),NA0370/01,NA0370/01,N/A,SGT-400 PACKAGE C3S GB,TRAIN_NA0370/01,SGT-400,2014-11-24,2015-11-23,2013-11-30,Operating,SGT-400,GB,Corrective LTP – Includes RDS,LTP,I4-008,10,GENERATOR
W8J6/01,6046988,Kuwait Oil Company - Ahmadi,Daelim,W8J6/01,,N/A,SGT-300 PACKAGE C2C M2C DC GB,TRAIN_W8J6/01,SGT-300,1999-02-01,2004-02-09,1998-02-01,Operating,SGT-300,KW,Call Off Contract Goods and Services,ADHOC,I4-021,10,GENERATOR
M8B1/01,6047379,Michelin-Avrille-Michelin La Roche,Michelin La Roche,M8B1/01,,N/A,SGT-100 PACKAGE - MOTHBALLED,TRAIN_M8B1/01,SGT-100-1S,1998-01-01,1998-10-10,1997-07-01,Scrapped,SGT-100,FR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NU0570/01,6701789,Oleoductos del Valle - Oldelval - Vivaldi,Oldelval Vivaldi,NU0570/01,BD001155U01,BD001155,SGT-100-2S PACKAGE (BD001155),TRAIN_NU0570/01,SGT-100-2S,2024-10-01,2025-09-29,2022-09-01,Order,SGT-100,AR,,,I4-003,,
NC0018/01,6055773,Total E&P Myanmar,Total E&P,NC0018/01,,N/A,SGT-400 C3S M2C E2S DC GB,TRAIN_NC0018/01,SGT-400,2015-06-03,2015-03-05,2015-03-01,Operating,SGT-400,MM,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
NR0220/02,6104653,MEGAL Verdichterstation Wildenranna,MEGAL GmbH Co,NR0220/02,,N/A,SGT-200 PACKAGE CX M1C E1C DS GB,TRAIN_NR0220/02,SGT-200-2S,2016-03-31,2019-03-30,2010-07-01,Operating,SGT-200,DE,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
HI4066/05,6039045,PDVSA - Div Faja - Unidades Moviles Div Faja,PDVSA Mobile Power,HI4066/05,NA0209/05,NA0209/05 HI4057,SGT-400 PACKAGE (NA0209) CANCELLED,TRAIN_HI4066/05,SGT-400,,,2010-03-10,Cancelled,SGT-400,VE,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
S632/01,6032929,MAERSK Oil N.Sea UK - NSP Platform (Decommissioned),McCulloch 3,S632/01,,N/A,SGT-200 PACKAGE GB,TRAIN_S632/01,SGT-200-1S,1996-08-10,1997-08-09,1995-01-01,Scrapped,SGT-200,GB,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NS0121/03,6046463,Donau Chem,ZNGT Romania,NS0121/03,,N/A,SGT-200 PACKAGE,TRAIN_NS0121/03,SGT-200-1S DLE,2013-01-14,2014-01-13,2007-12-01,Not Running – Not Preserved,SGT-200,RO,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M2F5/01,6042948,ABB HIGH VOLTAGE TECHNOLOGY-GUNORTA GAMYSHLYDJA-AB,ABB High Voltage Technolgy,M2F5/01,NM0057/01,N/A,SGT-100 PACKAGE C2S M1C E1C DS,TRAIN_M2F5/01,SGT-100-1S,2001-11-15,2004-04-28,2001-10-12,Not Running – Not Preserved,SGT-100,TM,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S632/02,6032930,MAERSK Oil N.Sea UK - NSP Platform (Decommissioned),McCulloch 4,S632/02,,N/A,SGT-200 PACKAGE GB,TRAIN_S632/02,SGT-200-1S,1996-08-15,1997-08-14,1995-12-01,Scrapped,SGT-200,GB,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NM0076/01,6054814,"Nitto Denko, Onomichi","Nitto Denko, Onomichi",NM0076/01,,N/A,SGT-100 PACKAGE C2S GB,TRAIN_NM0076/01,SGT-100-1S,2004-05-21,2006-08-04,2004-04-01,Not Running – Not Preserved,SGT-100,JP,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S0H2/01,6055133,"Seiko Epson, Kofu","Seiko Epson, Suwa Minami",S0H2/01,,N/A,SGT-200 PACKAGE - SCRAPPED,TRAIN_S0H2/01,SGT-200-1S,2002-03-22,2003-03-22,2001-03-22,Scrapped,SGT-200,JP,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NC0033/09,6039097,PDVSA - Los Morros - ICO Los Morros,"ICO, Los Morros",NC0033/09,HI6011,HI6011 1550011,SGT-400 (HOU HI6011) M2C E1C DC GB,TRAIN_NC0033/09,SGT-400,,,2006-03-01,Not Running – Not Preserved,SGT-400,VE,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
NC0021/01,6039018,PDVSA Gas - Maturin - Proyecto Jusepin 200,Anaco Santa Rosa,NC0021/01,,HI6006 1550006,SGT-400 (HOU HI6006) C1S M2C E1C DC GB,TRAIN_NC0021/01,SGT-400,2018-06-30,2019-06-30,2005-06-17,Not Running – Preserved,SGT-400,VE,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
NC0109/02,6146081,Sasol Komatipoort,Republic of Mozambique Pipeline,NC0109/02,,N/A,SGT-400 C3S M2C E2S DC GB,TRAIN_NC0109/02,SGT-400,2010-05-27,2011-11-26,2008-01-01,Operating,SGT-400,ZA,Corrective LTP,LTP,I4-008,30,COMPRESSOR
U0A1/01,6044323,Caspian Pipeline Consortium (CPC-K) - Atyrau,CPC Atyrau,U0A1/01,,N/A,SGT-100-2S PACKAGE C3C M2C E1C DC GB HC,TRAIN_U0A1/01,SGT-100-2S,2000-04-23,2003-12-30,1999-04-23,Operating,SGT-100,KZ,Frame Agreement – Includes RDS,ADHOC,I4-003,30,COMPRESSOR
R131/01,6010352,TC Energy - CDN Mainline - Kirkwall Station 1301,Original Spare Core Engine,R131/01,9080/096,N/A,SGT-200-2S PACKAGE,TRAIN_R131/01,SGT-200-2S,1992-08-10,1993-08-09,1990-01-01,Storage,SGT-200,CA,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NW0154/01,6108654,Reshadat Sadra,Reshadat Sadra,NW0154/01,,N/A,SGT-300 PACKAGE GB,TRAIN_NW0154/01,SGT-300,,,2009-07-01,Storage,SGT-300,IR,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
BF000078U18,6673464,Bid Boland,Bid Boland,BF000078U18,,NU0496/01,SGT-100-2S PACKAGE(NU0496)CX EX GB,TRAIN_BF000078U18,SGT-100-2S,,,2017-07-31,Operating,SGT-100,IR,No Contract or Agreement,ADHOC,I4-003,,COMPRESSOR
NU0570/02,6701790,Oleoductos del Valle - Oldelval - Vivaldi,Oldelval Vivaldi,NU0570/02,BD001155U02,,,,SGT-100-2S,2024-10-01,2025-09-29,2022-09-01,Order,SGT-100,AR,,,,,
S2A5/01,6051103,Zarubezhneft–Production Kharyaga LLC,Kharyaga 2 #1 Thru 4,S2A5/01,,N/A,SGT-200 PACKAGE C3S M1C E2S DS GB,TRAIN_S2A5/01,SGT-200-1S,2002-01-01,2004-03-29,2001-02-01,Operating,SGT-200,RU,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
R934/02,6051038,Thyssengas GmbH - Hünxe,RWE Hunxe,R934/02,,N/A,SGT-200-2S PACKAGE,TRAIN_R934/02,SGT-200-2S,1990-01-01,1991-12-01,1989-01-01,Not Running – Not Preserved,SGT-200,DE,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
HI6037/07,6083361,GLNG - Roma-HCS-02,Roma 02,HI6037/07,NC0247/07,NC0247/07,SGT-400 (LCN NC0247) C3S M2C E2S DC GB,TRAIN_HI6037/07,SGT-400,2015-06-30,2016-10-29,2011-03-31,Operating,SGT-400,AU,Call off Agreement / Support LTP,ADHOC,I4-008,30,COMPRESSOR
S2H4/01,6050090,OPS Angola - FPSO Ngoma - Block 15,N'Goma FPSO,S2H4/01,,N/A,SGT-200 PACKAGE C3C M2C E1C DC GB HS,TRAIN_S2H4/01,SGT-200-1S,2003-01-01,2004-09-13,2001-12-01,Operating,SGT-200,AO,Preventive LTP – Includes RDS,LTP,I4-005,10,GENERATOR
M575/02,6047377,MICHELIN / Plant operated by E.ON Business Solutions S.r.l.,Michelin Tours,M575/02,,N/A,SGT-100 PACKAGE - SITE SHUT,TRAIN_M575/02,SGT-100-1S,1996-07-01,1996-08-19,1995-07-01,Scrapped,SGT-100,IT,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M653/01,6157835,Cofely - Royal Free Hospital,Royal Free Hospital,M653/01,,N/A,SGT-100 PACKAGE - DEACTIVATED,TRAIN_M653/01,SGT-100-1S,1996-01-01,1997-09-04,1995-01-01,Scrapped,SGT-100,GB,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S2A5/04,6051107,Zarubezhneft–Production Kharyaga LLC,Kharyaga,S2A5/04,,N/A,SGT-200 PACKAGE C2S M1C E2S DS GB,TRAIN_S2A5/04,SGT-200-1S,2003-03-31,2004-03-29,2001-04-01,Operating,SGT-200,RU,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M9L9/01,6044346,CEFLA - HERA S.p.A. (formerly FBM/BFENG) Bologna,Cefla - FBM,M9L9/01,,N/A,SGT-100 C2S M2C E1C DC GB HP,TRAIN_M9L9/01,SGT-100-1S,2000-01-01,2001-02-27,1999-01-01,Operating,SGT-100,IT,Corrective LTP – Includes RDS,LTP,I4-002,10,GENERATOR
M529/01,6037473,EP Petroecuador / Shushufindi Power Plant,Shushufindi,M529/01,,N/A,SGT-100 PACKAGE C3S M1S GB,TRAIN_M529/01,SGT-100-1S,1996-06-06,1997-06-06,1995-06-06,Not Running – Preserved,SGT-100,EC,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NU0028/01,6047533,MOL UGS-Hungary,MOL - Hajduszoboszlo,NU0028/01,,N/A,SGT-100-2S PACKAGE - DEACTIVATED GB,TRAIN_NU0028/01,SGT-100-2S,2009-03-27,2012-03-26,2005-12-01,Not Running – Not Preserved,SGT-100,HU,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
NA0143/02,6159816,Hayan Petroleum Company,Jihar Petrofac,NA0143/02,,N/A,SGT-400 PACKAGE <C M1C E1C DS GB,TRAIN_NA0143/02,SGT-400,2011-02-10,2012-08-09,2008-10-01,Not Running – Preserved,SGT-400,SY,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
HI6064/05,6553359,"Esentia Pipeline de Occidente, S. de R.L. de C.V - Villa de Reyes",Villa de Reyes,HI6064/05,NC0469/03,NC0469/03,SGT-400 PKG(LCN NC0469) C3S M2C EX DC GB,TRAIN_HI6064/05,SGT-400,2020-11-01,2024-10-30,2016-06-30,Operating,SGT-400,MX,Preventive LTP,LTP,I4-008,,COMPRESSOR
BD000363U01,6662756,"AECC Gas Turbine (Zhuzhou) Co., Ltd.  (ZNGT)",ZNGT Hengyun,BD000363U01,NA0466/01,NA0466/01,SGT-400 PKG (Lcn Scope NA0466) <C EX GB,TRAIN_BD000363U01,SGT-400,2018-12-04,2020-12-03,2016-08-31,Not Running – Not Preserved,SGT-400,CN,No Contract or Agreement,ADHOC,I4-008,,GENERATOR
U777/02,6038828,Oleoductos del Valle - Oldelval - Algarrobo,Algarrobo,U777/02,,N/A,SGT-100-2S PACKAGE <C M1S EX GB,TRAIN_U777/02,SGT-100-2S,1999-01-01,1999-10-10,1998-01-01,Operating,SGT-100,AR,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
BD000184U01,6436966,GHANA TEMA POWERVOLTA RIVER AUTHORITY,GHANA,BD000184U01,NA0378/01 (Unit 7),NA0378/01,SGT-400 PKG (LCN NA0378) C3S M2S EX GB,TRAIN_BD000184U01,SGT-400,2019-06-30,2020-05-30,2013-11-30,Operating,SGT-400,GH,No Contract or Agreement,ADHOC,I4-008,,GENERATOR
BD000191U01,6490647,Groupement TouatGaz (GTG),Touat Gas,BD000191U01,NA0384/01,NA0384/01,SGT-400 PKG(LCNScopeNA0384)C3S M1S EX GB,TRAIN_BD000191U01,SGT-400,2019-10-14,2020-10-13,2014-02-28,Operating,SGT-400,DZ,Frame Agreement,ADHOC,I4-008,10,GENERATOR
NR0089/02,6038183,Petróleo Brasileiro S/A - Petrobras - G&E/UN-APGN/UTGC (Fase II),Cacimbas,NR0089/02,HI6012 - 1550012,HI6012 1550012,SGT-200 PACKAGE M1C E1C DS GB 2007,TRAIN_NR0089/02,SGT-200-2S,2008-07-01,2009-06-30,2006-06-01,Operating,SGT-200,BR,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
M8B8/01,6054365,Weizhou Terminal  - CNOOC China Ltd.,Weizhou Oil Field,M8B8/01,,N/A,SGT-100 PACKAGE C2S EX GB,TRAIN_M8B8/01,SGT-100-1S,1997-12-21,1998-12-20,1997-09-01,Operating,SGT-100,CN,Framework Agreement Goods and Services,ADHOC,I4-002,10,GENERATOR
M201/01,6048921,Radici Yarn-Canapale (Pistoia)-Radicifil (Texpotre,Radicifil (Texpotre) PT,M201/01,,N/A,SGT-100 PACKAGE - MOTHBALLED GB,TRAIN_M201/01,SGT-100-1S,1992-05-01,1993-05-01,1991-05-01,Scrapped,SGT-100,IT,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
HI4077/03,6362835,Jameson Energy Centre,KMEA,HI4077/03,NA0352/03,NA0352/03,SGT-400 (LCN NA0352) C3S M2S E2S DS GB,TRAIN_HI4077/03,SGT-400,2014-06-30,2015-05-29,2013-09-30,Operating,SGT-400,US,Other - please specify,ADHOC,I4-008,10,GENERATOR
BC000166U08,6662746,Palayesh Parsian Sepehr Company,Mohr Parsian,BC000166U08,NU0476/04,NU0467/04,SGT-100-2S PACKAGE(NU0467)CX M1S EX,TRAIN_BC000166U08,SGT-100-2S,,,2016-06-30,Operating,SGT-100,IR,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
M660/01,6044300,BURGO - Cartiera di Sarego,Cartiera di Sarego,M660/01,,N/A,SGT-100 PACKAGE C2S M2C EX DC GB,TRAIN_M660/01,SGT-100-1S,1997-04-01,1997-10-25,1996-04-01,Operating,SGT-100,IT,Corrective LTP,LTP,I4-002,10,GENERATOR
M528/01,6051719,UNIGRA' S.r.l.,Unigrà,M528/01,M528/01,N/A,SGT-100 PACKAGE C2S M2C EX DC GB,TRAIN_M528/01,SGT-100-1S,1995-09-01,1996-03-31,1994-09-01,Not Running – Preserved,SGT-100,IT,Other and 24/7 Global Helpdesk and/or RDS included,ADHOC,I4-002,10,GENERATOR
NA0430/03,6541147,Ocensa - Porvenir,,NA0430/03,NA0426/06,HI4088,SGT-400 (HOU HI4088) C3S M1R EX GB,TRAIN_NA0430/03,SGT-400,2016-05-25,2017-05-24,2014-12-01,Operating,SGT-400,CO,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
NU0214/01,6044320,Caspian Pipeline Consortium (CPC-R) - Kropotkin,CPC Kropotkin,NU0214/01,,N/A,SGT-100-2S PACKAGE C3S M2C E1C DC GB,TRAIN_NU0214/01,SGT-100-2S,2014-04-10,2016-04-09,2010-05-01,Operating,SGT-100,RU,Frame Agreement – Includes RDS,ADHOC,I4-003,30,COMPRESSOR
S1B4/01,6053113,Sarir Oil Operations - Jakhira Field,Jakhira,S1B4/01,,N/A,SGT-200 PACKAGE CX M1C E1C DS,TRAIN_S1B4/01,SGT-200-1S,2002-08-01,2003-05-30,2001-08-01,Operating,SGT-200,LY,Other and 24/7 Global Helpdesk and/or RDS included,ADHOC,I4-005,10,GENERATOR
HI4072/01,6156033,NEOM Saudi Arabia,Cardon IV,HI4072/01,NA0289/01,NA0289/01,SGT-400 PACKAGE(LCN SCOPE NA0289),TRAIN_HI4072/01,SGT-400,,,2011-12-31,Storage,SGT-400,SA,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
S366/01,217586,Caribou Lake,"Alberta Energy, Alberta, Canada",S366/01,9080/124,N/A,SGT-200-2S PACKAGE,TRAIN_S366/01,SGT-200-2S,1993-08-09,1994-08-08,1992-04-01,Scrapped,SGT-200,CA,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NC0016/04,6134883,National Iranian Oil Company (NIOC),South Pars Phase 7,NC0016/04,,N/A,SGT-400 PACKAGE CX M2C E1C DC GB,TRAIN_NC0016/04,SGT-400,2005-08-01,2009-07-30,2004-01-01,Operating,SGT-400,IR,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
BC000166U07,6662745,Palayesh Parsian Sepehr Company,Mohr Parsian,BC000166U07,NU0476/03,NU0467/03,SGT-100-2S PACKAGE(NU0467)CX M1S EX,TRAIN_BC000166U07,SGT-100-2S,,,2016-06-30,Operating,SGT-100,IR,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
M628/02,6054570,IKPP Tangerang - PT. Dian Swastatika Sentosa Power Station,Tangerang 2,M628/02,,N/A,SGT-100 PACKAGE C2S M2C EX DC GB,TRAIN_M628/02,SGT-100-1S,1996-01-01,1997-04-28,1995-09-01,Operating,SGT-100,ID,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S443/01,6050041,ASSALA GABON - Rabi Field,Rabi Field,S443/01,,N/A,SGT-200 PACKAGE <C M1C E2S DS GB 2022,TRAIN_S443/01,SGT-200-1S,1995-01-01,1995-08-07,1993-12-01,Operating,SGT-200,GA,Frame Agreement,ADHOC,I4-005,10,GENERATOR
U2D1/02,6050091,Single Buoy Moorings - FPSO Serpentina,Water Injection B,U2D1/02,,N/A,SGT-100-2S C4S M2C E1C DC GB HC,TRAIN_U2D1/02,SGT-100-2S,2002-11-15,2004-09-13,2001-12-01,Operating,SGT-100,GQ,Preventive LTP – Includes RDS,LTP,I4-003,30,COMPRESSOR
NA0475/01,6664661,Centrax Ltd.,Centrax 011 (CCB Besse Sur Braye),NA0475/01,BD000417U01,BD000417,SGT-400 PACKAGE GB,TRAIN_NA0475/01,SGT-400,2017-12-15,2018-12-14,2016-10-31,Not Running – Preserved,SGT-400,GB,No Contract or Agreement,ADHOC,I4-008,,GENERATOR
R222/01,6053409,Sonatrach DP - Mesdar,SONATRACH DIVISION PRODUCTION,R222/01,,N/A,SGT-200-TS PACKAGE,TRAIN_R222/01,SGT-200-2S,1992-02-01,1992-12-30,1991-02-01,Not Running – Not Preserved,SGT-200,DZ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
80/85121-02,6055421,"Daqing - PetroChina Daqing Oilfield Co., Ltd  Natural Gas Company",Daqing,80/85121-02,,N/A,SGT-200 PACKAGE,TRAIN_80/85121-02,SGT-200-2S,1988-07-05,1989-07-05,1987-07-05,Scrapped,SGT-200,CN,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
M2B9/01,6038178,AmBev S/A - Jacareí,UG1,M2B9/01,,1405010,SGT-100 (HOU 1405010) C2S EX GB,TRAIN_M2B9/01,SGT-100-1S,2002-01-01,2002-11-15,2001-04-01,Operating,SGT-100,BR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NC0021/05,6039150,PDVSA - Santa Rosa - Planta de Gas Anaco,"Anaco, Santa Rosa",NC0021/05,NC0021/05,HI6006 1550006,SGT-400 (HOU HI6006) C1S M2C E1C DC GB,TRAIN_NC0021/05,SGT-400,,,2005-06-01,Not Running – Not Preserved,SGT-400,VE,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
R321/01,6032934,Gas Networks Ireland - Brighouse Bay,Brighouse Bay 1,R321/01,,N/A,SGT-200-2S PACKAGE C3S M2C DC HP,TRAIN_R321/01,SGT-200-2S,1993-07-19,1994-07-18,1992-12-01,Operating,SGT-200,GB,Preventive LTP,LTP,I4-006,30,COMPRESSOR
LM0025/01,6334901,OGDCL - Uch Gas,,LM0025/01,M784/01,N/A,SGT-100 C3S M2C E1C DC GB,TRAIN_LM0025/01,SGT-100-1S,2007-01-01,2014-06-01,2006-01-01,Operating,SGT-100,PK,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NM0077/01,6037449,EP Petroecuador / La Libertad Refinery,La Libertad Refinery Facility,NM0077/01,HI6002 - 1405039,HI6002 1405039,SGT-100 (PKGD AS HI6002)<C M1C E2S DS GB,TRAIN_NM0077/01,SGT-100-1S,2005-07-11,2006-07-10,2004-03-01,Not Running – Not Preserved,SGT-100,EC,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NW0049/01,6045862,GHANA TEMA POWERVOLTA RIVER AUTHORITY,GHANA TEMA POWER,NW0049/01,,N/A,SGT-300 PACKAGE C2S M1C EX GB,TRAIN_NW0049/01,SGT-300,2008-12-19,2009-12-18,2007-12-01,Operating,SGT-300,GH,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
R007/01,220830,University of Toronto - Toronto,University of Toronto,R007/01,J216/01,J216/01,SGT-200 PACKAGE C3S M1S EX,TRAIN_R007/01,SGT-200-1S,1992-05-04,1993-05-04,1991-04-01,Operating,SGT-200,CA,Preventive LTP – Includes RDS,LTP,I4-005,10,GENERATOR
S907/01,6045162,Ringkøbing Fjernvarmeværk,Ringkobing 1,S907/01,9080/032,N/A,SGT-200 PACKAGE C2S M2C EX DC GB,TRAIN_S907/01,SGT-200-1S,1988-01-01,1989-05-16,1987-01-01,Scrapped,SGT-200,DK,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NC0211/01,6144127,Shwe Platform,SHWE,NC0211/01,,N/A,SGT-400 C3S M2C E2S DC GB,TRAIN_NC0211/01,SGT-400,2013-11-14,2015-03-13,2015-03-01,Operating,SGT-400,MM,Frame Agreement – Includes RDS,ADHOC,I4-008,30,COMPRESSOR
S942/01,6045696,FUJIFILM Manufacturing Europe B.V.,Tilburg,S942/01,,N/A,SGT-200 PACKAGE  (PR463) GB,TRAIN_S942/01,SGT-200-1S,1990-01-01,1990-12-27,1989-01-01,Not Running – Preserved,SGT-200,NL,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
S432/01,6049235,Sahinler Enerji,Sahinler Plant 1,S432/01,,N/A,SGT-200 PACKAGE GB,TRAIN_S432/01,SGT-200-1S,1995-01-01,1995-08-16,1994-01-01,Scrapped,SGT-200,TR,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
R0H8/01,6046075,"Kosmos-Trident Equatorial Guinea, Inc.",SENDJE CEIBA,R0H8/01,,N/A,SGT-200-2S PACKAGE M1C E1C DS GB,TRAIN_R0H8/01,SGT-200-2S,2002-01-30,2003-05-23,1995-12-01,Storage,SGT-200,GQ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
R2B2/01,6137997,National Iranian Oil Company (NIOC),Dorood Pumps,R2B2/01,,N/A,SGT-200-2S PACKAGE C1S M1C E1C DS,TRAIN_R2B2/01,SGT-200-2S,2003-07-01,2004-07-01,2002-07-01,Operating,SGT-200,IR,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NA0133/02,6112085,Dummy Account: Middle East - Syria,EBLA POWER GEN,NA0133/02,NA0133/02,N/A,SGT-400 PACKAGE C3S M2C E1C DC GB,TRAIN_NA0133/02,SGT-400,2010-03-31,2010-08-30,2008-05-01,Operating,SGT-400,SY,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
M359/05,219131,NYCDEP - Wards Island,New York City Department of Environmental Protection 26Th Ward Street,M359/05,9100/076,N/A,SGT-100 PACKAGE CX GB,TRAIN_M359/05,SGT-100-1S,1993-01-01,1995-01-11,1992-01-01,Operating,SGT-100,US,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NA0142/03,6108655,PJSC - Kiev Cardboard and Paper Mill,KIEVSKY PAPERMILL,NA0142/03,,BD000022U03,SGT-400(FSP BD000022) C3C M2S E2S DS GB,TRAIN_NA0142/03,SGT-400,2013-05-01,2014-04-30,2008-07-18,Operating,SGT-400,UA,Frame Agreement,ADHOC,I4-008,10,GENERATOR
R2E5/04,6053149,Sonatrach TRC - OZ2,OZ2,R2E5/04,,N/A,SGT-200-2S PACKAGE M2C E1C DC 2002,TRAIN_R2E5/04,SGT-200-2S,2002-09-19,2006-03-29,2001-09-19,Operating,SGT-200,DZ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
M0E6/02,6043919,Batıçim Enerji,M0E8/02-Rm317-Gt2,M0E6/02,,N/A,SGT-100 PACKAGE - MOTHBALLED GB,TRAIN_M0E6/02,SGT-100-1S,2002-03-07,2003-03-07,2001-03-07,Scrapped,SGT-100,TR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NA0287/01,6156039,ZNGT - Tianguan,ZNGT - Tianguan,NA0287/01,,N/A,SGT-400 PACKAGE <C M1C E1C DS GB,TRAIN_NA0287/01,SGT-400,2013-05-29,2014-05-28,2012-05-31,Scrapped,SGT-400,CN,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
NA0430/02,6541146,Ocensa - Porvenir,,NA0430/02,NA0426/05,HI4088,SGT-400 (HOU HI4088) C3S M1R EX GB,TRAIN_NA0430/02,SGT-400,2016-05-23,2017-05-22,2014-12-01,Operating,SGT-400,CO,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
S923/01,6032498,Greenray Turbines (Lincoln) Ltd.,Greenwich Refineries 1,S923/01,,N/A,SGT-200 PACKAGE,TRAIN_S923/01,SGT-200,1990-01-01,1990-11-23,1989-01-01,Scrapped,SGT-200,GB,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
HI4090/01,6551335,Cardinal  Power of Canada,Cardinal Power - Ingredion,HI4090/01,NA0439/01 - BD000292U01,NA0439/01 BD000292,SGT-400 PKG (NA0439) C3C M2C EX DC GB HC,TRAIN_HI4090/01,SGT-400,2017-09-20,2018-09-19,2015-09-07,Operating,SGT-400,CA,Corrective LTP – Includes RDS,LTP,I4-008,30,GENERATOR
NC0033/02,6039133,PDVSA - Moron - ICO Moron,"ICO, Moron",NC0033/02,,HI6011 1550011,SGT-400 (HOU HI6011) C1C M2C E1C DC GB,TRAIN_NC0033/02,SGT-400,2009-04-30,2010-07-29,2006-03-01,Not Running – Preserved,SGT-400,VE,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
R147/02,6053166,Mellitah Oil & Gas B.V - Bu Attiffel Field,Mellitah Oil & Gas - Oil Division,R147/02,,N/A,SGT-200 PACKAGE C2S M1S EX,TRAIN_R147/02,SGT-200-2S,1992-01-01,1993-01-01,1991-01-01,Operating,SGT-200,LY,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
80/85114-02,216602,Hilcorp Alaska LLC - Endicott,Ruston 1802 Endicott Field,80/85114-02,9080/0186-1,N/A,SGT-200-2S PACKAGE CX M1C E1C DS,TRAIN_80/85114-02,SGT-200-2S,1986-10-02,1987-10-02,1985-10-02,Operating,SGT-200,US,Frame Agreement,ADHOC,I4-006,30,COMPRESSOR
NC0326/01,6197127,Botas - Eskisehir,Botas  Eskisehir,NC0326/01,,N/A,SGT-400 PACKAGE C3S M2S E2S DS,TRAIN_NC0326/01,SGT-400,2016-04-08,2017-04-07,2012-07-31,Operating,SGT-400,TR,RDS,ADHOC,I4-008,30,COMPRESSOR
M576/01,6047375,PIRELLI / Plant operated by E.ON Business Solutions S.r.l.,Michelin Roanne,M576/01,,N/A,SGT-100 PACKAGE <C M2C DC GB,TRAIN_M576/01,SGT-100-1S,1997-04-08,1998-04-08,1996-04-08,Not Running – Not Preserved,SGT-100,IT,Corrective LTP,LTP,I4-002,10,GENERATOR
NR0104/01,6038184,Petróleo Brasileiro S/A - Petrobras - G&E/UN-APGN/UTGC (Fase III),SITI - CACIMBAS PHASE III,NR0104/01,HI6016 - 1550016,HI6016 1550016,SGT-200 PACKAGE C2S M1C E1C DS GB,TRAIN_NR0104/01,SGT-200-2S,2009-05-01,2010-04-30,2007-03-01,Operating,SGT-200,BR,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
U9M3/01,6051097,TotalEnergies E&P Nederland BV,Total E&P Nederland BVF15,U9M3/01,9105/020,N/A,SGT-100-2S PACKAGE C2S M1C E1C DS GB,TRAIN_U9M3/01,SGT-100-2S,2000-01-01,2002-02-14,1999-05-01,Operating,SGT-100,NL,Frame Agreement – Includes RDS,ADHOC,I4-003,30,COMPRESSOR
M309/02,6079809,Bumi Armada Claire FPSO,Griffin Field FPSO,M309/02,,N/A,SGT-100 PACKAGE C2S GB,TRAIN_M309/02,SGT-100-1S,1992-12-31,2014-07-30,1991-12-31,Not Running – Not Preserved,SGT-100,MY,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S039/04,6053072,Harouge Oil Operations - Ghani Field,Farrud - Ghani,S039/04,,N/A,SGT-200 PACKAGE GB,TRAIN_S039/04,SGT-200-1S,1991-01-01,1992-02-25,1990-01-01,Scrapped,SGT-200,LY,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M8B7/01,6054364,Weizhou Terminal  - CNOOC China Ltd.,Weizhou Oil Field,M8B7/01,,N/A,SGT-100 PACKAGE CX EX GB,TRAIN_M8B7/01,SGT-100-1S,1998-01-18,1999-01-17,1997-06-01,Operating,SGT-100,CN,Framework Agreement Goods and Services,ADHOC,I4-002,10,GENERATOR
NW0094/03,6061845,"Petronet LNG Ltd., Dahej",Dahej 2 SPARE CORE,NW0094/03,,N/A,SGT-300 PACKAGE CX,TRAIN_NW0094/03,SGT-300,2009-04-09,2011-04-09,2008-04-09,Operating,SGT-300,IN,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
S505/01,6141135,Dummy Account: Europe - Norway,Gamma West 1,S505/01,,N/A,REFURBISHED SGT-200 SEE LS0002/01,TRAIN_S505/01,SGT-200,1996-01-01,1997-01-01,1994-12-01,Scrapped,SGT-200,NO,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
S0H3/01,6054815,"Fuji Film, Odawara (HPS)","Fuji Film, Odawara",S0H3/01,,N/A,SGT-200 PACKAGE <C M2C E2S DC 2022?,TRAIN_S0H3/01,SGT-200-1S,1998-09-14,2003-08-01,1998-03-18,Operating,SGT-200,JP,Preventive LTP – Includes RDS,LTP,I4-005,10,GENERATOR
M604/01,217905,Fortistar Methane Group - Mallard Lake Power Plant,Gas Recovery Systems Inc Mallard Lake,M604/01,9100/153,N/A,SGT-100 PACKAGE GB,TRAIN_M604/01,SGT-100-1S,1996-03-01,1997-03-01,1995-03-01,Scrapped,SGT-100,US,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M8L4/01,6038518,PEP-Cantarell Offshore-APC-Akal L,Akal L (Offshore),M8L4/01,M8L4/01,N/A,SGT-100 C3S M1S EX,TRAIN_M8L4/01,SGT-100-1S,1999-01-01,2000-01-06,1998-05-01,Operating,SGT-100,MX,Frame Agreement,ADHOC,I4-002,10,GENERATOR
NW0556/01,6695195,Centrax Ltd.,Centrax 024 (Cottam),NW0556/01,BD000967U01,BD000967,SGT-300 PACKAGE CX MX EX DX,TRAIN_NW0556/01,SGT-300-1S,2022-05-28,2025-05-27,2020-12-01,Operating,SGT-300,GB,No Contract or Agreement,ADHOC,I4-021,,GENERATOR
BD000203U01,6490644,Akkas Gas Field - KOGAS B.A,Akkas,BD000203U01,NA0392/01,NA0392/01,SGT-400 PACKAGE (Lincoln Scope NA0392),TRAIN_BD000203U01,SGT-400,2022-11-30,,2014-03-31,Storage,SGT-400,IQ,No Contract or Agreement,ADHOC,I4-008,,GENERATOR
W772/02,6141718,NIPC (HQ),KPC,W772/02,,N/A,SGT-300 PACKAGE C2S M2C DC GB,TRAIN_W772/02,SGT-300,1998-01-01,2000-02-01,1996-12-01,Operating,SGT-300,IR,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
BD000731U04,6682269,Botas - Kuzey Marmara,Kuzey Marmara,BD000731U04,NC0527/04 - BC000294U04,NC0527/04 BC000294?,SGT-400 PACKAGE C3S M1S,TRAIN_BD000731U04,SGT-400,2024-03-01,,2018-12-31,Installation & Commissioning,SGT-400,TR,No Contract or Agreement,ADHOC,I4-008,,COMPRESSOR
BD000081U01,6104724,ALCO BIO FUEL NV,Alco Bio Fuels,BD000081U01,NA0270/01,NA0270/01,SGT-400 (NA0270) C3C M2S E2S DS GB HC,TRAIN_BD000081U01,SGT-400,2013-03-29,2014-03-29,2012-02-28,Operating,SGT-400,BE,Corrective LTP – Includes RDS,LTP,I4-008,10,GENERATOR
R0C2/01,6038851,Total Austral S.A. - Cañadon Alfa,Canadon Alfa,R0C2/01,,N/A,SGT-200 PACKAGE C2S M1C E1C DS,TRAIN_R0C2/01,SGT-200-2S,2000-01-01,2001-04-01,1999-01-01,Operating,SGT-200,AR,Other and 24/7 Global Helpdesk and/or RDS are not included,ADHOC,I4-006,30,COMPRESSOR
NA0015/01,6060855,Thai German Ceramic Industry PLC,Thai German Ceramics Industry,NA0015/01,,N/A,SGT-400 PACKAGE GB,TRAIN_NA0015/01,SGT-400,2005-08-24,2012-11-30,2004-07-01,Not Running – Not Preserved,SGT-400,TH,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
BD000434U01,6664665,"AECC Gas Turbine (Zhuzhou) Co., Ltd.  (ZNGT)",,BD000434U01,NA0482/01,NA0482/01,SGT-400 PKG(LCNScopeNA0482)C3S M1S EX GB,TRAIN_BD000434U01,SGT-400,2018-09-14,2019-09-13,2016-12-31,Not Running – Preserved,SGT-400,CN,No Contract or Agreement,ADHOC,I4-008,,GENERATOR
NC0199/02,6052882,Sonatrach DP - Gassi Touil,Gassi Touil,NC0199/02,,N/A,SGT-400 PACKAGE C3S M2C E2S DC GB,TRAIN_NC0199/02,SGT-400,2014-01-31,2014-12-30,2010-01-01,Operating,SGT-400,DZ,Frame Agreement,ADHOC,I4-008,30,COMPRESSOR
NA0426/03,6541144,Ocensa - Paez,,NA0426/03,,HI4088,SGT-400 (HOU 4088) C3S M2S E1C DS GB,TRAIN_NA0426/03,SGT-400,2016-06-14,2017-06-13,2014-12-01,Operating,SGT-400,CO,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
BC000538U02,6682259,New Fortress Energy - Gulf of Mexico (Mex),FAST LNG,BD001037U03,NA0529/03,NA0529/02 BD001037 HI6101,SGT-400 PACKAGE (NA0529) CX,TRAIN_BC000538U02,SGT-400,2024-02-01,2025-01-30,2019-02-28,Installation & Commissioning,SGT-400,MX,No Contract or Agreement,ADHOC,I4-008,,GENERATOR
A0K7/02,6051162,ADNOC Offshore -ABK,Khuff Gas (Nkpp),A0K7/02,NA0007/02,N/A,SGT-400 PACKAGE C3S M2C E2S DC GB,TRAIN_A0K7/02,SGT-400,2002-01-08,2003-01-08,2001-01-08,Operating,SGT-400,AE,Framework Agreement Goods and Services,ADHOC,I4-008,10,GENERATOR
S122/02,6054914,Petronas Carigali - Baronia Platform,Baronia Platform,S122/02,,N/A,SGT-200 PACKAGE GB,TRAIN_S122/02,SGT-200-1S,1991-01-01,1993-01-21,1990-09-01,Not Running – Preserved,SGT-200,MY,Frame Agreement,ADHOC,I4-005,10,GENERATOR
BF000091U02,6662753,Eni Ghana - San Zule,ENI Ghana,BF000091U02,NC0465/02,NC0465/02,SGT-400 (LCN NC0465) C3S M2C E2S DC,TRAIN_BF000091U02,SGT-400,2018-12-20,2020-12-19,2016-08-31,Operating,SGT-400,GH,Preventive LTP – Includes RDS,LTP,I4-008,,COMPRESSOR
NC0027/02,6047853,SOCAR-Azerbaijan-Gunashli LP,Gunashli,NC0027/02,NC0027/02,N/A,SGT-400 PACKAGE C3S M2C E1C DC GB,TRAIN_NC0027/02,SGT-400,2012-07-20,2013-03-19,2005-09-18,Operating,SGT-400,AZ,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
BC000153U02,6553205,TAP Greece,TAP Greece,BC000153U02,NC0447/02,NC0447/02,SGT-400 PKG (LCN NC0447) C3C M1S EX HC,TRAIN_BC000153U02,SGT-400,2021-03-30,2023-03-29,2016-02-01,Operating,SGT-400,GR,Preventive LTP – Includes RDS,LTP,I4-008,,COMPRESSOR
NC0031/02,6054837,ONGC B&S BCPA2 Platform,OIL AND NATURAL GAS COMMISSION (ONGC),NC0031/02,,N/A,SGT-400 PACKAGE C3S M2C E2S DC GB,TRAIN_NC0031/02,SGT-400,2009-05-26,2010-05-25,2015-03-01,Operating,SGT-400,IN,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
HI6080/03,6682259,New Fortress Energy - Gulf of Mexico (Mex),FAST LNG,BD001037U03,NA0529/03,NA0529/03 BD000750,SGT-400 PACKAGE CX,TRAIN_HI6080/03,SGT-400,2024-02-01,2025-01-30,2019-02-28,Installation & Commissioning,SGT-400,MX,No Contract or Agreement,ADHOC,I4-008,,GENERATOR
S644/01,6054935,Taiyo Oil,Taiyo Oil # 3,S644/01,,N/A,SGT-200-2S PACKAGE CX M2C EX DC GB,TRAIN_S644/01,SGT-200-1S,2004-07-01,1997-04-01,2001-10-16,Operating,SGT-200,JP,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NA0371/01,6436510,Centrax Ltd.,Centrax 004 (Munksjo),NA0371/01,NA0371/01,N/A,SGT-400 PACKAGE C4S GB HS,TRAIN_NA0371/01,SGT-400,2014-10-03,2015-10-02,2013-11-30,Operating,SGT-400,GB,Corrective LTP – Includes RDS,LTP,I4-008,10,GENERATOR
R8D9/01,220564,TC Energy - CDN Mainline - Les Cedres Station 148,TC Energy CDN Mainline Les Cedres Stn.148C1,R8D9/01,9080/233,N/A,SGT-200-2S PACKAGE CX EX,TRAIN_R8D9/01,SGT-200-2S,1999-01-05,2000-01-04,1990-01-01,Operating,SGT-200,CA,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
R8E0/01,220565,TC Energy - CDN Mainline - Les Cedres Station 148,TC Energy CDN Mainline Les Cedres Stn.148D1,R8E0/01,9080/232,N/A,SGT-200-2S PACKAGE CX EX,TRAIN_R8E0/01,SGT-200-2S,1998-02-19,2000-11-01,1997-01-01,Operating,SGT-200,CA,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NA0133/01,6112086,Dummy Account: Middle East - Syria,EBLA POWER GEN,NA0133/01,NA0133/01,N/A,SGT-400 PACKAGE C3S M2C E1C DC GB,TRAIN_NA0133/01,SGT-400,2010-03-31,2010-08-30,2008-05-01,Operating,SGT-400,SY,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
S614/01,6141679,Dummy Account: Asia Pacific - Japan,Miyakojima 1,S614/01,,N/A,SGT-200 PACKAGE - SCRAPPED,TRAIN_S614/01,SGT-200,1998-03-01,1999-03-01,1997-01-01,Scrapped,SGT-200,JP,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
A2F0/01,6032486,National Gas - Kings Lynn,NG Kings Lynn 1,A2F0/01,NC0009/01,N/A,SGT-400 PACKAGE CX M2C E1C DC GB,TRAIN_A2F0/01,SGT-400,2002-03-11,2003-03-11,2001-03-11,Operating,SGT-400,GB,Frame Agreement,ADHOC,I4-008,30,COMPRESSOR
HI4067/03,6083348,GLNG - Fairview-HCS-04 South,Fairview 04,HI4067/03,NA0246/03,NA0246/03,SGT-400 (LCN NA0246) C1S M1C E1C GB,TRAIN_HI4067/03,SGT-400,2015-05-31,2016-10-29,2010-12-31,Not Running – Preserved,SGT-400,AU,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
M407/01,6032722,RWE - Dudley,Dudley,M407/01,,N/A,SGT-100 PACKAGE - SCRAPPED,TRAIN_M407/01,SGT-100,1994-01-01,1995-01-23,1993-09-01,Scrapped,SGT-100,GB,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
BF000061U04,6544042,Beineu-Shymkent Gas Pipeline LLP,Beineu-Bozoy Shymkent,BF000061U04,NC0428/04,NC0428/04,SGT-400 (LCN NC0428) C3C M1S EX HC,TRAIN_BF000061U04,SGT-400,2016-08-27,2017-08-26,2015-05-31,Operating,SGT-400,KZ,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
S016/02,6045896,GRACE Manufacturing GmbH & Co. KG - Worms,Grace GT2,S016/02,,N/A,SGT-200 PACKAGE CX M1S EX GB,TRAIN_S016/02,SGT-200-1S,1991-01-01,1991-07-23,1990-01-01,Operating,SGT-200,DE,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M385/01,6696746,GreenField Ethanol,,M385/01,,,,,SGT-100-1S DLE,,,,Operating,SGT-100,CA,No Contract or Agreement,ADHOC,,,
NA0555/01,6692142,IGSA S.A. de C.V.,IGSA - Central TLACO,NA0555/01,BD000949U01,BD000949,SGT-400 PACKAGE M1S,TRAIN_NA0555/01,SGT-400,2024-06-01,2025-05-30,2020-09-01,Order,SGT-400,MX,No Contract or Agreement,ADHOC,I4-008,,GENERATOR
S041/01,6032605,International Paper Ltd - Inverurie Mill,Inverurie Mill 2,S041/01,,N/A,SGT-200 PACKAGE,TRAIN_S041/01,SGT-200-1S,1991-09-28,1992-09-26,1990-01-01,Scrapped,SGT-200,GB,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
HI4066/12,6039052,PDVSA - Div Faja - Unidades Moviles Div Faja,PDVSA Mobile Power,HI4066/12,NA0209/12,NA0209/12 HI4057,SGT-400 PACKAGE (NA0209) CANCELLED,TRAIN_HI4066/12,SGT-400,,,2010-03-04,Cancelled,SGT-400,VE,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
S0C0/03,6050737,Surgutneftegas-Surgut-Tianskoye,Tianskoye 3,S0C0/03,,N/A,SGT-200 PACKAGE,TRAIN_S0C0/03,SGT-200-1S,2001-08-01,2002-08-12,1999-12-01,Not Running – Not Preserved,SGT-200,RU,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
BF000061U05,6544043,Beineu-Shymkent Gas Pipeline LLP,Beineu-Bozoy Shymkent,BF000061U05,NC0428/05,NC0428/05,SGT-400 (LCN NC0428) C3S M1S EX,TRAIN_BF000061U05,SGT-400,2016-09-10,2017-09-09,2015-05-31,Operating,SGT-400,KZ,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
BD000199U03,6490642,Siba,SIBA,BD000199U03,NW0390/03,NW0390/03,SGT-300 PKG (Lincoln scope NW0390)C3S EX,TRAIN_BD000199U03,SGT-300,2018-06-06,2019-06-05,2014-05-31,Operating,SGT-300,IQ,Other and 24/7 Global Helpdesk and/or RDS included,ADHOC,I4-021,10,GENERATOR
R8K0/01,6038464,PLOG-CPTG Atasta Onshore-Atasta,Atasta,R8K0/01,R8K0/01,N/A,SGT-200 PACKAGE,TRAIN_R8K0/01,SGT-200-2S,1998-06-01,1999-05-31,1998-06-01,Scrapped,SGT-200,MX,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
S122/01,6054912,Petronas Carigali - Baronia Platform,Baronia Platform,S122/01,,N/A,SGT-200 PACKAGE GB,TRAIN_S122/01,SGT-200-1S,1991-01-01,1992-03-02,1990-09-01,Not Running – Preserved,SGT-200,MY,Frame Agreement,ADHOC,I4-005,10,GENERATOR
NA0016/06,6011876,Alyeska - Pump Station 01,PS1 SGT-400 #2,NA0016/06,HI6001/06,1550001,SGT-400 (SP CORE ONLY) CX M2S DS GB,TRAIN_NA0016/06,SGT-400,2007-09-28,2013-07-05,2004-01-07,Operating,SGT-400,US,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
M0B0/01,6044336,Caspian Pipeline Consortium (CPC-R) - Marine Terminal,CPC Tank Farm,M0B0/01,,N/A,SGT-100 PACKAGE C3S M2C E1C DC GB,TRAIN_M0B0/01,SGT-100-1S,2002-01-08,2003-01-08,2001-01-08,Operating,SGT-100,RU,Frame Agreement – Includes RDS,ADHOC,I4-002,10,GENERATOR
M359/04,219130,NYCDEP - Wards Island,New York City Department of Environmental Protection Hunts Point,M359/04,9100/069,N/A,SGT-100 PACKAGE CX EX GB,TRAIN_M359/04,SGT-100-1S,1993-01-01,1994-12-05,1992-01-01,Operating,SGT-100,US,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
R121/02,6032898,Chrysaor Holdings - Everest Platform,BG Everest 2,R121/02,R121/02,N/A,SGT-200-2S PACKAGE CX M2C EX DC,TRAIN_R121/02,SGT-200-2S,1992-03-27,1993-03-26,1990-01-01,Operating,SGT-200,GB,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
S546/02,6044228,Brødrene Hartmann-Tønder,Tonder 1,S546/02,ESN9080/163,N/A,SGT-200 PACKAGE CX M2C EX DC GB,TRAIN_S546/02,SGT-200-1S,1995-01-01,1996-07-09,1994-12-01,Operating,SGT-200,DK,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
R2E3/01,6053160,Sonatrach TRC - OZ2,OZ2 PIPELINE,R2E3/01,,N/A,SGT-200-2S PACKAGE M2C E1C DC 2002,TRAIN_R2E3/01,SGT-200-2S,2004-01-01,2006-06-26,2001-09-19,Operating,SGT-200,DZ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
M9C5/01,6141675,Dummy Account: Asia Pacific - Japan,Fujinomiyu Factory 2,M9C5/01,,N/A,SGT-100 PACKAGE - SCRAPPED,TRAIN_M9C5/01,SGT-100,1999-06-01,2000-05-22,1998-05-01,Scrapped,SGT-100,JP,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
W2A4/01,6055511,PHP_Power Generation Plant Ltd.,PHP Cold Rolling Mills,W2A4/01,,N/A,SGT-300 C3S M2C E2S DC GB HP,TRAIN_W2A4/01,SGT-300,2001-09-19,2015-12-12,2015-03-01,Operating,SGT-300,BD,Corrective LTP – Includes RDS,LTP,I4-021,10,GENERATOR
HI4086/01,6541475,Delta Indústria Cerâmica S/A,Ceramica Delta SA,HI4086/01,NA0411/01,NA0411/01,SGT-400 PACKAGE (Lincoln Scope NA0411),TRAIN_HI4086/01,SGT-400,2020-02-28,2021-01-30,2014-09-30,Not Running – Preserved,SGT-400,BR,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
NC0297/01,6154032,Gas Transmission Co Ltd,Hyundai Engineering,NC0297/01,,N/A,SGT-400 C3C M2S E2S DS GB,TRAIN_NC0297/01,SGT-400,2016-11-01,2018-11-30,2012-02-28,Operating,SGT-400,BD,Preventive LTP,LTP,I4-008,30,COMPRESSOR
M763/01,6047598,Mrd - Deschanim,Deschanim,M763/01,,N/A,SGT-100 PACKAGE - DEACTIVATED,TRAIN_M763/01,SGT-100,1997-01-01,1998-07-05,1996-12-01,Scrapped,SGT-100,IL,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
HI4081/01,6445081,PRO MDF S.A.P.I. DE C.V.,Tabasco,HI4081/01,NA0397/01,NA0397/01,SGT-400 (NA0397) C3S M2S E2S DS GB,TRAIN_HI4081/01,SGT-400,2017-02-10,2018-02-09,2014-05-31,Operating,SGT-400,MX,Preventive LTP – Includes RDS,LTP,I4-008,10,GENERATOR
HI4074/02,6154011,ONGC - Sagar Samrat  (Mercator),SAGAR SAMRAT ONGC,HI4074/02,NW0292/02 / BFF00413U04,NW0292/02,SGT-300(LCN NW0292)C3C M2C E2S DC,TRAIN_HI4074/02,SGT-300,2024-03-01,2025-02-27,2012-02-01,Installation & Commissioning,SGT-300,IN,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
BD000772U01,6683220,Map Ta Phut Olefins Co. Ltd.,MOC Rayong,BD000772U01,NA0533/01,NA0533/01,SGT-400 PACKAGE (LCN NA0533) C3S EX GB,TRAIN_BD000772U01,SGT-400,2021-08-01,2022-07-30,2019-05-31,Operating,SGT-400,TH,No Contract or Agreement,ADHOC,I4-008,,GENERATOR
A2C9/01,6050096,Single Buoy Moorings - FPSO Serpentina,Serpentina Gas Lift Compression,A2C9/01,,N/A,SGT-400 PACKAGE C3S M2C DC GB,TRAIN_A2C9/01,SGT-400,2002-03-11,2003-03-11,2001-03-11,Operating,SGT-400,GQ,Preventive LTP – Includes RDS,LTP,I4-008,30,COMPRESSOR
M504/03,6048800,Qatargas - North Field Bravo (NFB) Offshore Platform,North Field Phase 2- 1,M504/03,,N/A,SGT-100 PACKAGE C2S M1C E2S DS GB,TRAIN_M504/03,SGT-100-1S,1995-01-01,1996-06-03,1994-08-01,Operating,SGT-100,QA,Call Off Contract Goods and Services,ADHOC,I4-002,10,GENERATOR
NW0108/01,6079502,Altera-Ocyan - FPSO Cidade de Itajai,SGT Alpha,NW0108/01,,N/A,SGT-300 C3S M2C E2S DC GB,TRAIN_NW0108/01,SGT-300-1S,2012-11-05,2013-11-04,2007-06-18,Operating,SGT-300,BR,Preventive LTP – Includes RDS,LTP,I4-021,10,GENERATOR
R2H2/01,6051106,Total Exploration Production - Kharyaga,Togliatti Azot,R2H2/01,,N/A,SGT-200 PACKAGE,TRAIN_R2H2/01,SGT-200-2S,2002-01-01,2003-07-01,2001-02-01,Not Running – Preserved,SGT-200,RU,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
M557/01,6038138,Contour Global Solutions Rio Ltda.,Brahma 1,M557/01,,N/A,SGT-100 PACKAGE <C1S EX GB,TRAIN_M557/01,SGT-100-1S,1995-01-01,1996-10-17,1994-01-01,Operating,SGT-100,BR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
HI6041/01,6145220,TGP2 KP127,Kp-127,HI6041/01,NC0296/01,NC0296/01,SGT-400 (LCN NC0296) C3S M2C EX DC GB,TRAIN_HI6041/01,SGT-400,2016-04-21,2018-04-20,2012-01-01,Operating,SGT-400,PE,Preventive LTP – Includes RDS,LTP,I4-008,30,COMPRESSOR
NR0033/14,6053152,Sonatrach TRC - OZ2,OZ2,NR0033/14,R3D8/14,N/A,SGT-200-2S PACKAGE CX M2C E1C DC,TRAIN_NR0033/14,SGT-200-2S,2004-01-06,2007-03-07,2003-01-06,Operating,SGT-200,DZ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
S645/02,6052179,Yıldız Entegre - İzmit,Inegol 1,S645/02,,N/A,SGT-200 PACKAGE M2C DC GB,TRAIN_S645/02,SGT-200-1S,1997-01-01,1997-03-23,1996-01-01,Scrapped,SGT-200,TR,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
HI6037/01,6083355,GLNG - Fairview-HCS-04 South,Fairview 04,HI6037/01,NC0247/01,NC0247/01,SGT-400 (NC0247) C3S M2C E2S DC GB,TRAIN_HI6037/01,SGT-400,2015-05-31,2016-10-29,2011-03-31,Operating,SGT-400,AU,Call off Agreement / Support LTP,ADHOC,I4-008,30,COMPRESSOR
BD000731U05,6682270,Botas - Kuzey Marmara,Kuzey Marmara,BD000731U05,NC0527/05 - BC000294U05,NC0527/05 BC000294?,SGT-400 PACKAGE C3S M1S,TRAIN_BD000731U05,SGT-400,2024-03-01,,2018-12-31,Installation & Commissioning,SGT-400,TR,No Contract or Agreement,ADHOC,I4-008,,COMPRESSOR
80/84028-03,6141333,Dunn Paper,Dunn Paper Port Huron,80/84028-03,,N/A,SGT-200 PACKAGE,TRAIN_80/84028-03,SGT-200-1S,1985-01-01,1986-01-01,1984-01-01,Scrapped,SGT-200,US,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
S129/02,6032505,Dragon LNG UK Ltd - Milford Haven,Milford Haven 2 - 2,S129/02,,N/A,SGT-200 PACKAGE GB,TRAIN_S129/02,SGT-200-1S,1992-01-01,1993-03-24,1990-01-01,Scrapped,SGT-200,GB,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M359/06,219132,NYCDEP - Wards Island,New York City Department of Environmental Protection Hunts Point,M359/06,9100/080,N/A,SGT-100 PACKAGE CX EX GB,TRAIN_M359/06,SGT-100-1S,1993-01-01,1995-01-19,1992-01-01,Operating,SGT-100,US,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
R131/02,6000402,TC Energy - CDN Mainline - Kirkwall Station 1301,TC Energy CDN Mainline Kirkwall Stn.1301A1,R131/02,9080/100,N/A,SGT-200-2S PACKAGE CX EX,TRAIN_R131/02,SGT-200-2S,1992-09-17,1993-09-16,1991-01-01,Operating,SGT-200,CA,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NR0033/08,6053151,Sonatrach TRC - OZ2,OZ2,NR0033/08,R3D8/08,N/A,SGT-200-2S PACKAGE CX M2C E1C DC,TRAIN_NR0033/08,SGT-200-2S,2004-01-06,2007-03-07,2003-01-06,Operating,SGT-200,DZ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
HI6094/01,6690202,PETRONAS Carigali Sdn Bhd - Sarawak Operations - E11P-B PLATFORM,Petronas Kasawari,BD000853U01,NW0543/01,NW0543/01 BD000853,SGT-300 PACKAGE (LCN NW0543) CX,TRAIN_HI6094/01,SGT-300-1S,2024-01-01,2026-12-30,2019-12-01,Installation & Commissioning,SGT-300,MY,,,I4-021,,GENERATOR
W3B9/01,6055228,"Shell Hazira, LNG Terminal, Surat",Shell Hazira 1,W3B9/01,NW0032/01,N/A,SGT-300 PACKAGE C2S M1C E1C DS GB,TRAIN_W3B9/01,SGT-300,2005-08-06,2006-08-16,2002-08-01,Operating,SGT-300,IN,Other and 24/7 Global Helpdesk and/or RDS included,ADHOC,I4-021,10,GENERATOR
A2A1/01,6055782,Single Buoy Moorings - FPSO Falcon,SBM Falcon,A2A1/01,,N/A,SGT-400 PACKAGE <C GB,TRAIN_A2A1/01,SGT-400,2004-04-02,2005-04-02,2003-04-02,Not Running – Not Preserved,SGT-400,SG,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
R2E3/02,6053142,Sonatrach TRC - OZ2,OZ2,R2E3/02,,N/A,SGT-200-2S PACKAGE M2C E1C DC 2002,TRAIN_R2E3/02,SGT-200-2S,2004-01-01,2006-03-29,2001-09-19,Operating,SGT-200,DZ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NU0029/03,6045185,ENAGAS DENIA,Enagas Denia,NU0029/03,,N/A,SGT-100-2S PACKAGE C2S M1C E1C DS GB,TRAIN_NU0029/03,SGT-100-2S,2014-09-26,2015-09-25,2006-11-01,Operating,SGT-100,ES,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
S432/02,6049236,Sahinler Enerji,Sahinler Plant 2,S432/02,,N/A,SGT-200 PACKAGE GB,TRAIN_S432/02,SGT-200-1S,1995-01-01,1995-08-16,1994-01-01,Scrapped,SGT-200,TR,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NA0172/02,6108649,Restricted Location - RNA (AMENTUM),SITI H&A,NA0172/02,4055,HI4055,SGT-400 (HOU HI4055) C3S M2C E2S DC GB,TRAIN_NA0172/02,SGT-400,2013-03-26,2018-03-25,2009-05-01,Operating,SGT-400,US,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
W3B2/01,6048910,Qatar Petroleum - Halul Island,Hallul Island 1,W3B2/01,,N/A,SGT-300 PACKAGE C3C M2C E2S DC,TRAIN_W3B2/01,SGT-300,2001-01-01,2005-03-30,2000-09-01,Not Running – Preserved,SGT-300,QA,Call Off Contract Goods and Services,ADHOC,I4-021,10,GENERATOR
HI6081/02,6695640,"Esentia Pipeline de La Laguna, S. de R.L. de C.V",La Laguna,HI6081/02,NC0530/02,NC0530/02 BD000751,SGT-400 PKG (LCN NC0530) M2C EX DC C3S,TRAIN_HI6081/02,SGT-400,2022-02-28,2023-01-30,2019-03-01,Operating,SGT-400,MX,Preventive LTP – Includes RDS,LTP,I4-008,,COMPRESSOR
S921/01,6032482,De La Rue - Overton Mill,De La Rue,S921/01,,N/A,SGT-200 C1S M2C E1C DC GB,TRAIN_S921/01,SGT-200-1S,1989-08-18,1990-08-17,1988-08-18,Scrapped,SGT-200,GB,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
HI6067/01,6553364,Energy Assets Do Brasil Ltda - Coari,Coari Guascor,HI6067/01,NA0502/01 - BD000518U01,NA0502/01 BD000518,SGT-400 (LCN NA0502) C3C M2S EX DS GB HC,TRAIN_HI6067/01,SGT-400,2019-07-01,2020-06-30,2017-11-30,Operating,SGT-400,BR,Preventive LTP – Includes RDS,LTP,I4-008,,GENERATOR
NW0044/01,6009680,UNH EMCOR,UNIVERSITY OF NEW HAMPSHIRE,NW0044/01,,HI4032 1405032,SGT-300 (HOU HI4032) C3S M1C E1C DS GB,TRAIN_NW0044/01,SGT-300,2006-02-01,2007-07-12,2004-08-12,Operating,SGT-300,US,Corrective LTP,LTP,I4-021,10,GENERATOR
NS0125/02,6046465,Donau Chem,ZNGT Romania,NS0125/02,,N/A,SGT-200 PACKAGE,TRAIN_NS0125/02,SGT-200-1S DLE,2014-04-27,2015-04-26,2008-07-01,Not Running – Not Preserved,SGT-200,RO,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M0B1/01,6044335,Caspian Pipeline Consortium (CPC-R) - APS4A,CPC APS4-A,M0B1/01,,N/A,SGT-100 PACKAGE C3S M2C E1C DC GB,TRAIN_M0B1/01,SGT-100-1S,2000-03-08,2001-03-08,1999-03-08,Operating,SGT-100,RU,Frame Agreement – Includes RDS,ADHOC,I4-002,10,GENERATOR
HI4067/04,6083349,GLNG - Fairview-HCS-04 South,Fairview 04,HI4067/04,NA0246/04,NA0246/04,SGT-400 (LCN NA0246) C1S M1C E1C GB,TRAIN_HI4067/04,SGT-400,2015-08-31,2016-10-29,2011-03-31,Not Running – Preserved,SGT-400,AU,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
NJ0481/01,6664664,Centrax Ltd.,Centrax 017 (Florim),NJ0481/01,BD000424U01,BD000424,SGT-300-2S PACKAGE  CX EX HP,TRAIN_NJ0481/01,SGT-300-2S,2017-09-12,2018-09-11,2016-11-30,Operating,SGT-300,GB,Corrective LTP – Includes RDS,LTP,I4-022,,GENERATOR
BD000042U02,6104689,VCNG Power,VCNG Power,BD000042U02,NA0242/02,NA0242/02,SGT-400(LCN NA0242) C3C M2C E2S DC GB HC,TRAIN_BD000042U02,SGT-400,2016-02-07,2018-02-06,2011-01-01,Operating,SGT-400,RU,Preventive LTP,LTP,I4-008,10,GENERATOR
S510/01,6044480,Cisa Cerdisa,Cisa Cerdisa Richetti,S510/01,,N/A,SGT-200 C3S M2C EX DC GB,TRAIN_S510/01,SGT-200-1S,1995-12-01,1996-11-14,1994-12-01,Operating,SGT-200,IT,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M8C6/01,6045140,Total E&P -  OML58 (Obite / Obagi),Obite TGB,M8C6/01,,N/A,SGT-100 C2S M1S EX EX GB,TRAIN_M8C6/01,SGT-100-1S,1998-01-01,1999-02-15,1997-03-01,Operating,SGT-100,NG,Resident Engineer,ADHOC,I4-002,10,GENERATOR
NA0564/02,6700704,21st Century Technologies Ltd,21st Century Technologies - IKEJA,NA0564/02,BD001084U02,BD001084 (EX BD000346),SGT-400 PACKAGE (BD001084) M1S,TRAIN_NA0564/02,SGT-400,2024-08-01,2025-07-30,2022-01-31,Order,SGT-400,NG,,,I4-008,,GENERATOR
NM0082/01,6138002,Foroozan - Compressor station,Foroozan Platform,NM0082/01,,N/A,SGT-100 PACKAGE - STORAGE GB,TRAIN_NM0082/01,SGT-100-1S,,,2004-01-01,Not Running – Preserved,SGT-100,IR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
A0C4/03,6141712,National Iranian Gas Company (NIGC) (HQ),Tabriz 3,A0C4/03,,N/A,SGT-400 PACKAGE C3S M2S E1C DS <GB,TRAIN_A0C4/03,SGT-400,2001-12-08,2002-12-08,2000-12-08,Operating,SGT-400,IR,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
W615/01,6046701,Kale Seramik-Canakkale,Canakkale 2,W615/01,,N/A,SGT-300 PACKAGE C3S M2C DC GB HP,TRAIN_W615/01,SGT-300,1997-06-01,1999-04-03,1996-06-01,Operating,SGT-300,TR,Corrective LTP – Includes RDS,LTP,I4-021,10,GENERATOR
M9D3/01,6044347,Cefla-Oxon,Cefla - Oxon,M9D3/01,,N/A,SGT-100 PACKAGE C2S M1C E1C DS GB,TRAIN_M9D3/01,SGT-100-1S,1999-01-01,2000-06-29,1998-01-01,Operating,SGT-100,IT,Corrective LTP,LTP,I4-002,10,GENERATOR
NW0040/01,6047973,Occidental Oman - Wadi Latham,Occidental Oman - Wadi Latham,NW0040/01,,N/A,SGT-300 PACKAGE C3S M1C E2S DS GB,TRAIN_NW0040/01,SGT-300,2004-09-01,2005-05-14,2003-09-01,Operating,SGT-300,OM,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
M9J6/01,6032824,CARBERY FOOD INGREDIENTS LTD (CORK),Carbery,M9J6/01,,N/A,SGT-100 PACKAGE C4S M2C E1C GB DC HS,TRAIN_M9J6/01,SGT-100-1S,2001-01-01,2001-12-31,2000-01-01,Operating,SGT-100,IE,Corrective LTP – Includes RDS,LTP,I4-002,10,GENERATOR
M359/01,219125,GreenField Ethanol,,M359/01,,N/A,SGT-100 PACKAGE RETIRED GB,TRAIN_M359/01,SGT-100-1S,1993-01-01,1994-08-27,1992-01-01,Not Running – Not Preserved,SGT-100,CA,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M0D9/03,6142039,National Iranian Oil Company (NIOC) HQ,Balal,M0D9/03,,N/A,SGT-100 PACKAGE CX M1C E1C DS GB,TRAIN_M0D9/03,SGT-100-1S,2004-03-01,2005-03-02,2003-03-01,Operating,SGT-100,IR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NA0014/01,6050378,Erlanger Stadtwerke AG - Erlangen,Erlangen (LTP),NA0014/01,9170/049,N/A,SGT-400 PACKAGE C3S M2C E2S DC GB,TRAIN_NA0014/01,SGT-400,2004-06-24,2007-05-12,2003-12-01,Operating,SGT-400,DE,Corrective LTP – Includes RDS,LTP,I4-008,10,GENERATOR
BF000126U04,6680141,Energean Oil & Gas FPSO - Karish and Tanin Fields,Energean Karish Tanin FPSO,BF000126U04,NA0522/04,NA0522/04,SGT-400 PKG (LCN SCOPE NA0522) C3S EX GB,TRAIN_BF000126U04,SGT-400,2022-06-23,2023-06-22,2018-07-31,Operating,SGT-400,IL,Other and 24/7 Global Helpdesk and/or RDS included,ADHOC,I4-008,,GENERATOR
BF000078U07,6553248,Bid Boland,Bid Boland II (Acid compressor train),BF000078U02,NC0452/02,NC0452/02,SGT-400 PKG (Lincoln Scope NC0452) CX EX,TRAIN_BF000078U07,SGT-400,,,2016-01-31,Operating,SGT-400,IR,No Contract or Agreement,ADHOC,I4-008,,COMPRESSOR
BD000244U02,6552739,Nawara OMV (GTP/CPF),OMV Nawara Gas Field - CPF,BD000244U02,NW0424/02,NW0424/02,SGT-300 PACKAGE (LCN SCOPE NW0424) C3SEX,TRAIN_BD000244U02,SGT-300,2020-03-18,2022-03-17,2015-09-04,Operating,SGT-300,TN,Other and 24/7 Global Helpdesk and/or RDS included,ADHOC,I4-021,,GENERATOR
NC0116/01,6044243,Burullus (Rashpetco),Burullus Phase V,NC0116/01,NC0116/01,N/A,SGT-400 C3S M2C E2S DC GB HP,TRAIN_NC0116/01,SGT-400,2009-08-11,2010-08-10,2008-01-01,Operating,SGT-400,EG,Preventive LTP – Includes RDS,LTP,I4-008,30,COMPRESSOR
W772/01,6141719,NIPC (HQ),KPC,W772/01,,N/A,SGT-300 PACKAGE C2S M2C DC GB,TRAIN_W772/01,SGT-300,1998-01-01,1999-07-01,1996-12-01,Operating,SGT-300,IR,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
NC0016/01,6134886,National Iranian Oil Company (NIOC),South Pars Phase 6,NC0016/01,,N/A,SGT-400 PACKAGE CX M2C E1C DC GB,TRAIN_NC0016/01,SGT-400,2005-08-01,2008-11-28,2004-01-01,Operating,SGT-400,IR,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
NA0514/01,6678691,Centrax Ltd.,Centrax 018 (Arlenxeo),NA0514/01,BD000562U01,BD000562 NK0520,SGT-400 PACKAGE CX GB E,TRAIN_NA0514/01,SGT-400,2018-11-30,2019-11-29,2018-01-31,Operating,SGT-400,GB,Corrective LTP – Includes RDS,LTP,I4-008,,GENERATOR
BD000296U01,6553200,Sichuan Green Energy Company,Sichuan Nanchong,BD000296U01,NA0441/01,NA0441/01,SGT-400 PACKAGE <C GB,TRAIN_BD000296U01,SGT-400,2018-09-30,2019-08-30,2016-02-01,Not Running – Preserved,SGT-400,CN,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
NU0202/01,6052878,Sonatrach DP - Rhoude Nouss,Rhoude Nouss,NU0202/01,,N/A,SGT-100-2S PACKAGE C3S< M2C E1C DC GB,TRAIN_NU0202/01,SGT-100-2S,2015-02-20,2017-02-19,2010-03-01,Storage,SGT-100,DZ,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
M359/03,219129,NYCDEP - Wards Island,New York City Department of Environmental Protection Hunts Point,M359/03,9100/063,N/A,SGT-100 PACKAGE C2S EX GB,TRAIN_M359/03,SGT-100-1S,1993-01-01,1994-11-03,1992-01-01,Operating,SGT-100,US,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NR0107/06,6038154,Transportadora Associada de Gás S/A - TAG - Ecomp de Aracruz,SITI - GASENE,NR0107/06,HI6017/06,HI6017 1550017,SGT-200 PACKAGE C2S M2S E2S DS GB,TRAIN_NR0107/06,SGT-200-2S,2010-09-30,2011-09-29,2007-05-01,Operating,SGT-200,BR,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NU0213/01,6044318,Caspian Pipeline Consortium (CPC-R) - Komsomolsk,CPC Komsomolsk,NU0213/01,,N/A,SGT-100-2S PACKAGE C3S M2C E2S DC GB,TRAIN_NU0213/01,SGT-100-2S,2014-04-10,2016-04-09,2010-05-01,Operating,SGT-100,RU,Frame Agreement – Includes RDS,ADHOC,I4-003,30,COMPRESSOR
NS0191/01,6056052,ZNGT - Zouping COG,Zouping COG--ZNGT,NS0191/01,NS0232/01,N/A,SGT-200 PACKAGE GB,TRAIN_NS0191/01,SGT-200-1S,2011-07-13,2012-07-12,2010-10-31,Not Running – Not Preserved,SGT-200,CN,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NR0035/01,6134888,Salman Flash Gas,Salman,NR0035/01,,N/A,SGT-200-2S PACKAGE  C1S,TRAIN_NR0035/01,SGT-200-2S DLE,,,2003-07-24,Storage,SGT-200,IR,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NC0029/04,6054945,ONGC B&S BCPB2 Platform,Oil and Natural Gas Corporatio,NC0029/04,,N/A,SGT-400 PACKAGE C3S M2C E2S DC GB,TRAIN_NC0029/04,SGT-400,2008-02-29,2009-02-28,2006-05-01,Operating,SGT-400,IN,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
S8J4/01,6032812,UCC - Dow Silicones Barry,Dow Corning 2,S8J4/01,,N/A,SGT-200 PACKAGE C2S M2C EX DC GB,TRAIN_S8J4/01,SGT-200-1S,1998-01-01,2000-04-28,1997-11-01,Operating,SGT-200,GB,Corrective LTP,LTP,I4-005,10,GENERATOR
A3B0/01,6142698,Floaters SPA - FPSO Mystras,,A3B0/01,,N/A,SGT-400 PACKAGE C3S M1C GB,TRAIN_A3B0/01,SGT-400,2005-09-08,2006-09-08,2004-09-08,Not Running – Not Preserved,SGT-400,NG,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
HI6051/05,6437664,NUVOIL S.A. DE C.V.,TREN 5,HI6051/05,NC0375/05,NC0375/05,SGT-400 (LCN NC0375) C3S M2S E2S DS GB,TRAIN_HI6051/05,SGT-400,2016-08-14,2017-08-13,2013-09-30,Operating,SGT-400,MX,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
BD000291U01,6490638,"Mayak-Energy, LLC",Mayak Penza,BD000291U01,NW0385/01 / NW0438/01,NW0438/01,SGT-300 PKG (Lncln Scope NW0438) EX C3S,TRAIN_BD000291U01,SGT-300,2018-03-31,2019-02-27,2014-02-28,Operating,SGT-300,RU,No Contract or Agreement,ADHOC,I4-021,,GENERATOR
80/84031-01,6032834,Heinzmann UK Ltd.,Diageo,80/84031-01,,N/A,SGT-200 PACKAGE GB,TRAIN_80/84031-01,SGT-200-1S,1985-04-04,1986-04-04,1984-04-04,Not Running – Not Preserved,SGT-200,GB,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
S827/01,6044200,Talisman Energy-Norway-Gyda Field,Bp Norway,S827/01,,N/A,SGT-200 PACKAGE GB,TRAIN_S827/01,SGT-200-1S,1990-12-09,1991-04-01,1987-04-01,Scrapped,SGT-200,NO,No Contract or Agreement,ADHOC,I4-005,,GENERATOR
R9M5/01,6045249,ENI E&P,Agip Cervia K,R9M5/01,,N/A,SGT-200-2S PACKAGE C2S,TRAIN_R9M5/01,SGT-200-2S,2000-02-15,2002-08-28,1999-02-15,Not Running – Not Preserved,SGT-200,IT,Frame Agreement – Includes RDS,ADHOC,I4-006,30,COMPRESSOR
NR0104/06,6038189,Petróleo Brasileiro S/A - Petrobras - G&E/UN-APGN/UTGC (Fase III),SITI - CACIMBAS PHASE III,NR0104/06,HI6016 - 1550016,HI6016 1550016,SGT-200 PACKAGE M1C E1C DS GB 2007,TRAIN_NR0104/06,SGT-200-2S,2010-11-15,2011-11-14,2007-03-01,Operating,SGT-200,BR,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
R2E5/05,6053143,Sonatrach TRC - OZ2,OZ2,R2E5/05,,N/A,SGT-200-2S PACKAGE M2C E1C DC 2002,TRAIN_R2E5/05,SGT-200-2S,2002-09-19,2006-03-29,2001-09-19,Operating,SGT-200,DZ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
S561/01,6046493,IPSA-Newcastle-East Lancs Paper,Ex. EAST LANCS PAPER 11,S561/01,,N/A,SGT-200 PACKAGE GB,TRAIN_S561/01,SGT-200-1S,1996-01-01,1996-10-27,1995-02-01,Not Running – Preserved,SGT-200,ZA,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NS0125/01,6046464,Donau Chem,ZNGT Romania,NS0125/01,,N/A,SGT-200 PACKAGE,TRAIN_NS0125/01,SGT-200-1S DLE,2014-04-26,2015-04-25,2008-07-01,Not Running – Not Preserved,SGT-200,RO,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
BD000135U02,6435827,Halfaya Oil Field (PetroChina),Halfaya - Phase 2,BD000135U02,NA0353/02,NA0353/02,SGT-400 PKG(LCN NA0353) CX M2S E2S DS GB,TRAIN_BD000135U02,SGT-400,2017-04-30,2019-04-29,2013-09-30,Operating,SGT-400,IQ,Frame Agreement,ADHOC,I4-008,10,GENERATOR
NW0237/02,6104636,ATP Cheviot,ATP Cheviot,NW0237/02,,,,,SGT-300,2015-12-30,2018-12-29,2011-05-01,Scrapped,SGT-300,GB,No Contract or Agreement,ADHOC,,,
M031/01,6696745,GreenField Ethanol,,M031/01,,,,,SGT-100 DLE,,,,Operating,SGT-100,CA,,,,,
BD001194U01,6704649,New Fortress Energy - Gulf of Mexico (US),FAST LNG PHASE 3,BD001194U01,NA0577/01,,,,SGT-400,,,2022-12-01,Order,SGT-400,US,No Contract or Agreement,ADHOC,,,
BD001194U02,6704650,New Fortress Energy - Gulf of Mexico (US),FAST LNG PHASE 3,BD001194U02,NA0577/02,,,,SGT-400,,,2022-12-01,Order,SGT-400,US,No Contract or Agreement,ADHOC,,,
M619/01,6054433,ALEMBIC LIMITED,Alembic 2,M619/01,,N/A,SGT-100 PACKAGE C2S GB,TRAIN_M619/01,SGT-100-1S,1996-09-01,1997-09-01,1995-09-01,Scrapped,SGT-100,IN,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NU0574/01,,,,,,,SGT-100-2S PACKAGE (BD001155),TRAIN_NU0574/01,,,,,,,,,,I4-003,,
NA0092/02,6049988,SPDC - Gbaran Ubie,Shell Gbaran Ubie GTG2,NA0092/02,,N/A,SGT-400 PACKAGE C1C M2C E2S DC GB,TRAIN_NA0092/02,SGT-400,2010-06-23,2011-06-22,2006-08-01,Operating,SGT-400,NG,Frame Agreement,ADHOC,I4-008,10,GENERATOR
NJ0450/02,6553265,Rospan International,Rospan,NJ0450/02,BD000316U02,N/A,SGT-300-2S PACKAGE CX M2C E1C DC,TRAIN_NJ0450/02,SGT-300-2S,2021-07-01,2023-06-29,2016-02-01,Operating,SGT-300,RU,Preventive LTP,LTP,I4-022,,COMPRESSOR
M037/01,6032827,Cambridgeshire Health Authority - Addenbrookes Hospital,Addenbrookes Hospital,M037/01,,N/A,SGT-100 PACKAGE C2S GB,TRAIN_M037/01,SGT-100-1S,1992-06-04,1993-06-04,1991-06-04,Scrapped,SGT-100,GB,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M221/01,6051990,R Erikssons Maskiner AB,Värnamo,M221/01,,N/A,SGT-100 PACKAGE - DEACTIVATED GB,TRAIN_M221/01,SGT-100-1S,1992-01-01,1993-01-01,1991-01-01,Not Running – Not Preserved,SGT-100,SE,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M578/01,6054434,ALEMBIC LIMITED,Alembic 1,M578/01,,N/A,SGT-100 PACKAGE C2S GB,TRAIN_M578/01,SGT-100-1S,2015-01-04,2015-04-01,2015-04-01,Scrapped,SGT-100,IN,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NA0134/02,6112059,Dummy Account: Middle East - Syria,EBLA POWER GEN,NA0134/02,,N/A,SGT-400 PACKAGE <C M1C E1C DS GB,TRAIN_NA0134/02,SGT-400,2010-03-16,2010-08-30,2008-05-18,Not Running – Not Preserved,SGT-400,SY,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
NM0360/02,6437666,SAGAR Laxmi,SAGAR Pragati_LAXMI_Export Gas Compressor,NM0360/02,HI6052,N/A,SGT-100 PACKAGE GB,TRAIN_NM0360/02,SGT-100-1S,,,2013-11-30,Storage,SGT-100,IN,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S126/01,6032809,Shell UK - Nelson Platform,Nelson Field 5,S126/01,,N/A,SGT-200 PACKAGE <C M2C E1C DC GB 2021,TRAIN_S126/01,SGT-200-1S,1991-01-01,2014-03-01,1990-01-01,Operating,SGT-200,GB,Frame Agreement,ADHOC,I4-005,10,GENERATOR
M453/01,6142383,,,,,9100/096,SGT-100 PACKAGE - DEACTIVATED,TRAIN_M453/01,,,,,,,,,,I4-002,10,GENERATOR
NS0125/07,6046470,Donau Chem,ZNGT Romania,NS0125/07,,N/A,SGT-200 PACKAGE,TRAIN_NS0125/07,SGT-200-1S DLE,,,2008-07-01,Not Running – Not Preserved,SGT-200,RO,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NC0031/04,6054835,ONGC B&S BCPA2 Platform,OIL AND NATURAL GAS COMMISSION (ONGC),NC0031/04,,N/A,SGT-400 C3S M2C E2S DC GB,TRAIN_NC0031/04,SGT-400,2009-07-27,2010-07-26,2015-03-01,Operating,SGT-400,IN,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
BF000132U02,6680137,"Uzbekistan GTL JV, LLC / OOO",Uchkir_Premium Enerdzhi Uzbekneftegaz,BF000132U02,NC0519/02,NC0519/02,SGT-400 PKG (LCNScope NC0519) C3S M1S EX,TRAIN_BF000132U02,SGT-400,2021-04-30,2023-04-29,2018-06-30,Operating,SGT-400,UZ,Preventive LTP – Includes RDS,LTP,I4-008,,COMPRESSOR
R8C4/01,6184800,Total Austral S.A. - Cañadon Alfa,Canadon Alfa,R8C4/01,S750/01A,N/A,SGT-200 PACKAGE <C M1S EX 2022,TRAIN_R8C4/01,SGT-200-2S,1997-05-22,1998-05-22,1996-01-01,Operating,SGT-200,AR,Other and 24/7 Global Helpdesk and/or RDS are not included,ADHOC,I4-006,30,COMPRESSOR
HI4066/01,6039041,PDVSA - Div Faja - Unidades Moviles Div Faja,PDVSA Mobile Power,HI4066/01,NA0209/01,NA0209/01 HI4057,SGT-400 PACKAGE (NA0209) CANCELLED,TRAIN_HI4066/01,SGT-400,,,2010-03-01,Cancelled,SGT-400,VE,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
M2B7/01,6038123,AmBev S/A - Nova Rio,Ambev Rio,M2B7/01,,N/A,SGT-100 PKG - SPARE CORE NEVER RAN,TRAIN_M2B7/01,SGT-100-1S,2002-09-19,2005-01-23,2001-09-19,Not Running – Not Preserved,SGT-100,BR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
W2B0/02,1021665,iPR Pharmaceuticals,Canovanas 1 & 2,W2B0/02,2001/050,1405004,SGT-300 PACKAGE,TRAIN_W2B0/02,SGT-300,2002-01-01,2005-01-23,2001-01-01,Scrapped,SGT-300,PR,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
M0J1/02,6142076,Pars Oil & Gas - South Pars Imd,South Pars Phase1,M0J1/02,M0J1/02,N/A,SGT-100 PACKAGE CX M1C E1C DS GB,TRAIN_M0J1/02,SGT-100-1S,2002-01-01,2004-06-28,2001-06-01,Operating,SGT-100,IR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S322/01,6032708,Regie du Sieds - Bressuire,Regie du Sieds,S322/01,,N/A,SGT-200 PACKAGE - SOLD TO WOODGROUP,TRAIN_S322/01,SGT-200-1S,1994-01-01,1994-06-23,1992-11-01,Scrapped,SGT-200,GB,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NR0193/01,6145385,Ecopetrol S.A. - Campo Cupiagua,SITI ECOPETROL,NR0193/01,6029GT,HI6029GT,SGT-200 PACKAGE CX M1C E1C DS,TRAIN_NR0193/01,SGT-200-2S,2012-09-14,2013-09-13,2009-11-01,Operating,SGT-200,CO,Frame Agreement,ADHOC,I4-006,30,COMPRESSOR
M326/01,6137999,"AZUVI COGENERACION, S.A.",Azuvi Cogeneracion,M326/01,,N/A,SGT-100 PACKAGE - SOLD GB,TRAIN_M326/01,SGT-100,1993-06-24,1994-06-24,1992-04-01,Scrapped,SGT-100,ES,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NU0027/02,6044135,Botas - Hanak,Botas Petroleum Pipeline Corpo,NU0027/02,,N/A,SGT-100-2S PACKAGE C2S M2C E1C DC GB,TRAIN_NU0027/02,SGT-100-2S,2010-05-30,2012-05-29,2006-03-01,Operating,SGT-100,TR,RDS,ADHOC,I4-003,30,COMPRESSOR
NM0068/01,6054770,"Otsu Paper, Otsu (DIR)",Otsu Paper,NM0068/01,,N/A,SGT-100 PKG(CTRCT LOST)C2S M1C E1C DS GB,TRAIN_NM0068/01,SGT-100-1S,2005-06-08,2006-06-08,2000-10-23,Scrapped,SGT-100,JP,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
U2A3/02,6142731,Single Buoy Moorings - FPSO Falcon,FPSO Falcon,U2A3/02,,N/A,SGT-100-2S PACKAGE <C GB,TRAIN_U2A3/02,SGT-100-2S,2003-01-01,2003-12-14,2001-12-01,Not Running – Not Preserved,SGT-100,SG,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
M8B2/01,6054361,Weizhou W121  - CNOOC China Ltd.,Weizhou Oil Field,M8B2/01,,N/A,SGT-100 PACKAGE C2S EX GB,TRAIN_M8B2/01,SGT-100-1S,1997-12-21,1998-10-14,1997-05-01,Operating,SGT-100,CN,Framework Agreement Goods and Services,ADHOC,I4-002,10,GENERATOR
M2C1/01,6038116,AmBev S/A - Jacareí,Ambev 2 Thru 6,M2C1/01,,N/A,SGT-100 PACKAGE - MOTHBALLED,TRAIN_M2C1/01,SGT-100-1S,2001-09-19,2003-01-10,2001-04-01,Storage,SGT-100,BR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M539/01,6038842,Tecpetrol - Comodoro Rivadavia - El Tordillo,El Tordillo 3,M539/01,,N/A,SGT-100 PACKAGE C2S GB,TRAIN_M539/01,SGT-100-1S,1995-10-05,1996-10-05,1994-10-05,Not Running – Preserved,SGT-100,AR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
HI6053/01,6459626,Akkas Gas Field - KOGAS B.A,Akkas,HI6053/01,NU0388/01,NU0388/01,SGT-100-2S PKG(LCN SCOPE NU0388),TRAIN_HI6053/01,SGT-100-2S,2022-12-31,,2014-01-31,Storage,SGT-100,IQ,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
80/86252-01,216393,AGL - Cherokee LNG Plant,Atlanta Gas Light Cherokee Georgia,80/86252-01,,N/A,SGT-200-2S PACKAGE C3S M1S EX,TRAIN_80/86252-01,SGT-200-2S,1987-09-08,1988-09-08,1986-01-01,Operating,SGT-200,US,Corrective LTP – Includes RDS,LTP,I4-006,30,COMPRESSOR
A0B8/03,6046507,Iraq Mobile,A 4,NA0011/01,A0B8/03,N/A,SGT-400 PACKAGE,TRAIN_A0B8/03,SGT-400,2002-01-08,2003-01-08,2001-01-08,Scrapped,SGT-400,IQ,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
A2B5/01,218713,MATEP,Matep 1,A2B5/01,HOU,1405008,SGT-400(HOU HI4008) C4S M2C E2S DC GB HC,TRAIN_A2B5/01,SGT-400,2003-08-02,2004-08-02,2002-01-02,Operating,SGT-400,US,Corrective LTP – Includes RDS,LTP,I4-008,10,GENERATOR
M0E2/01,6054480,Wenchang Nanhai_West FPSO - CNOOC China Ltd.,Nanhai_West FPSO,M0E2/01,,N/A,SGT-100 PACKAGE C2S M1C E2S DS GB,TRAIN_M0E2/01,SGT-100-1S,2002-01-08,2003-01-08,2001-01-08,Operating,SGT-100,CN,Framework Agreement Goods and Services,ADHOC,I4-002,10,GENERATOR
M368/01,6144873,Bumi Armada Claire FPSO,Griffin Field FPSO (Docs),M368/01,,N/A,SGT-100 PACKAGE - SKID EMPTY,TRAIN_M368/01,SGT-100-1S,1995-08-11,1997-02-11,1994-08-11,Scrapped,SGT-100,MY,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M580/03,6038834,Chevron Argentina S.R.L. (ex-Sullair Argentina S.A.) - El Trapial,San Jorge 3,M580/03,,N/A,SGT-100 PACKAGE C3S M2C DC GB,TRAIN_M580/03,SGT-100-1S,1997-10-01,1998-10-01,1996-10-01,Operating,SGT-100,AR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NC0163/02,6111952,"Dummy Account: Middle East - Iran, Islamic Republic of",Kharg Propane Refridge,NC0163/02,,N/A,SGT-400 PACKAGE M1C E1C DS,TRAIN_NC0163/02,SGT-400,,,2009-04-01,Storage,SGT-400,IR,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
80/86258-01,6038253,Methanex - Punta Arenas - Methanex Cabo Negro,Methanex Cabo Negro,80/86258-01,,N/A,SGT-200 PACKAGE <C M2C E1C DC GB 2021,TRAIN_80/86258-01,SGT-200-1S,1988-04-05,1989-04-05,1986-04-05,Operating,SGT-200,CL,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NR0041/01,6032712,WINGAS Storage UK Ltd. - Saltfleetby,Saltfleetby,NR0041/01,NR0041/01,N/A,SGT-200-2S PACKAGE,TRAIN_NR0041/01,SGT-200-2S,,,2006-05-01,Cancelled,SGT-200,GB,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NW0427/02,6541630,"PAREX RESOURCES COLOMBIA LTD SUCURSAL (BOGOTA, D.C.)",MASA-Ocensa,NW0427/02,HI4088/08,HI4088,SGT-300 PACKAGE HP C3S,TRAIN_NW0427/02,SGT-300,2023-07-01,2024-06-29,2014-12-01,Operating,SGT-300,CO,No Contract or Agreement,ADHOC,I4-021,,GENERATOR
NW0244/03,6150316,Halfaya Oil Field (PetroChina),CNPC,NW0244/03,,N/A,SGT-300 - PACKAGE C3C M2C E2S DC GB,TRAIN_NW0244/03,SGT-300,2013-03-15,2015-03-14,2011-03-16,Operating,SGT-300,IQ,Frame Agreement,ADHOC,I4-021,10,GENERATOR
NC0297/03,6154034,Gas Transmission Co Ltd,Hyundai Engineering,NC0297/03,,N/A,SGT-400 C3C M2S E2S DS GB HC,TRAIN_NC0297/03,SGT-400,2017-07-03,2019-08-02,2012-02-28,Not Running – Preserved,SGT-400,BD,Preventive LTP – Includes RDS,LTP,I4-008,30,COMPRESSOR
NR0033/13,6058269,Sonatrach TRC - OZ2,OZ2,NR0033/13,R3D8/13,N/A,SGT-200-2S PACKAGE CX M2C E1C DC,TRAIN_NR0033/13,SGT-200-2S,2005-01-01,2007-03-01,2003-01-06,Operating,SGT-200,DZ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NA0172/01,6108650,Restricted Location - RNA (AMENTUM),SITI H&A,NA0172/01,4055,HI4055,SGT-400 (HOU HI4055) C3S M2C E2S DC GB,TRAIN_NA0172/01,SGT-400,2012-06-28,2017-06-27,2009-05-01,Operating,SGT-400,US,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
NC0163/01,6111953,"Dummy Account: Middle East - Iran, Islamic Republic of",Kharg Propane Refridge,NC0163/01,,N/A,SGT-400 PACKAGE M2S E1C DS,TRAIN_NC0163/01,SGT-400,,,2009-04-01,Storage,SGT-400,IR,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
A2E6/01,218331,"Iroquois, Boonville",Iroquois Boonville,A2E6/01,HOU,1405015,SGT-400 PACKAGE C4S M2C E2S DC GB HC,TRAIN_A2E6/01,SGT-400,2005-07-08,2006-07-08,2004-07-08,Operating,SGT-400,US,Corrective LTP – Includes RDS,LTP,I4-008,30,COMPRESSOR
M207/01,6032738,Tioxide - Grimsby,Ex Tioxide Grimsby 1,M207/01,,N/A,SGT-100 PACKAGE - SITE SHUT,TRAIN_M207/01,SGT-100-1S,1992-01-01,1993-01-01,1991-01-01,Scrapped,SGT-100,GB,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S424/01,6044838,Dupont-Grand Duchy-Contern,Contern 2,S424/01,9080/137,N/A,SGT-200 PACKAGE GB,TRAIN_S424/01,SGT-200-1S,1994-01-01,1995-05-19,1993-09-01,Scrapped,SGT-200,LU,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
S645/01,6052180,Yıldız Entegre - İzmit,Inegol 3,S645/01,,N/A,SGT-200 PACKAGE M2C EX DC GB,TRAIN_S645/01,SGT-200-1S,1997-01-01,1998-03-23,1996-01-01,Not Running – Not Preserved,SGT-200,TR,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
BD000199U01,6490640,Siba,SIBA,BD000199U01,NW0390/01,NW0390/01,SGT-300 PKG (LCN scope NW0390) C3S EX,TRAIN_BD000199U01,SGT-300,2018-06-06,2019-06-05,2014-05-31,Operating,SGT-300,IQ,Other and 24/7 Global Helpdesk and/or RDS included,ADHOC,I4-021,10,GENERATOR
NC0032/03,6133283,National Iranian Oil Company (NIOC),NAR Compressor Station,NC0032/03,,N/A,SGT-400 PACKAGE C3S M2C E1C DC GB,TRAIN_NC0032/03,SGT-400,2009-12-10,2010-05-30,2006-04-01,Operating,SGT-400,IR,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
R0H6/01,6051100,TotalEnergies E&P Nederland BV,Total E&P Nederland BV K5PK,R0H6/01,9080/367,N/A,SGT-200-2S PACKAGE <C M1C E1C DS GB 2022,TRAIN_R0H6/01,SGT-200-2S,2001-01-01,2004-02-27,2000-11-01,Operating,SGT-200,NL,Frame Agreement – Includes RDS,ADHOC,I4-006,30,COMPRESSOR
S602/01,6055265,"Ishizuka Glass, Iwakura",Ishizuka Glass,S602/01,,N/A,SGT-200 PACKAGE GB,TRAIN_S602/01,SGT-200-1S,1990-01-01,1991-01-01,1989-07-01,Not Running – Not Preserved,SGT-200,JP,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M9D0/01,6054918,"DNP, Mihara","DNP, Mihara Unit #1",M9D0/01,,N/A,SGT-100 PACKAGE - RETIRED GB,TRAIN_M9D0/01,SGT-100-1S,1999-01-01,2002-11-18,1998-01-01,Not Running – Not Preserved,SGT-100,JP,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
BD000106U01,6101047,Engie Solutions Sud Est.,Sochaux PSA,BD000106U01,NA0328/01,NA0328/01,SGT-400(LCN NA0328) C3C M2C E2S DC GB HC,TRAIN_BD000106U01,SGT-400,2014-01-28,2016-01-27,2012-09-30,Operating,SGT-400,FR,Corrective LTP – Includes RDS,LTP,I4-008,10,GENERATOR
A2H3/02,6050098,OPS Angola - FPSO Ngoma - Block 15,N'Goma FPSO,A2H3/02,A2H3/02,N/A,SGT-400 C4S M2R E2S DC GB HC,TRAIN_A2H3/02,SGT-400,2003-01-01,2007-01-07,2001-12-01,Operating,SGT-400,AO,Preventive LTP – Includes RDS,LTP,I4-008,30,COMPRESSOR
R9E0/01,6038103,3R POTIGUAR S.A.,Ubarrana,R9E0/01,,N/A,SGT-200-2S PACKAGE EX,TRAIN_R9E0/01,SGT-200-2S,1998-08-01,1999-10-01,1997-04-01,Not Running – Preserved,SGT-200,BR,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NS0302/01,6154006,OPS Angola - FPSO Ngoma - Block 15,N'Goma FPSO,NS0302/01,,N/A,SGT-200 C1S M2C E1C DC GB,TRAIN_NS0302/01,SGT-200-1S,2015-12-31,2016-11-29,2012-02-01,Operating,SGT-200,AO,Preventive LTP – Includes RDS,LTP,I4-005,10,GENERATOR
NW0041/01,6048323,ARJOWIGGINS LE BOURRAY,Le Bourray,NW0041/01,,N/A,SGT-300 PACKAGE C2S M1C E1C DS GB,TRAIN_NW0041/01,SGT-300,2004-12-01,2005-04-29,2003-12-02,Operating,SGT-300,FR,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
NW0094/01,6055458,"Petronet LNG Ltd., Dahej",Dahej Port Lng 4,NW0094/01,,N/A,SGT-300 PACKAGE C2S M1C E1C DS GB,TRAIN_NW0094/01,SGT-300,2015-09-04,2015-04-08,2015-01-01,Operating,SGT-300,IN,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
M312/01,6054496,Toyota Autobody Inabe,"Toyota Autobodies, Inabe",M312/01,,N/A,SGT-100 PACKAGE,TRAIN_M312/01,SGT-100-1S,1993-01-01,1994-11-28,1992-01-01,Scrapped,SGT-100,JP,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
A0A4/01,6079537,NUVOIL S.A. DE C.V.,BIEP Bulwer Island,A0A4/01,,N/A,SGT-400 PACKAGE C1C GB,TRAIN_A0A4/01,SGT-400,2015-07-02,2015-02-07,1999-04-26,Storage,SGT-400,MX,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
BD000117U01,6258187,Greif,Greif II,BD000117U01,NW0322/01,NW0322/01,SGT-300 PKG (Lincoln Scope NW0322),TRAIN_BD000117U01,SGT-300,,,2012-07-31,Scrapped,SGT-300,TM,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
HI4071/02,6153999,Gas Transmission Co Ltd,GTCL Elanga (HGO / Hyundai Engineering),HI4071/02,NU0298/02,NU0298/02,SGT-1002S(LCN NU0298)C3S M2C E2S DC,TRAIN_HI4071/02,SGT-100-2S,2015-04-11,2017-05-10,2012-02-28,Operating,SGT-100,BD,Preventive LTP – Includes RDS,LTP,I4-003,30,COMPRESSOR
NA0239/01,6089546,Altera-Ocyan - FPSO Cidade de Itajai,SGT Delta,NA0239/01,,N/A,SGT-400 C3C M2C E2S DC GB HC,TRAIN_NA0239/01,SGT-400,2012-11-03,2013-11-02,2010-11-01,Operating,SGT-400,BR,Preventive LTP – Includes RDS,LTP,I4-008,10,GENERATOR
M736/01,6048952,Qatargas - South (Rasgas Alpha - RGA) Offshore Platform,Ras Laffan 6- 2,M736/01,,N/A,SGT-100 PACKAGE C2S EX GB,TRAIN_M736/01,SGT-100-1S,1997-03-19,1998-03-18,1996-08-01,Operating,SGT-100,QA,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NW0146/01,6045251,FPSO Firenze Baleine Field (ex Aquila - Brindisi) - Saipem for ENI Cote d'Ivoire,ENI Floaters Firenze FPSO - Ex Saipem Aquila,NW0146/01,NW0146/01,N/A,SGT-300 C3S M1C E2S DS GB,TRAIN_NW0146/01,SGT-300,,,2008-10-01,Installation & Commissioning,SGT-300,CI,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
HI4087/02,6541633,CFE - El Vizcaino,El Vizcaino,HI4087/02,NA0422/02,NA0422/02,SGT-400 (LCN NA0422) CX M2S E1C DS GB,TRAIN_HI4087/02,SGT-400,2015-06-07,2016-06-06,2015-01-01,Operating,SGT-400,MX,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
NC0291/02,6154031,ONGC - Sagar Samrat  (Mercator),SAGAR SAMRAT ONGC,NC0291/02,BFF00413U02,N/A,SGT-400 PACKAGE C3S M2C E1C DC,TRAIN_NC0291/02,SGT-400,2024-04-01,2025-03-30,2012-02-28,Installation & Commissioning,SGT-400,IN,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
NC0023/01,6133295,Foroozan - Compressor station,Foroozan,NC0023/01,,N/A,SGT-400 PACKAGE <C M2R E1C DS GB,TRAIN_NC0023/01,SGT-400,,,2005-09-01,Storage,SGT-400,IR,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
W2G3/01,6049699,IREN ENERGIA (S.E.I. Cento),SEI - Cento 2,W2G3/01,,N/A,SGT-300 PACKAGE <C M1C E1C GB,TRAIN_W2G3/01,SGT-300,2002-12-27,2004-02-14,2001-12-27,Not Running – Preserved,SGT-300,IT,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
80/85121-01,6055422,"Daqing - PetroChina Daqing Oilfield Co., Ltd  Natural Gas Company",Daqing,80/85121-01,,N/A,SGT-200-2S PACKAGE,TRAIN_80/85121-01,SGT-200-2S,1988-09-05,1988-09-05,1986-09-05,Storage,SGT-200,CN,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
W664/01,6055138,"FFK, Kitakyushu",FFK,W664/01,,N/A,SGT-300 PACKAGE GB,TRAIN_W664/01,SGT-300,1997-04-23,1998-04-23,1995-05-03,Scrapped,SGT-300,JP,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
M467/02,6138018,Dummy Account: Asia Pacific - Japan,Suzuki Kosai,M467/02,,N/A,SGT-100 PACKAGE - SCRAPPED,TRAIN_M467/02,SGT-100-1S,1995-03-07,1996-04-01,1994-04-01,Scrapped,SGT-100,JP,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NR0033/04,6053135,Sonatrach TRC - OZ2,OZ2,NR0033/04,R3D8/04,N/A,SGT-200-2S PACKAGE CX M2C E1C DC,TRAIN_NR0033/04,SGT-200-2S,2004-01-06,2007-03-07,2003-01-06,Operating,SGT-200,DZ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
HI4083/03,6541053,Cenovus Energy Inc. - St. John's,Husky,HI4083/03,NA0403/03,NA0403/03,SGT-400 PACKAGE (EX LCN NA0403)CX M1S GB,TRAIN_HI4083/03,SGT-400,2024-06-01,2025-05-30,2014-06-30,Installation & Commissioning,SGT-400,CA,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
R0H8/02,6046074,"Kosmos-Trident Equatorial Guinea, Inc.",SENDJE CEIBA,R0H8/02,,N/A,SGT-200-2S PACKAGE M1C E1C DS GB 2008,TRAIN_R0H8/02,SGT-200-2S,2001-08-12,2003-05-23,1994-01-01,Operating,SGT-200,GQ,Other and 24/7 Global Helpdesk and/or RDS included,ADHOC,I4-006,30,COMPRESSOR
M2C0/01,6038179,AmBev S/A - Jacareí,UG2,M2C0/01,,1405010,SGT-100 (HOU 1405010) C2S EX GB,TRAIN_M2C0/01,SGT-100-1S,2001-09-19,2002-11-29,2001-04-01,Operating,SGT-100,BR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
80/86202-01,6044277,Cargill - Sas Van Gent,Sas Ven Gent,80/86202-01,,N/A,SGT-200 PACKAGE C1S M1S EX GB 2016,TRAIN_80/86202-01,SGT-200-1S,1988-02-06,1989-02-06,1986-01-01,Operating,SGT-200,NL,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NA0092/03,6049989,SPDC - Gbaran Ubie,Shell Gbaran Ubie GTG3,NA0092/03,,N/A,SGT-400 PACKAGE C3S M2C E2S DC GB,TRAIN_NA0092/03,SGT-400,2010-07-10,2011-07-09,2006-08-01,Operating,SGT-400,NG,Frame Agreement,ADHOC,I4-008,10,GENERATOR
NW0227/01,6104648,TEC RU-1 Belaruskaly,Belaruskali RU-1,NW0227/01,,BD000021,SGT-300 (FSP BD000021) CX M2C E1C DC GB,TRAIN_NW0227/01,SGT-300,2013-01-23,2015-01-22,2010-08-01,Operating,SGT-300,BY,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
NA0206/02,6108616,"Petronet LNG Ltd., Kochi",KOCHI LNG,NA0206/02,,N/A,SGT-400 PACKAGE C3S M2C E1C DC GB,TRAIN_NA0206/02,SGT-400,2013-01-21,2015-01-20,2015-04-01,Not Running – Preserved,SGT-400,IN,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
NC0553/04,6692152,"Kazancompressormash, OJSC / Kazankompressormash, OAO",GPN Zapolyarie Pestsovoye BCS,NC0553/04,BD000950U04,BD000950,SGT-400 PACKAGE CX M1S,TRAIN_NC0553/04,SGT-400,,,2020-09-01,Installation & Commissioning,SGT-400,RU,,,I4-008,,COMPRESSOR
NA0477/01,6664663,Centrax Ltd.,Centrax 013 (Dalkia Croco),NA0477/01,BD000419U01,BD000419,SGT-400 PACKAGE GB,TRAIN_NA0477/01,SGT-400,,,2016-10-31,Storage,SGT-400,GB,No Contract or Agreement,ADHOC,I4-008,,GENERATOR
M318/01,6032816,RWE - Rhodia Oldbury,Rhodia Oldbury,M318/01,,N/A,SGT-100 PACKAGE - SITE SHUT GB,TRAIN_M318/01,SGT-100-1S,1994-01-01,1994-09-10,1992-01-01,Scrapped,SGT-100,GB,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
S138/01,6549908,TC Energy - CDN Mainline - Kirkwall Station 1301,TC Energy CDN Mainline Kirkwall Stn.1301A2,S138/01,9080/120,N/A,SGT-200 PACKAGE C2S EX,TRAIN_S138/01,SGT-200-2S,1993-11-14,1994-11-13,1990-01-01,Operating,SGT-200,CA,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
BC000116U04,6541470,GAZ-SYSTEM S.A. - Rembelszczyzna,Rembelszczyzna,BC000116U04,NR0420/01 - HI6060/01,NR0420/01,SGT-200 PKG (LCN NR0420) C2S M1S EX,TRAIN_BC000116U04,SGT-200-2S,2022-04-02,2023-04-01,2014-12-31,Operating,SGT-200,PL,Preventive LTP,LTP,I4-006,30,COMPRESSOR
BD000067U01,6103792,CrimeaSoda,CrimeaSoda,BD000067U01,NA0269/01,NA0269/01,SGT-400 (LCN NA0269) <C M1C E1C DS GB,TRAIN_BD000067U01,SGT-400,2014-04-30,2015-03-30,2011-08-01,Not Running – Preserved,SGT-400,RU,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
M637/01,6047378,PIRELLI / Plant operated by E.ON Business Solutions S.r.l.,Michelin,M637/01,,N/A,SGT-100 PACKAGE - DEACTIVATED GB,TRAIN_M637/01,SGT-100-1S,1997-08-09,1998-08-09,1996-08-09,Not Running – Not Preserved,SGT-100,IT,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NW0154/03,6108652,Reshadat Sadra,Reshadat Sadra,NW0154/03,,N/A,SGT-300 PACKAGE GB,TRAIN_NW0154/03,SGT-300,,,2009-07-01,Storage,SGT-300,IR,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
BD000110U03,6241646,Ithaca - FPF-1,Ithaca Stella,BD000110U03,NW0329/03,NW0329/03,SGT-300PKG(LINCOLNSCOPENW0329)C3S E1C DS,TRAIN_BD000110U03,SGT-300,2016-06-30,2017-12-30,2013-01-31,Not Running – Not Preserved,SGT-300,GB,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
S381/01,6032819,Cristal Global - Millennium Chemicals Stallingborough,Stallingborough 1,S381/01,,N/A,SGT-200 PACKAGE C2S M2C EX DC GB,TRAIN_S381/01,SGT-200-1S,1995-01-01,1995-05-08,1993-09-01,Operating,SGT-200,GB,,,I4-005,10,GENERATOR
J341/01,6044339,AB Mauri Italy S.p.A. - Casteggio Lieviti S.r.l.,GBI,J341/01,,,,,SGT-100-1S,1993-06-01,1995-01-01,1992-06-01,Scrapped,SGT-100,IT,No Contract or Agreement,ADHOC,,,
NW0237/01,6104640,ATP Cheviot,ATP Cheviot,NW0237/01,,,,,SGT-300,2015-12-23,2018-12-22,2011-05-01,Scrapped,SGT-300,GB,No Contract or Agreement,ADHOC,,,
NA0124/01,6045858,GHANA TEMA POWERVOLTA RIVER AUTHORITY,Ghana Tema Power,NA0124/01,,N/A,SGT-400 PACKAGE C3S M1C EX GB,TRAIN_NA0124/01,SGT-400,2010-04-01,2011-03-31,2007-12-20,Operating,SGT-400,GH,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
NW0114/01,6046659,"GRODNO AZOT, JSC / AO",Grodno Azot,NW0114/01,,N/A,SGT-300 PACKAGE C3S M2C E1C DC GB,TRAIN_NW0114/01,SGT-300,2009-10-01,2011-09-30,2008-02-01,Operating,SGT-300,BY,Preventive LTP,LTP,I4-021,10,GENERATOR
NR0159/02,6038164,Transportadora Associada de Gás S/A - TAG - EComp de Juaruna,SITI Petrobras Coari,NR0159/02,6024,HI6024,SGT-200 PKG(HOU HI6024)C2S M2S E2S DS GB,TRAIN_NR0159/02,SGT-200-2S,2010-11-01,2011-11-29,2009-01-01,Operating,SGT-200,BR,No Contract or Agreement,ADHOC,I4-006,,COMPRESSOR
M606/02,6055069,"Lintec, Kumagaya",Lintec # 2,M606/02,,N/A,SGT-100 PACKAGE GB,TRAIN_M606/02,SGT-100-1S,1996-08-13,1997-08-15,1996-02-15,Not Running – Not Preserved,SGT-100,JP,Frame Agreement,ADHOC,I4-002,10,GENERATOR
HI4067/02,6083347,GLNG - Fairview-HCS-04 South,Fairview 04,HI4067/02,NA0246/02,NA0246/02,SGT-400 (LCN NA0246) C1S M1C E1C GB,TRAIN_HI4067/02,SGT-400,2015-05-31,2016-10-29,2011-03-31,Not Running – Preserved,SGT-400,AU,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
HI6069/01,6664287,Duke Energy Progress - Clemson University,Duke Energy (Clemson) (HI6069),HI6069/01,NA0517/01 - BD000544U01,NA0517/01 BD000544,SGT-400(LCN NA0517) C3C M2C EX DC GB HC,TRAIN_HI6069/01,SGT-400,2020-10-18,2022-10-17,2017-12-31,Operating,SGT-400,US,Corrective LTP – Includes RDS,LTP,I4-008,,GENERATOR
NW0427/01,6541629,Ocensa - Porvenir,,NW0427/01,HI4088/07,HI4088/07,SGT-300 (HI4088/07-09) C3C M1S EX,TRAIN_NW0427/01,SGT-300,2019-05-01,2020-04-30,2014-12-01,Operating,SGT-300,CO,No Contract or Agreement,ADHOC,I4-021,,GENERATOR
NR0033/03,6053145,Sonatrach TRC - OZ2,OZ2,NR0033/03,R3D8/03,N/A,SGT-200-2S PACKAGE CX M2C E1C DC,TRAIN_NR0033/03,SGT-200-2S,2004-01-06,2007-03-07,2003-01-06,Operating,SGT-200,DZ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
W8J1/02,6051172,"Teplarna Kyjov, a.s. (Kyjov)",Tp Kyjov,W8J1/02,,N/A,SGT-300 PACKAGE C2S M2C EX DC GB,TRAIN_W8J1/02,SGT-300,1999-01-01,2000-10-01,1998-01-01,Operating,SGT-300,CZ,Preventive LTP – Includes RDS,LTP,I4-021,10,GENERATOR
80/87324-01,216394,AGL - Macon LNG Plant,Atlanta Gas Light Macon Georgia,80/87324-01,,N/A,SGT-200-2S PACKAGE C3S M1S EX,TRAIN_80/87324-01,SGT-200-2S,1987-01-01,1988-01-01,1986-01-01,Operating,SGT-200,US,Corrective LTP – Includes RDS,LTP,I4-006,30,COMPRESSOR
M364/03,6047525,Mohn media Mohndruck GmbH - Gütersloh,Mohn Media GT3 (LTP),M364/03,9100/070,N/A,SGT-100 PACKAGE C3S M2C E2S DC GB HP,TRAIN_M364/03,SGT-100-1S,1994-01-01,1995-01-04,1993-09-01,Operating,SGT-100,DE,Corrective LTP – Includes RDS,LTP,I4-002,10,GENERATOR
S844/01,6032828,Cargill PLC - Manchester,Cargill 1,S844/01,,N/A,SGT-200 PACKAGE C1S M2C E1C DC HS,TRAIN_S844/01,SGT-200-1S,1989-01-01,1990-05-20,1988-01-01,Operating,SGT-200,GB,Corrective LTP – Includes RDS,LTP,I4-005,10,GENERATOR
S436/01,6046946,Kronospan - Sanem,Sanem,S436/01,9080/151,N/A,SGT-200 PACKAGE CX M2C EX DC GB,TRAIN_S436/01,SGT-200-1S,1995-01-01,1996-04-08,1994-02-01,Operating,SGT-200,LU,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
S125/04,6032693,Shell UK - Nelson Platform,Nelson Field 4,S125/04,,N/A,SGT-200 PACKAGE <C M2C E1C DC 2021,TRAIN_S125/04,SGT-200-1S,1991-01-01,2014-03-01,1990-01-01,Operating,SGT-200,GB,Frame Agreement,ADHOC,I4-005,10,GENERATOR
NA0197/02,6104632,ONGC Assam-Lakwa Site,Assam Renewal Project,NA0197/02,,N/A,SGT-400 PACKAGE CX M2C E2S DC GB,TRAIN_NA0197/02,SGT-400,2015-08-11,2016-08-10,2011-05-01,Operating,SGT-400,IN,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
HI4066/06,6039046,PDVSA - Div Faja - Unidades Moviles Div Faja,PDVSA Mobile Power,HI4066/06,NA0209/06,NA0209/06 HI4057,SGT-400 PACKAGE (NA0209) CANCELLED,TRAIN_HI4066/06,SGT-400,,,2010-03-10,Cancelled,SGT-400,VE,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
M358/01,219128,Hunts Point Wpcp,New York City Department of Environmental Protection Hunts Point,M358/01,,N/A,SGT-100 PACKAGE RETIRED GB,TRAIN_M358/01,SGT-100-1S,1993-01-01,1994-05-16,1992-01-01,Not Running – Not Preserved,SGT-100,US,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
A2E6/02,218333,"Iroquois, Dover",Iroquois Dover,A2E6/02,HOU,1405015,SGT-400 C3S M2C E2S DC GB,TRAIN_A2E6/02,SGT-400,2005-09-11,2006-09-11,2004-09-11,Operating,SGT-400,US,Corrective LTP – Includes RDS,LTP,I4-008,30,COMPRESSOR
M0J1/01,6142077,Pars Oil & Gas - South Pars Imd,South Pars Phase1,M0J1/01,M0J1/01,N/A,SGT-100 PACKAGE CX M1C E1C DS GB,TRAIN_M0J1/01,SGT-100-1S,2002-01-01,2004-06-28,2001-06-01,Operating,SGT-100,IR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M451/02,6043692,Fabrica Boliviana de Ceramica S.R.L. (Faboce Ltda.),,M451/02,,N/A,SGT-100 PACKAGE M2C EX DC GB,TRAIN_M451/02,SGT-100-1S,1994-01-01,1996-04-17,1993-01-01,Not Running – Preserved,SGT-100,BO,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NJ0450/03,6553266,Rospan International,Rospan,NJ0450/03,BD000316U03,N/A,SGT-300-2S PACKAGE C2S M2C E1C DC,TRAIN_NJ0450/03,SGT-300-2S,2021-09-01,2023-08-31,2016-02-01,Operating,SGT-300,RU,Preventive LTP,LTP,I4-022,,COMPRESSOR
R2E4/02,6053141,Sonatrach TRC - OZ2,OZ2,R2E4/02,,N/A,SGT-200-2S PACKAGE M2C E1C DC 2002,TRAIN_R2E4/02,SGT-200-2S,2002-09-19,2006-03-29,2001-09-19,Operating,SGT-200,DZ,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
M033/01,6043464,Amsterdam University,Amsterdam,M033/01,9100/007,N/A,SGT-100 PACKAGE C2S GB,TRAIN_M033/01,SGT-100-1S,1991-04-02,1992-04-02,1990-04-02,Not Running – Not Preserved,SGT-100,NL,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NA0143/01,6159815,Hayan Petroleum Company,Jihar Petrofac,NA0143/01,,N/A,SGT-400 PACKAGE <C M2C E1C DC GB,TRAIN_NA0143/01,SGT-400,2010-10-31,2012-04-30,2008-10-01,Not Running – Preserved,SGT-400,SY,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
80/84040-01,220841,US Gypsum - Oakfield,United Gypsum Oakfield New York,80/84040-01,9080/0885-1,N/A,SGT-200 PACKAGE CX EX GB,TRAIN_80/84040-01,SGT-200-1S,1985-02-09,1986-02-09,1984-02-09,Operating,SGT-200,US,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NU1259/01,6706964,Oleoductos del Valle - Oldelval - Duplicar Plus,Oldelval Duplicar Plus,NU1259/01,BD001257U03,BD001259U01,SGT-100-2S PACKAGE,TRAIN_NU1259/01,SGT-100-2S,2025-09-01,2026-08-30,2023-12-01,Order,SGT-100,AR,,,I4-003,,
HI6094/03,6690204,PETRONAS Carigali Sdn Bhd - Sarawak Operations - E11P-B PLATFORM,Petronas Kasawari,BD000853U03,NW0543/03,NW0543/03 BD000853,SGT-300 PACKAGE (LCN NW0543) CX,TRAIN_HI6094/03,SGT-300-1S,2024-01-01,2026-12-30,2019-12-01,Installation & Commissioning,SGT-300,MY,,,I4-021,,GENERATOR
BF000003U02,6364386,TUBA & ZUBAIR-1,TUBA & ZUBAIR-1,BF000003U02,NC0339/02,NC0339/02,SGT-400 (LCN NC0339) C3S M2S E1C DS GB,TRAIN_BF000003U02,SGT-400,2017-02-05,2018-02-04,2013-03-31,Operating,SGT-400,IQ,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
R8A4/01,6137992,National Iranian Oil Company (NIOC),Sirri Island,R8A4/01,,N/A,SGT-200-2S PACKAGE M2C DC 1998,TRAIN_R8A4/01,SGT-200-2S,1999-06-03,2000-06-03,1998-06-03,Operating,SGT-200,IR,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
S344/01,6079570,La Trobe University - Melbourne,La Trobe University,S344/01,,N/A,SGT-200 PACKAGE GB,TRAIN_S344/01,SGT-200-1S,1993-12-31,1994-06-18,1991-12-31,Not Running – Not Preserved,SGT-200,AU,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NC0116/02,6044244,Burullus (Rashpetco),Burullus Phase V,NC0116/02,NC0116/02,N/A,SGT-400 C3S M2C E2S DC GB HP,TRAIN_NC0116/02,SGT-400,2009-08-11,2010-08-10,2008-01-01,Operating,SGT-400,EG,Preventive LTP – Includes RDS,LTP,I4-008,30,COMPRESSOR
U8K9/01,6038852,Oleoductos del Valle - Oldelval - Chinchinales,Chinchinales,U8K9/01,,N/A,SGT-100-2S PACKAGE C2S MX EX DX,TRAIN_U8K9/01,SGT-100-2S,1998-01-01,2000-08-08,1997-09-01,Operating,SGT-100,AR,No Contract or Agreement,ADHOC,I4-003,30,COMPRESSOR
M304/01,6043604,Dana Petroleum Netherlands B.V. - De Ruyter,,M304/01,,N/A,SGT-100 PACKAGE - SCRAPPED,TRAIN_M304/01,SGT-100-1S,1994-12-01,1995-10-13,1993-12-01,Scrapped,SGT-100,NL,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NA0141/01,6049839,JSC “Severodonetsk Azot Association”,SEVERODONETSK AZOT,NA0141/01,,N/A,SGT-400 PACKAGE M2C E1C DC GB,TRAIN_NA0141/01,SGT-400,2018-11-01,2019-10-31,2008-08-01,Not Running – Not Preserved,SGT-400,UA,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
M504/01,6048801,Qatargas - North Field Bravo (NFB) Offshore Platform,North Field Phase 2- 3,M504/01,,N/A,SGT-100 PACKAGE C2S M1C E2S DS GB,TRAIN_M504/01,SGT-100-1S,1995-11-04,1996-11-04,1994-11-04,Operating,SGT-100,QA,Call Off Contract Goods and Services,ADHOC,I4-002,10,GENERATOR
BD000244U01,6552738,Nawara OMV (GTP/CPF),OMV Nawara Gas Field - CPF,BD000244U01,NW0424/01,NW0424/01,SGT-300 PACKAGE (NW0424)C3S EX HP,TRAIN_BD000244U01,SGT-300,2020-03-18,2022-03-17,2015-09-04,Operating,SGT-300,TN,Other and 24/7 Global Helpdesk and/or RDS included,ADHOC,I4-021,,GENERATOR
W2E7/02,6045151,Total E&P -  OML58 (Obite / Obagi),Obite,W2E7/02,,N/A,SGT-300 PACKAGE C3S M2C E2S DC GB,TRAIN_W2E7/02,SGT-300,2002-01-01,2005-01-23,2001-01-01,Operating,SGT-300,NG,Resident Engineer,ADHOC,I4-021,10,GENERATOR
BD000434U02,6664666,"AECC Gas Turbine (Zhuzhou) Co., Ltd.  (ZNGT)",,BD000434U02,NA0482/02,NA0482/02,SGT-400 PKG(LCNScopeNA0482)C3S M1S EX GB,TRAIN_BD000434U02,SGT-400,2018-09-11,2019-09-10,2016-12-31,Not Running – Preserved,SGT-400,CN,No Contract or Agreement,ADHOC,I4-008,,GENERATOR
M0A9/02,6044333,Caspian Pipeline Consortium (CPC-R) - Kropotkin,CPC Kropotkin,M0A9/02,,N/A,SGT-100 PACKAGE C3S M2C E1C DC GB,TRAIN_M0A9/02,SGT-100-1S,2003-05-03,2004-05-03,2002-05-03,Operating,SGT-100,RU,Frame Agreement – Includes RDS,ADHOC,I4-002,10,GENERATOR
M0A9/01,6044332,Caspian Pipeline Consortium (CPC-R) - Kropotkin,CPC Kropotkin,M0A9/01,,N/A,SGT-100 PACKAGE C3S M2C E1C DC GB,TRAIN_M0A9/01,SGT-100-1S,2002-02-09,2003-02-09,2001-02-09,Operating,SGT-100,RU,Frame Agreement – Includes RDS,ADHOC,I4-002,10,GENERATOR
S129/01,6032504,Dragon LNG UK Ltd - Milford Haven,Milford Haven 2 - 1,S129/01,,N/A,SGT-200 PACKAGE GB,TRAIN_S129/01,SGT-200-1S,1992-01-01,1993-03-17,1990-01-01,Not Running – Not Preserved,SGT-200,GB,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
BF000078U17,6673463,Bid Boland,Bid Boland,BF000078U06,,NC0495/02,SGT-400 PKG (Lincoln Scope NC0495) CX EX,TRAIN_BF000078U17,SGT-400,,,2017-07-31,Operating,SGT-400,IR,No Contract or Agreement,ADHOC,I4-008,,COMPRESSOR
HI6068/01,6666239,Southern California Gas Company-Blythe,SoCalGas Blythe_Worley Parsons,HI6068/01,NJ0518/01 - BD000593U01,NJ0518/01 BD000593,SGT-300-2S PKG ( LCN NJ0518)CX M2C EX DC,TRAIN_HI6068/01,SGT-300-2S,2021-12-31,2022-11-29,2017-12-31,Operating,SGT-300,US,Frame Agreement,ADHOC,I4-022,,COMPRESSOR
M918/01,6142293,Dummy Account: Africa,Ex. Shildon Co Durham,M918/01,,N/A,SGT-100 PACKAGE - SITE SHUT,TRAIN_M918/01,SGT-100-1S,1990-01-01,1996-04-01,1989-01-01,Not Running – Not Preserved,SGT-100,ZA,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NA0206/01,6108617,"Petronet LNG Ltd., Kochi",KOCHI LNG,NA0206/01,,N/A,SGT-400 PACKAGE C3S M2C E1C DC GB,TRAIN_NA0206/01,SGT-400,2013-01-15,2015-01-14,2015-04-01,Not Running – Preserved,SGT-400,IN,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
S039/02,6053075,Harouge Oil Operations - Ghani Field,Farrud - Ghani,S039/02,,N/A,SGT-200 PACKAGE GB,TRAIN_S039/02,SGT-200-1S,1991-01-01,1992-02-25,1990-01-01,Scrapped,SGT-200,LY,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
NC0020/03,6112081,,,,,N/A,SGT-400 PACKAGE Project Cancelled,TRAIN_NC0020/03,,,,,,,,,,I4-008,30,COMPRESSOR
R121/03,6032896,Chrysaor Holdings - Lomond Platform,BG Lomond 1,R121/03,,N/A,SGT-200-2S PACKAGE CX M2C EX DC,TRAIN_R121/03,SGT-200-2S,1992-04-20,1993-04-19,1990-04-01,Operating,SGT-200,GB,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NM0072/01,6053336,Dummy Account: Middle East,Station SP6 - OZ2 Pipeline,NM0072/01,,N/A,SGT-100 PACKAGE - ORDER CANCELLED,TRAIN_NM0072/01,SGT-100-1S,2012-01-01,2013-01-01,2008-01-01,Cancelled,SGT-100,AF,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M0F6/01,6032732,Star Energy - Humbly Grove,Humbly Grove 1,M0F6/01,,N/A,SGT-100 PACKAGE C2S M1C E1C DS GB,TRAIN_M0F6/01,SGT-100-1S,2001-01-01,2002-07-09,2000-06-01,Operating,SGT-100,GB,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
BF000003U04,6364388,TUBA & ZUBAIR-1,,BF000003U04,NC0339/04,NC0339/04,SGT-400 (LCN NC0339) CX M2S E1C DS GB,TRAIN_BF000003U04,SGT-400,2017-03-01,2018-02-28,2013-03-31,Operating,SGT-400,IQ,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
HI6052RT/01,6437669,Larsen & Toubro Infotech Limited-Mumbai,SAGAR Pragatti,HI6052/01 RT,NR0368/01,NR0368/01,SGT-200 (LCN NR0368) C1C M1C E1C,TRAIN_HI6052RT/01,SGT-200-2S,,,2013-09-30,Not Running – Preserved,SGT-200,IN,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
HI6037/05,6083359,GLNG - Fairview-HCS-05 North,Fairview 05,HI6037/05,NC0247/05,NC0247/05,SGT-400 (LCN NC0247) C3C M2C E2S DC GB,TRAIN_HI6037/05,SGT-400,2014-12-31,2016-10-29,2011-03-31,Scrapped,SGT-400,AU,Call off Agreement / Support LTP,ADHOC,I4-008,30,COMPRESSOR
W0G6/01,6045144,Qatar Petroleum - Halul Island,Halul Island 6,W0G6/01,,N/A,SGT-300 PACKAGE C3S M2C E1C DC,TRAIN_W0G6/01,SGT-300,2004-01-01,2005-07-13,2002-06-01,Not Running – Preserved,SGT-300,QA,Call Off Contract Goods and Services,ADHOC,I4-021,10,GENERATOR
BD000151U01,6436229,ZNGT - Aotou,Aotou 01,BD000151U01,NA0369/01,NA0369/01,SGT-400 (LCN NA0369) C3S M2S E2S DS,TRAIN_BD000151U01,SGT-400,2015-08-08,2017-08-07,2013-09-30,Operating,SGT-400,CN,No Contract or Agreement,ADHOC,I4-008,,GENERATOR
M711/01,6032931,Shell UK - Curlew Offshore Platform,Curlew 2,M711/01,,N/A,SGT-100 PACKAGE C2S M1C E1C GB,TRAIN_M711/01,SGT-100-1S,1997-01-01,2014-03-01,1996-09-01,Not Running – Not Preserved,SGT-100,GB,Frame Agreement,ADHOC,I4-002,10,GENERATOR
HI6081/03,6694914,"Esentia Compression Solutions, S. de R.L. de C.V. -  San Juan",Fermaca Kratos - San Juan 2,HI6081/03,NC0530/03 / BD000751U03,NC0530/03 BD000751,SGT-400 PKG (LCN NC0530) C3S M2C EX DC,TRAIN_HI6081/03,SGT-400,2024-02-01,2025-01-30,2019-03-01,Installation & Commissioning,SGT-400,MX,,,I4-008,,COMPRESSOR
M723/01,6048953,Qatargas - South (Rasgas Alpha - RGA) Offshore Platform,Ras Laffan 6- 3,M723/01,,N/A,SGT-100 PACKAGE C2S EX GB,TRAIN_M723/01,SGT-100-1S,2000-01-01,2001-03-24,1996-08-01,Operating,SGT-100,QA,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
M451/01,6043691,Fabrica Boliviana de Ceramica S.R.L. (Faboce Ltda.),,M451/01,,N/A,SGT-100 PACKAGE C2S M2C EX DC GB,TRAIN_M451/01,SGT-100-1S,1994-06-01,1996-04-17,1993-06-01,Not Running – Preserved,SGT-100,BO,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
NC0016/06,6134881,National Iranian Oil Company (NIOC),South Pars Phase 8,NC0016/06,,N/A,SGT-400 PACKAGE CX M2C E1C DC GB,TRAIN_NC0016/06,SGT-400,2005-08-01,2009-07-30,2004-01-01,Operating,SGT-400,IR,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
NC0337/04,6197322,Uz-Kor Chemical,UGCC Akchalak,NC0337/04,,BF000008,SGT400(FSP BF000008)C3C M2S E2S DS GB HC,TRAIN_NC0337/04,SGT-400,2015-07-15,2016-07-14,2012-12-31,Operating,SGT-400,UZ,Preventive LTP – Includes RDS,LTP,I4-008,30,COMPRESSOR
S766/02,6047599,Mrd-Tel Aviv-Haifa Chem Mrd 2,Haifa Chem Mrd 2,S766/02,,N/A,SGT-200 PACKAGE,TRAIN_S766/02,SGT-200-1S,1999-01-04,2000-04-22,1997-04-23,Scrapped,SGT-200,IL,No Contract or Agreement,ADHOC,I4-005,,GENERATOR
R9M5/02,6045250,ENI E&P,Agip Cervia K,R9M5/02,,N/A,SGT-200-2S PACKAGE C2S,TRAIN_R9M5/02,SGT-200-2S,2000-02-15,2002-08-28,1999-02-15,Not Running – Not Preserved,SGT-200,IT,Framework Agreement Goods and Services,ADHOC,I4-006,30,COMPRESSOR
HI6038/01,6104641,XCEL ENERGY - WESCOTT LNG PLANT,XCEL Energy - Wescott Plant Compressor Replacement,HI6038/01,NR0255/01,NR0255/01,SGT-200 (LCN NR0255) C2S M1C E1C DS,TRAIN_HI6038/01,SGT-200-2S,2012-11-15,2013-11-14,2011-04-01,Operating,SGT-200,US,No Contract or Agreement,ADHOC,I4-006,30,COMPRESSOR
NW0039/01,6141710,National Iranian Oil Company (NIOC),Salman Offshore,NW0039/01,,N/A,SGT-300 PACKAGE C2S M1C E1C DS GB,TRAIN_NW0039/01,SGT-300,2011-02-14,2011-06-13,2004-03-01,Operating,SGT-300,IR,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
M519/02,6032777,Veolia - Eli Lilly Company Ltd,Eli Lilly 2,M519/02,,N/A,SGT-100 PACKAGE C3S M2C E2S DC GB,TRAIN_M519/02,SGT-100-1S,1995-01-01,1996-06-06,1994-08-01,Operating,SGT-100,GB,Corrective LTP – Includes RDS,LTP,I4-002,10,GENERATOR
M0D9/01,6142021,National Iranian Oil Company (NIOC) HQ,Balal,M0D9/01,,N/A,SGT-100 PACKAGE C2S M1C E1C DS GB,TRAIN_M0D9/01,SGT-100-1S,2004-03-02,2005-03-02,2003-03-02,Operating,SGT-100,IR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
80/84021-04,6048945,Rak White Cement - Ras Al Khaimah,Rak White Cement Factory,80/84021-04,,N/A,SGT-200 PACKAGE GB,TRAIN_80/84021-04,SGT-200-1S,1986-11-01,1987-11-01,1985-11-01,Not Running – Not Preserved,SGT-200,AE,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M8D7/01,6038132,EnergyWorks do Brasil Ltda - Jacareí - Femsa (Plant fully decommissioned),Energy Works 1,M8D7/01,,N/A,SGT-100 PACKAGE - MOTHBALLED GB,TRAIN_M8D7/01,SGT-100-1S,1998-01-01,1999-04-05,1997-03-01,Scrapped,SGT-100,BR,No Contract or Agreement,ADHOC,I4-002,10,GENERATOR
U0J2/01,6045257,SOCIETA' ADRIATICA IDROCARBURI S.p.A.,Agip Pineto,U0J2/01,,N/A,SGT-100-2S PACKAGE C1S GB,TRAIN_U0J2/01,SGT-100-2S,2002-03-01,2004-05-17,2001-02-09,Not Running – Not Preserved,SGT-100,IT,Framework Agreement Goods and Services,ADHOC,I4-003,30,COMPRESSOR
NA0148/01,6111996,"Kinder Morgan - Gulf LNG - Pascagoula, MS",SITI Gulf LNG,NA0148/01,4052,HI4052,SGT-400 (HOU HI4052) C3S M2S E1C DS GB,TRAIN_NA0148/01,SGT-400,2011-10-06,2013-04-05,2008-08-01,Operating,SGT-400,US,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
NW0043/02,6112075,Dummy Account: Europe - Russia,TOMSKNEFT,NW0043/02,NW0043/02,N/A,SGT-300 PACKAGE Project cancelled,TRAIN_NW0043/02,TEMPEST,2005-10-21,2007-04-21,2004-06-17,Cancelled,SGT-300,RU,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
HI4066/07,6039047,PDVSA - Div Faja - Unidades Moviles Div Faja,PDVSA Mobile Power,HI4066/07,NA0209/07,NA0209/07 HI4057,SGT-400 PACKAGE (NA0209) CANCELLED,TRAIN_HI4066/07,SGT-400,,,2010-03-10,Cancelled,SGT-400,VE,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
M711/02,6032932,Shell UK - Curlew Offshore Platform,Curlew 3,M711/02,,N/A,SGT-100 PACKAGE C2S M1C E1C GB,TRAIN_M711/02,SGT-100-1S,1997-01-01,2014-03-01,1996-09-01,Not Running – Not Preserved,SGT-100,GB,Frame Agreement,ADHOC,I4-002,10,GENERATOR
S139/01,6046496,IQOXE,IQA,S139/01,,N/A,SGT-200 PACKAGE GB,TRAIN_S139/01,SGT-200-1S,1992-01-01,1993-05-28,1991-07-01,Scrapped,SGT-200,ES,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
M726/01,6032481,Solenis plc - Bradford,CIBA Speciality Chemicals Ltd 2,M726/01,M726/01,N/A,SGT-100 PACKAGE C3S <M2C E1C DC GB,TRAIN_M726/01,SGT-100-1S,1997-11-30,1998-11-29,1996-11-30,Operating,SGT-100,GB,Corrective LTP – Includes RDS,LTP,I4-002,10,GENERATOR
NS0451/04,6553253,BP West Nile Delta,BP West Nile Delta,NS0451/04,BD000321U04,N/A,SGT-200 PACKAGE C3S M2C E2S DC GB HP,TRAIN_NS0451/04,SGT-200-1S,2019-08-13,2021-08-12,2016-02-28,Operating,SGT-200,EG,Preventive LTP – Includes RDS,LTP,I4-005,,GENERATOR
W2D7/01,6038737,Cerveceria Polar C.A. - Barcelona - Planta Oriente,Planta Oriente,W2D7/01,,1405016,SGT-300 PACKAGE GB,TRAIN_W2D7/01,SGT-300,2015-01-30,2016-01-30,2002-01-01,Not Running – Preserved,SGT-300,VE,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
NW0228/01,6150829,Kuwait Oil Company - Ahmadi,SAIPEM,NW0228/01,,BD000030,SGT-300PKG(FSPSCOPEBD000030)C3S M1S EX,TRAIN_NW0228/01,SGT-300,2020-01-01,2020-12-30,2010-10-01,Operating,SGT-300,KW,No Contract or Agreement,ADHOC,I4-021,10,GENERATOR
NC0033/01,6039132,PDVSA - Moron - ICO Moron,"ICO, Moron",NC0033/01,HI6011,HI6011 1550011,SGT-400 (HOU HI6011) C1C M2C E1C DC GB,TRAIN_NC0033/01,SGT-400,2009-01-01,2010-03-31,2006-03-01,Not Running – Preserved,SGT-400,VE,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
M618/02,6032492,Boots Company - Nottingham,Boots Company 2,M618/02,,N/A,SGT-100 PACKAGE C3C M2C E1C DC GB HC,TRAIN_M618/02,SGT-100-1S,1997-07-02,1998-07-02,1996-07-02,Operating,SGT-100,GB,Corrective LTP – Includes RDS,LTP,I4-002,10,GENERATOR
NC0144/01,6159512,Foroozan - Compressor station,FOROOZAN,NC0144/01,,N/A,SGT-400 PACKAGE <C M2S E1C DS,TRAIN_NC0144/01,SGT-400,,,2008-12-01,Storage,SGT-400,IR,No Contract or Agreement,ADHOC,I4-008,30,COMPRESSOR
S114/02,6048839,Qatar Petroleum - Doha - Dukhan Power Station,Dukhan Power Station,S114/02,,N/A,SGT-200 PACKAGE GB,TRAIN_S114/02,SGT-200-1S,1991-01-01,1992-06-14,1990-01-01,Not Running – Not Preserved,SGT-200,QA,No Contract or Agreement,ADHOC,I4-005,10,GENERATOR
HI6067/02,6664204,Energy Assets Do Brasil Ltda - Coari,Coari Guascor,HI6067/02,NA0502/02 - BD000518U02,NA0502/02 BD000518,SGT-400 (LCN NA0502) C3C M2S EX DS GB HC,TRAIN_HI6067/02,SGT-400,2019-07-01,2020-06-30,2017-11-30,Operating,SGT-400,BR,Preventive LTP – Includes RDS,LTP,I4-008,,GENERATOR
NW0114/02,6046660,"GRODNO AZOT, JSC / AO",Grodno Azot,NW0114/02,,N/A,SGT-300 PACKAGE C3C M2C E1C DC GB HC,TRAIN_NW0114/02,SGT-300,2009-10-01,2011-09-30,2008-02-01,Operating,SGT-300,BY,Preventive LTP,LTP,I4-021,10,GENERATOR
BD001112U01,,,,,,,SGT-300 PACKAGE (LCN NW0565),TRAIN_BD001112U01,,,,,,,,,,I4-021,,
NC0020/02,6112082,,,,,N/A,SGT-400 PACKAGE Project cancelled,TRAIN_NC0020/02,,,,,,,,,,I4-008,30,COMPRESSOR
R2G2/02,6045747,GRTGaz - Voisines,"GRT Gaz de France, VOISINES",R2G2/02,NR0025/02,N/A,SGT-200-2S PACKAGE C3S M2C E2S DC GB,TRAIN_R2G2/02,SGT-200-2S,2003-01-01,2004-11-13,2001-12-04,Operating,SGT-200,FR,Frame Agreement,ADHOC,I4-006,30,COMPRESSOR
NA0260/02,6157654,Ethos Energy Group Limited,Marubeni Utility Services,NA0260/02,,N/A,SGT-400 PACKAGE C1C GB,TRAIN_NA0260/02,SGT-400,1993-01-03,1994-01-05,1992-07-07,Not Running – Not Preserved,SGT-400,GB,No Contract or Agreement,ADHOC,I4-008,10,GENERATOR
M0J3/03,6045156,Total E&P - FSO Unity,FSO Unity,M0J3/03,,N/A,SGT-100 PACKAGE C2S M1C DS GB,TRAIN_M0J3/03,SGT-100-1S,2002-01-01,2004-03-29,2001-06-01,Operating,SGT-100,NG,Resident Engineer,ADHOC,I4-002,10,GENERATOR
BD001239U01,6703442,Sidi Kerir Basic Chemicals & Petrochemicals- Sidpec SAE,SIDPEC - Korra,BD001239U01,NA0582/01,,,,SGT-400,2025-03-01,,2023-05-01,Order,SGT-400,EG,Preventive LTP – Includes RDS,LTP,,,
NA1256/01,6706959,Centrax Ltd.,Centrax 025 (Cargill Castelmassa),NA1256/01,BD001256U01,,,,SGT-400,2025-10-01,2026-09-29,2023-11-01,Order,SGT-400,GB,,,,,
NA1252/01,6706232,Centrax Ltd.,Centrax 025 (Cogenio Castelmassa),NA1252/01,BD001252U01,,,,SGT-400,2024-10-01,2025-09-29,2023-07-01,Order,SGT-400,GB,,,,,
A0K7/03,6108665,ADNOC Offshore,ABU DHABI,A0K7/03,,,,,SGT-400,2001-09-01,2002-09-01,2000-09-01,Scrapped,SGT-400,AE,No Contract or Agreement,ADHOC,,,
BD001291U01,6703773,Riverton Power Station,Riverton Fast Start Project,BD001291U01,NA1291/01,,,,SGT-400,2025-10-01,2026-09-29,2023-12-01,Order,SGT-400,US,,,,,`;
