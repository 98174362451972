import React, { useEffect, useState } from "react";
import { createFileRoute } from '@tanstack/react-router'
import { DocumentsRoute } from '../../containers/DocumentsRoute'
import { ChatVectorIndexShorthand, DRAWINGS, FSR, RDS, SF } from "src/db";

export const Route = createFileRoute('/_layout-nosidebar/documents')({
  component: Documents,
})

function Documents() {
  const [selectedIndex, setSelectedIndex] = useState<ChatVectorIndexShorthand>(localStorage.getItem('selectedIndex') as ChatVectorIndexShorthand ?? FSR);

  useEffect(() => {
    if (location.pathname === "/salesforce" && selectedIndex !== SF) {
      setSelectedIndex(SF);
    } else if (
      location.pathname === "/documents" &&
      selectedIndex !== FSR
    ) {
      setSelectedIndex(FSR);
    } else if (
      location.pathname === "/drawings" &&
      selectedIndex !== DRAWINGS
    ) {
      setSelectedIndex(DRAWINGS);
    } else if (
      location.pathname === "/rds" &&
      selectedIndex !== RDS
    ) {
      setSelectedIndex(RDS);
    }
  }, [location.pathname]);

  useEffect(() => {
    localStorage.setItem('selectedIndex', selectedIndex);
    setSelectedIndex(selectedIndex);
  }, [selectedIndex]);

  return <DocumentsRoute index={selectedIndex} setSelectedIndex={setSelectedIndex} />
}
